import React, {useState} from "react";
import {ConfigProvider} from "antd";
import ApiTestcaseContent from "./ApiTestcaseContent";
import HeaderTable from "../../../molecules/tables/HeaderTable";
import {isApiAuthority, projectState} from "../../../../recoil/project/projectState";
import {TestButton} from "../../../atoms/Button";
import {useRecoilState} from "recoil";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  helpIconMessageState,
  selectedApiState,
  tabOpenListState
} from "../../../../recoil/api/apiState";
import {loadResponseApi, saveResponseCaseJsonApi, saveResponseJsonApi} from "../../../../api/response/responseApi";
import useAxiosInterceptor from "../../../../axios/axios";
import Loader from "../../../common/Loader";

const ApiResponseContent = ({ openFileDialog, saveJsonApiFailCallback }) => {
  const axios = useAxiosInterceptor();
  const [project] = useRecoilState(projectState);
  const [selectedApi] = useRecoilState(selectedApiState);
  const [, setTabOpenList] = useRecoilState(tabOpenListState);
  const [activeSubTab, setActiveSubTab] = useState("BODY");
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [loading, setLoading] = useState(false);

  const items = [
    {
      key: "HEADER",
      label: <span className="subtab-title">Header</span>,
      children: <HeaderTable />,
    },
    {
      key: "BODY",
      label: <span className="subtab-title">Body</span>,
      children: <ApiTestcaseContent />,
    },
  ];


  const selectFile = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      setHelpIconMessage({
        code: "error",
        message: "파일을 선택하세요.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return { error: true };
    }

    const splitFileName = selectedFile.name.split(".");
    const extension = splitFileName[1];
    if (extension !== 'json') {
      setHelpIconMessage({
        code: "error",
        message: "json 확장자만 업로드 할 수 있습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return { error: true };
    }

    return { selectedFile, extension };
  }

  const handleFileChange = (event, tabType) => {
    const { selectedFile, extension } = selectFile(event);

    if (!selectedFile) {
      return;
    }

    const newFileName = `upload.${extension ?? "json"}`;
    const uploadFile = new File([selectedFile], newFileName, { type: selectedFile.type });

    const params = {
      tabType: tabType,
      file: uploadFile,
      apiId: selectedApi.id,
    }

    setLoading(true);

    saveResponseJsonApi(
      axios,
      params,
      saveJsonApiSuccessCallback,
      (message) => {
        saveJsonApiFailCallback(message);
        setLoading(false);
      });
  };

  const createTestCase = (event) => {
    const { selectedFile, extension } = selectFile(event);

    if (!selectedFile) {
      return;
    }

    const newFileName = `upload.${extension ?? "json"}`;
    const uploadFile = new File([selectedFile], newFileName, { type: selectedFile.type });

    const params = {
      file: uploadFile,
      apiId: selectedApi.id,
    }

    setLoading(true);

    saveResponseCaseJsonApi(
      axios,
      params,
      saveResponseCaseJsonApiSuccessCallback,
      (message) => {
        saveJsonApiFailCallback(message);
        setLoading(false);
      });
  }

  const saveResponseCaseJsonApiSuccessCallback = (response) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseCase: [
              ...tab.responseCase,
              createTestCaseData(response.data.data),
            ],
          };
        }
        return tab;
      });
    })

    setLoading(false);
  }

  const createTestCaseData = (data) => {
    return {
      key: data.key,
      name: data.name,
      responseType: data.responseType,
      defaultResponse: data.defaultResponse,
      createUserId: data.createUserId,
      createUser: data.createUser,
      updateUser: data.updateUser,
      createDate: data.createDate,
      apiId: data.apiId,
      order: data.order,
      search: data.search,
      responseCaseDetail: {
        testCase: data.testCase,
      }
    };
  }

  const saveJsonApiSuccessCallback = (data, tabType) => {
    const params = {
      tabType: tabType,
      apiId: selectedApi.id,
    }

    loadResponseApi(axios, params, loadResponseApiSuccessCallback);
  };

  const loadResponseApiSuccessCallback = (data, tabType) => {
    setLoading(false);

    setTabOpenList((prev) => {
      const existingTab = prev.find((v) => v.id === selectedApi.id);
      if (existingTab) {
        return prev.map((tab) =>
          tab.id === selectedApi.id
            ? {
              ...tab,
              [tabType === "HEADER" ? "responseHeader" : "responseParameter"]: data.data.data,
            }
            : tab,
        );
      }
    });
  };


  return (
    <>
      {loading &&
        <div className="loader-container">
          <Loader/>
        </div>
      }
      <div className="Body7_R g900 mb10">
        API Response Parameter 정보 및 Test 요청 시 응답 받을 Test Case 용
        Sample Data 전문을 관리 합니다.
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#131418",
              itemHoverColor: "#131418",
              itemColor: "#949eb7",
              inkBarColor: "#7c60ff",
            },
            Table: {
              headerColor: "#636c83",
              headerBg: "#ffffff",
              cellPaddingBlock: 0,
              cellPaddingInline: 10,
              cellFontSize: 13,
              borderColor: "#dde2ee",
              headerBorderRadius: 3,
            },
          },
        }}
      >
        <div className="content jc-sb">
          <div>
            {items.map((v) => {
              return (
                <div
                  className={`api-content-subtab ${activeSubTab === v.key}`}
                  onClick={() => setActiveSubTab(v.key)}
                  key={v.key}
                >
                  {v.label}
                </div>
              );
            })}
          </div>
          {
            activeSubTab !== "TESTCASE" &&
              <div>
                <input
                  type="file"
                  id={
                    activeSubTab === "HEADER" ? "fileHeaderInput" : "fileBodyInput"
                  }
                  style={{display: "none"}}
                  onChange={(e) => activeSubTab === "HEADER" ?
                    handleFileChange(e, activeSubTab) :
                    createTestCase(e)
                  }
                />
                {isApiAuthority(project.authority) && (
                  <TestButton
                    className={"ml4 mb8"}
                    text={"Json 업로드"}
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_upload"}
                    onClick={() => openFileDialog(activeSubTab)}
                  />
                )}
              </div>
          }
        </div>
        <div className="table-overflow-auto -true" >
          {
            items.find((value) => {
              return value.key === activeSubTab;
            })?.children
          }
        </div>
      </ConfigProvider>
    </>
  );
};

export default ApiResponseContent;
