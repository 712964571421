import React, {useEffect, useState} from "react";
import {ConfigProvider, Dropdown, Space, Tree} from "antd";
import Image from "../../atoms/Image";
import {selectedUserState, userListGroupByAuthorityState} from "../../../recoil/invite/inviteState";
import {useRecoilState} from "recoil";
import {isApiAuthority} from "../../../recoil/project/projectState";
import {DownOutlined} from "@ant-design/icons";
import {createModifyRequest, deepCopy, findDifferent} from "../../organisms/subSider/ApiTree";
import {useParams} from "react-router-dom";
import {userState} from "../../../recoil/user/userState";
import {alertHelpIcon, alertHelpIconOpenState, helpIconMessageState} from "../../../recoil/api/apiState";

const InviteProjectUserTable = ({
  name,
  userList,
  useInviteUserHandle,
  filteringTreeData,
  expandedKeys,
  setExpandedKeys,
  handleDropdownChangeAuthority,
  project,
}) => {
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const colWidth =
    name === "api" ? ["auto", "15%", "5%"] : ["auto", "20%", "10%"];

  const [activeKey, setActiveKey] = useState("0");
  // 2024.04.05 [shiningtrue]: 관리자
  const isManagerAuth = project.authority;
  const { projectId } = useParams();
  const [user] = useRecoilState(userState);
  const [checkedKey, setCheckedKey] = useState([]);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [userListGroupByAuthority, setUserListGroupByAuthority] = useRecoilState(userListGroupByAuthorityState);


  useEffect(() => {
    if (name === 'user') {
      const id = selectedUser.id ?? selectedUser.guestUserId ?? user.id;
      const users = createUserList();
      defaultExpandedMe(users, id);
      setUserListGroupByAuthority(users);
    }
  }, [userList])

  const createUserList = () => {
    return [
      {
        id: 0,
        title: 'Owner',
        key: '0-0',
        search: true,
        packageYn: true,
        upperId: undefined,
        authority: 'OWNER',
        children: getChildren(
          0,
          user => user.authority === 'OWNER' && user.inviteStatus !== 'PENDING',
        ),
      },
      {
        id: 1,
        title: 'Admin',
        key: '0-1',
        search: true,
        packageYn: true,
        upperId: undefined,
        authority: 'ADMIN',
        children: getChildren(
          1,
          user => user.authority === 'ADMIN' && user.inviteStatus !== 'PENDING',
        ),
      },
      {
        id: 2,
        title: 'Agent',
        key: '0-2',
        search: true,
        packageYn: true,
        upperId: undefined,
        authority: 'AGENT',
        children: getChildren(
          2,
          user => user.authority === 'AGENT' && user.inviteStatus !== 'PENDING'
        ),
      },
      {
        id: 3,
        title: 'User',
        key: '0-3',
        search: true,
        packageYn: true,
        upperId: undefined,
        authority: 'USER',
        children: getChildren(
          3,
          user => user.authority === 'USER' && user.inviteStatus !== 'PENDING'
        ),
      },
      {
        id: 4,
        title: '초대중',
        key: '0-4',
        search: true,
        packageYn: true,
        upperId: undefined,
        authority: 'ANONYMOUS',
        children: getChildren(
          4,
          user => user.inviteStatus === 'PENDING',
        ),
      },
    ];
  }


  const defaultExpandedMe = (userList, id) => {
    const findUser = findGuestUserId(userList, id);
    if (!findUser) return;

    const parent = userList.find(upper => upper.id === findUser.upperId);
    if (!parent) return;

    setExpandedKeys([parent.key]);
    setActiveKey(findUser.guestUserId);
    setCheckedKey([findUser.key]);
    setSelectedUser(findUser);
  }

  const findGuestUserId = (userList, id) => {
    if (userList.length === 0) return undefined;

    const findUser = userList.find(user => {
      if (user.guestUserId) {
        return user.guestUserId === id;
      }
    });

    if (findUser) {
      return findUser;
    }

    for (let i = 0; i < userList.length; i++) {
      if (userList[i].children.length > 0) {
        const foundChild = findGuestUserId(userList[i].children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }

    return undefined;
  }

  /**
   * 2024.04.11 [energysteel]: 각 권한에 해당하는 유저 리스트를 반환
   * @param parentIndex 권한의 key 값 중 마지막 숫자 (0-0, 0-1)
   * @param filter 권한 구분 조건
   * @return {*}
   */
  const getChildren = (parentIndex, filter) => {
    return userList.filter(filter)
      .map((user, index) => (
        {
          title: `${user.guestUserName}`,
          key: `0-${parentIndex}-${index}`,
          guestUserId: user.guestUserId,
          guestUserName: user.guestUserName,
          guestUserEmail: user.guestUserEmail,
          authority: user.authority,
          search: true,
          packageYn: false,
          upperId: parentIndex,
          children: [],
        }
      ));
  }

  /**
   * 2024.04.11 [energysteel]: 좌측 사이드를 통해 Modal을 사용하는 경우
   * @param event onClick Event
   * @param user 유저 정보
   */
  const userSubSideOnClick = (event, user) => {
    setExpandedKeys(prev => {
      return [
        ...prev,
        user.key,
      ]
    });

    // 2024.04.24 [energysteel]: 부모 노드인 경우 return
    if (user.upperId === undefined) {
      return;
    }

    setActiveKey(user.guestUserId);
    setSelectedUser(user);
  };

  /**
   * 영문 대문자 authority를 PascalCase로 변환
   * ex) OWNER -> Owner
   */
  const convertAuthority = (authority) => {
    return (
      authority?.substring(0, 1).toUpperCase() +
      authority?.substring(1).toLowerCase()
    );
  };

  const handleExpand = (expandedKeys) => {
    expandTree(userListGroupByAuthority, expandedKeys);
  }

  const handleOnSelect = (checkedKeys) => {
    setCheckedKey(checkedKeys);
  }


  /**
   * 2024.03.08 [energysteel]: Drag & Drop Event
   * @param info Drag & Drop 상태
   */
  const handleDragAndDrop = (info) => {
    if (user.email === info.dragNode.guestUserEmail) {
      setHelpIconMessage({
        code: "error",
        message: "변경할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);
      return;
    }

    const apis = [...userListGroupByAuthority];
    let copyTreeData = deepCopy(userListGroupByAuthority);

    const dragAuthority = copyTreeData.find(api => api.id === info.dragNode.id)?.title ??
      copyTreeData.find(api => api.id === info.dragNode.upperId).title;

    if (dragAuthority === '초대중') {
      setHelpIconMessage({
        code: "error",
        message: "변경할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);
      return;
    }

    const dropAuthority = copyTreeData.find(api => api.id === info.node.id)?.title ??
      copyTreeData.find(api => api.id === info.node.upperId).title;

    if (dropAuthority === '초대중') {
      setHelpIconMessage({
        code: "error",
        message: "변경할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);
      return;
    }

    let dragNodePos = info.dragNode.pos.split('-').length;
    let dropNodePos = info.node.pos.split('-').length;
    
    if ((dragNodePos === 3 && dropNodePos === 3 &&
         !info.node.dragOver) ||
        (dragNodePos === 3 && dropNodePos === 2 &&
         info.node.packageYn && info.node.dragOver)
    ) {
      // 2024.03.08 [energysteel]: Drag & Drop 실행 이상 발생 시 종료
      if (!dragAndDropAndFailExit(info, copyTreeData)) {
        return;
      }

      copyTreeData = rotateTree(copyTreeData);
      refreshExpanded(copyTreeData, info);
      handleModifyApiOrder(copyTreeData, apis);

      handleDropdownChangeAuthority(projectId, info.dragNode.guestUserId, info.dragNode.authority?.toUpperCase(), info.node.authority?.toUpperCase());
    }
  }

  /**
   * 2024.03.08 [energysteel]: spliceStartIndex에 맞는 위치에 Drag 노드 삽입
   * @param apis 전체 API 데이터
   * @param dragNode Drag 노드 정보
   * @param dropNode Drop 노드 정보
   * @param spliceStartIndex splice 시작 Index
   * @param nodeMoveToTop 최상단 API로 이동하는지 여부
   */
  const insertNodeAtPosition = (apis, dragNode, dropNode, spliceStartIndex, nodeMoveToTop)=> {
    const updatedDragNode = {...dragNode};
    if (nodeMoveToTop) {
      updatedDragNode.key = dropNode.key;
    } else {
      spliceStartIndex++;
    }

    updatedDragNode.upperId = dropNode.upperId;
    apis.splice(spliceStartIndex, 0, updatedDragNode);
  }

  /**
   * 2024.03.08 [energysteel]: Drag & Drop 실행. 이상 있는 경우 함수 종료
   * @param info Drag, Drop 정보
   * @param apis API Tree에 노출되는 API 리스트
   * @returns {boolean}
   */
  const dragAndDropAndFailExit = (info, apis)=> {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let findNodeCallback = findNodeAndCallback();

    // 2024.03.08 [energysteel]: apis 배열에서 Drag 노드 제거 후 반환한 임시 저장 변수
    let dragNode = extractDragNode(findNodeCallback, apis, dragKey);

    // 2024.03.08 [energysteel]: Package 최상단으로 이동 한 경우
    let isNodeMoveToParentTop = !info.dropToGap;
    if (isNodeMoveToParentTop) {
      // 2024.03.08 [energysteel]: Drag 노드를 Parent 노드의 최상위 노드로 이동, 실패 시 함수 종료
      if (nodeMoveToParentTopAndFailExit(findNodeCallback, info, dragNode, apis)) {
        // 2024.03.08 [energysteel]: 잘못된 이동을 한 경우 Drag 노드 원복
        restoreDeletedDragNode(apis, dragNode);
        return false;
      }
    } else {
      // 2024.04.09 [energysteel]: 패키지 하위에 패키지 넣는것을 막음
      if (dragNode.packageYn && info.node.upperId) {
        return false;
      }
      findNodeCallback(apis, dropKey, (dropNode, index, arr) => {
        // 2024.03.08 [energysteel]: 최상단 노드로 이동한 경우
        let nodeMoveToTop = dropPosition === -1;
        insertNodeAtPosition(arr, dragNode, dropNode, index, nodeMoveToTop);
      });
    }

    return true;
  }

  /**
   * 전체 Array에서 Drag 노드를 삭제하고 (Callback), Drag 노드를 반환
   * @param loop 전체 Array에서 대상을 찾는 함수
   * @param arr 전체 Array
   * @param dragKey 전체 Array에서 찾을 대상 Key (Drag 노드)
   * @param #dragNodeDeleteCallback.node Drag 노드
   * @param #dragNodeDeleteCallback.index Array 내의 Drag 노드 Index
   * @param #dragNodeDeleteCallback.arr TreeData
   * @returns {*}
   */
  const extractDragNode = (loop, arr, dragKey) => {
    let dragNode;

    const dragNodeDeleteCallback = (node, index, arr) => {
      arr.splice(index, 1);
      dragNode = node;
    }

    loop(arr, dragKey, dragNodeDeleteCallback);
    return dragNode;
  }

  /**
   * 2024.03.08 [energysteel]: Array에서 Key를 기준으로 Node를 찾은 후 Callback 실행
   * @param #loop.arr 전체 Data
   * @param #loop.key 찾으려는 Node Key
   * @param #loop.callback Node를 찾은 후 수행될 Callback
   * @returns {(function(*, *, *): (*|undefined))|*}
   */
  const findNodeAndCallback = () => {
    const loop = (arr, key, callback) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === key) {
          return callback(arr[i], i, arr);
        }
        if (arr[i].children) {
          loop(arr[i].children, key, callback, arr);
        }
      }
    };
    return loop;
  }

  /**
   * 2024.03.08 [energysteel]: Drag 노드를 Parent 노드의 최상위 노드로 이동
   *  - API Tree Depth는 `Parent - Children` 구조로 2 Depth 까지만 허용
   * @param info Drag Drop 정보
   * @param dragNode Drag 노드
   * @param loop 전체 Array에서 대상을 찾는 함수
   * @param apis 전체 Tree Data
   * @returns {boolean} Package가 Package 하위로 이동 시 false (Drag & Drop 종료)
   */
  const nodeMoveToParentTopAndFailExit = (loop, info, dragNode, apis) => {
    let terminateFlag = true;
    const dropKey = info.node.key;

    // 2024.03.08 [energysteel]: Drop 노드를 찾은 후 Drag 노드를 children으로 등록
    loop(apis, dropKey, (dropNode) => {
      let updatedDropNode = {...dropNode};
      let updatedDragNode = {...dragNode};
      updatedDropNode.children = updatedDropNode.children || [];
        // 2024.03.08 [energysteel]: Package로 이동한 경우 0번 노드에 추가
        // Drag 노드는 패키지일 수 없고, Drop 노드는 패키지여야 추가
        if (updatedDragNode && !updatedDragNode.packageYn && updatedDropNode.packageYn) {
          updatedDragNode.upperId = updatedDropNode.id;
          updatedDragNode.key = `${info.node.pos}-0`;

          updatedDropNode.children.unshift(updatedDragNode);
          terminateFlag = false;
          // 2024.03.08 [energysteel]: Package 하위에 또 다른 Package로써 이동 불가
        } else {
          terminateFlag = true;
        }
      }
    );

    return terminateFlag;
  }

  /**
   * 2024.03.08 [energysteel]: 비정상적인 이동을 한 경우 이미 삭제된 Drag 노드 원상 복구
   *  - if   : Drag 노드가 Children이 아닌 경우
   *    else : Drag 노드가 Children인 경우
   * @param apis 전체 API 목록
   * @param dragNode Drag 노드
   */
  const restoreDeletedDragNode = (apis, dragNode)=> {
    const dragPos = dragNode.key.split("-");
    if (dragPos.length === 2) {
      apis.splice(dragPos.pop(), 0, dragNode);
    } else {
      const preLastValue = dragPos[dragPos.length - 2];
      apis[preLastValue].children.splice(dragPos.pop(), 0, dragNode);
    }
  }

  /**
   * 2024.03.08 [energysteel]: 정렬되어있는 순번을 기준으로 Key 재조립
   * @param apis 전체 API Tree 데이터
   */
  const rotateTree = (apis) => {
    return apis.map((value, index) => {
      const updatedValue = { ...value, key: `0-${index}` };
      if (updatedValue.children && updatedValue.children.length > 0) {
        updatedValue.children = updatedValue.children.map((childValue, childIndex) => {
          return { ...childValue, key: `0-${index}-${childIndex}` };
        });
      }
      return updatedValue;
    });
  }

    /**
     * 2024.03.15 [energysteel]: Tree Data 위치 변경에 따른 확장 대상 Key 변경
   * @param apis API Tree 전체 데이터
   * @param info Drag & Drop 정보
   */
  const refreshExpanded = (apis, info) => {
    let updatedExpandedKeys = apis.filter(api => api.expanded)
      .map(api => api.key);

    if (!info.dropToGap) {
      const api = apis.find(api => api.id === info.node.id);
      updatedExpandedKeys = [...updatedExpandedKeys, api?.key];
    } else {
      const api = apis.find(api => api.id === info.node.upperId)
      if (api) {
        updatedExpandedKeys = [...updatedExpandedKeys, api?.key];
      }
    }

    expandTree(apis, [...new Set(updatedExpandedKeys)]);
  }

  const expandTree = (apis, expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setUserListGroupByAuthority(prev => {
      return apis.map(tree => {
        if (expandedKeys.includes(tree.key)) {
          return {
            ...tree,
            expanded: true,
          }
        }
        return tree;
      })
    })
  }


  /**
   * 2024.03.08 [energysteel]: API Tree Order 변경 Handler
   * @param originalTreeData Tree 원본 데이터
   * @param modifyTreeData Drag & Drop 후 변경 된 데이터
   * @param successCallback 데이터 삭제 시 Callback
   */
  const handleModifyApiOrder = (originalTreeData, modifyTreeData, successCallback = () => {}) => {
    const differentTreeData = findDifferent(originalTreeData, modifyTreeData);
    const modifyApiRequest = createModifyRequest(differentTreeData);

    if (modifyApiRequest.length === 0) {
      return;
    }

    setUserListGroupByAuthority(modifyTreeData);
  }

  const titleRender = (item) => {
    // 2024.04.15 [energysteel]: Dropdown OWNER 패키지가 아닌 경우
    let isNotDropdownOwnerPackage = item.title !== 'Owner';

    return (
      <>
        <div
          className="sider jc-sb"
        >
          <div className={"ellipsis-mw200"}>
            {item.title}
          </div>
          <div className="IconTest">
            {
              isNotDropdownOwnerPackage ?
              (
                <Dropdown
                  menu={
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useInviteUserHandle(
                      item,
                      convertAuthority(item.authority),
                    )
                  }
                  placement="bottomRight"
                  overlayStyle={{
                    width: "156px",
                    height: "114px",
                    borderRadius: 3,
                  }}
                  onClick={(e) => e.stopPropagation()}
                  trigger={["click"]}
                >
                  <Space style={{lineHeight: "16px"}}>
                    <Image
                      className="mb-4"
                      path={`${process.env.PUBLIC_URL}/header`}
                      name={"ic_more"}
                    />
                  </Space>
                </Dropdown>
              )
            :
            <div></div>
            }
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {name === 'user' ?
        <ConfigProvider
          theme={{
            components: {
              Tree: {
                nodeSelectedBg: "#dde2ee",
                nodeHoverBg: "none",
                titleHeight: 32,
              },
            },
            token: {
              colorText: "#636c83",
              colorBgContainer: "#eef1f7",
            },
          }}
        >
          <Tree
            treeData={filteringTreeData(userListGroupByAuthority).filter(tree => tree.title === 'Owner')}
            onClick={userSubSideOnClick}
            height={1080}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            selectedKeys={checkedKey}
            onSelect={handleOnSelect}
            className="draggable-tree"
            switcherIcon={<DownOutlined/>}
            blockNode
            titleRender={titleRender}
          />
          <Tree
            treeData={filteringTreeData(userListGroupByAuthority).filter(tree => tree.title !== 'Owner' && tree.title !== '초대중')}
            onClick={userSubSideOnClick}
            height={1080}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            selectedKeys={checkedKey}
            onSelect={handleOnSelect}
            className="draggable-tree"
            switcherIcon={<DownOutlined/>}
            blockNode
            titleRender={titleRender}
            onDrop={handleDragAndDrop}
            draggable={isApiAuthority(project.authority) ?
              {
                icon: (
                  <Image
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_move"}
                  />
                ),
              } :
              false
            }
          />
          <div className={"dividing-line"}/>
          <Tree
            treeData={filteringTreeData(userListGroupByAuthority).filter(tree => tree.title === '초대중')}
            onClick={userSubSideOnClick}
            height={1080}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            selectedKeys={checkedKey}
            onSelect={handleOnSelect}
            className="draggable-tree"
            switcherIcon={<DownOutlined/>}
            blockNode
            titleRender={titleRender}
          />
        </ConfigProvider>
        :
        <table
          className="table user g500 Body6_R"
          width={"100%"}
          style={{borderSpacing: "0"}}
        >
          <colgroup>
            {colWidth.map((v, i) => (
              <col key={i} width={v}/>
            ))}
          </colgroup>
          <tbody>
            {userList.map((item, i) => {
              return (
                <>
                  {/* 내가 ADMIN이고 리스트 사용자가 OWNER가 아닐 때
                   OR 내가 OWNER이고 리스트 사용자가 OWNER가 아닐 때 */}
                  {(isManagerAuth === "ADMIN") || (isManagerAuth === "OWNER") ? (
                    <Dropdown
                      menu={
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useInviteUserHandle(
                          item,
                          convertAuthority(item.authority),
                        )
                      }
                      placement="bottomRight"
                      overlayStyle={{
                        width: "156px",
                        height: "114px",
                        borderRadius: 3,
                      }}
                      trigger={["click"]}
                    >
                      <tr
                        key={i}
                        height={32}
                        className={item.key === activeKey ? "active" : ""}
                      >
                        <td
                          className={
                            item.key === activeKey && name === "user" ? "bor" : ""
                          }
                          style={{padding: "7px 18px"}}
                        >
                          {item.guestUserEmail}
                        </td>
                        <td>{item.authorityLabel}</td>

                        <td>
                          <Space style={{lineHeight: "16px"}}>
                            <Image
                              path={`${process.env.PUBLIC_URL}/header`}
                              name={"ic_dropdown_down"}
                            />
                          </Space>
                        </td>
                      </tr>
                    </Dropdown>
                  ) : (
                    <tr
                      key={i}
                      height={32}
                      className={item.key === activeKey ? "active" : ""}
                    >
                      <td
                        className={
                          item.key === activeKey && name === "user" ? "bor" : ""
                        }
                        style={{padding: "7px 18px"}}
                      >
                        {item.guestUserEmail}
                      </td>
                      <td>{item.authorityLabel}</td>
                      <td></td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      }
    </>
  );
}
  ;
  export default InviteProjectUserTable;
