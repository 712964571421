import React from "react";

const API = ({ clazz, setManualKey }) =>{
    return (
      <>
        <div className={`${clazz}`}>
            <p>MockBot의 API를 추가하면 HTTP 요청을 포함한 REST API를 만들고 응답값을 받을 수 있습니다.<br/>
                MockBot을 사용하면 엔드포인트로 요청을 보내거나, 데이터 소스에서 데이터를 검색하거나, <br/>서버의 API 기능을 테스트할 수 있습니다.<br/>
                이를 위해서 서비스를 실행시키거나 터미널에 명령을 입력하거나 코드를 작성할 필요가 없습니다. <br/>새 API를 만들고 정보를 등록하면 API 응답이 MockBot을 통해서 바로 전달됩니다.
            </p>
        </div>
      </>
    )
}
export default API;