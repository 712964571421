import React, {useEffect, useState} from "react";
import {ConfigProvider, Input, Layout, Menu} from "antd";
import Image from "../../atoms/Image";
import {useRecoilState} from "recoil";
import {manualKeyState} from "../../../recoil/manual/manualState";
import {useNavigate} from "react-router-dom";

const { Sider: AntdSider } = Layout;

export const MenuGuide = ({ value }) => {
  const [manualKey, setManualKey] = useRecoilState(manualKeyState);
  const [openManualKey, setOpenManualKey] = useState(["0"]);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      const projectId = window.localStorage.getItem("projectId");
      if (projectId) {
        window.localStorage.removeItem("projectId");
      }
    }
  }, [])

  useEffect(() => {
    handleOnClickNextOrPrev(manualKey);
  }, [manualKey]);

  /**
   * 2024.05.16 [energysteel]: 이전, 다음 버튼 클릭으로 이동한 메뉴가 닫힌 Group인 경우 open
   * @param key 이동한 메뉴의 key (ex: 1-1)
   */
  const handleOnClickNextOrPrev = (key) => {
    const selectParentKey = key.split("-")[0];

    setOpenManualKey(prev => {
      const openKey = [...prev];
      if (!prev.includes(selectParentKey)) {
        return [
          ...openKey,
          selectParentKey,
        ]
      }
      else {
        return [
          ...openKey
        ];
      }
    })
  }

  /**
   * 2024.05.16 [energysteel]: 그룹 열고 닫기 Event
   * @param keys 열려있는 그룹
   */
  const handleOnOpenChange = (keys) => {
    setOpenManualKey(keys);
  }
  const handleClick = (key) => {
    setManualKey(key);
  };

  const items = [
    {
      key: "0",
      label: "HOME",
      search: true,
      onClick: () => handleClick("0"), // 클릭 이벤트 핸들러 설정
    },
    {
      key: "1",
      label: "프로젝트",
      search: true,
      children: [
        {
          key: "1-1",
          label: "프로젝트 생성",
          search: true,
          onClick: () => handleClick("1-1"), // 클릭 이벤트 핸들러 설정
        },
      ],
    },
    {
      key: "2",
      label: "APIs",
      search: true,
      children: [
        { key: "2-1", label: "API 추가", search: true, onClick: () => handleClick("2-1") },
        { key: "2-2", label: "URI 등록", search: true, onClick: () => handleClick("2-2") },
        {
          key: "2-3",
          label: "Request 등록",
          search: true,
          onClick: () => handleClick("2-3"),
        },
        {
          key: "2-4",
          label: "Response 등록",
          search: true,
          onClick: () => handleClick("2-4"),
        },
      ],
    },
    {
      key: "3",
      label: "공통 에러",
      search: true,
      children: [
        {
          key: "3-1",
          label: "Response 에러 등록",
          search: true,
          onClick: () => handleClick("3-1"),
        },
        {
          key: "3-2",
          label: "에러 코드 등록",
          search: true,
          onClick: () => handleClick("3-2"),
        },
      ],
    },
    {
      key: "4",
      label: "공통 변수",
      search: true,
      children: [
        {
          key: "4-1",
          label: "사용자변수 등록",
          search: true,
          onClick: () => handleClick("4-1"),
        },
        {
          key: "4-2",
          label: "환경변수 등록",
          search: true,
          onClick: () => handleClick("4-2"),
        },
      ],
    },
  ];

  /**
   * 2024.05.20 [energysteel]: 검색어에 맞는 item의 flag를 true로 설정
   * @return {((*&{search: boolean, children: *})|(*&{search: *, children: *}))[]}
   */
  const searchItems = () => {
    const searchData = value.replace(/ /g, "").toLowerCase(); // 입력된 검색어
    return items.map((item) => {
      let parentIncludedSearch = item.label
        .replace(/ /g, "")
        .toLowerCase()
        .includes(searchData);

      // 2024.04.11 [energysteel]: 부모노드가 조회되었다면 자식 노드까지 조회
      if (parentIncludedSearch) {
        return {
          ...item,
          search: true,
          children: item.children?.map((child) => ({
            ...child,
            search: true,
          })),
        };
      }

      // 2024.04.11 [energysteel]: 자식노드가 조회되었다면 부모 노드까지 조회
      const updatedChildren = item.children?.map((child) => {
        let childIncludedSearch = false;
        const childLabelSearch = child.label
          .replace(/ /g, "")
          .toLowerCase()
          .includes(searchData);

        if (childLabelSearch) {
          childIncludedSearch = true;
          parentIncludedSearch = true;
        }

        return {
          ...child,
          search: childIncludedSearch,
        };
      });

      return {
        ...item,
        search: parentIncludedSearch,
        children: updatedChildren,
      };
    });
  };

  /**
   * 2024.05.20 [energysteel]: search flag가 true인 item을 리턴
   * @return {(boolean|(*&{children: *}))[]}
   */
  const filteringItems = () => {
    return searchItems()
      .map((item) => {
        if (item.search !== true) return false;
        const filteredChildren = item.children?.filter(
          (child) => child.search === true,
        );

        return {
          ...item,
          children: filteredChildren,
        };
      })
      .filter(Boolean);
  };

  return <>
    <Menu
      className="manual-sider-menu"
      mode="inline"
      selectedKeys={[manualKey]}
      openKeys={openManualKey}
      onOpenChange={handleOnOpenChange}
      items={filteringItems()}
    />
    {window.localStorage.getItem("projectId") &&
      <div
        className={"bg-brand500 br4"}
        style={{
          height: "84px",
          width: "72px",
          textAline: "center",
          padding: "25px 0",
          margin: "4px",
          position: "absolute",
          bottom: "0",
        }}
        onClick={() => {
          const projectId = window.localStorage.getItem("projectId");
          if (projectId) {
            navigate(`/project/${projectId}`);
          }
        }}
      >
        <div style={{lineHeight: "20px", textAlign: "center"}}>
          <Image
            path={`${process.env.PUBLIC_URL}/siderbar`}
            name={"ic_info"}
          />
        </div>
        <div className="Body7_R white" style={{textAlign: "center"}}>
          <p>프로젝트로</p>
          <p>이동</p>
        </div>
      </div>
    }
  </>;

};



const Sider = () => {
  const [value, setValue] = useState("");

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemSelectedBg: "#978eff5e",
            itemSelectedColor: "#7c60ff",
            itemBg: "#eef1f7",
            itemHoverColor: "#636c83",
            itemHoverBg: "#eef1f7",
            itemMarginInline: 10,
            subMenuItemBg: "",
          },
        },
        token: {
          lineWidth: 0,
        },
      }}
    >
      <AntdSider className="manual-sider" width="252px">
        <div className="manual-sider-title">사용 가이드</div>
        <Input
          style={{ width: 231, margin: "0 10px", border: "1px solid #dde2ee" }}
          className="search"
          placeholder=""
          onChange={(event) => setValue(event.target.value)}
          prefix={
            <Image
              className="mr9"
              path={`${process.env.PUBLIC_URL}/projectList`}
              name={"ic_search_24"}
              size={"sm"}
            />
          }
        />

        <MenuGuide value={value} />
      </AntdSider>
    </ConfigProvider>
  );
};

export default Sider;
