import {Space, Tooltip} from "antd";
import Image from "../../atoms/Image";
import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {
  alertHelpIconOpenState, helpIconMessageState,
  helpIconState,
  selectedApiState,
  tabOpenListState
} from "../../../recoil/api/apiState";
import {
  commonEnvironmentVariableListState,
  commonResponseFormBodyListState
} from "../../../recoil/project/projectState";

const HelpIcon = ({ setMenuKey, code, text }) => {
  // 2024.04.16 [shiningtrue]: help icon 상태 관리
  const [helpIcon, setHelpIcon] = useRecoilState(helpIconState);
  const [alertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [helpIconMessage] = useRecoilState(helpIconMessageState);
  // 2024.03.07 [energysteel]: 열려있는 Tab 리스트
  const [tabOpenList] = useRecoilState(tabOpenListState);
  // 2024.03.07 [energysteel]: API Tree에서 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.04.16 [shiningtrue]: 공통에러 Response Body List 상태 관리 변수 Header
  const [commonResponseFormBodyRecoilState] = useRecoilState(
    commonResponseFormBodyListState,
  );
  // 2024.04.18 [shiningtrue]: List 환경변수 상태 관리 변수
  const [commonEnvironmentVariableListRecoilState] = useRecoilState(
    commonEnvironmentVariableListState,
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // 2024.04.16 [shiningtrue]: help icon 컨트롤
  useEffect(() => {
    // 2024.04.16 [shiningtrue]: 선택 되어있는 Tab obejct 정보
    let tabItem = tabOpenList.find((item) => item.id === selectedApi.id);
    let iconFlag = true;
    let errorCount = 0; // icon을 error로 표시하기 위함
    let errorMessage = "";
    let helpMessage = "";
    let screenMovement = "";

    // 2024.04.16 [shiningtrue]: 공통에러 Response Body 등록 여부 체크
    if (commonResponseFormBodyRecoilState.length === 0) {
      helpMessage += "공통에러 메뉴의 Response Body를 생성해 주십시오.<br>";
      screenMovement = "commonError";
      iconFlag = false;
    }

    if (tabItem?.uri?.encryptionMethodId) {
      const encryptionMethod = commonEnvironmentVariableListRecoilState.find(
        (item) => item.key === parseInt(tabItem?.uri?.encryptionMethodId ?? 0),
      );

      if (encryptionMethod !== undefined) {
        const isAllZeros = encryptionMethod.value
          .split("")
          .every((char) => char === "0");
        // 값이 전부 0 일 때
        if (isAllZeros) {
          errorMessage +=
            "공통변수 > 환경변수에서 암호화 키 value를 설정해 주십시오.<br>";
          screenMovement = "commonVariable";
          iconFlag = false;
          errorCount += 1;
        }
      }
    }
    // 2024.04.16 [shiningtrue]: 선택한 API가 존재 할 때
    if (Object.keys(selectedApi).length > 0) {
      // 2024.04.16 [shiningtrue]: URI 등록 여부 확인
      if (tabItem?.uri?.uri === "") {
        errorMessage += "해당 API에 URI가 등록되어 있지 않습니다.<br>";
        screenMovement = "api";
        iconFlag = false;
        errorCount += 1;
      }
    }

    // 2024.04.16 [shiningtrue]: Test Case 등록 여부 확인
    if (tabItem?.responseCase?.length <= 0) {
      helpMessage +=
        "해당 API에 Response Test Case가 등록되어 있지 않습니다.<br>";
      screenMovement = "api";
      iconFlag = false;
    }

    setHelpIcon((prev) => ({
      ...prev, // 이전 상태를 가져옴
      icon: errorCount > 0 ? "error" : iconFlag ? "success" : "warn",
      text: errorCount > 0 ? errorMessage : helpMessage,
      move: screenMovement,
    }));
  }, [commonResponseFormBodyRecoilState, tabOpenList, selectedApi]);

  const handleTooltipClick = () => {
    if (helpIcon.move === "api") {
      setMenuKey("1");
    } else if (helpIcon.move === "commonError") {
      setMenuKey("2");
    } else if (helpIcon.move === "commonVariable") {
      setMenuKey("3");
    }
  };

  const handleMouseOver = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  return (
    <>
      {!alertHelpIconOpen ?
        <Tooltip
            open={tooltipOpen}
          placement="bottomLeft"
          title={
              <div className="Body7_R help-icon-body">
                  <span className="Body7_B">
                    {code === "success" &&
                      <span dangerouslySetInnerHTML={{ __html: "[정상]<br>" }}></span>
                    }
                    {code === "error" &&
                      <span dangerouslySetInnerHTML={{ __html: "[에러]<br>" }}></span>
                    }
                  </span>
                <span
                  dangerouslySetInnerHTML={{ __html: text }}
                ></span>
              </div>
          }
          color={
            code === "error"
                ? "#ff5757"
                : code === "warn"
                  ? "#f68b00"
                  : "#00b252"
          }
          overlayStyle={{ maxWidth: "390px" }}
          onClick={handleTooltipClick}
        >
          <Space className="content">
            <Image
              className={`content jc-c ml10`}
              path={`${process.env.PUBLIC_URL}/header`}
              name={
                code === "error"
                    ? "ic_header_error"
                    : code === "warn"
                      ? "ic_header_warning"
                      : "ic_header_success"
              }
              style={{ cursor: "pointer" }} // 인라인 스타일로 클릭 가능한 스타일 적용
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            />
          </Space>
        </Tooltip>
      :
        <Tooltip
          open={alertHelpIconOpen}
          placement="bottomLeft"
          title={
              <div className="Body7_R help-icon-body">
                <span className="Body7_B">
                  {helpIconMessage.code === "success" &&
                    <span dangerouslySetInnerHTML={{ __html: "[정상]<br>" }}></span>
                  }
                  {helpIconMessage.code === "error" &&
                    <span dangerouslySetInnerHTML={{ __html: "[에러]<br>" }}></span>
                  }
                </span>
                <span
                  dangerouslySetInnerHTML={{ __html: helpIconMessage.message }}
                ></span>
              </div>

          }
          color={helpIconMessage.code === 'error' ? "#ff5757" : "#00b252"}
          overlayStyle={{ maxWidth: "390px" }}
          onClick={handleTooltipClick}
        >
          <Space className="content">
            <Image
              className={`content jc-c ml10`}
              path={`${process.env.PUBLIC_URL}/header`}
              name={
                helpIconMessage.code === "error" ? "ic_header_error" : "ic_header_success"
              }
              style={{ cursor: "pointer" }} // 인라인 스타일로 클릭 가능한 스타일 적용
            />
          </Space>
        </Tooltip>
      }
    </>
  );
};

export default HelpIcon