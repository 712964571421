import React, {useEffect, useState} from "react";
import {Dropdown, Layout, Space} from "antd";
import Image from "../../atoms/Image";
import UserSubSider from "../../organisms/subSider/UserSubSider";
import APISubSider from "../../organisms/subSider/APISubSider";
import SearchInput from "../../atoms/SearchInput";
import InviteProjectUserModal from "../../organisms/modals/InviteProjectUserModal";
import {isModalOpenState, openModal, projectState} from "../../../recoil/project/projectState";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import {saveApiApi} from "../../../api/api/apiApi";

import {
  alertHelpIcon, helpIconMessageState,
  alertHelpIconOpenState,
  isApiCopyState,
  selectedApiState,
  tabOpenListState, apiTabRecoilPersistState
} from "../../../recoil/api/apiState";
import useAxiosInterceptor from "../../../axios/axios";
import _ from "lodash";

const { Sider: AntdSider } = Layout;

const SubSider = ({
  menuKey,
  isSubSiderOpen,
  treeData,
  setTreeData,
  userListGroupByAuthority,
  setUserListGroupByAuthority,
  handleSearch,
  filteringTreeData,
}) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.04.12 [energysteel]: Modal 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  // 2024.04.12 [energysteel]: API > + 버튼 > API 추가 버튼 클릭 Flag
  const [newData, setNewData] = useState(false);
  // 2024.04.12 [energysteel]: API > + 버튼 > API Group 추가 버튼 클릭 Flag
  const [isGroup, setIsGroup] = useState(false);
  // 2024.04.12 [energysteel]: Project ID
  const { projectId } = useParams();
  // 2024.04.12 [energysteel]: 현재 Project 정보
  const [project] = useRecoilState(projectState);
  // 2024.04.12 [energysteel]: 검색어
  const [inputValue, setInputValue] = useState("");
  // 2024.03.08 [energysteel]: 선택한 API
  const [selectedApi, setSelectedApi] = useRecoilState(selectedApiState);
  // 2024.03.08 [energysteel]: 탭 리스트
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.04.17 [energysteel]: 복사한 API 정보
  const [copyApi, setCopyApi] = useState({});
  // 2024.04.17 [energysteel]: 복사중 상태
  const [isApiCopy, setIsApiCopy] = useRecoilState(isApiCopyState);
  // 2024.03.27 [energysteel]: Tree Check할 노드의 Key
  const [checkedKey, setCheckedKey] = useState([]);
  const [copyApiStack, setCopyApiStack] = useState([]);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  // 2024.04.17 [shiningtrue]: API Tab 브라우저 종료 시에도 데이터 관리하기 위한 Recoil
  const [, setTabRecoilPersistState] = useRecoilState(apiTabRecoilPersistState);

  useEffect(() => {
    setInputValue("");
  }, [menuKey])

  /**
   * 2024.04.17 [energysteel]: API 붙여넣기
   */
  const apiPaste = (event) => {
    if (Object.keys(copyApi).length === 0) {
      return false;
    }

    // 2024.04.18 [energysteel]: 붙여넣기 진행중인 경우 && 신규 API 생성중인 경우 && API 이름 수정중인 경우 return
    if (isApiCopy || newData || findUpdatedNodeWithChildren(treeData)) {
      return;
    }

    // 2024.04.17 [energysteel]: 복사중 flag on
    setIsApiCopy(true);

    const key = findLastTreeOrder();

    // 2024.04.17 [energysteel]: Order, Tree Key 마지막 값으로 재정의
    const updateKeyCopyApi = {...copyApi};
    updateKeyCopyApi.tree.key = key;

    saveApiApi(
        axios,
        project.id,
        updateKeyCopyApi.apiSaveRequest,
        (responseData) => saveApiApiSuccessCallback(responseData, updateKeyCopyApi, event),
        () => saveApiApiFailCallback(event),
    )
  }

  const apiPasteThrottled = _.debounce(apiPaste, 200);

  /**
   * 2024.05.02 [energysteel]:
   * @return {string}
   */
  const findLastTreeOrder = () => {
    const tree = treeData[treeData.length - 1];
    if (!tree) {
      return "0-0";
    }

    const lastOrder = Number(tree.key.split("-").pop()) + 1;
    return `0-${lastOrder}`
  }

  /**
   * 2024.04.17 [energysteel]: API 복사
   *  - Package 복사 기능 추가 시 selectedApi.packageYn undefined 확인 작업 필요함 확인 후 주석 제거
   */
  const apiCopy = (event) => {
    const currentTab = tabOpenList.find(tab => tab.id === selectedApi.id);

    if (!currentTab) {
      return false;
    }

    // 2024.04.18 [energysteel]: 신규 API 생성중인 경우 && API 이름 수정중인 경우 return
    if (newData || findUpdatedNodeWithChildren(treeData)) {
      return;
    }

    const title = selectedApi?.title ? selectedApi?.title : selectedApi.api?.apiName;

    const saveApiRequest = {
      tree: {
        id: undefined,
        title: `${title} Copy`,
        packageYn: selectedApi.packageYn,
        isLeaf: !selectedApi.packageYn,
        update: false,
        search: true,
        upperId: undefined,
        children: [],
      },
      apiSaveRequest: {
        apiName: `${title} Copy`,
        packageYn: selectedApi.packageYn,
        isLeaf: !selectedApi.packageYn,
        isChild: false,
        projectId: project.id,
        update: false,
        upperId: undefined,
      },
      detail: {
        id: undefined,
        ...currentTab,
        api: {
          ...currentTab.api,
          apiName: `${title} Copy`,
        },
        uri: {
          httpMethod: "GET",
          contentType: "APPLICATION_JSON",
          delay: 0,
        },
      }
    }

    setCopyApi(saveApiRequest);

    if (event) {
      event.preventDefault();
    }
  }

  /**
   * 2024.04.18 [energysteel]: Tree 전체 순회하여 이름 변경중인 노드 찾기
   * @param nodes
   * @return {null}
   */
  const findUpdatedNodeWithChildren = (nodes) => {
    let updatedNode = false;

    const traverseNodes = (node) => {
      if (node.update === true) {
        updatedNode = true;
        return;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseNodes(child);
        });
      }
    };

    nodes.forEach(node => {
      traverseNodes(node);
    });

    return updatedNode;
  }

  /**
   * 2024.04.17 [energysteel]: 저장 API 성공 Callback 데이터 최신화
   * @param responseData API 응답 값
   * @param updateKeyCopyApi 복사한 API 정보
   * @param event onClick/onKeyDown Event
   */
  const saveApiApiSuccessCallback = (responseData, updateKeyCopyApi, event) => {
    const detail = updateKeyCopyApi.detail;
    const response = responseData.data.data;
    detail.id = response.id;

    detailEditingDataAndReturnFlag(detail);
    const updatedDetail = traversalChangeApiId(detail, response.id);

    setSelectedApi(
      {
        ...updateKeyCopyApi.tree,
        id: response.id,
      }
    );

    const tree = {...updateKeyCopyApi.tree};
    tree.id = response.id;

    setTreeData(prev => {
      return [
        ...prev,
        tree,
      ]
    })

    setTabOpenList(prev => {
      return [
        ...prev,
        updatedDetail,
      ]
    })

    // 2024.04.17 [shiningtrue]: 열려 있는 탭 브라우저 종료시에도 유지 시키기 위함
    setTabRecoilPersistState((prev) => {
      const existingIds = prev[project.id] || []; // 이전 데이터의 id 리스트를 가져옵니다.
      // 새로운 데이터의 id가 이미 리스트에 있는지 확인합니다.
      const isDuplicate = existingIds.includes(response.id);
      // 중복되지 않으면 새로운 데이터를 추가하고, 중복된 경우에는 기존 데이터를 그대로 반환합니다.
      return {
        ...prev,
        [project.id]: isDuplicate ? existingIds : [...existingIds, response.id],
      };
    });

    setIsApiCopy(false);

    setCopyApiStack(prev => {
      return [
        ...prev,
        response.id,
      ]
    })

    event.preventDefault();
  }

  /**
   * 2024.04.17 [energysteel]: 저장 API 실패 Callback
   *  - 복사중 flag off
   * @param event onClick/onKeyDown Event
   */
  const saveApiApiFailCallback = (event) => {
    setIsApiCopy(false);
    setHelpIconMessage({
      code: "error",
      message: "잠시 후 다시 시도해주세요.",
    });
    alertHelpIcon(setAlertHelpIconOpen);
    event.preventDefault();
  }

  /**
   * 2024.04.17 [energysteel]: API 상세 정보 등록 여부 파악
   *  - 한개라도 등록되어있는 경우 true 반환
   * @param detail API 상세 정보 (uri, request, response, testcase)
   * @return {boolean}
   */
  const detailEditingDataAndReturnFlag = (detail) => {
    let detailEditFlag = false;

    if (Object.keys(detail.requestHeader).length > 0 || Object.keys(detail.requestBody).length > 0) {
      detail.requestEditing = true;
      detailEditFlag = true;
    }

    if (Object.keys(detail.responseHeader).length > 0) {
      detail.responseHeaderEditing = true;
      detailEditFlag = true;
    }

    if (Object.keys(detail.responseParameter).length > 0) {
      detail.responseParameterEditing = true;
      detailEditFlag = true;
    }

    if (Object.keys(detail.responseCase).length > 0) {
      detail.responseCaseEditing = true;
      detailEditFlag = true;
    }

    return detailEditFlag;
  }

  /**
   * 2024.04.17 [energysteel]: Data Object 전체 순회하여 API ID 최신화
   * @param detail API 상세 정보 (uri, request, response, testcase)
   * @param apiId API ID
   * @return {*}
   */
  const traversalChangeApiId = (detail, apiId) => {
    if (isObject(detail)) {
      const updatedDetail = { ...detail };
      for (const key in updatedDetail) {
        if (key === 'apiId') {
          updatedDetail[key] = apiId;
        } else {
          updatedDetail[key] = traversalChangeApiId(updatedDetail[key], apiId);
        }
      }
      return updatedDetail;
    } else if (Array.isArray(detail)) {
      return detail.map(element => traversalChangeApiId(element, apiId));
    } else {
      return detail;
    }
  }

  /**
   * 2024.04.17 [energysteel]: Data가 Object인지 확인
   * @param data API 상세 정보
   * @return {boolean}
   */
  const isObject = (data) => {
    return typeof data === 'object' &&
          !Array.isArray(data) &&
          data !== null;
  }

  const contentList = [
    {
      key: "1",
      content: (
        <APISubSider
          newData={newData}
          setNewData={setNewData}
          isGroup={isGroup}
          treeData={treeData}
          setTreeData={setTreeData}
          filteringTreeData={filteringTreeData}
          inputValue={inputValue}
          copyApi={copyApi}
          apiCopy={apiCopy}
          apiPaste={apiPasteThrottled}
          copyApiStack={copyApiStack}
          setCopyApiStack={setCopyApiStack}
          checkedKey={checkedKey}
          setCheckedKey={setCheckedKey}
        />
      ),
    },
    {
      key: "4",
      content: (
        <UserSubSider
          userListGroupByAuthority={userListGroupByAuthority}
          setUserListGroupByAuthority={setUserListGroupByAuthority}
          filteringTreeData={filteringTreeData}
        />
      )
    },
  ];

  const moreItems = {
    items: [
      {
        label: (
          <div className="Body6_B g500" onClick={() => makeNew(false)}>
            API 추가
          </div>
        ),
        key: "1",
      },
      {
        label: (
          <div className="Body6_B g500" onClick={() => makeNew(true)}>
            API Group 추가
          </div>
        ),
        key: "2",
      },
      {
        label: (
          <div className="Body6_B g500" onClick={() => apiPasteThrottled()}>
            API 붙여넣기
          </div>
        ),
        key: "3",
      },
    ],
  };

  const makeNew = (value) => {
    setNewData(true);
    setIsGroup(value);
  };

  useEffect(() => {
    setIsModalOpen((prevStates) => {
      const findPrev = prevStates.find(value => value.id === projectId);

      if (findPrev) {
        return prevStates;
      }

      return [
        ...prevStates,
        {
          id: Number(projectId),
          create: false,
          update: false,
          inviteProject: false,
          inviteAccept: false,
          delete: false,
        }
      ]
    });
  }, [projectId, setIsModalOpen])

  const showModal = () => {
    openModal(setIsModalOpen, Number(projectId), "invite")
  }

  return (
    <AntdSider
      className={`subSider ${isSubSiderOpen} br-g200`}
      width={298}
      style={{
        backgroundColor: "#EEF1F7",
      }}
    >
      <div>
        <div className="sider jc-sb" style={{ padding: "12px 10px" }}>
          <SearchInput
            inputValue={inputValue}
            onChange={
              menuKey === '1' ?
                (event) => handleSearch(event, treeData, setTreeData, setInputValue) :
                (event) => handleSearch(event, userListGroupByAuthority, setUserListGroupByAuthority, setInputValue)
          }
          />
          {(menuKey === "1" &&
            (project.authority === 'OWNER' ||
              project.authority === 'ADMIN' ||
              project.authority === 'AGENT'
            )
          ) ? (
            <Dropdown
              menu={moreItems}
              overlayStyle={{ width: "156px", height: "114px" }}
              trigger={["click"]}
            >
              <Space style={{ lineHeight: "16px" }}>
                <Image
                  className="ml10"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={"ic_add"}
                />
              </Space>
            </Dropdown>
          ) : (
            <>
              {(project.authority === 'OWNER' ||
                  project.authority === 'ADMIN') &&
                <>
                  <Image
                    className="ml10"
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_add"}
                    onClick={() => showModal()}
                  />
                  <InviteProjectUserModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    project={project}
                  />
                </>
              }
            </>
          )}
        </div>
      </div>
      {contentList.find((v) => v.key === menuKey).content}
    </AntdSider>
  );
};

export default SubSider;
