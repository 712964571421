import { Button } from "antd";
import CreateProjectModal from "../organisms/modals/CreateProjectModal";
import Image from "../atoms/Image";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const NonProject = ({ loading }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <div className="main-body-non">
            <div className="ta Body2_R g500">
              <Image
                path={`${process.env.PUBLIC_URL}/projectList`}
                name={"ic_empty_project"}
                size={"xl"}
              />
              <div className="mt28 mb17">
                <p className="Body1_B g900">
                  등록되어 있는 Project가 없습니다.
                </p>
              </div>
              <p>‘Project 생성하기’ 를 통해 MockBot 서비스를 이용해 보세요.</p>
              <p>
                자세한 이용 방법은{" "}
                <Link to="/manual">
                  <span
                    className="Body2_B g500 cur"
                    style={{ textDecoration: "underline" }}
                  >
                    사용가이드
                  </span>
                </Link>
                를 참고해 주세요.
              </p>
              <Button
                className="btn-pri Body4_B mt36 br5"
                style={{ width: "200px", height: "48px" }}
                type="primary"
                onClick={handleCreateModal}
              >
                Project 생성하기
              </Button>
              {isCreateModalOpen && (
                <CreateProjectModal
                  isCreateModalOpen={isCreateModalOpen}
                  setIsCreateModalOpen={setIsCreateModalOpen}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NonProject;
