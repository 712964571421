import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../AuthContext";
import useAxiosInterceptor from "../../../axios/axios";
import {toast} from "react-toastify";

/* eslint-disable */
const LoginCallback = () => {
    // 2024.03.05[holywater]: axios 선언
    const axios = useAxiosInterceptor();
    const { authContextLogin } = useAuth();  //로그인 상태관리
    const navigate = useNavigate();
    const location = useLocation();
    let loginType = [];
    let code = "";
    useEffect(() => {
        const splitUrl = location.pathname.split('/');  // 2024.01.25[shiningtrue]: 로그인한 소셜 타입을 가지고 오기 위한 url path
        const params = new URLSearchParams(location.search); // 2024.01.25[shiningtrue]: 로그인한 소셜의 code 값을 가지고 오기 위함
        loginType = splitUrl[splitUrl.length - 2];
        code = params.get("code");
        callBack();
    }, [])

    const callBack = () => {
        axios.get(`/login/oauth2/code/${loginType}`,
            {
                params: {
                    code: code
                }
            })
            .then(function(response) {
                if (response.data.code === "0001") {
                    authContextLogin(response); //사용자 로그인 상태 관리
                    navigate("/main");  //2024.01.25[shiningtrue]: 화면 이동
                } else {
                    toast.error(response.data.message);
                    navigate("/signin");  //2024.01.25[shiningtrue]: 화면 이동
                }
            }).catch(error => {
            console.log(error);
            // 오류 발생 시 실행
        }).then(() => {
            // 항상 실행
        });
    }
};

export default LoginCallback;
