import React from "react";
import Image from "./Image";
import { Tooltip, Space } from "antd";

const TooltipImage = ({ title, className, path, name, onClick }) => {
  return (
    <Tooltip placement="bottom" title={title}>
      <Space className="content">
        <Image
          className={`content jc-c ${className}`}
          path={path}
          name={name}
          onClick={onClick}
        />
      </Space>
    </Tooltip>
  );
};

export default TooltipImage;
