import React from "react";

const Response = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
                <p>MockBot의 API Response 는 API 연동 시 애플리케이션이 응답받을 값을 등록하는 단계 입니다.<br/>
                    Response는 Key-Value 중 Key 로 등록되며 실제 응답값은 테스트 케이스 등록으로 정상응답과 다양한 오류<br/>
                    응답을 등록하여 애플리케이션에서 비즈니스 로직을 쉽게 구현할 수 있도록 도와줍니다.
                </p>
            </div>
        </>
    )
}
export default Response;