import React from "react";

const Numbering = ({
  num,
  text,
  type = "pri",
  className = "content mb16 cur",
  style,
  onClick,
}) => {
  return (
    <div className={className} onClick={onClick} style={style}>
      <span
        className={
          "content jc-c white mr12 br100 roboto_B " +
          (type === "pri" ? "bg-brand500" : "bg-g400")
        }
        style={{ height: 28, width: 28, fontSize: 16 }}
      >
        {num}
      </span>
      <span className={"Body1_B " + (type === "pri" ? "g800" : "g300")}>
        {text}
      </span>
    </div>
  );
};

export default Numbering;
