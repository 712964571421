/* 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Header */
export const loadCommonResponseFormApi = (
  axios,
  projectId,
  type,
  successCallback,
) => {
  axios
    .get(`/commonResponseForm/load`, {
      params: {
        projectId: projectId,
        type: type,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

/* 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Body */
export const loadCommonResponseFormBodyApi = (
  axios,
  projectId,
  successCallback,
) => {
  axios
    .get(`/commonResponseForm/load`, {
      params: {
        projectId: projectId,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

/* 2024.03.22 [shiningtrue]: 공통 응답규격 저장 */
export const saveCommonResponseFormApi = async (axios, params, successCallback) => {
  axios
    .post(`/commonResponseForm/save`, params)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
