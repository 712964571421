import React from "react";
import Image from "../../../atoms/Image";
import { Link } from "react-router-dom";

const SelectApiContent = () => {
  return (
    <div
      className="content jc-c"
      style={{
        minHeight: "972px",
      }}
    >
      <div className="ta Body2_R g500">
        <Image
          path={`${process.env.PUBLIC_URL}/projectList`}
          name={"ic_mockbot_logo_40"}
          size="xl"
        />
        <div className="mt28 mb17">
          <p className="Body1_B g900">조회 할 API를 선택해 주세요.</p>
        </div>
        <p>‘API’는 MockBot Project를 진행하기 위한 필수 정보 입니다.</p>
        <p>API 추가 등록은 등록버튼을 클릭해 주세요.</p>
        <br />
        <p>
          자세한 이용방법은{" "}
          <Link to="/manual">
            <span
              className="Body2_B g500 cur"
              style={{ textDecoration: "underline" }}
            >
              사용가이드
            </span>
          </Link>
          를 참고해 주세요.
        </p>
      </div>
    </div>
  );
};

export default SelectApiContent;
