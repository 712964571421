import React from "react";

const URI = ({ clazz, setManualKey }) =>{
    return (
        <>
        <div className={clazz}>
            <p>URI는 "Uniform Resource Identifier"의 약자로, 인터넷에서 식별할 수 있는 리소스를 가리키는 고유한 주소 또는 식별자를 나타냅니다.</p>
            <p>MockBot은 API의 URI정보를 초기값으로 기본 설정합니다.</p>
        </div>
    </>
)
}
export default URI;