import React from "react";
import {Modal} from "antd";
import Title from "../../molecules/modals/Title";
import {closeModal, isModalOpenState, pageState, projectListState,} from "../../../recoil/project/projectState";
import Dialogs from "../../molecules/modals/Dialogs";
import {inviteAcceptApi, inviteRejectApi,} from "../../../api/invite/inviteApi";
import {useRecoilState, useRecoilValue} from "recoil";
import {userState} from "../../../recoil/user/userState";
import useAxiosInterceptor from "../../../axios/axios";
import {loadProjectsApi, loadProjectsApiSuccessCallback} from "../../../api/project/projectApi";
import {toast} from "react-toastify";

const InviteAcceptModal = ({ project }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 유저 ID
  const userId = useRecoilValue(userState).id;
  // 2024.03.06 [energysteel]: Modal Open 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  const [page, setPage] = useRecoilState(pageState);
  const [, setProjectList] = useRecoilState(projectListState);


  /**
   * 2024.03.06 [energysteel]: 초대 수락 Modal > 네 onClick Event
   */
  const handleInviteAcceptOk = () => {
    inviteAcceptApi(
      axios,
      project.id,
      userId,
      () => inviteApiSuccessCallback(),
      (response) => inviteApiFailCallback(response),
      () => closeModal(setIsModalOpen, project.id, "inviteAccept"),
    );
  }

  /**
   * 2024.03.06 [energysteel]: 초대 수락 Modal > 거절 onClick Event
   */
  const handleInviteReject = () => {
    inviteRejectApi(
      axios,
      project.id,
      [userId],
      () => inviteApiSuccessCallback(),
      () => closeModal(setIsModalOpen, project.id, "inviteAccept"),
    );
  }

  // 2024.03.06 [energysteel]: 프로젝트 조회 객체
  const loadProjectRequest = {
    pageNumber: 0,
    pageSize: page.pageSize,
    searchWord: "",
    searchCategories: "ALL",
  };

  /**
   * 2024.03.06 [energysteel]: 초대 수락 Modal > 네, 거절 성공 Callback
   *  - 프로젝트 랜더링
   */
  const inviteApiSuccessCallback = () => {
    loadProjectsApi(
      axios,
      loadProjectRequest,
      (response) => loadProjectsApiSuccessCallback(response, setProjectList, setPage),
      () => {},
    );
  }

  const inviteApiFailCallback = (response) => {
    toast.error(`${project.name} ${response.data.message}`);

    loadProjectsApi(
      axios,
      {
        ...loadProjectRequest,
        searchCategories: "INVITE_PROJECT",
      },
      (response) => loadProjectsApiSuccessCallback(response, setProjectList, setPage),
      () => {},
    );
  }

  /**
   * 2024.03.06 [energysteel]: 초대 수락 Modal > 취소 onClick Event
   */
  const handleCancel = () => {
    closeModal(setIsModalOpen, project.id, "inviteAccept");
  };

  return (
    <Modal
      className="modal"
      title={
        <Title
          title={"Project 초대 수락"}
          subTitle={
            "Project 사용자로 초대된 경우 해당 Project 사용을 위해 초대를 수락합니다."
          }
        />
      }
      open={isModalOpen?.find(v => v.id === project.id)?.inviteAccept}
      onCancel={handleCancel}
      width={528}
      centered
      footer={
        <Dialogs
          textLeft={"거절"}
          handleLeft={handleInviteReject}
          textCenter={"아니오"}
          handleCenter={handleCancel}
          textRight={"네"}
          handleRight={handleInviteAcceptOk}
        />
      }
    >
      <div
        className="ta b-g200 bg-g100 g500 mt24"
        style={{ padding: "32px 20px" }}
      >
        <p style={{ whiteSpace: "pre-line" }}>
          {`${project.hostUserEmail} 님이
            ${project.name} project의 ${project.authority}로 초대하였습니다.`}
        </p>
        <br />
        <p className="Body6_B">수락하시겠습니까?</p>
      </div>
    </Modal>
  );
};

export default InviteAcceptModal;
