/* 2024.03.04 [shiningtrue]: API > Request 조회 */

export const loadRequestApi = async (axios, params, name, successCallback) => {
  await axios
    .get(`/request/load`, {
      params: {
        apiId: params.apiId,
        tabType: params.tabType,
      },
    })
    .then(function (response) {
      successCallback(response, params.tabType, name);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

/* 2024.03.04 [shiningtrue]: API > Request 저장 */
export const saveRequestApi = async (
  axios,
  params,
  successCallback,
  finallyCallback,
  modalOpenFun,
  completeFlag,
) => {
  axios
    .post(`/request/save`, params)
    .then(function (response) {
      successCallback(response, modalOpenFun, completeFlag);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      finallyCallback();
    });
};

/* 2024.03.27 [shiningtrue]: JSON 업로드 */
export const saveRequestJsonApi = (axios, params, name, successCallback, failCallback) => {
  axios
    .post("/request/saveJson", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response, params.tabType, name);
      } else {
        failCallback(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      if (error.response.status === 413) {
        failCallback("업로드 용량을 초과하였습니다.");
      }
    })
    .then(() => {
      // 항상 실행
    });
};
