import { atom, useRecoilState } from "recoil";
import { selectedApiState, tabOpenListState } from "../api/apiState";

export const saveProjectRequestObject = {
  name: undefined,
  context: undefined,
  accessAuthority: "PUBLIC",
  description: undefined,
  commonErrorCode: {
    commonErrorCodeObject: [
      {
        key: "new1",
        name: "Request 필수 여부",
        code: "0000",
        message: "${key}필수 여부 값이 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "ESSENTIAL",
        canDelete: false,
        httpCode: "OK",
      },
      {
        key: "new2",
        name: "Request 암호화 여부",
        code: "0001",
        message: "${key} 암호화가 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "ENCRYPTION",
        canDelete: false,
        httpCode: "OK",
      },
      {
        key: "new3",
        name: "Request Enum 체크",
        code: "0003",
        message: "${key} Enum값이 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "ENUM",
        canDelete: false,
        httpCode: "OK",
      },
      {
        key: "new4",
        name: "Request 정규식 체크",
        code: "0004",
        message: "${key} 정규식이 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "REGULAR_EXPRESSION",
        canDelete: false,
        httpCode: "OK",
      },
      {
        key: "new5",
        name: "Request Data Type 체크",
        code: "0005",
        message: "${key} Data Type이 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "DATA_TYPE",
        canDelete: false,
        httpCode: "OK",
      },
      {
        key: "new6",
        name: "Request Min Max 체크",
        code: "0006",
        message: "${key} Min Max가 올바르지 않습니다.",
        type: "REQ_VALIDATION",
        typeDetail: "MIN_MAX",
        canDelete: false,
        httpCode: "OK",
      },
    ],
  },
}

const projectLayout = () => {
  return {
    inviteStatus: undefined,
    name: undefined,
    context: undefined,
    createDate: undefined,
    updateDate: undefined,
    accessAuthority: undefined,
    description: undefined,
    authority: undefined,
    expiredDate: undefined,
    key: undefined,
    userId: Number(),
    id: Number(),
    hostUserEmail: undefined,
  };
};

export const projectState = atom({
  key: "projectState",
  default: projectLayout(),
});

export const projectListState = atom({
  key: "projectListState",
  default: [projectLayout()],
});

export const projectValidationErrorsState = atom({
  key: "projectValidationErrorsState",
  default: {
    name: false,
    context: false,
    authority: false,
    description: false,
  },
});

export const saveProjectRequestState = atom({
  key: "saveProjectState",
  default: saveProjectRequestObject,
});

export const saveProjectCommonErrorCodeState = {

};

export const modifyProjectState = atom({
  key: "modifyProjectState",
  default: {
    id: Number(),
    accessAuthority: "PUBLIC",
    context: undefined,
    description: undefined,
  },
});

export const pageState = atom({
  key: "pageState",
  default: {
    pageNumber: 0,
    pageSize: 3,
  },
});

export const searchWordState = atom({
  key: "searchWordState",
  default: "",
});

export const isModalOpenState = atom({
  key: "isModalOpenState",
  default: [
    {
      id: 0,
      create: false,
      update: false,
      invite: false,
      inviteAccept: false,
      delete: false,
    },
  ],
});

export const projectCountState = atom({
  key: "projectCountState",
  default: {}
})

export const useIsModalOpen = () => {
  return useRecoilState(isModalOpenState);
};

export const useShowCreateModal = () => {
  const [, setIsModalOpen] = useRecoilState(isModalOpenState);

  const showCreateModal = () => {
    setIsModalOpen((prevStates) => [
      ...prevStates,
      {
        id: 0,
        create: true,
        update: false,
        invite: false,
        inviteAccept: false,
        delete: false,
      },
    ]);
  };

  return showCreateModal;
};

export const useCreateModal = () => {
  const [, setIsModalOpen] = useRecoilState(isModalOpenState);

  const createModal = (id) => {
    setIsModalOpen((prevStates) => [
      ...prevStates,
      {
        id: id,
        create: false,
        update: false,
        invite: false,
        inviteAccept: false,
        delete: false,
      },
    ]);
  };

  return createModal;
};

export const useDropdownItems = (
  project,
  modifyHandle,
  inviteHandle,
  exportHandle,
  deleteHandle,
) => {
  const items = [
    {
      label: (
        <div className="Body6_B g500" onClick={() => modifyHandle()}>
          {
            project.authority === 'OWNER' ?
              'Project 설정' :
              'Project 정보'
          }
        </div>
      ),
      key: "1",
      authority: ["OWNER", "ADMIN", "AGENT", "USER", "ANONYMOUS"],
    },
    {
      label: (
        <div className="Body6_B g500" onClick={() => inviteHandle()}>
          사용자 초대
        </div>
      ),
      key: "2",
      authority: ["OWNER", "ADMIN"],
    },
    {
      label: <div className="Body6_B g500" onClick={() => exportHandle()}>Export</div>,
      key: "3",
      authority: ["OWNER"],
    },
    {
      label: (
        <div className="Body6_B g500" onClick={() => deleteHandle()}>
          삭제
        </div>
      ),
      key: "4",
      authority: ["OWNER"],
    },
  ];

  return setupDropdown(items, project);
};

export const openModal = (setIsModalOpen, projectId, key) => {
  modalControl(setIsModalOpen, projectId, key, true);
};

export const closeModal = (setIsModalOpen, projectId, key) => {
  modalControl(setIsModalOpen, projectId, key, false);
};

const modalControl = (setIsModalOpen, projectId, key, value) => {
  setIsModalOpen((prevStates) => {
    return prevStates.map((item) => {
      if (item.id === projectId) {
        return { ...item, [key]: value };
      } else {
        return item;
      }
    });
  });
};

const setupDropdown = (items, project) => {
  const filteredItems = items.filter((item) =>
    item.authority.includes(project.authority),
  );

  return {
    items: filteredItems,
  };
};

export const encryptionMethodListState = atom({
  key: "encryptionMethodListState",
  default: [],
})

export const contentTypeListState = atom({
  key: "contentTypeListState",
  default: [],
})

export const commonUserVariableListState = atom({
  key: "commonUserVariableListState",
  default: [],
});

export const badgeState = atom({
  key: "badgeState",
  default: false,
});

export const commonEnvironmentVariableListState = atom({
  key: "commonEnvironmentVariableListState",
  default: [],
});

export const commonErrorCodeListState = atom({
  key: "commonErrorCodeListState",
  default: [],
});

export const commonResponseFormHeaderListState = atom({
  key: "commonResponseFormHeaderListState",
  default: [],
});

export const commonResponseFormBodyListState = atom({
  key: "commonResponseFormBodyListState",
  default: [],
});

// 2024.03.15 [shiningtrue]: project 내부 recoil 초기화 함수
export const useProjectRecoilReset = () => {
  // api 탭정보
  const [, setTapOpenListStateReset] = useRecoilState(tabOpenListState);
  // 공통 변수 (사용자변수)
  const [, setCommonUserVariableListStateReset] = useRecoilState(
    commonUserVariableListState,
  );
  // 공통 변수 (환경변수)
  const [, setCommonEnvironmentVariableListState] = useRecoilState(
      commonEnvironmentVariableListState,
  );
  // 선택된 api
  const [, setSelectedApiStateReset] = useRecoilState(selectedApiState);
  // 공통 에러코드
  const [, setCommonErrorCodeListReset] = useRecoilState(
    commonErrorCodeListState,
  );
  // 공통 응답규격
  const [, setCommonResponseFormHeaderListReset] = useRecoilState(
    commonResponseFormHeaderListState,
  );
  const [, setCommonResponseFormBodyListReset] = useRecoilState(
    commonResponseFormBodyListState,
  );

  const projectRecoilReset = () => {
    setTapOpenListStateReset([]);
    setCommonUserVariableListStateReset([]);
    setCommonEnvironmentVariableListState([]);
    setCommonErrorCodeListReset([]);
    setCommonResponseFormHeaderListReset([]);
    setCommonResponseFormBodyListReset([]);
    setSelectedApiStateReset({});
  };

  return projectRecoilReset;
};

/**
 * 2024.04.05 [energysteel]: Project 관련 권한 확인
 * @param authority 유저 권한
 * @returns {boolean}
 */
export const isProjectOwnerAuthority = (authority) => {
  return authority === 'OWNER';
}

/**
 * 2024.04.05 [energysteel]: Project 사용자 탭 권한 확인
 * @param authority 유저 권한
 * @returns {boolean}
 */
export const isProjectOwnerOrAdminAuthority = (authority) => {
  return authority === 'OWNER' || authority === 'ADMIN';
}

/**
 * 2024.04.05 [energysteel]: API 관련 권한 확인
 * @param authority 유저 권한
 * @returns {boolean}
 */
export const isApiAuthority = (authority) => {
  return authority === 'OWNER' || authority === 'ADMIN' || authority === 'AGENT';
}