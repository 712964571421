import {ManualButton} from "../../../../atoms/Button";
import Image from "../../../../atoms/Image";
import React from "react";
import {useLocation} from "react-router-dom";

const ManualButtonContent = ({ onPrevText, onPrevClick, onNextText, onNextClick }) => {
  const location = useLocation();

  return (
    <div className="guide-btn">
      <ManualButton
        text={onPrevText}
        icon={
          <Image
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_arrow_left"}
          />
        }
        onClick={() => {
          onPrevClick();
          location.hash = '';
        }}
      />
      <ManualButton
        text={onNextText}
        pos={"right"}
        icon={
          <Image
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_arrow_right"}
          />
        }
        onClick={() => {
          onNextClick();
          location.hash = '';
        }}
      />
    </div>
  )
}

export default ManualButtonContent;