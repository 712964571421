import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SquareButton } from "../../atoms/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Image from "../../atoms/Image";
import { SignInput } from "../../atoms/Input";
import {
  certificationNumberCheckApi,
  emailCheckAndResendApi,
  emailOverLapCheckApi,
  findPasswordSendApi,
  passwordChangeApi,
  registerApi,
} from "../../../api/signup/signupApi";
import Loader from "../../common/Loader";
import useAxiosInterceptor from "../../../axios/axios";
import { toast } from "react-toastify";
const SignupContent = () => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();

  const [state, setState] = useState(useLocation().state?.key || "1");

  // 2024.01.29 [shiningtrue]: 다음 화면으로 정보 넘기기 위함
  const [nextUserInfo, setNextUserInfo] = useState("");

  //email& 비밀번호 정규식
  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
  const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

  // 2024.02.01 [shiningtrue]: 인증 시간 한자리 앞에 0 처리
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
  const Signup = () => {
    const handleKeyDown = (event) => {
      // Enter 키의 키 코드는 13입니다.
      if (event.keyCode === 13) {
        nextButton();
      }
    };

    // 2024.01.26 [shiningtrue]: 약관 동의 체크 여부
    const [check, setCheck] = useState(false);

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    // 2024.01.26 [shiningtrue]: 패스워드 형식 체크
    const [password, setPassword] = React.useState("");

    // 2024.01.26 [shiningtrue]: 패스워드 더블 체크
    const [passwordChk, setPasswordChk] = React.useState("");

    // 2024.01.26 [shiningtrue]: errorMsg show hide용
    const [errorMassageStyle, setErrorMsgStyle] = useState({
      email: { display: "none" },
      password: { display: "none" },
      passwordDoubleCheck: { display: "none" },
    });

    // 2024.01.30 [shiningtrue]: errorMsg 용
    const [errorMassage, setErrorMsg] = useState("");

    // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
    const [loading, setLoading] = useState(false);

    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    // 2024.01.26 [shiningtrue]: 에러 메세지 show & hide
    const errorMsgStyle = (name, type) => {
      setErrorMsgStyle((prevState) => {
        return { ...prevState, [name]: { display: type } };
      });
    };

    const nextUserInfoSetting = (name, value) => {
      setNextUserInfo((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    // 2024.01.26 [shiningtrue]: 이메일 정규식 체크
    const emailCheck = (email) => {
      if (email.match(emailRegEx) === null) {
        //형식에 맞지 않을 경우 아래 콘솔 출력
        setErrorMsg("이메일 형식이 맞지 않습니다.");
        errorMsgStyle("email", "block");
        return;
      } else {
        // 맞을 경우 출력
        errorMsgStyle("email", "none");
        //setErrorMsgStyle({'email':{display: 'none'}});
      }
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 정규식 체크
    const passwordCheck = (password) => {
      setPassword(password);
      passwordDoubleCheck(password, passwordChk);
      if (password.match(passwordRegEx) === null) {
        //형식에 맞지 않을 경우 아래 콘솔 출력
        errorMsgStyle("password", "block");
        return;
      } else {
        // 맞을 경우 출력
        errorMsgStyle("password", "none");
      }
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 재입력 체크
    const passwordDoubleCheck = (password, passwordChk) => {
      setPasswordChk(passwordChk);
      if (password !== passwordChk) {
        errorMsgStyle("passwordDoubleCheck", "block");
        return;
      } else {
        errorMsgStyle("passwordDoubleCheck", "none");
      }
    };

    const emailOverLapCheckApiSuccessCallback = useCallback((data, params) => {
      errorMsgStyle("email", "none");
      if (data.data.code === "0001") {
        nextUserInfoSetting("email", params.email);
        nextUserInfoSetting("password", params.password);
        nextUserInfoSetting("nickName", params.nickName);
        setState("2"); //이메일 인증 화면으로 이동
      } else {
        setErrorMsg(data.data.message);
        errorMsgStyle("email", "block");
      }
    }, []);

    // 2024.01.26 [shiningtrue]: Email 인증 버튼
    const nextButton = async (e) => {
      if (!inputValues.email) {
        toast.error("이메일을 입력해주세요.");
        return false;
      }

      if (!inputValues.nickName) {
        toast.error("이름을 입력해주세요.");
        return false;
      }

      if (!inputValues.password) {
        toast.error("비밀번호를 입력해주세요.");
        return false;
      }

      if (!inputValues.passwordCheck) {
        toast.error("비밀번호 재입력을 입력해주세요.");
        return false;
      }

      if (password !== passwordChk) {
        errorMsgStyle("passwordDoubleCheck", "block");
        toast.error("가입정보를 확인해주세요.");
        return false;
      }

      if (!check) {
        toast.error("서비스이용약관 및 개인정보수집이용에 동의해주세요.");
        return false;
      }

      if (
        errorMassageStyle.email.display === "block" ||
        errorMassageStyle.password.display === "block" ||
        errorMassageStyle.passwordDoubleCheck.display === "block"
      ) {
        toast.error("가입정보를 확인해주세요.");
        return false;
      }
      setLoading(true);
      const params = {
        email: inputValues.email,
        password: inputValues.password,
        nickName: inputValues.nickName,
      };
      await emailOverLapCheckApi(
        axios,
        params,
        emailOverLapCheckApiSuccessCallback
      );
      setLoading(false);
    };

    return (
      <>
        {/* Loader 실행 div */}
        {loading && (
          <>
            <div className="dim-overlay" />
            <div className="loader-container">
              <Loader />
            </div>
          </>
        )}
        <div>
          <div className="ta">
            <p className="H3_B g900 mt72 mb48">이메일로 가입하기</p>
            <div className="mb24" style={{ textAlign: "start" }}>
              <SignInput
                title={"이메일"}
                placeholder={"이메일을 입력해주세요."}
                mt={"36"}
                name="email"
                onChange={(e) => {
                  onChangeInput(e);
                  emailCheck(e.target.value);
                }}
                errorMsg={errorMassage}
                errorMsgStyle={errorMassageStyle.email}
                onKeyDown={handleKeyDown}
              />
              <SignInput
                title={"이름"}
                placeholder={"이름을 입력해주세요."}
                mt={"36"}
                name="nickName"
                onChange={(e) => {
                  onChangeInput(e);
                }}
                onKeyDown={handleKeyDown}
              />
              <SignInput
                title={"비밀번호"}
                placeholder={"비밀번호를 입력해주세요."}
                mt={"36"}
                name="password"
                type="password"
                onChange={(e) => {
                  onChangeInput(e);
                  passwordCheck(e.target.value);
                }}
                errorMsg={
                  "비밀번호는 영문,숫자,특수문자 조합 8자리 이상으로 입력해주십시오."
                }
                errorMsgStyle={errorMassageStyle.password}
                onKeyDown={handleKeyDown}
              />

              <SignInput
                title={"비밀번호 재입력"}
                placeholder={"비밀번호를 재입력해주세요."}
                mt={"36"}
                name="passwordCheck"
                type="password"
                onChange={(e) => {
                  onChangeInput(e);
                  passwordDoubleCheck(password, e.target.value);
                }}
                errorMsg={"비밀번호가 동일하지 않습니다."}
                errorMsgStyle={errorMassageStyle.passwordDoubleCheck}
                onKeyDown={handleKeyDown}
              />
              <div
                className="content g500"
                style={{ marginTop: "36px", marginBottom: "36px" }}
              >
                <Image
                  className="mr6"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={check ? "ic_checkbox_on" : "ic_checkbox_off"}
                  size={"sm"}
                  onClick={() => setCheck((prev) => !prev)}
                />
                <span>
                  <strong>
                    <u>
                      <Link
                        to={"/term"}
                        state={{type: "termOfUse"}}
                        style={{
                          color: "#636c83"
                        }}>
                        서비스 이용약관
                      </Link>
                    </u>
                  </strong>{" "}
                  및{" "}
                  <strong>
                    <u>
                      <Link
                        to={"/term"}
                        state={{type: "privacyPolicy"}}
                        style={{
                          color: "#636c83"
                        }}>
                        개인정보처리방침
                      </Link>
                    </u>
                  </strong>
                  에 동의합니다.
                </span>
              </div>
              <SquareButton
                className={"btn-pri mt16"}
                text={"다음으로 (Email 인증)"}
                size="lg"
                onClick={nextButton}
              />
            </div>
            <div className="bt-g150"></div>
            {/*<div className="mt24 mb36">
            <SquareButton
              className={"bg-g100 g900 b-g150"}
              text={"카카오로 가입하기"}
              size="lg"
              type="sec"
              icon={
                <Image
                  className="mr6"
                  path={process.env.PUBLIC_URL}
                  name={"ic_kakao"}
                  size={"md"}
                />
              }
            />
            <SquareButton
              className={"bg-g100 g900 b-g150 mt8 mb8"}
              text={"구글로 가입하기"}
              size="lg"
              type="sec"
              icon={
                <Image
                  className="mr6"
                  path={process.env.PUBLIC_URL}
                  name={"ic_google"}
                  size={"md"}
                />
              }
            />
            <SquareButton
              className={"bg-g100 g900 b-g150"}
              text={"페이스북으로 가입하기"}
              size="lg"
              type="sec"
              icon={
                <Image
                  className="mr6"
                  path={process.env.PUBLIC_URL}
                  name={"ic_facebook"}
                  size={"md"}
                />
              }
            />
          </div>*/}
          </div>
        </div>
      </>
    );
  };

  const SignupEmail = () => {
    const navigate = useNavigate();

    const [seconds, setSeconds] = useState(300);

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
    const [loading, setLoading] = useState(false);

    let timer = "";

    useEffect(() => {
      timer =
        seconds > 0 &&
        setInterval(() => {
          setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
        }, 1000);

      // 컴포넌트가 언마운트되거나 타이머가 재설정될 때 타이머를 정리합니다.
      return () => clearTimeout(timer);
    }, [seconds]); // seconds가 변경될 때마다 타이머를 재설정합니다.

    // 분과 초를 계산
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // 2024.01.26 [shiningtrue]: input값 담기 위함
    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const registerApiSuccessCallback = useCallback((data) => {
      if (data.data.code === "0001") {
        toast.success("회원가입이 완료되었습니다.");
        navigate("/signin"); //로그인 화면으로 이동
      } else {
        toast.error(data.data.message);
      }
    }, []);

    const certificationAndjoin = async () => {
      const params = {
        email: nextUserInfo.email,
        password: nextUserInfo.password,
        certificationNumber: inputValues.certificationNumber,
        name: nextUserInfo.nickName,
        useYn: true,
        privacyYn: true,
        dormantAccountYn: false,
        lastLoginDt: "",
      };
      await registerApi(axios, params, registerApiSuccessCallback);
    };

    const emailCheckAndResendApiSuccessCallback = useCallback((data) => {
      if (data.data.code === "0001") {
        toast.success("인증번호가 재전송 되었습니다.");
        clearTimeout(timer);
        setSeconds(300);
      } else {
        toast.error(data.data.message);
      }
    }, []);

    // 2024.01.26 [shiningtrue]: Email 재전송
    const reSend = async (e) => {
      const params = {
        email: nextUserInfo.email,
      };
      setLoading(true);
      await emailCheckAndResendApi(
        axios,
        params,
        emailCheckAndResendApiSuccessCallback
      );
      setLoading(false);
    };
    return (
      <>
        {/* Loader 실행 div */}
        {loading && (
          <>
            <div className="dim-overlay" />
            <div className="loader-container">
              <Loader />
            </div>
          </>
        )}
        <div>
          <div className="ta">
            <p className="H3_B g900">이메일로 가입하기</p>
            <div className="Body4_R g700 mt14 mb48">
              <p>{nextUserInfo.email} 으로</p>
              <p>인증번호를 전송하였습니다.</p>
            </div>
            <div className="mb48" style={{ textAlign: "start" }}>
              <SignInput
                title={"인증번호"}
                placeholder={"인증번호 입력 후 Enter키를 입력해주세요."}
                name="certificationNumber"
                onChange={(e) => {
                  onChangeInput(e);
                }}
                suffix={
                  <div className="error ">
                    {addLeadingZero(minutes)}:{addLeadingZero(remainingSeconds)}
                  </div>
                }
              />
              <SquareButton
                className={"btn-line"}
                text={"재전송"}
                size="lg"
                type={"sec"}
                onClick={reSend}
              />
            </div>
            <div className="content jc-c">
              <Link to="/signin">
                <SquareButton
                  className={"btn-sec"}
                  text={"가입 취소"}
                  size="md"
                />
              </Link>
              <SquareButton
                className={"btn-pri ml6"}
                text={"인증 확인 (가입 완료)"}
                size="md"
                onClick={certificationAndjoin}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const SignupSns = () => {
    return (
      <div>
        <div className="ta">
          <p className="H3_B g900">SNS로 가입하기</p>
          <div className="Body4_R g700 mt14">
            <p>해당 E-mail로 가입된 사용자 계정이 있습니다.</p>
            <p>E-mail 로그인을 이용해 주세요.</p>
          </div>
          <Link to="/signin">
            <SquareButton
              className={"btn-pri mt48 mb36"}
              text={"이메일로 로그인"}
              size="lg"
              // onClick={}
            />
          </Link>
          <div className="Body5_R g500" onClick={() => setState("4")}>
            비밀번호 찾기
          </div>
        </div>
      </div>
    );
  };

  const FindPw = () => {
    const navigate = useNavigate();

    const handleKeyDown = (event) => {
      // Enter 키의 키 코드는 13입니다.
      if (event.keyCode === 13) {
        nextButton();
      }
    };

    const [seconds, setSeconds] = useState(300);

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
    const [loading, setLoading] = useState(false);

    // 분과 초를 계산
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    // 한 자리 숫자 앞에 0을 붙이는 함수
    let timer = "";

    useEffect(() => {
      // 컴포넌트가 언마운트되거나 타이머가 재설정될 때 타이머를 정리합니다.
      return () => clearTimeout(timer);
    }, [seconds, timer]); // seconds가 변경될 때마다 타이머를 재설정합니다.

    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const findPasswordSendApiSuccessCallback = useCallback((data) => {
      if (data.data.code === "0001") {
        toast.success("인증번호가 전송 되었습니다.");
        clearTimeout(timer);
        setSeconds(300);
        timer =
          seconds > 0 &&
          setInterval(() => {
            setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
          }, 1000);
      } else {
        toast.error(data.data.data.message);
      }
    }, []);

    // 2024.01.31 [shiningtrue]: 비밀번호 찾기 인증 메일 전송
    const emailSend = async (e) => {
      if (!inputValues.email) {
        toast.error("이메일을 입력해 주세요.");
        return false;
      }
      setLoading(true);
      const params = {
        email: inputValues.email,
      };
      await findPasswordSendApi(
        axios,
        params,
        findPasswordSendApiSuccessCallback
      );
      setLoading(false);
    };

    //취소 버튼
    const cancle = () => {
      navigate("/signin");
    };

    const nextUserInfoSetting = (name, value) => {
      setNextUserInfo((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const certificationNumberCheckApiSuccessCallback = useCallback((data) => {
      if (data.data.code === "0001") {
        nextUserInfoSetting("email", inputValues.email);
        setState("5"); //비밀번호 변경 화면으로 이동
      } else {
        toast.error(data.data.message);
      }
    }, []);

    const nextButton = async () => {
      if (!inputValues.certificationNumber) {
        toast.error("인증번호를 입력해 주세요.");
        return false;
      }
      const params = {
        email: inputValues.email,
        certificationNumber: inputValues.certificationNumber,
      };
      await certificationNumberCheckApi(
        axios,
        params,
        certificationNumberCheckApiSuccessCallback
      );
    };

    return (
      <>
        {/* Loader 실행 div */}
        {loading && (
          <>
            <div className="dim-overlay" />
            <div className="loader-container">
              <Loader />
            </div>
          </>
        )}
        <div>
          <div className="ta">
            <p className="H3_B g900">비밀번호 찾기</p>
            <div className="Body4_R g700 mt14 mb48">
              <p>가입하신 E-mail 인증을 통해 비밀번호를 </p>
              <p>재설정할 수 있습니다.</p>
            </div>
            <div className="mb48" style={{ textAlign: "start" }}>
              <SignInput
                title={"이메일"}
                placeholder={"E-mail 주소를 입력해주세요."}
                name="email"
                onChange={(e) => {
                  onChangeInput(e);
                }}
              />
              <SquareButton
                className={"btn-line mb36"}
                text={"인증번호 전송"}
                size="lg"
                type={"sec"}
                onClick={emailSend}
              />
              <SignInput
                title={"인증번호"}
                placeholder="인증번호 입력 후 Enter키를 입력해주세요."
                suffix={
                  <div className="error ">
                    {addLeadingZero(minutes)}:{addLeadingZero(remainingSeconds)}
                  </div>
                }
                name="certificationNumber"
                onChange={(e) => {
                  onChangeInput(e);
                }}
                onKeyDown={handleKeyDown}
                mb="0"
              />
            </div>
            <div className="content jc-c">
              <SquareButton
                className={"btn-sec"}
                text={"취소"}
                size="md"
                onClick={cancle}
              />
              <SquareButton
                className={"btn-pri ml6"}
                text={"인증 확인 (다음)"}
                size="md"
                onClick={nextButton}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const UpdatePw = () => {
    const navigate = useNavigate();

    // 2024.01.26 [shiningtrue]: 패스워드 형식 체크
    const [password, setPassword] = React.useState("");

    // 2024.01.26 [shiningtrue]: 패스워드 더블 체크
    const [passwordChk, setPasswordChk] = React.useState("");

    // 2024.01.26 [shiningtrue]: errorMsg show hide용
    const [errorMassageStyle, setErrorMsgStyle] = useState({
      password: { display: "none" },
      passwordDoubleCheck: { display: "none" },
    });

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const errorMsgStyle = (name, type) => {
      setErrorMsgStyle((prevState) => {
        return { ...prevState, [name]: { display: type } };
      });
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 정규식 체크
    const passwordCheck = (password) => {
      setPassword(password);
      passwordDoubleCheck(password, passwordChk);
      if (password.match(passwordRegEx) === null) {
        //형식에 맞지 않을 경우 아래 콘솔 출력
        errorMsgStyle("password", "block");
        return;
      } else {
        // 맞을 경우 출력
        errorMsgStyle("password", "none");
      }
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 재입력 체크
    const passwordDoubleCheck = (password, passwordChk) => {
      setPasswordChk(passwordChk);
      if (password !== passwordChk) {
        errorMsgStyle("passwordDoubleCheck", "block");
        return;
      } else {
        errorMsgStyle("passwordDoubleCheck", "none");
      }
    };

    const passwordChangeApiSuccessCallback = useCallback((data) => {
      if (data.data.code === "0001") {
        toast.success("비밀번호가 변경 되었습니다.");
        navigate("/signin");
      } else {
        toast.error(data.data.message);
      }
    }, []);

    const changeButton = async () => {
      if (!inputValues.password) {
        toast.error("비밀번호를 입력해주세요.");
        return false;
      }

      if (!inputValues.passwordCheck) {
        toast.error("비밀번호 재입력을 입력해주세요.");
        return false;
      }

      if (
        errorMassageStyle.password.display === "block" ||
        errorMassageStyle.passwordDoubleCheck.display === "block"
      ) {
        toast.error("비밀번호를 확인해주세요.");
        return false;
      }

      const params = {
        email: nextUserInfo.email,
        password: inputValues.password,
      };
      await passwordChangeApi(axios, params, passwordChangeApiSuccessCallback);
    };
    return (
      <div>
        <div className="ta">
          <p className="H3_B g900">비밀번호 찾기</p>
          <div className="Body4_R g700 mt14 mb48">
            <p>E-mail 인증이 완료되었습니다.</p>
            <p>비밀번호를 다시 설정해 주세요.</p>
          </div>
          <div className="mb48" style={{ textAlign: "start" }}>
            <SignInput
              title={"비밀번호"}
              placeholder={"신규 비밀번호를 입력해주세요."}
              mt={"36"}
              name="password"
              type="password"
              onChange={(e) => {
                onChangeInput(e);
                passwordCheck(e.target.value);
              }}
              errorMsg={
                "비밀번호는 영문,숫자,특수문자 조합 8자리 이상으로 입력해주십시오."
              }
              errorMsgStyle={errorMassageStyle.password}
            />
            <SignInput
              title={"새 비밀번호"}
              placeholder={"신규 비밀번호를 재입력해주세요."}
              mt={"36"}
              name="passwordCheck"
              type="password"
              onChange={(e) => {
                onChangeInput(e);
                passwordDoubleCheck(password, e.target.value);
              }}
              errorMsg={"비밀번호가 동일하지 않습니다."}
              errorMsgStyle={errorMassageStyle.passwordDoubleCheck}
            />
            <SquareButton
              className={"btn-pri"}
              text={"변경하기"}
              size="lg"
              onClick={changeButton}
            />
          </div>
        </div>
      </div>
    );
  };

  const contentList = [
    { key: "1", content: <Signup /> },
    { key: "2", content: <SignupEmail /> },
    { key: "3", content: <SignupSns /> },
    { key: "4", content: <FindPw /> },
    { key: "5", content: <UpdatePw /> },
  ];
  return (
    <div className="signup-body">
      {
        contentList.find((v) => {
          return v.key === state;
        }).content
      }
    </div>
  );
};
export default SignupContent;
