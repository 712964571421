// 로그인 API
import { toast } from "react-toastify";

export const loginApi = async (
  axios,
  params,
  successCallback,
  failCallback,
) => {
  await axios
    .post(`/user/login`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        failCallback(response);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 자동로그인 API
export const autoLoginApi = async (axios, params, successCallback) => {
  await axios
    .post(`/user/autoLogin`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 비밀번호 재입력 체크 API
export const passwordChangeApi = async (axios, params, successCallback) => {
  await axios
    .patch(`/user/passwordChange`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 인증번호 전송 API
export const certificationNumberSendApi = async (
  axios,
  params,
  successCallback,
) => {
  await axios
    .get(`/user/sendCertificationNumber`, {
      params: {
        email: params.email,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 휴먼계정 해제 API
export const cancelSleeperApi = async (axios, params, successCallback) => {
  await axios
    .patch(`/user/dormantAccountClear`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
