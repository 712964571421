import React, { useEffect, useState } from "react";
import { Layout, Menu, ConfigProvider } from "antd";
import Image from "../../atoms/Image";
import {Link, useNavigate} from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import { tabOpenListState } from "../../../recoil/api/apiState";
import { commonErrorEditingRecoilState } from "../../../recoil/commonErrorCode/commonErrorCodeState";
import {commonVariableEditingRecoilState} from "../../../recoil/commonVariableState/commonVariableState";

const { Sider } = Layout;

let navList = [
  { key: "1", icon: "ic_api", text: "APIs" },
  { key: "2", icon: "ic_common_error", text: "공통에러" },
  { key: "3", icon: "ic_common_variable", text: "공통변수" },
  { key: "4", icon: "ic_user", text: "사용자" },
];

const MainSider = ({ menuKey, setMenuKey, isSubSiderOpen, setIsSubSiderOpen }) => {
  const [project] = useRecoilState(projectState);
  // 2024.03.07 [energysteel]: 열려있는 Tab 리스트
  const [tabOpenList] = useRecoilState(tabOpenListState);
  // 2024.03.18 [shiningtrue]: 공통에러 수정 여부 상태 관리 변수
  const [commonResponseFormEditingState] = useRecoilState(
    commonErrorEditingRecoilState,
  );
  // 2024.03.18 [shiningtrue]: 공통변수 수정 여부 상태 관리 변수
  const [commonVariableEditingState] = useRecoilState(commonVariableEditingRecoilState);
  const navigate = useNavigate();

  // 2024.04.17 [shiningtrue]: 메뉴 이동시 불필요 class 제거 하기 위함
  useEffect(() => {
    const menuItems = document.querySelectorAll(".ant-menu-item");
    menuItems.forEach((item) => {
      const key = item.getAttribute("data-menu-key");
      if (key === menuKey) {
        item.classList.add("bg-g700", "br4", "b-g500");
        item.classList.remove("bg-g800");
      } else {
        item.classList.remove(
          "ant-menu-item",
          "ant-menu-item-selected",
          "bg-g800",
          "br4",
        );
        item.classList.add("ant-menu-item", "bg-g800", "br4");
      }
    });
  }, [menuKey]);

  // 2024.04.26 [shiningtrue]: 좌측 메뉴 수정여부 표시를 위해 API 수정여부 확인 하기위함
  const [hasApiEditingTrue, setHasApiEditingTrue] = useState(false);
  useEffect(() => {
    setHasApiEditingTrue(
      tabOpenList.some(
        (item) =>
          item.requestEditing === true ||
          item.responseHeaderEditing === true ||
          item.responseParameterEditing === true ||
          item.responseCaseEditing === true ||
          item.uriEditing === true,
      ),
    );
  }, [tabOpenList]);

  // 2024.04.26 [shiningtrue]: 좌측 메뉴 수정여부 표시를 위해 공통에러 수정여부 확인 하기위함
  const [hasCommonErrorEditingTrue, setHasCommonErrorEditingTrue] =
    useState(false);
  useEffect(() => {
    setHasCommonErrorEditingTrue(commonResponseFormEditingState.errorCodeEditing || commonResponseFormEditingState.responseBodyEditing || commonResponseFormEditingState.responseHeaderEditing);
  }, [commonResponseFormEditingState]);

  // 2024.04.26 [shiningtrue]: 좌측 메뉴 수정여부 표시를 위해 공통변수 수정여부 확인 하기위함
  const [hasCommonVariableditingTrue, setHasCommonVariableEditingTrue] =
    useState(false);
  useEffect(() => {
    setHasCommonVariableEditingTrue(commonVariableEditingState.editing);
  }, [commonVariableEditingState]);

  const getSplitTextList = (text) => {
    const textList = text.split(" ");
    return textList;
  };

  const onClickMenu = (e) => {
    setMenuKey(e.key);
    if (e.key === menuKey) {
      setIsSubSiderOpen(!isSubSiderOpen);
    } else {
      setIsSubSiderOpen(true);
    }
  };

  /**
   * 2024.05.16 [energysteel]: 사용가이드 이동 시 다시 돌아오기 버튼을 위해 projectId localStorage 저장
   */
  const handleManualClick = () => {
    window.localStorage.setItem("projectId", project.id);
    navigate("/manual");
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemSelectedBg: "#2f333e",
            darkItemBg: "#20232A",
            darkItemHoverBg: "#181a1f",
          },
        },
      }}
    >
      <Sider className="project-sider" style={{ background: "#20232A" }}>
        {/* TODO : Deprecated 해결 */}
        <Menu theme="dark">
          {navList
            .filter((nav) => {
              if (isProjectOwnerOrAdminAuthority(project.authority)) {
                return nav;
              } else {
                return nav.key !== "4";
              }
            })
            .map(({ key, icon, text }) => {
              return (
                <Menu.Item
                  className={`menu-box br4 ${
                    key === menuKey ? "b-g500" : "b-g800"
                  }`}
                  key={key}
                  data-menu-key={key}
                  onClick={onClickMenu}
                >
                  {key === "1" ? (
                    <>
                      {hasApiEditingTrue ? (
                        <div className="active-icon" />
                      ) : null}
                    </>
                  ) : key === "2" ? (
                    <>
                      {hasCommonErrorEditingTrue ? (
                        <div className="active-icon" />
                      ) : null}
                    </>
                  ) : key === "3" ? (
                    <>
                      {hasCommonVariableditingTrue ? (
                        <div className="active-icon" />
                      ) : null}
                    </>
                  ) : null}

                  <div style={{ lineHeight: "20px", textAlign: "center" }}>
                    <Image
                      path={`${process.env.PUBLIC_URL}/siderbar`}
                      name={icon}
                    />
                  </div>
                  {getSplitTextList(text).map((text, i) => {
                    return (
                      <div
                        key={key + i}
                        className="menu-desc Body7_R white"
                        style={{ textAlign: "center" }}
                      >
                        {text}
                      </div>
                    );
                  })}
                </Menu.Item>
              );
            })}
        </Menu>
        <div>
          <div
            className={"menu-box bg-g850 br4"}
            style={{
              margin: "4px",
              position: "absolute",
              bottom: "0",
            }}
            onClick={handleManualClick}
          >
            <div style={{ lineHeight: "20px", textAlign: "center" }}>
              <Image
                path={`${process.env.PUBLIC_URL}/siderbar`}
                name={"ic_info"}
              />
            </div>
            <div
              className="menu-desc Body7_R white"
              style={{ textAlign: "center" }}
            >
              사용가이드
            </div>
          </div>
        </div>
      </Sider>
    </ConfigProvider>
  );
};

export default MainSider;
