export const deepCopy = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    const copy = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }

    return copy;
}

export const findDifferent = (originalArray, modifiedArray) => {
    const differentObjects = [];

    // 2024.03.08 [energysteel]: 데이터 추가 파악
    for (const modifiedObject of modifiedArray) {
        const originalObject = originalArray.find(item => item.id === modifiedObject.id);
        if (!originalObject) {
            differentObjects.push(modifiedObject);
        }
    }

    for (const originalObject of originalArray) {
        let modifiedObject = modifiedArray.find(item => item.id === originalObject.id);

        // 2024.04.18 [energysteel]: 데이터 수정 파악
        if (modifiedObject && !isObjectEqual(originalObject, modifiedObject)) {
            differentObjects.push(modifiedObject);
        // 2024.04.18 [energysteel]: 데이터 삭제 파악
        } else if (!modifiedObject) {
            originalObject.deleteFlag = true;
            differentObjects.push(originalObject);
        }
    }

    return differentObjects;
}

const isObjectEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            const isArrayObj1 = Array.isArray(obj1[key]);
            const isArrayObj2 = Array.isArray(obj2[key]);

            if (isArrayObj1 && isArrayObj2) {
                if (obj1[key].length === 0 && obj2[key].length === 0) {
                    return true;
                }
                return isObjectEqual(obj1[key], obj2[key]);
            } else if(typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                return isObjectEqual(obj1[key], obj2[key]);
            } else {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        }
    }

    return true;
}

export const createModifyRequest = (updatedTreeData) => {
    const request = [];
    for (let i = 0; i < updatedTreeData.length; i++) {
        if (updatedTreeData[i].deleteFlag) {
            continue;
        }

        pushModifyRequest(request, updatedTreeData[i]);
        for (let j = 0; j < updatedTreeData[i].children.length; j++) {
            pushModifyRequest(request, updatedTreeData[i].children[j]);
        }
    }
    return request;
}

const pushModifyRequest = (request, updatedTreeData) => {
    const obj = {
        id: updatedTreeData.id,
        order: updatedTreeData.key.split("-").pop(),
        upperId: updatedTreeData.upperId,
    }
    request.push(obj);
}