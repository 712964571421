import {atom, useRecoilState} from "recoil";
import {toast} from "react-toastify";
import {alertHelpIcon} from "../api/apiState";

export const commonErrorCodeConstantListState = atom({
    key: "commonErrorCodeConstantListState",
    default: [],
})

export const commonErrorEditingRecoilState = atom({
    key: "commonErrorEditingRecoilState",
    default: {
        responseHeaderEditing: false,
        responseBodyEditing: false,
        errorCodeEditing: false,
    },
})

export const commonErrorCodeCurrentTimeState = atom({
    key: "commonErrorCodeCurrentTimeState",
    default: {
        response: "",
        errorCode: "",
    },
})

export const validateErrorCode = (commonErrorCodeList, setHelpIconMessage) => {
    let isValid = true;
    let type = '';
    let typeMessage = '';
    let name = '';

    const commonErrorCodeValidateMessage = commonErrorCodeList.filter(header => header.message === "");
    if (commonErrorCodeValidateMessage.length > 0) {
        isValid = false;
        type = commonErrorCodeValidateMessage[0].type;;
        name = 'Error Message';
    }

    const commonErrorCodeValidateCode = commonErrorCodeList.filter(header => header.code === "");
    if (commonErrorCodeValidateCode.length > 0) {
        isValid = false;
        type = commonErrorCodeValidateCode[0].type;
        name = 'Error Code';
    }

    const commonErrorCodeValidateName = commonErrorCodeList.filter(header => header.name === "");
    if (commonErrorCodeValidateName.length > 0) {
        isValid = false;
        type = commonErrorCodeValidateName[0].type;
        name = 'Error Name';
    }

    if (type === "BUSINESS") {
        typeMessage = "Business";
    } else if (type === "REQ_VALIDATION") {
        typeMessage = "Validation";
    } else if (type === "HTTP") {
        typeMessage = "Http";
    } else if (type === "MOCKBOT") {
        typeMessage = "MockBot";
    }

    if (!isValid) {
        setHelpIconMessage({
            code: "error",
            message: `에러 코드 ${typeMessage}의 ${name}을(를) 입력해주세요.`,
        });
    }

    return isValid;
}