import { useEffect, useRef } from "react";
import {useRecoilState} from "recoil";
import {manualActiveIdState} from "../recoil/manual/manualState";

const useIntersectionObservation = () => {
  const contentRef = useRef({});
  const [activeId, setActiveId] = useRecoilState(manualActiveIdState);

  useEffect(() => {
    const callback = (observedContent) => {
      try {
        observedContent.forEach((content) => {
          contentRef.current[content.target?.id] = content;
        });

        const visibleContent = Object.values(contentRef.current).filter(
          (content) => content.isIntersecting
        );

        setActiveId(visibleContent[0]?.target?.id);
      } catch (error) {
        console.error("Error in IntersectionObserver.callback:", error);
      }
    };
    //1. 새로운 observer 설정
    const observer = new IntersectionObserver(callback, {
      rootMargin: "-20% 0px",
      threshold: [0, 0.5, 1],
    });

    //2. DOM 요소 찾고 Observer달아주기
    const contents = [...document.querySelectorAll(".item")];

    contents.forEach((content) => observer.observe(content));

    //3. 언 마운트시 옵저버 해제
    return () => observer.disconnect();
  }, [activeId]);
};

export default useIntersectionObservation;
