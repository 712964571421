import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {useRecoilValue} from "recoil";
import {userState} from "../../recoil/user/userState";

const AuthLayout = () => {
    const userSession = useRecoilValue(userState);

    return (
        <div>
            {
                userSession.id == null ? <Navigate to="/signin"/> : <div><Outlet/></div>
            }
        </div>
    );
};

export default AuthLayout;