import React, {useEffect, useState} from "react";
import useIntersectionObservation from "../../hooks/useIntersectionObservation";
import {useRecoilState} from "recoil";
import {manualActiveIdState} from "../../recoil/manual/manualState";

const Navigation = ({ list }) => {
  const [activeId, setActiveId] = useRecoilState(manualActiveIdState);


  useIntersectionObservation();

  return (
    <nav className="nav">
      <p className="nav-title">목차</p>
      {list.map((value) => {
        const isActive = activeId === `content${value.idx}` ? "active" : "";
        return (
          <React.Fragment key={value.idx}>
            <p>
              <a className={isActive} href={`#${value.idx}`}>
                {value.plainTitle}
              </a>
            </p>
            {value.children &&
              value.children.map((val) => {
                const isActive =
                  activeId === `content${val.idx}` ? "active" : "";
                return (
                  <p key={val.idx} className={"sub"}>
                    <a className={isActive} href={`#${val.idx}`}>
                      {val.plainTitle}
                    </a>
                  </p>
                );
              })}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default Navigation;
