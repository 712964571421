import { atom } from "recoil";
import {recoilPersist} from "recoil-persist";
const { persistAtom } = recoilPersist();

export const bbsPageState = atom({
  key: "bbsPageState",
  default: {
    pageNumber: 0,
    pageSize: 10,
  },
});

export const noticeTapState = atom({
  key: "noticeTapState",
  default: {
    all: true,
    service: false,
    work: false,
  },
});

export const supportMenuKeyState = atom({
  key: "supportMenuKeyState",
  default: {
    menu: ""
  },
  effects_UNSTABLE: [persistAtom],
});