import {Modal} from "antd";
import CreateProjectTable from "../../molecules/tables/CreateProjectTable";
import ModalHeadline from "../../atoms/ModalHeadline";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import { useRecoilState } from "recoil";
import { projectListState, modifyProjectState, isModalOpenState, projectState, closeModal } from '../../../recoil/project/projectState';
import { modifyProjectApi } from "../../../api/project/projectApi";
import useAxiosInterceptor from "../../../axios/axios";
import {toast} from "react-toastify";
import React, {useEffect} from "react";
import AlertFooter from "../../molecules/modals/AlertFooter";
import {alertHelpIcon, helpIconMessageState, alertHelpIconOpenState} from "../../../recoil/api/apiState";

const SettingProjectModal = ({ project }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 현재 프로젝트 정보
  const [, setProject] = useRecoilState(projectState);
  // 2024.03.06 [energysteel]: 프로젝트 수정 후 메인 페이지 랜더링 용도의 전체 프로젝트 리스트
  const [projectList, setProjectList] = useRecoilState(projectListState);
  // 2024.03.06 [energysteel]: 프로젝트 수정 객체
  const [modifyProject, setModifyProject] = useRecoilState(modifyProjectState);
  // 2024.03.06 [energysteel]: Modal Open 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  // 2024.04.03 [energysteel]: Owner가 아닌 유저가 프로젝트 정보를 클릭한지 파악
  const isNotOwnerAndProjectInfoClick = project.authority !== 'OWNER';
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);



  /**
   * 2024.03.06 [energysteel]: 프로젝트 설정 > 수정하기 버튼 onClick Event
   */
  const handleModifyOk = () => {
    setModifyProject((prevStates) => ({
      ...prevStates,
      id: project.id,
    }));

    modifyProjectApi(
      axios,
      modifyProject, 
      modifyProjectApiSuccessCallback,
      failCallback,
    );
  }

  /**
   * 2024.03.06 [energysteel]: 프로젝트 설정 > 수정하기 성공 Callback
   */
  const modifyProjectApiSuccessCallback = (response) => {
    setProjectList((prevProjectList) => {
      return prevProjectList.map(item => {
        if (item.id === project.id) {
          return {
            ...setupSuccessModifyProject(item, response.data.data),
          };
        }
        return item;
      });
    });

    setProject(prevProject => {
        return setupSuccessModifyProject(prevProject, response.data.data);
      }
    )

    closeModal(setIsModalOpen, project.id, 'update');
  }

  /**
   * 2024.04.04 [energysteel]: 수정된 Project Data setup
   * @param prev 기존에 가지고 있는 Data
   * @param response API 성공 응답 값
   * @returns {*&{updateDate: *, accessAuthority: (string|*), context, description}}
   */
  const setupSuccessModifyProject = (prev, response) => {
    return {
      ...prev,
      name: response.name,
      context: response.context,
      accessAuthority: response.accessAuthority,
      description: response.description,
      updateDate: response.updateDate
    }
  };

  /**
   * 2024.03.27 [energysteel]: 프로젝트명 중복
   */
  const failCallback = (response) => {
    setHelpIconMessage({
      code: "error",
      message: response.data.message,
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }

  /**
   * 2024.03.06 [energysteel]: 프로젝트 설정 > 수정하기 > 취소 onClick Event
   */
  const handleCancel = () => {
    closeModal(setIsModalOpen, project.id, 'update');
  }

  return (
    <Modal
      className="modal"
      title={
        <Title
          title={isNotOwnerAndProjectInfoClick ? "Project 정보" : "Project 설정"}
          subTitle={isNotOwnerAndProjectInfoClick ? "Project 정보를 확인하세요." : "설정 정보를 입력해주세요."}
        />
      }
      open={isModalOpen?.find(v => v.id === project.id)?.update}
      onCancel={handleCancel}
      width={528}
      centered
      footer={
        isNotOwnerAndProjectInfoClick ?
          <AlertFooter
            textOk={"확인"}
            handleOk={handleCancel}
          />
          :
          <Footer
            textCancel={"취소"}
            textOk={"수정하기"}
            handleCancel={handleCancel}
            handleOk={handleModifyOk}
          />
      }
    >
      <ModalHeadline />
      <CreateProjectTable
        isUpdate={true}
        project={project}
        isNotOwnerAndProjectInfoClick={isNotOwnerAndProjectInfoClick}
      />
    </Modal>
  );
};

export default SettingProjectModal;
