import React from "react";

const APIChild = ({ clazz, setManualKey }) => {
  return (
    <div className={clazz}>
      <p>API는 한 애플리케이션이 다른 애플리케이션의 기능에 접근할 수 있는 구조화된 방법을 제공합니다.<br/>
        클라이언트 애플리케이션(예: 모바일 앱)은 서버에 요청을 보내고, 요청이 처리된 후 MockBot은 <br/>클라이언트에 응답을 반환합니다.</p>
      <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_api_1.png`}
            alt={"example_head"}
            width={891}
            height={388}
          />
          <br/>
          <br/>
          <p>API가 없으면 먼저 프로젝트의 API를 생성합니다.</p>
        </div>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_api_2.png`}
            alt={"example_head"}
            width={495}
            height={366}
          />
          <br/>
          <br/>
          <p>작업 영역의 <b>+</b> 버튼을 선택해서 새로운 탭을 열어주세요.</p>
          <br/>
          <p>1. API 추가 : 단일 API를 추가합니다.</p>
          <br/>
          <p>2. API Group 추가 : API를 묶어서 관리할 Group을 추가합니다.</p>
          <br/>
          <p>3. API 붙여넣기 : 복사한 API를 붙여넣습니다.</p>
          <br/>

          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_api_3.png`}
            alt={"example_head"}
            width={495}
            height={366}
          />
          <br/>
          <br/>
          <p>사용할 API의 이름을 입력합니다</p>
          <br/>

          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_api_4.png`}
            alt={"example_head"}
            width={891}
            height={490}
          />
          <br/>
          <br/>
          <p>이제 사용할 API가 추가되었습니다.</p>
          <br/>
          <br/>
          <p>다음장에서 API의 기본 속성들을 추가하는 방법을 알아보겠습니다.</p>
        </div>
    </div>
  )
}

export default APIChild;