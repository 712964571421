import {Modal} from "antd";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import {useRecoilState} from "recoil";
import {projectState} from "../../../recoil/project/projectState";
import {deleteShowModalState} from "../../../recoil/api/apiState";
import useAxiosInterceptor from "../../../axios/axios";
import {deleteApiApi} from "../../../api/api/apiApi";

const ApiDeleteModal = ({
  deleteApiRequest,
  setDeleteApiRequest,
  deleteApiApiSuccessCallback,
  deleteApiApiFailCallback,
}) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  const [deleteShowModal, setDeleteShowModal] = useRecoilState(deleteShowModalState);
  const [project] = useRecoilState(projectState);


  const handleDeleteApi = () => {
    deleteApiApi(
      axios,
      project.id,
      deleteApiRequest,
      deleteApiApiSuccessCallback,
      deleteApiApiFailCallback,
    );
  }

  const handleCancel = () => {
    setDeleteApiRequest({});
    setDeleteShowModal(false);
  }

  const handleEnterKeyboardInputDelete = () => {
    handleDeleteApi();
  }


  return (
    <div
      tabIndex={0}
      onKeyDown={handleEnterKeyboardInputDelete}
    >
    <Modal
      className="modal"
      title={
        <Title
          title={"API 삭제"}
          subTitle={
            "API를 삭제합니다."
          }
        />
      }
      open={deleteShowModal}
      onCancel={handleCancel}
      width={528}
      centered
      footer={
        <Footer
          textCancel={"아니오"}
          textOk={"네"}
          handleCancel={handleCancel}
          handleOk={handleDeleteApi}
        />
      }
    >
      <div
        className="ta b-g200 bg-g100 g500 mt24"
        style={{ padding: "32px 109px" }}
      >
        <p className="Body6_B">API를 삭제 하시겠습니까?</p>
      </div>
    </Modal>
    </div>
  )
}

export default ApiDeleteModal;
