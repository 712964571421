import React from "react";

const RequiredText = ({ text, className }) => {
  return (
    <div className={className}>
      <span>{text}</span>
      <span className="error ml2">*</span>
    </div>
  );
};
export default RequiredText;
