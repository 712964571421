import React, {useEffect, useState} from "react";
import {ConfigProvider, Tree} from "antd";
import SearchInput from "../../atoms/SearchInput";
import {DownOutlined} from "@ant-design/icons";
import Image from "../../atoms/Image";
import {useRecoilState} from "recoil";
import {isProjectOwnerOrAdminAuthority, projectState,} from "../../../recoil/project/projectState";
import {
  invitedUserListState,
  selectedUserState,
  userListGroupByAuthorityState,
} from "../../../recoil/invite/inviteState";
import {loadUserApiRoleApi, saveUserApiRolesApi,} from "../../../api/userrole/userRoleApi";
import useAxiosInterceptor from "../../../axios/axios";
import {SquareButton} from "../../atoms/Button";
import {toast} from "react-toastify";
import _ from "lodash";

const UserContent = ({
  treeData,
  handleSearch,
  filteringTreeData,
}) => {
  // 2024.03.11 [energysteel]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.14 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.14 [energysteel]: 클릭한 유저 정보
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  // 2024.03.26 [energysteel]: 초대된 유저 목록
  const [invitedUserList] = useRecoilState(invitedUserListState);
  // 2024.03.14 [energysteel]: 클릭한 유저의 API 권한 정보
  const [userRoles, setUserRoles] = useState([]);
  // 2024.03.14 [energysteel]: 전체 API Tree 정보
  const [userRolesTreeData, setUserRolesTreeData] = useState(treeData);
  const [allChecked, setAllChecked] = useState(false);
  // 2024.03.14 [energysteel]: API 권한 있음
  const IS_ACCESS = "ic_access";
  // 2024.03.14 [energysteel]: API 권한 없음
  const IS_NO_ACCESS = "ic_no_access";
  // 2024.03.14 [energysteel]: API Package 하위 API 권한 부분 있음
  const IS_ACCESS_PART = "ic_access_part";
  // 2024.04.12 [energysteel]: 검색어
  const [inputValue, setInputValue] = useState("");
  const [userListGroupByAuthority, setUserListGroupByAuthority] = useRecoilState(userListGroupByAuthorityState);


  /**
   * 2024.04.08 [shiningtrue]: 키보드 저장 이벤트
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        if (isProjectOwnerOrAdminAuthority(project.authority)) {
          handleSaveUserRolesDebounce();
        }
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    userRoles
  ]);


  /**
   * 2024.03.26 [energysteel]: 선택한 유저의 정보가 변경 된 경우 수정
   */
  useEffect(() => {
    const user = invitedUserList.find(
      (user) => user.guestUserId === selectedUser.guestUserId,
    );
    if (!user) {
      return;
    }
    setSelectedUser(user);
  }, [invitedUserList]);

  /**
   * 2024.03.14 [energysteel]: 좌측 사이드바 유저 클릭 시 유저 별 API 권한 조회 API
   *  - selectedUser가 없는 경우 API 조회하지 않음
   *  - 이미 조회한 경우 caching 한 데이터를 재활용
   */
  useEffect(() => {
    if (!selectedUser.guestUserId) {
      const findUser = findGuestUserId(userListGroupByAuthority, selectedUser.guestUserId);
      if (!findUser) return;
      setSelectedUser(findUser);
    }

    if (selectedUser.authority !== 'USER') {
      return;
    }

    loadUserApiRoleApi(
      axios,
      project.id,
      selectedUser.guestUserId,
      loadUserApiRoleApiSuccessCallback,
    );
    // 2024.03.06 [energysteel]: axios
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, selectedUser, invitedUserList, userListGroupByAuthority]);

  const findGuestUserId = (userList, id) => {
    if (userList.length === 0) return;
    const findUser = userList.find(user => user.guestUserId === id);

    if (findUser) {
      return findUser;
    }

    for (let i = 0; i < userList.length; i++) {
      if (userList[i].children.length > 0) {
        return findGuestUserId(userList[i].children, id);
      }
    }
  }

  /**
   * 2024.03.26 [energysteel]: 전체 권한 체크/해제 시 체크박스 상태 변경
   */
  useEffect(() => {
    const accessCount = userRolesTreeData.filter(
      (userRole) => userRole.role === IS_ACCESS,
    ).length;
    setAllChecked(accessCount === userRolesTreeData.length);
  }, [userRolesTreeData]);

  /**
   * 2024.03.14 [energysteel]: 유저 별 API 권한 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const loadUserApiRoleApiSuccessCallback = (response) => {
    setUserRoles((prev) => {
      if (response.length === 0) {
        return [];
      }

      return response.filter(
        (userRole) =>
          !prev.some(
            (prevData) =>
              prevData.projectId === userRole.projectId &&
              prevData.apiId === userRole.apiId &&
              prevData.userId === userRole.userId,
          ),
      );
    });

    drawUserRolesTree(response);
  };

  /**
   * 2024.03.14 [energysteel]: 유저 별 API 권한 트리 생성 Handler
   * @param userRoles 유저가 가진 API 권한 목록
   */
  const drawUserRolesTree = (userRoles) => {
    setUserRolesTreeData((userRolesTree) =>
      modifyUserRolesInTree(userRolesTree, userRoles),
    );
  };

  /**
   * 2024.03.14 [energysteel]: 전체 Tree에서 유저의 권한 목록을 통해 유저 별 API 권한 트리 생성
   *  - findNode를 찾으면 권한 부여, 못찾으면 권한 미부여
   *  - children이 존재할 경우 모든 children을 조회하며
   *    유저 권한과 같은 API를 찾으면 권한 부여, 못찾으면 권한 미부여
   * @param userRolesTree 전체 API 권한 Tree
   * @param userRoles 선택한 유저의 API 권한
   * @returns {*}
   */
  const modifyUserRolesInTree = (userRolesTree, userRoles) => {
    return userRolesTree.map((node) => {
      const findNode = userRoles.find((userRole) => userRole.apiId === node.id);
      const hasChildren = node.children?.length > 0;

      if (hasChildren) {
        modifyUserRolesInTree(node.children, userRoles);
        modifyParentUserRoles(node);
      } else if (findNode) {
        modifyUserRoles(node, IS_ACCESS);
      } else {
        modifyUserRoles(node, IS_NO_ACCESS);
      }

      return node;
    });
  };

  /**
   * 2024.03.14 [energysteel]
   *  - children 수와 체크 되어있는 children 수가 동일하면 parent도 체크
   *  - 체크 되어있는 children 수가 없으면 parent도 체크 해제
   *  - children 수와 체크 되어있는 children 수가 다르면 parent는 `-` 부분 체크
   * @param node
   */
  const modifyParentUserRoles = (node) => {
    const accessCount = node.children.filter(
      (child) => child.role === IS_ACCESS,
    ).length;
    let role;
    if (accessCount === node.children.length) {
      role = IS_ACCESS;
    } else if (accessCount === 0) {
      role = IS_NO_ACCESS;
    } else {
      role = IS_ACCESS_PART;
    }
    modifyUserRoles(node, role);
  };

  /**
   * 2024.03.14 [energysteel]: 유저 API 권한 수정
   * @param node Tree 데이터 중 권한을 수정한 node
   * @param role 수정한 권한
   */
  const modifyUserRoles = (node, role) => {
    node.role = role;
  };

  /**
   * 2024.03.14 [energysteel]: 유저 API 권한 저장 API
   */
  const handleSaveUserRoles = () => {
    const saveUserRolesRequest = [...userRoles];

    saveUserApiRolesApi(
      axios,
      project.id,
      selectedUser.guestUserId,
      saveUserRolesRequest,
      toast.success("저장되었습니다."),
      (e) => {
        console.log(e);
      },
    );
  };

  const handleSaveUserRolesDebounce = _.debounce(
    handleSaveUserRoles, 350
  );

  /**
   * 2024.03.14 [energysteel]: API 권한 전체 수정 체크박스 onClick Event
   *  - 모든 체크박스가 체크되어 있다면, 모두 해제
   *  - 모든 체크박스가 체크되어 있지 않다면, 모두 선택
   */
  const handleAllApiRoleCheckboxOnClick = () => {
    const userRoleAccessCount = userRoles.filter(
      (userRole) => userRole.userId === selectedUser.guestUserId,
    ).length;
    const treeAccessCount = countUserNodesInTree(userRolesTreeData);

    setUserRoles((prev) => {
      const updatedPrev = prev.filter(
        (userRole) => userRole.userId !== selectedUser.guestUserId,
      );
      const allCheckedUserRoles =
        userRoleAccessCount !== treeAccessCount
          ? userRolesTreeData.flatMap(extractUserRoles)
          : [];
      return updatedPrev.concat(allCheckedUserRoles);
    });

    setUserRolesTreeData((prev) =>
      prev.map((data) => ({
        ...data,
        role:
          userRoleAccessCount === treeAccessCount ? IS_NO_ACCESS : IS_ACCESS,
        children: data.children
          ? data.children.map((child) => ({
              ...child,
              role:
                userRoleAccessCount === treeAccessCount
                  ? IS_NO_ACCESS
                  : IS_ACCESS,
            }))
          : [],
      })),
    );

    setAllChecked(!allChecked);
  };

  /**
   * 2024.03.14 [energysteel]: API 권한 수정 체크박스 onClick Event
   * @param key API Tree Key
   */
  const handleUserRolesCheckboxOnClick = (key) => {
    modifyUserRolesTree(key);
    const { node } = findNodeAndParentByKey(key, userRolesTreeData);
    const data = {
      projectId: project.id,
      apiId: node.id,
      userId: selectedUser.guestUserId,
    };

    setUserRoles((prev) => {
      const updatedUserRole = [...prev];
      const existingIndex = updatedUserRole.findIndex(
        (item) => item.apiId === node.id,
      );
      const isNodeNoAccess = node.role === IS_NO_ACCESS;

      if (node.children.length === 0) {
        changeUserRoleArray(
          existingIndex,
          isNodeNoAccess,
          updatedUserRole,
          data,
        );
      } else {
        changeUserRoleArray(
          existingIndex,
          isNodeNoAccess,
          updatedUserRole,
          data,
        );

        for (const children of node.children) {
          const childData = {
            projectId: project.id,
            apiId: children.id,
            userId: selectedUser.guestUserId,
          };
          changeUserRoleArray(
            existingIndex,
            isNodeNoAccess,
            updatedUserRole,
            childData,
          );
        }
      }

      return updatedUserRole;
    });
  };

  /**
   * 2024.03.26 [energysteel]: UserRole 수정
   *  - 배열안에 이미 있는 경우
   *    - 체크박스 해제한 경우 제거
   *    - 체크박스 클릭한 경우 데이터 교체
   *  - 배열안에 없는 경우
   *    - 추가
   * @param existingIndex 체크박스 클릭한 API의 배열 내 index (없는 경우 -1)
   * @param isNodeNoAccess 선택한 API의 권한 여부
   * @param updatedUserRole 수정 대상
   * @param data 수정 데이터
   */
  const changeUserRoleArray = (
    existingIndex,
    isNodeNoAccess,
    updatedUserRole,
    data,
  ) => {
    if (existingIndex !== -1) {
      if (isNodeNoAccess) {
        updatedUserRole.splice(existingIndex, 1);
      } else {
        updatedUserRole[existingIndex] = data;
      }
    } else {
      updatedUserRole.push(data);
    }
  };

  /**
   * 2024.03.14 [energysteel]: API Tree를 순회하면서 모든 API의 수를 반환
   * @param apiAuthTree API Tree 전체 데이터
   * @returns {number} API Tree API 수
   */
  const countUserNodesInTree = (apiAuthTree) => {
    let count = 0;

    for (const node of apiAuthTree) {
      count += countUserNodes(node);
    }

    return count;
  };

  /**
   * 2024.03.14 [energysteel]: 주어진 노드에서 자식 노드까지 순회하면서 모든 API의 수를 반환
   * @param node API Tree의 노드
   * @returns {number} 주어진 노드와 그 자식 노드의 모든 API 수
   */
  const countUserNodes = (node) => {
    // 2024.03.14 [energysteel]: 자신 포함
    let count = 1;

    for (const child of node.children) {
      count += countUserNodes(child);
    }

    return count;
  };

  /**
   * 2024.03.14 [energysteel]: node의 id를 가지는 UserRole을 반환
   *  - 체크박스 전체 선택을 하기위해 userRoles State를 set 하기 위함
   * @param node API Tree
   * @returns {[{projectId: (*|number), userId: *, apiId}]}
   */
  const extractUserRoles = (node) => {
    const userRole = {
      projectId: project.id,
      apiId: node.id,
      userId: selectedUser.guestUserId,
    };

    if (node.children && node.children.length > 0) {
      const childrenUserRoles = node.children.flatMap((child) =>
        extractUserRoles(child),
      );
      return [userRole, ...childrenUserRoles];
    } else {
      return [userRole];
    }
  };

  /**
   * 2024.03.13 [energysteel]: API Tree 권한 체크박스 클릭으로 인한 수정
   * @param key API Tree Key
   */
  const modifyUserRolesTree = (key) => {
    const { node, parent } = findNodeAndParentByKey(key, userRolesTreeData);
    if (!node) {
      return;
    }

    modifyChildUserRolesTree(node);
    updateParentApiRoleTree(parent);

    setUserRolesTreeData([...userRolesTreeData]);
  };

  const findNodeAndParentByParentId = (parentId, nodes, parentKey = null) => {
    for (const node of nodes) {
      if (node.upperId === parentId) {
        return {
          node,
          parent: parentKey
            ? userRolesTreeData.find((n) =>
                n.children.some((c) => c.upperId === parentId),
              )
            : null,
        };
      }
      const result = findNodeAndParentByParentId(
        parentId,
        node.children,
        node.upperId,
      );
      if (result) return result;
    }
    return null;
  };

  /**
   * 2024.03.14 [energysteel]: 자식 노드와 부모 노드 조회
   * @param key API Tree Key
   * @param nodes 조회 대상 데이터
   * @param parentKey 부모노드 Key
   * @returns {{node: *, parent: *|null}|*|null}
   */
  const findNodeAndParentByKey = (key, nodes, parentKey = null) => {
    for (const node of nodes) {
      if (node.key === key) {
        return {
          node,
          parent: parentKey
            ? userRolesTreeData.find((n) =>
                n.children.some((c) => c.key === key),
              )
            : null,
        };
      }
      const result = findNodeAndParentByKey(key, node.children, node.key);
      if (result) return result;
    }
    return null;
  };

  /**
   * 2024.03.14 [energysteel]: 자신과 자식 노드 role 업데이트
   * @param node
   */
  const modifyChildUserRolesTree = (node) => {
    let role;
    if (node.role === IS_ACCESS) {
      role = IS_NO_ACCESS;
    } else {
      role = IS_ACCESS;
    }
    for (const child of node.children) {
      child.role = role;
    }

    node.role = role;
  };

  /**
   * 2024.03.14 [energysteel]: 자식 노드에 따른 부모 노드 변경
   *  - 1. 자식 노드의 role이 모두 `IS_ACCESS` 라면 부모 노드도 `IS_ACCESS`
   *  - 2. 자식 노드의 role이 모두 `IS_NO_ACCESS` 라면 부모 노드도 `IS_NO_ACCESS`
   *  - 3. 자식 노드의 role이 하나라도 `IS_ACCESS`가 아니면 부모 노드는 `IS_ACCESS_PART`
   * @param parent 부모 노드
   */
  const updateParentApiRoleTree = (parent) => {
    if (!parent) return;

    const childrenRoles = parent.children.map((child) => child.role);

    if (childrenRoles.every((image) => image === IS_ACCESS)) {
      parent.role = IS_ACCESS;
    } else if (childrenRoles.every((image) => image === IS_NO_ACCESS)) {
      parent.role = IS_NO_ACCESS;
    } else {
      parent.role = IS_ACCESS_PART;
    }
  }


  return (
    <div className="project-user-content">
      {selectedUser.guestUserEmail && selectedUser.authority && (
        <div className="h-full" style={{ padding: "16px" }}>
          <div>
            <span className="Body6_B g900 mr8" style={{ fontSize: '14px' }}>
              {selectedUser.guestUserName}
            </span>
            <span className="Body6_B g900 mr8" style={{ fontSize: '14px' }}>
              {selectedUser.guestUserEmail}
            </span>
            <span className="Body7_R g900" style={{ fontSize: '14px' }}>
              {selectedUser.authority.substring(0, 1).toUpperCase() +
                selectedUser.authority.substring(1).toLowerCase()}
            </span>
          </div>
          <div className="mt20 mb20">
            <div style={{display: "flex", alignItems: "center" }}>
              <SearchInput
                inputValue={inputValue}
                onChange={(event) =>
                  handleSearch(
                    event,
                    userRolesTreeData,
                    setUserRolesTreeData,
                    setInputValue
                  )
              }
              />
              {isProjectOwnerOrAdminAuthority(project.authority) && (
                <div style={{ marginLeft: "auto" }}>
                  <SquareButton
                    className={`btn-pri Body6_B`}
                    size={"sm-s"}
                    onClick={handleSaveUserRolesDebounce}handleSaveUserRolesDebounce
                    text={"저장"}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="table-overflow-auto -user">
            <div className="user-content-table b-g200 g500 Body6_B">
              <div className="content m10">
                <Image
                  className="mr10 cur"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={
                    selectedUser.authority !== "USER"
                      ? IS_ACCESS
                      : allChecked
                        ? IS_ACCESS
                        : IS_NO_ACCESS
                  }
                  onClick={() =>
                    selectedUser.authority === 'USER' ?
                      isProjectOwnerOrAdminAuthority(project.authority)
                      ? handleAllApiRoleCheckboxOnClick()
                      : {}
                    : {}
                  }
                />
                <span className="Body6_B g900 mr8">{`${project.name}`}</span>
                <span>API 사용 권한</span>
              </div>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Tree: {
                    titleHeight: 36,
                    nodeSelectedBg: "#ffffff",
                  },
                },
                token: {
                  colorBorder: "#dde2ee",
                  controlItemBgHover: "#ffffff",
                },
              }}
            >
              <Tree
                className="Body6_R g500 tree user"
                switcherIcon={<DownOutlined />}
                treeData={filteringTreeData(userRolesTreeData)}
                blockNode
                titleRender={(item) => (
                  <div
                    className="content ml-9"
                    onClick={() =>
                      selectedUser.authority === 'USER' ?
                        isProjectOwnerOrAdminAuthority(project.authority)
                          ? handleUserRolesCheckboxOnClick(item.key)
                          : {}
                        : {}
                    }
                  >
                    <Image
                      key={item.key}
                      className="mr10"
                      path={`${process.env.PUBLIC_URL}/content`}

                      name={
                        selectedUser.authority !== "USER"
                          ? IS_ACCESS
                          : item.role
                      }
                    />
                    {item.title}
                  </div>
                )}
              />
            </ConfigProvider>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserContent;
