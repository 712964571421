import {atom, useRecoilState} from "recoil";
import {toast} from "react-toastify";

export const validateVariable = (commonVariableCombine, setHelpIconMessage) => {
    let isValid = true;
    let name = '';
    const invalidName = commonVariableCombine.filter(
        (item) => !item.name.trim(),
    );

    const invalidValue = commonVariableCombine.filter(
        (item) => !item.value.trim(),
    );

    if (invalidValue.length > 0) {
        isValid = false;
        name = 'Value';
    }

    if (invalidName.length > 0) {
        isValid = false;
        name = 'Key';
    }

    if (!isValid) {
        setHelpIconMessage({
            code: "error",
            message: `${name}을(를) 입력해주세요.`,
        });
    }

    return isValid;
}

export const commonVariableEditingRecoilState = atom({
    key: "commonVariableEditingRecoilState",
    default: {
        editing: false,
    },
});

export const commonVariableCurrentTimeState = atom({
    key: "commonVariableCurrentTimeState",
    default: {
        commonVariable: "",
    },
})
