import {toast} from "react-toastify";

// 2024.03.06 [energysteel]: 프로젝트 저장 API
export const saveProjectApi = async (axios, saveProjectRequest, successCallback, failCallback) => {
    axios
      .post(`/project`, saveProjectRequest)
      .then((response) => {
          if (response.data.code === '0001') {
              successCallback(response);
          } else {
              failCallback(response);
          }
      })
      .catch((error) => {
        // 오류발생시 실행
        console.log(error);
      })
      .then(() => {
        // 항상 실행
      });
}

// 2024.04.23 [energysteel]: 프로젝트 건수 조회 API
export const loadProjectCategoriesCount = async (axios, searchWord, successCallback) => {
  axios
    .get(`/project/count`, {
      params: {
        searchWord,
      }
    })
    .then(response => {
      if (response.data.code === '0001') {
        successCallback(response);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}

// 2024.03.06 [energysteel]: 프로젝트 조회 API
export const loadProjectsApi = async (axios, loadProjectRequest, successCallback, thenCallback) => {
    if (loadProjectRequest.searchWord) loadProjectRequest.pageNumber = 0;

    axios
        .get(`/project`, {
            params: {
                pageNumber: loadProjectRequest.pageNumber,
                size: loadProjectRequest.pageSize,
                searchWord: loadProjectRequest.searchWord,
                searchCategories: loadProjectRequest.searchCategories,
            },
        })
        .then(response => {
            if (response.data.code === '0001') {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
            thenCallback();
        });
}

/**
 * 2024.04.23 [energysteel]: Project 리스트 API 성공 Callback
 * @param response API 응답 값
 */
export const loadProjectsApiSuccessCallback = (response, setProjectList, setPage) => {
  setProjectList(prevProjectList => {
    if (JSON.stringify(response.data.data.content) !== JSON.stringify(prevProjectList)) {
      return response.data.data.content;
    }
    return prevProjectList;
  });

  setPage(prevPage => {
    const newPage = {
      totalElements: response.data.data.totalElements,
      totalPages: response.data.data.totalPages,
      numberOfElements: response.data.data.numberOfElements,
      pageNumber: response.data.data.pageable.pageNumber,
      pageSize: response.data.data.pageable.pageSize,
    };

    if (JSON.stringify(prevPage) !== JSON.stringify(newPage)) {
      return newPage;
    }

    return prevPage;
  });
}

// 2024.03.06 [energysteel]: 프로젝트 단건 조회 API
export const loadProjectApi = (axios, projectId, successCallback, failCallback) => {
    axios
        .get(`/project/${projectId}`)
        .then(response => {
            if (response.data.code === '0001') {
              successCallback(response);
            } else {
              failCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.06 [energysteel]: 프로젝트 이름 리스트 조회 API
export const loadProjectNamesApi = (axios, userId, successCallBack) => {
    axios
        .get(`/project/names`, {
            params: {
                userId,
            }
        })
        .then(function (response) {
            if (response.data.code === '0001') {
                successCallBack(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.06 [energysteel]: 프로젝트 수정 API
export const modifyProjectApi = async (axios, modifyProject, successCallback, failCallback) => {
    axios
        .patch(`/project`, modifyProject)
        .then( (response)=>  {
            if (response.data.code === '0001') {
                successCallback(response);
            } else {
                failCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.06 [energysteel]: 프로젝트 삭제 API
export const deleteProjectApi = async (axios, projectId, successCallback, failCallback) => {
    axios
        .delete(`/project/${projectId}`)
        .then((response) => {
            if (response.data.code === '0001') {
              successCallback();
            } else {
              failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
            failCallback();
        })
        .then(() => {
            // 항상 실행
        });
}

export const exportApi = async (axios, project) => {
  axios
    .get(`/project/export/${project.id}`)
    .then((response) => {
      if (response.data.code === "0001") {
        const blob = new Blob([response.data.data]);
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${project.name}.dat`);
        document.body.appendChild(link);

        link.click();
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}

export const importApi = async (axios, formData, successCallback, failCallback) => {
  axios
    .post(`/project/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        failCallback();
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      failCallback();
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}

export const apiDuplicationSaveCheckApi = async (axios, params, successCallback) => {
  await axios
    .get('/project/api/duplication', {
      params
    })
    .then((response) => {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}

export const commonDuplicationSaveCheckApi = async (axios, params, successCallback) => {
  await axios
    .get('/project/common/duplication', {
      params
    })
    .then((response) => {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}