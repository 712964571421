import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <h1>
        보이저소프트 플랫폼 서비스 개인정보처리방침
      </h1>
      <div
        className="g900 Body4_R bb-g200 term-content"
          style={{
            display: "flex",
            overflow: "scroll",
            height: "600px",
          }}>
        <div>
          <p>
            제1조 (총칙)<br/>
            보이저소프트(이하 "회사"라 함)는 이용자의 개인정보를 중요시하며, 「개인정보 보호법」 등 관련 법령을 준수하고 있습니다.<br/>
            본 개인정보처리방침은 회사가 제공하는 모든 서비스(이하 "서비스"라 함)에 적용되며, 이용자의 개인정보가 어떻게 처리되는지에 대해 설명합니다.<br/>
          </p>
          <br/>

          <p>
            제2조 (수집하는 개인정보의 항목 및 수집 방법)<br/>
            수집하는 개인정보의 항목:<br/>
            필수항목: 이름, 이메일 주소, 비밀번호<br/>
            수집 방법:<br/>
            회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우<br/>
            서비스 이용 과정에서 자동으로 생성되는 정보의 수집(쿠키, IP 주소, 방문 기록 등)<br/>
          </p>
          <br/>

          <p>
            제3조 (개인정보의 수집 및 이용 목적)<br/>
            회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다:<br/>
          </p>
          <br/>

          <p>
            회원 관리: 회원제 서비스 이용에 따른 본인 확인, 개인 식별, 부정 이용 방지<br/>
            서비스 제공: 콘텐츠 제공, 특정 맞춤 서비스 제공, 본인 인증<br/>
            마케팅 및 광고: 이벤트 정보 및 참여 기회 제공, 광고성 정보 제공<br/>
            고객 지원: 고객 문의에 대한 응대, 문제 해결<br/>
          </p>
          <br/>

          <p>
            제4조 (개인정보의 보유 및 이용 기간)<br/>
            회사는 이용자의 개인정보를 원칙적으로 개인정보의 수집 및 이용 목적이 달성되면 지체 없이 파기합니다.<br/>
            다만, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다:<br/>
            관련 법령에 의한 정보 보유 사유<br/>
            계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래법)<br/>
            대금 결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래법)<br/>
            소비자의 불만 또는 분쟁 처리에 관한 기록: 3년 (전자상거래법)<br/>
          </p>
          <br/>

          <p>
            제5조 (개인정보의 파기 절차 및 방법)<br/>
            파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)<br/>
            내부 방침 및 기타 관련 법령에 따라 일정 기간 저장된 후 파기됩니다.<br/>
            파기 방법:<br/>
            전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제<br/>
            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각<br/>
          </p>
          <br/>

          <p>
            제6조 (개인정보의 제3자 제공)<br/>
            회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다:<br/>
            이용자가 사전에 동의한 경우<br/>
            법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
          </p>
          <br/>

          <p>
            제7조 (개인정보의 처리 위탁)<br/>
            회사는 서비스 향상을 위해 이용자의 개인정보를 외부에 위탁할 수 있습니다.<br/>
            이 경우, 회사는 위탁 계약 등을 통해 서비스 제공자의 개인정보 보호 수준을 충분히 확보합니다.<br/>
          </p>
          <br/>

          <p>
            제8조 (이용자 및 법정대리인의 권리와 그 행사 방법)<br/>
            이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며, 가입 해지를 요청할 수도 있습니다.<br/>
            개인정보 조회, 수정 및 해지 요청은 '개인정보변경' 혹은 '회원탈퇴'를 클릭하여 본인 확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
          </p>
          <br/>

          <p>
            제9조 (개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항)<br/>
            회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용자의 정보를 수시로 저장하고 불러오는 '쿠키(cookie)'를 운용합니다.<br/>
            이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹 브라우저에서<br/>
            옵션을 설정함으로써 쿠키 저장을 거부할 수 있습니다.<br/>
          </p>
          <br/>

          <p>
            제10조 (개인정보의 안전성 확보 조치)<br/>
            회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록<br/>
            안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다<br/>
            개인정보의 암호화<br/>
            해킹 등에 대비한 대책<br/>
            개인정보 취급 직원의 최소화 및 교육<br/>
          </p>
          <br/>

          <p>
            제11조 (개인정보 보호책임자 및 담당자의 연락처)<br/>
            회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
            <br/>
            개인정보 보호책임자: 이승재<br/>
            연락처: [02-587-0616], [captain@voyagersoft.co.kr]<br/>
          </p>
          <br/>

          <p>
            제12조 (개인정보처리방침의 변경)<br/>
            현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행 7일 전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.<br/>
            <br/>
            공고일자: [공고일자]<br/>
            시행일자: [시행일자]<br/>
          </p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy;