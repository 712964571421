import axios from "axios";
import { useRecoilState } from "recoil";
import { Form, Input } from "antd";
import React from "react";
import { toast } from "react-toastify";

let accessToken = sessionStorage.getItem("accessToken");

// 고객지원 리스트 API
export const loadSupportListApi = async (
  axios,
  params,
  uri,
  menuCondition,
  successCallback,
  thenCallback,
) => {
  axios
    .get(`/${uri}`, {
      params: {
        pageNumber: params.pageNumber,
        size: params.size,
        searchAll: params.searchAll,
        searchService: params.searchService,
        searchWork: params.searchWork,
        searchText: params.searchText,
        adminYn: params.adminYn,
        userId: params.userId,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response, menuCondition);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      thenCallback();
      // 항상 실행
    });
};

// 고객지원 수정 상세 데이터 API
export const loadSupportModifyDetailApi = (
  axios,
  params,
  uri,
  successCallback,
) => {
  axios
    .get(`/${uri}/detail`, {
      params: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 고객지원 상세 데이터 API
export const loadSupportDetailApi = async (
  axios,
  params,
  uri,
  successCallback,
) => {
  await axios
    .get(`/${uri}/detail`, {
      params: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 1:1문의 답변 상세 데이터 조회 함수
export const loadSupportAnswerDetailApi = async (
  axios,
  params,
  uri,
  successCallback,
) => {
  await axios
    .get(`/${uri}/answerDetail`, {
      params: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
};

// 게시물 삭제
export const deleteSupportApi = async (axios, params, uri, successCallback) => {
  axios
    .delete(`/${uri}`, {
      data: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response, uri);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 1:1문의 답변 삭제
export const deleteAnswerApi = async (axios, params, successCallback) => {
  axios
    .delete("/inquiry/answer", {
      data: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback();
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 게시물 등록
export const saveApi = async (axios, params, successCallback) => {
  axios
    .post(params.uri, params.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      if (error.response.status === 413) {
        toast.error("업로드 용량을 초과하였습니다.");
      }
    })
    .then(() => {
      // 항상 실행
    });
};

// 1:1문의 답변 등록
export const saveAnswerApi = async (axios, params, successCallback) => {
  axios
    .post(params.uri, params.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      if (error.response.status === 413) {
        toast.error("업로드 용량을 초과하였습니다.");
      }
    })
    .then(() => {
      // 항상 실행
    });
};
