// 이메일 중복체크 API
export const emailOverLapCheckApi = async (axios, params, successCallback) => {
  //이메일 중복체크
  await axios
    .get(`/user/emailCheck`, {
      params: {
        email: params.email,
      },
    })
    .then(function (response) {
      successCallback(response, params);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {});
};

// 회원가입 API
export const registerApi = async (axios, params, successCallback) => {
  await axios
    .patch(`/user/register`, params)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 이메일 중복체크 및 인증메일 재전송 API
export const emailCheckAndResendApi = async (axios, params, successCallback) => {
  await axios
    .get(`/user/emailCheck`, {
      params: {
        email: params.email,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 비밀번호 찾기 인증 메일 전송 API
export const findPasswordSendApi = async (axios, params, successCallback) => {
  //비밀번호 찾기 인증메일 재전송
  await axios
    .get(`/user/findPassword`, {
      params: {
        email: params.email,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 인증번호 확인 API
export const certificationNumberCheckApi = async (axios, params, successCallback) => {
  await axios
    .get(`/user/certificationNumberCheck`, {
      params: {
        email: params.email,
        certificationNumber: params.certificationNumber,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 비밀번호 변경 API
export const passwordChangeApi = async (axios, params, successCallback) => {
  await axios
    .patch(`/user/passwordChange`, params)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
