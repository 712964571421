import {Modal} from "antd";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import React from "react";

const ConfirmModal = ({
  title,
  subTitle,
  message,
  open,
  textOk,
  handleOk,
  textCancel,
  handleCancel,
}) => {

  return (
    <>
      <Modal
        className="modal"
        title={
          <Title
            title={title}
            subTitle={subTitle}
          />
        }
        open={open}
        onCancel={handleCancel}
        width={528}
        centered
        footer={
          <Footer
            textCancel={textCancel}
            textOk={textOk}
            handleCancel={handleCancel}
            handleOk={handleOk}
          />
        }
      >
        <div
          className="ta b-g200 bg-g100 g500 mt24"
          style={{padding: "32px 10px"}}
        >
          <p
            className="Body6_B"
             style={{whiteSpace: "pre-line"}}
          >
            {message}
          </p>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmModal;
