import React, { useCallback, useEffect, useState } from "react";
import { SquareButton } from "../../atoms/Button";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import Image from "../../atoms/Image";
import { SignInput } from "../../atoms/Input";
import { useCookies } from "react-cookie";
import { useAuth } from "../../../AuthContext";
import {
  cancelSleeperApi,
  certificationNumberSendApi,
  loginApi,
  passwordChangeApi,
} from "../../../api/signin/signinApi";
import useAxiosInterceptor from "../../../axios/axios";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userLastLoginState } from "../../../recoil/user/userState";

/* eslint-disable */
const SigninContent = () => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.01.30 [shiningtrue]: 화면 이동용
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refreshToken") !== null) {
      navigate("/main");
    }
  }, []);

  const { authContextLogin } = useAuth(); //로그인 상태관리

  const [state, setState] = useState("1");

  // 2024.01.29 [shiningtrue]: 다음 화면으로 정보 넘기기 위함
  const [nextUserInfo, setNextUserInfo] = useState("");

  // 2024.02.01 [shiningtrue]: 인증 시간 한자리 앞에 0 처리
  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

  let timer = "";
  const Signin = () => {
    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    // 2024.01.30 [shiningtrue]: input에서 emailDefalutValue를 담기 위한 state 생성
    const [emailDefaultValue, setEmailDefaultValue] = useState("");

    // 2024.01.26 [shiningtrue]: errorMsg show hide용
    const [errorMassageStyle, setErrorMsgStyle] = useState({ display: "none" });

    //아이디 저장, 자동 로그인 체크 값
    const [check, setCheck] = useState({ saveId: false, autoLogin: false });

    // 2024.01.30 [shiningtrue]: 소셜 로그인 최종 로그인 여부
    const [socialEndLogin, setSocialEndLogin] =
      useRecoilState(userLastLoginState);

    /* 아이디 저장 및 자동 로그인 */
    const [isRemember, setIsRemember] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies([]);

    const nextUserInfoSetting = (name, value) => {
      setNextUserInfo((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    function deleteCookie(name) {
      document.cookie =
        name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const loginApiSuccessCallback = useCallback((data) => {
      nextUserInfoSetting("email", inputValues.email);
      if (data.data.data === "CHANGE_PASSWORD") {
        //비밀번호 갱신 요청
        setState("2");
      } else if (data.data.data === "DORMANT_ACCOUNT") {
        //휴면 계정 해제 요청
        setState("3");
      } else {
        authContextLogin(data); //사용자 로그인 상태 관리
        socialEndLoginCheck("");
        navigate("/main"); //2024.01.25[shiningtrue]: 화면 이동
      }
    }, []);

    const loginApiFailCallback = useCallback((data) => {
      setErrorMsgStyle({ display: "block" });
    }, []);

    // 2024.01.30 [shiningtrue]: 로그인 버튼
    const login = async () => {
      if (!inputValues.email || !inputValues.password) {
        setErrorMsgStyle({ display: "block" });
        return false;
      } else {
        setErrorMsgStyle({ display: "none" });
      }
      const params = {
        email: inputValues.email,
        password: inputValues.password,
      };

      await loginApi(
        axios,
        params,
        loginApiSuccessCallback,
        loginApiFailCallback
      );
    };

    const handleKeyDown = (event) => {
      // Enter 키의 키 코드는 13입니다.
      if (event.keyCode === 13) {
        login();
      }
    };

    // 2024.01.30 [shiningtrue]: 소셜 로그인 URL
    const kakaoLoginPopup = () => {
      socialEndLoginCheck("kakao");
      window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=3791a728473739cdac37a51494c78527&redirect_uri=${process.env.REACT_APP_WEB_URL}/kakao/loginCallback`;
    };
    const googleLoginPopup = () => {
      socialEndLoginCheck("google");
      window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=759243507256-0k7sp4jn7o0dav5mmb9vn29m693rkh28.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_WEB_URL}/google/loginCallback&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
    };
    const naverLoginPopup = () => {
      socialEndLoginCheck("naver");
      window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=VixDpKfDz4hUazLMYPkw&redirect_uri=${process.env.REACT_APP_WEB_URL}/naver/loginCallback`;
    };

    useEffect(() => {
      //쿠키에 있는 이메일값이 undefined가 아닐 때
      if (cookies.rememberEmail !== undefined) {
        //쿠키에 있는 이메일 값 세팅
        setInputValues((prevValues) => ({
          ...prevValues,
          ["email"]: cookies.rememberEmail,
        }));
        setIsRemember(true);

        //아이디저장 체크박스 체크 처리
        setCheck((prev) => {
          return {
            ...prev,
            ["saveId"]: true,
          };
        });
      }

      if (cookies.autoLogin) {
        setCheck((prev) => {
          return {
            ...prev,
            ["autoLogin"]: true,
          };
        });
      }
    }, []);

    // 2024.01.30 [shiningtrue]: 소셜 최종 로그인 체크
    const socialEndLoginCheck = (value) => {
      setSocialEndLogin(value);
    };

    // 2024.01.30 [shiningtrue]: input onChange시 값 세팅
    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));

      //아이디 저장 체크시 이메일값 변경 될때마다 쿠키에 담기
      if (name === "email" && value !== undefined) {
        if (check.saveId) {
          removeCookie("rememberEmail");
          setCookie("rememberEmail", value, {
            maxAge: 60 * 60 * 24 * 365 * 10,
          });
        } else {
          removeCookie("rememberEmail");
        }
      }
    };

    // 2024.01.30 [shiningtrue]: 비밀번호 찾기
    const onFindPwClick = () => {
      navigate("/signup", { state: { key: "4" } });
    };

    // 2024.01.30 [shiningtrue]: 아이디 저장, 자동 로그인 체크박스 값 세팅
    const handleChange = (e) => {
      const { id } = e.target;
      setCheck((prev) => {
        return {
          ...prev,
          [id]: !check[id],
        };
      });

      //아이디저장 체크시 이메일 값 쿠키에 담기
      if (id === "saveId") {
        if (!check[id] && inputValues.email !== undefined) {
          setCookie("rememberEmail", inputValues.email, {
            maxAge: 60 * 60 * 24 * 365 * 10,
          });
        } else {
          removeCookie("rememberEmail");
        }
      } else if (id === "autoLogin") {
        if (!check[id]) {
          setCookie("autoLogin", true, { maxAge: 60 * 60 * 24 * 365 * 10 });
        } else {
          removeCookie("autoLogin");
        }
      }
    };

    return (
      <div>
        <div className="ta">
          <p className="H3_B g900 mb48">로그인</p>
          <div className="mb24" style={{ textAlign: "start" }}>
            <SignInput
              title={"이메일"}
              placeholder={"이메일을 입력해주세요."}
              mb="36"
              name="email"
              onChange={(e) => {
                onChangeInput(e);
              }}
              onKeyDown={handleKeyDown}
              defaultValue={cookies.rememberEmail}
            />
            <SignInput
              type={"password"}
              title={"비밀번호"}
              placeholder={"비밀번호를 입력해주세요."}
              mb="4"
              name="password"
              onChange={(e) => {
                onChangeInput(e);
              }}
              onKeyDown={handleKeyDown}
            />
            <div className="error" style={errorMassageStyle}>
              E-mail 또는 Password가 맞지 않습니다.
            </div>
            <div className="content g500 mb36 mt36">
              <Image
                className="mr6"
                path={`${process.env.PUBLIC_URL}/content`}
                name={check.saveId ? "ic_checkbox_on" : "ic_checkbox_off"}
                size={"sm"}
                id={"saveId"}
                onClick={handleChange}
              />
              <span>아이디저장</span>
              <Image
                className="mr6 ml20"
                path={`${process.env.PUBLIC_URL}/content`}
                name={check.autoLogin ? "ic_checkbox_on" : "ic_checkbox_off"}
                size={"sm"}
                id={"autoLogin"}
                onClick={handleChange}
              />
              <span>자동로그인</span>
            </div>
            <SquareButton
              className={"btn-pri mt16"}
              text={"로그인"}
              size="lg"
              onClick={login}
            />
          </div>

          <div className="bt-g150"></div>
          <div className="mt24 mb36">
            <Tooltip
              placement="right"
              title={"최종 로그인"}
              open={socialEndLogin === "kakao"}
            >
              <SquareButton
                className={"cur bg-g100 g900 b-g150"}
                text={"카카오로 로그인"}
                size="lg"
                type="sec"
                icon={
                  <Image
                    className="mr6"
                    path={process.env.PUBLIC_URL}
                    name={"ic_kakao"}
                    size={"md"}
                  />
                }
                onClick={kakaoLoginPopup}
              />
            </Tooltip>
            <Tooltip
              placement="right"
              title={"최종 로그인"}
              open={socialEndLogin === "google"}
            >
              <SquareButton
                className={"cur bg-g100 g900 b-g150 mt8 mb8"}
                text={"구글로 로그인"}
                size="lg"
                type="sec"
                icon={
                  <Image
                    className="mr6"
                    path={process.env.PUBLIC_URL}
                    name={"ic_google"}
                    size={"md"}
                  />
                }
                onClick={googleLoginPopup}
              />
            </Tooltip>
            <Tooltip
              placement="right"
              title={"최종 로그인"}
              open={socialEndLogin === "naver"}
            >
              <SquareButton
                className={"cur bg-g100 g900 b-g150"}
                text={"네이버로 로그인"}
                size="lg"
                type="sec"
                icon={
                  <Image
                    className="mr6"
                    path={process.env.PUBLIC_URL}
                    name={"ic_naver"}
                    size={"md"}
                  />
                }
                onClick={naverLoginPopup}
              />
            </Tooltip>
          </div>
          <div className="Body5_R g500">
            <span className="cur" onClick={() => onFindPwClick()}>
              비밀번호 찾기{" "}
            </span>
            ·
            <Link to="/signup">
              <span className="g500"> 회원가입</span>
            </Link>
          </div>
        </div>
      </div>
    );
  };
  //비밀번호 정규식
  const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

  const UpdatePw = () => {
    // 2024.01.26 [shiningtrue]: 패스워드 형식 체크
    const [password, setPassword] = React.useState("");

    // 2024.01.26 [shiningtrue]: 패스워드 더블 체크
    const [passwordChk, setPasswordChk] = React.useState("");

    // 2024.01.26 [shiningtrue]: errorMsg show hide용
    const [errorMassageStyle, setErrorMsgStyle] = useState({
      password: { display: "none" },
      passwordDoubleCheck: { display: "none" },
    });

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");
    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const errorMsgStyle = (name, type) => {
      setErrorMsgStyle((prevState) => {
        return { ...prevState, [name]: { display: type } };
      });
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 정규식 체크
    const passwordCheck = (password) => {
      setPassword(password);
      passwordDoubleCheck(password, passwordChk);
      if (password.match(passwordRegEx) === null) {
        //형식에 맞지 않을 경우 아래 콘솔 출력
        errorMsgStyle("password", "block");
        return;
      } else {
        // 맞을 경우 출력
        errorMsgStyle("password", "none");
      }
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 재입력 체크
    const passwordDoubleCheck = (password, passwordChk) => {
      setPasswordChk(passwordChk);
      if (password !== passwordChk) {
        errorMsgStyle("passwordDoubleCheck", "block");
        return;
      } else {
        errorMsgStyle("passwordDoubleCheck", "none");
      }
    };

    const passwordChangeApiSuccessCallback = useCallback((data) => {
      toast.success("저장 되었습니다.");
      setState("1");
    }, []);

    const passwordChange = async (type) => {
      const params = {
        email: nextUserInfo.email,
        password: inputValues.password,
        type: type,
      };
      await passwordChangeApi(axios, params, passwordChangeApiSuccessCallback);
    };

    return (
      <div>
        <div className="ta">
          <p className="H3_B g900">비밀번호 갱신</p>
          <div className="Body4_R g700 mt14 mb48">
            <p>비밀번호 유효기간 (90일)이 지났습니다.</p>
            <p>새로운 비밀번호를 설정해 주세요.</p>
          </div>
          <div className="mb48" style={{ textAlign: "start" }}>
            <SignInput
              title={"비밀번호"}
              placeholder={"비밀번호를 입력해주세요."}
              mt={"36"}
              name="password"
              type="password"
              onChange={(e) => {
                onChangeInput(e);
                passwordCheck(e.target.value);
              }}
              errorMsg={
                "비밀번호는 영문,숫자,특수문자 조합 8자리 이상으로 입력해주십시오."
              }
              errorMsgStyle={errorMassageStyle.password}
            />
            <SignInput
              title={"새 비밀번호"}
              placeholder={"비밀번호를 입력해주세요."}
              mt={"36"}
              name="passwordCheck"
              type="password"
              onChange={(e) => {
                onChangeInput(e);
                passwordDoubleCheck(password, e.target.value);
              }}
              errorMsg={"비밀번호가 동일하지 않습니다."}
              errorMsgStyle={errorMassageStyle.passwordDoubleCheck}
            />
          </div>
          <div className="content jc-c">
            <SquareButton
              className={"btn-sec"}
              text={"현재 비밀번호 연장 "}
              size="md"
              onClick={() => {
                passwordChange("now");
              }}
            />
            <SquareButton
              className={"btn-pri ml6"}
              text={"비밀번호 갱신"}
              size="md"
              onClick={() => {
                passwordChange("change");
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const certificationNumberSendApiSuccessCallback = useCallback((data) => {
    toast.success("인증번호가 전송 되었습니다.");
    setState("4");
  }, []);

  const Sleeper = () => {
    // 2024.02.01 [shiningtrue]: 인증번호 전송
    const nextButton = async () => {
      let params = {
        email: nextUserInfo.email,
      };
      await certificationNumberSendApi(
        axios,
        params,
        certificationNumberSendApiSuccessCallback
      );
    };
    return (
      <div>
        <div className="ta">
          <p className="H3_B g900">휴면계정 해제</p>
          <div className="Body4_R g700 mt14 mb48">
            <p>장기 미접속(1년)으로 휴면계정으로 전환되었습니다.</p>
            <p>휴면계정을 해제하시겠습니까? </p>
          </div>
          <div className="content jc-c">
            <SquareButton
              className={"btn-sec"}
              text={"아니오"}
              size="md"
              onClick={() => setState("1")}
            />
            <SquareButton
              className={"btn-pri ml6"}
              text={"네 (Email 인증 받기)"}
              size="md"
              onClick={nextButton}
            />
          </div>
        </div>
      </div>
    );
  };

  const CancelSleeper = () => {
    const [seconds, setSeconds] = useState(300);

    useEffect(() => {
      timer =
        seconds > 0 &&
        setInterval(() => {
          setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
        }, 1000);

      // 컴포넌트가 언마운트되거나 타이머가 재설정될 때 타이머를 정리합니다.
      return () => clearTimeout(timer);
    }, [seconds]); // seconds가 변경될 때마다 타이머를 재설정합니다.

    // 분과 초를 계산
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const certificationNumberReSendApiSuccessCallback = useCallback((data) => {
      toast.success("인증번호가 재전송 되었습니다.");
      clearTimeout(timer);
      setSeconds(300);
      timer =
        seconds > 0 &&
        setInterval(() => {
          setSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
        }, 1000);
    }, []);

    // 2024.01.26 [shiningtrue]: Email 재전송
    const reSend = async (e) => {
      let params = {
        email: nextUserInfo.email,
      };
      await certificationNumberSendApi(
        axios,
        params,
        certificationNumberReSendApiSuccessCallback
      );
    };

    const cancelSleeperApiSuccessCallback = useCallback((data) => {
      toast.success("휴면계정이 해제 되었습니다.");
      setState("1");
    }, []);

    // 인증 확인 (해제)
    const confirmation = async (e) => {
      const params = {
        email: nextUserInfo.email,
      };
      await cancelSleeperApi(axios, params, cancelSleeperApiSuccessCallback);
    };

    return (
      <div>
        <div className="ta">
          <p className="H3_B g900">휴면계정 해제</p>
          <div className="Body4_R g700 mt14 mb48">
            <p>{nextUserInfo.email}</p>
            <p>인증번호를 전송하였습니다.</p>
          </div>
          <div className="mb48" style={{ textAlign: "start" }}>
            <SignInput
              title={"인증번호"}
              placeholder={"인증번호 입력 후 Enter키를 입력해주세요."}
              suffix={
                <div className="error ">
                  {addLeadingZero(minutes)}:{addLeadingZero(remainingSeconds)}
                </div>
              }
            />
            <SquareButton
              className={"btn-line"}
              text={"재전송"}
              size="lg"
              type={"sec"}
              onClick={reSend}
            />
          </div>
          <div className="content jc-c">
            <SquareButton
              className={"btn-sec"}
              text={"휴면계정 해제 취소"}
              size="md"
              onClick={() => setState("1")}
            />
            <SquareButton
              className={"btn-pri ml6"}
              text={"인증 확인 (해제)"}
              size="md"
              onClick={confirmation}
            />
          </div>
        </div>
      </div>
    );
  };

  const contentList = [
    { key: "1", content: <Signin /> },
    { key: "2", content: <UpdatePw /> },
    { key: "3", content: <Sleeper /> },
    { key: "4", content: <CancelSleeper /> },
  ];
  return (
    <div className="signin-body">
      {
        contentList.find((v) => {
          return v.key === state;
        }).content
      }
    </div>
  );
};

export default SigninContent;
