import React, {useEffect} from "react";
import {Modal} from "antd";
import ModalHeadline from "../../atoms/ModalHeadline";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import DataDetailSettingTable from "../../molecules/tables/DataDetailSettingTable";
import {useRecoilState} from "recoil";
import {
    selectedApiState,
    tabOpenListState,
} from "../../../recoil/api/apiState";
import {requestState} from "../../../recoil/request/requestState";
import {isApiAuthority, projectState} from "../../../recoil/project/projectState";
import AlertFooter from "../../molecules/modals/AlertFooter";


const DataDetailSettingModal = ({
  isModalOpen,
  setIsModalOpen,
  record,
  numberRange,
  minMaxChange,
  name,
  tabType,
}) => {
  // 2024.03.20 [energysteel]: Request 정보 (Request 상세 포함)
  const [request, setRequest] = useRecoilState(requestState);
  // 2024.03.20 [energysteel]: 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.20 [energysteel]: 전체 Tab 정보
  const [, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.04.05 [energysteel]: 현재 Project 정보
  const [project] = useRecoilState(projectState);

  /**
   * 2024.03.20 [energysteel]: Record를 State로 사용하기 위해 옮겨담음
   */
  useEffect(() => {
    setRequest(record);
  }, [record]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        if (isApiAuthority(project.authority)) {
          handleOk();
        }

        event.preventDefault();
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, true);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [request]);


  /**
   * 2024.03.20 [energysteel]: Modal 확인 버튼 onClick Event
   */
  const handleOk = () => {
    setTabOpenList((prev) => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
            return {
                ...item,
                requestEditing: true,
                [name]: item[name].map(updateRequestBody),
            }
        }
        return item;
      });
    });

    modalClose();
  }

  /**
   * 2024.03.27 [energysteel]:
   * @param body
   * @returns {(*&{children: *})|(*&{requiredYn: (boolean|*), dataType: (string|string|*), requestDetail: {min: (*|undefined), max: (*|undefined), requestId: (*|undefined), regular: (*|undefined), requestEnums: *[], isEncryption: (boolean|*)}})|*}
   */
  const updateRequestBody = (body) => {
    if (body.key === request.key) {
      return {
        ...body,
        dataType: request.dataType,
        requiredYn: request.requiredYn,
        requestDetail: setUpRequestDetail(request.requestDetail)
      };
    } else if (body.children && body.children.length > 0) {
      return {
        ...body,
        children: body.children.map(updateRequestBody)
      };
    }

    return body;
  }

  /**
   * 2024.03.20 [energysteel]: Request Detail 수정할 데이터 생성
   * @param requestDetail
   */
  const setUpRequestDetail = (requestDetail) => {
    return {
      requestId: requestDetail?.requestId ?? undefined,
      isEncryption: requestDetail?.isEncryption ?? false,
      min: requestDetail?.min ?? undefined,
      max: requestDetail?.max ?? undefined,
      regular: requestDetail?.regular ?? undefined,
      requestEnums: requestDetail?.requestEnums ?
        [ ...requestDetail?.requestEnums ] : [],
    }
  }
  
  /**
   * 2024.03.20 [energysteel]: Modal 종료 Event
   */
  const handleCancel = () => {
    setRequest(record);
    modalClose();
  };

  /**
   * 2024.03.20 [energysteel]: Modal 종료
   */
  const modalClose = () => {
      setIsModalOpen(prev => {
          return prev.map(modal => {
              if (modal.id === request.key) {
                  return {
                      ...modal,
                      open: false,
                  }
              } else {
                  return modal;
              }
          })
      });
  }


  return (
    <Modal
      className="modal"
      title={
        <>
          <span
            className="Body3_B g900"
            style={{ color: "#ff6c37" }}
          >
            {`{${request.keyValue}} `}
          </span>
          <span>Data 상세 설정</span>
          <div className="Body7_R g400 mt10 mb14">
            {
              <>
                <p>
                  Data Validation 체크를 위한 상세 조건을 설정합니다.
                </p>
                <p>
                  API 요청시 Validation 에러가 발생된 경우 설정메시지가 응답됩니다.
                </p>
              </>
            }
          </div>
        </>
      }
      open={isModalOpen?.find(modal => modal.id === request.key)?.open}
      onCancel={handleCancel}
      width={528}
      centered
      footer={isApiAuthority(project.authority) ?
        <Footer
          textCancel={"취소"}
          textOk={"확인"}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
        :
        <AlertFooter
          textOk={"확인"}
          handleOk={handleCancel}
        />
      }
    >
      <ModalHeadline />
      <DataDetailSettingTable
        numberRange={numberRange}
        minMaxChange={minMaxChange}
        name={name}
        tabType={tabType}
      />
    </Modal>
  );
};

export default DataDetailSettingModal;
