import React from "react";

const ErrorCode = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
              <p>MockBot의 공통 에러는 Business, Validation, Http 오류에 대한 오류 코드를 등록하여</p>
              <p>API <a href={'#2-4'} onClick={() => setManualKey("2-4")}>Response</a>의 Test Case 등록에서 선택하여 사용할 수
                있습니다.</p>
            </div>
        </>
    )
}
export default ErrorCode;