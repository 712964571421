import React, {useState} from "react";
import {Input, Modal} from "antd";
import InviteProjectUserTable from "../../molecules/tables/InviteProjectUserTable";
import ModalHeadline from "../../atoms/ModalHeadline";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import ModalSelect from "../../atoms/ModalSelect";
import Image from "../../atoms/Image";
import {useRecoilState} from "recoil";
import {closeModal, isModalOpenState} from "../../../recoil/project/projectState";
import {invitedUserListState, invitingUserListState, useInviteItems} from "../../../recoil/invite/inviteState";
import {invitePossibleUserApi, inviteUserApi} from "../../../api/invite/inviteApi";
import useAxiosInterceptor from "../../../axios/axios";
import {toast} from "react-toastify";
import {alertHelpIcon, helpIconMessageState, alertHelpIconOpenState} from "../../../recoil/api/apiState";
import _ from "lodash";

/**
 * 초대 Modal
 */
const InviteProjectUserModal = ({ project }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.07 [energysteel]: Modal Open 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  // 2024.03.07 [energysteel]: 초대 대기 중 유저 리스트 (Modal 내 초대 가능 여부 확인 된 유저)
  const [invitingUserList, setInvitingUserList] = useRecoilState(invitingUserListState);
  // 2024.03.07 [energysteel]: 초대 완료된 유저 리스트
  const [, setInvitedUserList] = useRecoilState(invitedUserListState);
  // 2024.03.07 [energysteel]: Input 유저 이메일
  const [inputGuestUserEmail, setInputGuestUserEmail] = useState("");
  // 2024.03.07 [energysteel]: Select 유저 권한
  const [authority, setAuthority] = useState("USER");
  // 2024.03.07 [energysteel]: 서버 에러 메시지
  const [errorMessage, setErrorMessage] = useState("");
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.03.07 [energysteel]: 권한 목록
  const authorities = {
    OWNER: { value: "OWNER", label: "Owner" },
    ADMIN: { value: "ADMIN", label: "Admin" },
    AGENT: { value: "AGENT", label: "Agent" },
    USER: { value: "USER", label: "User" },
  };


  /**
   * 2024.03.07 [energysteel]: 사용자 초대하기 버튼 onClick Event
   */
  const handleInviteOk = () => {
    if (!validateSaveHandle()) {
      return;
    }

    // 2024.03.07 [energysteel]: 사용자 초대 API
    inviteUserApi(
        axios,
        project.id,
        invitingUserList,
        inviteUserApiSuccessCallback,
        inviteUserApiFailCallback,
    );
  }

  // 2024.05.19 [shiningtrue]: throttle
  const handleInviteOkThrottled = _.debounce(handleInviteOk, 200);

  /**
   * 2024.03.07 [energysteel]: 사용자 초대하기 검증
   *  - invitingUserList: Table Row 건수
   * @returns {boolean}
   */
  const validateSaveHandle = () => {
    if (!invitingUserList || invitingUserList.length === 0) {
      setHelpIconMessage({
        code: "error",
        message: "초대할 유저를 입력해주세요.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    return true;
  }

  /**
   * 2024.03.07 [energysteel]: 사용자 초대하기 API 성공 Callback
   */
  const inviteUserApiSuccessCallback = (response) => {
    const updatedUserList = response.data.data.map(user => {
        return {
          ...user,
          authorityLabel: '초대중',
        };
    });

    // 2024.03.07 [energysteel]: 프로젝트 > 사용자 리스트 최신화
    setInvitedUserList(prev => {
      return [...prev, ...updatedUserList];
    })

    // 2024.03.07 [energysteel]: 프로젝트 > 사용자 Modal Clear
    setInvitingUserList([]);

    toast.success("초대가 완료되었습니다.");
    handleCancel();
  }

  /**
   * 2024.03.07 [energysteel]: 사용자 초대하기 API 실패 Callback
   * @param response API 응답 값
   */
  const inviteUserApiFailCallback = (response) => {
    setInvitingUserList(prev => {
      return prev.filter(user =>
          !prev.some(savedUser => savedUser.guestUserId === user.guestUserId));
    })

    setErrorMessage(response.data.message);
  }


  /**
   * 2024.03.07 [energysteel]: 프로젝트 > 사용자 > 사용자 초대 Modal > Dropdown handler
   * @param user 대상 유저
   * @param currentAuthority 현재 권한
   * @returns {{items: *}}
   */
  const useInvitePendingUserHandle = (user, currentAuthority) => {
    return useInviteItems(
        user,
        project.authority,
        (event) => handleDropdownChangeAuthority(user.guestUserId, currentAuthority, event.target.textContent),
        () => handleDropdownReject(user),
    );
  }

  /**
   * 2024.03.07 [energysteel]: 프로젝트 > 사용자 > 사용자 초대 Modal > Dropdown 초대 전 유저 권한 변경
   * @param guestUserId 초대 대상 유저 ID
   * @param currentAuthority 현재 권한
   * @param changingAuthority 변경하려는 권한
   */
  const handleDropdownChangeAuthority = (guestUserId, currentAuthority, changingAuthority) => {
    // 2024.03.07 [energysteel]: 현재 Authority와 변경하려는 Authority가 같다면 return
    if (currentAuthority.toUpperCase() === changingAuthority.toUpperCase()) return;

    setInvitingUserList(prev => {
      return prev.map(value => {
        if (value.guestUserId === guestUserId) {
          return {
            ...value,
            authority: changingAuthority.toUpperCase(),
            authorityLabel: changingAuthority,
          };
        }

        return value;
      })
    })
  }


  /**
   * 2024.03.07 [energysteel]: 프로젝트 > 사용자 > 사용자 초대 Modal > Dropdown 초대 전 유저 삭제
   * @param user 삭제 대상 유저
   */
  const handleDropdownReject = (user) => {
    setInvitingUserList(prev => {
      return prev.filter(value => value.guestUserId !== user.guestUserId);
    })
  }

  /**
   * 2024.03.07 [energysteel]: 프로젝트 > 사용자 > 사용자 초대 Modal > + 버튼 onClick Event
   */
  const listAddHandleOnClick = () => {
    if (invitingUserList.find(user => user.guestUserEmail === inputGuestUserEmail)) {
      setErrorMessage("이미 추가 된 유저입니다.");
      return;
    }

    // 2024.03.07 [energysteel]: 초대 가능한 유저인지 조회 API
    invitePossibleUserApi(
        axios,
        project.id,
        inputGuestUserEmail,
        invitePossibleUserApiSuccessCallback,
        invitePossibleUserApiFailCallback,
    );
  }

  /**
   * 2024.03.07 [energysteel]: 초대 가능한 유저인지 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const invitePossibleUserApiSuccessCallback = (response) => {
    const data = response.data.data;

    // 2024.03.07 [energysteel]: 초대 가능한 유저 정보 추가
    setInvitingUserList((prev) => {
      return [
        ...prev,
        {
          key: invitingUserList.length,
          guestUserId: data.guestUserId,
          guestUserName: data.guestUserName,
          guestUserEmail: data.guestUserEmail,
          authority: authority,
          inviteStatus: data.inviteStatus,
          authorityLabel: authorities[authority].label,
        }
      ];
    });

    setInputGuestUserEmail("");
  }

  /**
   * 2024.03.07 [energysteel]: 초대 가능한 유저인지 조회 API 실패 Callback
   * @param response API 응답 값
   */
  const invitePossibleUserApiFailCallback = (response) => {
    setErrorMessage(response.data.message);
  }

  /**
   * 2024.03.07 [energysteel]: Input onChange Event
   *  - 입력 시 에러 메시지 초기화
   * @param event input onChange Event
   */
  const handleChangeInput = (event) => {
    setInputGuestUserEmail(event.target.value);
    setErrorMessage("");
  }

  /**
   * 2024.03.07 [energysteel]: 권한 Select onChange Event
   * @param value
   */
  const handleChangeAuthority = (value) => {
    setAuthority(value);
  }

  /**
   * 2024.03.07 [energysteel]: Modal 종료
   */
  const handleCancel = () => {
    setInvitingUserList([]);
    closeModal(setIsModalOpen, project.id, 'invite');
  }

  return (
    <Modal
      className="modal"
      title={
        <Title
          title={`${project.name} 사용자 초대`}
          subTitle={"Project 협업을 위한 사용자를 초대합니다."}
        />
      }
      open={isModalOpen?.find(v => v.id === project.id)?.invite}
      onCancel={handleCancel}
      width={528}
      centered
      footer={
        <Footer
          textCancel={"취소"}
          textOk={"사용자 초대하기"}
          handleCancel={handleCancel}
          handleOk={handleInviteOkThrottled}
        />
      }
    >
      <ModalHeadline />
      <div>
        <Input
          className="Body6_R g300 bg-white b-g200 br3"
          style={{ width: 277, height: 36, color: 'black' }}
          placeholder="사용자 계정 입력"
          value={inputGuestUserEmail}
          onChange={handleChangeInput}
        />
        <ModalSelect
          value={authority}
          width={171}
          options={Object.values(authorities)}
          handleChange={handleChangeAuthority}
        />
        <Image
          className="ml10"
          path={`${process.env.PUBLIC_URL}/content`}
          name={"ic_add"}
          onClick={listAddHandleOnClick}
        />
        {errorMessage &&
          <div className="Body6_R error bg-white br3 ml10 mt3">
            {errorMessage}
          </div>
        }

      </div>
      <div className="b-g200 bg-g100 mt16 " style={{ height: 331 }}>
        <InviteProjectUserTable
          name={"api"}
          userList={invitingUserList}
          useInviteUserHandle={useInvitePendingUserHandle}
          project={project}
        />
      </div>
      <div className="content jc-c Body7_R g400 ta mt28">
        사용자를 초대할 경우, 전체 API 사용권한이 기본 부여 됩니다.
        <br /> 수정이 필요한 경우 초대 후 ‘사용자’ 메뉴에서 API 별 권한 설정을
        하세요.
      </div>
    </Modal>
  );
};

export default InviteProjectUserModal;
