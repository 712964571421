import React from "react";

const RequestHeaderChild = ({ clazz, setManualKey }) => {
  return (
    <div className={clazz}>
      <p>API Request Header는 클라이언트가 서버로 요청을 보낼 때 요청에 대한 추가 정보를 제공하는 부분입니다.</p>
      <p>Header HTTP 요청의 일부로 전송되며,
        서버에서 사용하고 있는 API의 Header 정보를 동일하게 입력하면 됩니다.</p>
      <br/>
      <br/>
      <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_request_1.png`}
            alt={"example_head"}
            width={693}
            height={501}
          />
          <br/>
          <br/>
          <p>MockBot은 기본 Header 키값을 선택할 수 있습니다. 다음은 주요 Header 설명입니다.</p><br/>
          <p>Header는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
          <p>1. Authorization: 요청을 인증하기 위한 토큰이나 API 키를 포함합니다.</p><br/>
          <p>2. Accept: 응답으로 선호하는 콘텐츠 유형을 명시합니다.</p><br/>
          <p>3. Cache-Control: 캐시 동작을 제어합니다.</p><br/>
          <p>4. User-Agent: 클라이언트의 정보를 포함합니다.</p>
      </div>
    </div>
  )
}

const RequestParameterChild = ({ clazz, setManualKey }) => {
  return (
    <div className={clazz}>
      <p>API Request Parameter는 클라이언트가 서버에게 보내는 요청의 일부로서, 요청에 대한 추가 정보를 전달하는 데 사용됩니다.</p>
      <p>이러한 파라미터는 URL 쿼리 문자열, 요청 본문의 데이터, 또는 헤더 내에 포함될 수 있습니다.</p>
      <br/>
      <br/>
      <div style={{width: 786}}>
        <img
          src={`${process.env.PUBLIC_URL}/manual/manual_request_2.png`}
          alt={"example_head"}
          width={693}
          height={302}
        />
        <br/>
        <br/>
        <p>Parameter 은 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
        <p>지원하는 Data Type은 아래와 같습니다.</p><br/>
        <p>1. 문자형: String을 지원합니다.</p><br/>
        <p>2. 정수형: Int, Short, Long을 지원합니다.</p><br/>
        <p>3. 실수형: Float, Double을 지원합니다.</p><br/>
        <p>4. 논리형: Boolean을 지원합니다.</p><br/>
        <p>5. 객체형: Object를 지원합니다. <b>하위 Object를 포함할 수 있습니다.</b></p>
      </div>
    </div>
  )
}

const RequestDataDetailChild = ({ clazz, setManualKey }) => {
  return (
    <div className={clazz}>
      <p>Request Data 상세 설정은 입력 값에 다양한 설정을 추가하여 입력값을 검증하는데 사용됩니다.<br/>
        이 기능을 활용하면 서버에서 입력값 체크 후 발생할 수 있는 Exception 처리를 구현할 수 있습니다.</p><br/>
      <br/>
      <div style={{width: 786}}>
        <img
          src={`${process.env.PUBLIC_URL}/manual/manual_request_3.png`}
          alt={"example_head"}
          width={693}
          height={473}
        />
        <br/>
        <br/>
        <p>Parameter 은 <b>작성아이콘</b> 버튼으로 추가할 수 있습니다.</p><br/>
        <p>Data 상세 설정으로 발생되는 Exception은 <a href={'#3-1-1'} onClick={() => setManualKey("3-1")}>공통에러</a>에서 등록할 수 있습니다.
        </p><br/>
        <p>1. Data Type: Parameter Data Type을 변경할 수 있습니다.</p><br/>
        <p>2. 필수 여부: 입력 값의 필수 여부를 선택할 수 있습니다.</p><br/>
        <p>3. 암호화 여부: 해당 입력 값의 암호화 여부를 선택합니다. 선택할 경우 <a href={'#2-2'} onClick={() => setManualKey("2-2")}>URI</a>에서 선택된
          방식으로 암호화 됩니다.</p><br/>
        <p>4. Min/Max: 입력 값의 최소값과 최대값의 범위를 지정합니다.</p><br/>
        <p>5. Enum: 입력 값을 Enum자료형에 포함된 값과 비교합니다.</p><br/>
        <p>6. 정규식: 입력 값의 정규식을 체크합니다.<b>전화번호, 이메일 등등.</b></p>
      </div>
      <br/>
      <p>다음은 Response 등록에 대해 알아보겠습니다.</p>
    </div>
  )
}

export { RequestHeaderChild, RequestParameterChild, RequestDataDetailChild };
