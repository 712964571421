import React from "react";
import { Select, ConfigProvider } from "antd";

const ModalSelect = ({
  color,
  placeholder,
  width = 362,
  options,
  value,
  handleChange,
  height = 36,
  listHeight = 1000,
  disabled = false,
  marginRight,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 3,
          colorText: color,
          colorPrimary: "#dde2ee",
          colorPrimaryHover: "#dde2ee",
          colorBorder: "#dde2ee",
          fontSize: 13,
        },
        components: {
          Select: {
            optionSelectedColor: "#7c60ff",
          },
        },
      }}
    >
      <Select
        placeholder={placeholder}
        value={value}
        style={{ width: width, height: height, marginRight: marginRight }}
        listHeight={listHeight}
        onChange={handleChange}
        options={options}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};
export default ModalSelect;
