import { atom } from "recoil";
import {isProjectOwnerOrAdminAuthority} from "../project/projectState";


// 2024.03.06 [energysteel]: 초대 된, 초대중인 전체 유저
export const invitedUserListState = atom({
  key: "invitedUserListState",
  default: [],
})

// 2024.03.06 [energysteel]: 초대중인 유저
export const invitingUserListState = atom({
  key: "invitingUserListState",
  default: [],
})

export const userListGroupByAuthorityState = atom({
  key: "userListGroupByAuthorityState",
  default: [],
})

export const selectedUserState = atom({
  key: "selectedUserState",
  default: {},
})

export const searchUserDataState = atom({
  key: "searchUserDataState",
  default: "",
})

export const useInviteItems = (user, userAuthority, handleChangeAuthority, handleReject) => {
  const items = [
    {
      label: <div className="Body6_B g500" onClick={(event) => handleChangeAuthority(event)}>Owner</div>,
      key: "0",
      value: "Owner",
    },
    {
      label: <div className="Body6_B g500" onClick={(event) => handleChangeAuthority(event)}>Admin</div>,
      key: "1",
      value: "Admin",
    },
    {
      label: <div className="Body6_B g500" onClick={(event) => handleChangeAuthority(event)}>Agent</div>,
      key: "2",
      value: "Agent",
    },
    {
      label: <div className="Body6_B g500" onClick={(event) => handleChangeAuthority(event)}>User</div>,
      key: "3",
      value: "User",
    },
    {
      label: <div className="Body6_B g500" onClick={(event) => handleReject()}>삭제</div>,
      key: "4",
      value: "Delete",
    },
  ]
  
  return setupDropdown(items, user, userAuthority);
}

const setupDropdown = (items, user, userAuthority) => {
  // 2024.04.15 [energysteel]: Owner/ADMIN 인 경우
  if (isProjectOwnerOrAdminAuthority(userAuthority)) {
    // 2024.04.15 [energysteel]: 사용자를 Dropdown 한 경우 모든 items 목록 노출
    if (!isNaN(user.guestUserId)) {
      return {
        items: userAuthority === 'OWNER' ?
          items :
          items.filter(item => item.value !== 'Owner'),
      }
    // 2024.04.15 [energysteel]: 권한을 Dropdown 한 경우 삭제만 노출
    } else {
      return {
        items: items.filter(item => item.value === 'Delete'),
      }
    }
  }

  // 2024.04.15 [energysteel]: Admin은 Owner 권한 미노출
  return {
    items: items.filter(item => item.value !== 'Owner'),
  };
}