import { Layout } from "antd";
import UserContent from "../../organisms/project/UserContent";
import ApiContent from "../../organisms/project/ApiContent";
import CommonVariableContent from "../../organisms/project/CommonVariableContent";
import { searchUserDataState } from "../../../recoil/invite/inviteState";
import { searchApiDataState } from "../../../recoil/api/apiState";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import {
  projectState,
  useProjectRecoilReset,
} from "../../../recoil/project/projectState";
import CommonErrorContent from "../../organisms/project/CommonErrorContent";

const { Content: AntdContent } = Layout;

const Content = ({
  menuKey,
  treeData,
  isSubSiderOpen,
  setTreeData,
  handleSearch,
  filteringTreeData,
}) => {
  const [, setSearchUserData] = useRecoilState(searchUserDataState);
  const [, setSearchApiData] = useRecoilState(searchApiDataState);
  //2024.03.15 [shiningtrue]: project 내부 recoil 초기화 함수
  const projectRecoilReset = useProjectRecoilReset();
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  //2024.04.18 [shiningtrue]: 열려있는 탭 상태 관리
  const [treeFlag, setTreeFlag] = useState(false);
  useEffect(() => {
    setSearchUserData("");
    setSearchApiData("");
  }, [menuKey]);

  const handleProjectRecoilReset = () => {
    projectRecoilReset();
  };

  useEffect(() => {
    setTreeFlag(false);
    return () => {
      handleProjectRecoilReset();
    };
  }, [project.id]);

  const contentList = [
    {
      key: "1",
      content: (
        <ApiContent
          treeData={treeData}
          setTreeData={setTreeData}
          isSubSiderOpen={isSubSiderOpen}
          treeFlag={treeFlag}
          setTreeFlag={setTreeFlag}
        />
      ),
    },
    { key: "2", content: <CommonErrorContent /> },
    { key: "3", content: <CommonVariableContent /> },
    {
      key: "4",
      content: (
        <UserContent
          treeData={treeData}
          handleSearch={handleSearch}
          filteringTreeData={filteringTreeData}
        />
      ),
    },
  ];

  return (
    <AntdContent>
      {contentList.find((v) => v.key === menuKey).content}
    </AntdContent>
  );
};

export default Content;
