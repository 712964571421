import "./scss/App.scss";
import {Route, Routes} from "react-router-dom";
import ProjectMain from "./components/pages/ProjectMain";
import Manual from "./components/pages/Manual";
import Main from "./components/pages/Main";
import {ToastContainer} from "react-toastify";
import AuthLayout from "./components/pages/AuthLayout";
import {ErrorBoundary} from "react-error-boundary";

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={OurFallbackComponent}>
        <Routes>
          <Route path="/" element={<Main name={"root"}/>}/>
          <Route path="/manual" element={<Manual/>}/>
          <Route path="/signin" element={<Main name={"signin"}/>}/>
          <Route path="/signup" element={<Main name={"signup"}/>}/>
          <Route path="/support" element={<Main name={"support"}/>}/>
          <Route path="/faq" element={<Main name={"faq"} extend={"support"}/>}/>
          <Route path="/support/:id" element={<Main name={"support"}/>}/>
          <Route path="/kakao/loginCallback" element={<Main name={"loginCallback"}/>}/>
          <Route path="/google/loginCallback" element={<Main name={"loginCallback"}/>}/>
          <Route path="/naver/loginCallback" element={<Main name={"loginCallback"}/>}/>
          <Route path="/term" element={<Main name={"term"} extend={"account"}/>}/>

          <Route element={<AuthLayout/>}>
            <Route path="/main" element={<Main name={"main"}/>}/>
            <Route path="/project/:projectId" element={<ProjectMain/>}/>

            <Route path="/account" element={<Main name={"account"}/>}/>
          </Route>

          <Route path="*" element={<Main name={"error"}/>}/>
        </Routes>
      </ErrorBoundary>
      <ToastContainer
        className={"custom-toast Body8_R"}
        position="bottom-center"
        autoClose={1500}
        theme={'colored'}
        closeButton={false}
        hideProgressBar={true}
      />
    </div>
  );
}

const OurFallbackComponent = ({error, resetErrorBoundary}) => {
  return (
    <div>
      <Main name={"error"} error={error} resetErrorBoundary={resetErrorBoundary}/>
    </div>
  );
};

export default App;
