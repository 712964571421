/* 2024.03.15 [shiningtrue]: 공통 변수 조회 */
export const loadCommonVariableApi = (axios, projectId, successCallback) => {
  axios
    .get(`/commonVariable/load`, {
      params: {
        projectId: projectId,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

/* 2024.03.15 [shiningtrue]: 공통 변수 저장 */
export const saveCommonVariableApi = (axios, params, successCallback) => {
  axios
    .post(`/commonVariable/save`, params)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
