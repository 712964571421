import React from "react";

const EnvVariable = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
              <p>MockBot의 환경변수는 현재 암호화에 사용할 키값을 설정하는 기능으로 제한되어 있습니다.</p>
              <p>향후 서비스의 다양한 기능과 함께 추가될 예정입니다.</p>
            </div>
        </>
    )
}
export default EnvVariable;