import React from "react";
import { ConfigProvider } from "antd";

const TableWrapper = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerColor: "#636c83",
            headerBg: "#ffffff",
            cellPaddingBlock: 0,
            cellPaddingInline: 10,
            cellFontSize: 13,
            borderColor: "#dde2ee",
            headerBorderRadius: 3,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default TableWrapper;
