// 2024.05.22 [energysteel]: API > Response 조회

export const loadResponseApi = async (axios, params, successCallback) => {
  await axios
    .get(`/response`, {
      params: {
        apiId: params.apiId,
        tabType: params.tabType,
      },
    })
    .then(function (response) {
      successCallback(response, params.tabType);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};


/* 2024.04.12 [shiningtrue]: API > response > responseHeader */
export const saveResponseHeaderApi = async (
  axios,
  params,
  successCallback,
  finallyCallback,
  modalOpenFunc,
  completeFlag,
) => {
    axios
        .post(`/response/save`, params)
        .then(function (response) {
          successCallback(response, modalOpenFunc, completeFlag);
        })
        .catch((error) => {
            // 오류발생시 실행
        })
        .then(() => {
          finallyCallback();
        });
};

/* 2024.03.19 [shiningtrue]: API > response > responseParameter */
export const saveResponseParameterApi = async (
  axios,
  params,
  successCallback,
  finallyCallback,
  modalOpenFun,
  completeFlag,
) => {
    axios
        .post(`/response/save`, params)
        .then(function (response) {
          successCallback(response, modalOpenFun, completeFlag);
        })
        .catch((error) => {
            // 오류발생시 실행
        })
        .then(() => {
          finallyCallback();
        });
};

/* 2024.03.19 [shiningtrue]: API > response > responseCase */
export const saveResponseCaseApi = async (
  axios,
  params,
  successCallback,
  finallyCallback,
  modalOpenFun,
  completeFlag,
) => {
    axios
        .post(`/responseCase/save`, params)
        .then(function (response) {
          successCallback(response, modalOpenFun, completeFlag);
        })
        .catch((error) => {
            // 오류발생시 실행
        })
        .then(() => {
          finallyCallback();
        })
};

// 2024.04.03 [energysteel]: 테스트케이스 상세 조회
export const loadResponseCaseDetailApi = async (axios, id, successCallback) => {
  axios
    .get(`/responseCase/${id}`)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

export const saveResponseJsonApi = (axios, params, successCallback, failCallback) => {
  axios
    .post("/response/saveJson", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response, params.tabType);
      } else {
        failCallback(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      if (error.response.status === 413) {
        failCallback("업로드 용량을 초과하였습니다.");
      }
    })
    .then(() => {
      // 항상 실행
    });
};


export const saveResponseCaseJsonApi = (axios, params, successCallback, failCallback) => {
  axios
    .post("/responseCase/saveJson", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        failCallback(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      if (error.response.status === 413) {
        failCallback("업로드 용량을 초과하였습니다.");
      }
    })
    .then(() => {
      // 항상 실행
    });
};
