import React from "react";

const Request = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>MockBot의 API를 사용하기 위해서는 Request 정보 입력이 필수 입니다.</p>
        <p>MockBot API의 Request는 요청값의 조건을 추가하여 서버에서 발생할 수 있는 다양한 상황의 Test Case를 지원합니다.</p>
      </div>
    </>
  )
}
export default Request;