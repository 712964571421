import React, {useEffect, useState} from "react";
import {Input} from "antd";
import Image from "../../atoms/Image";
import {Link, useLocation} from "react-router-dom";
import SupportTable from "./Support/SupportTable";
import SupportDetail from "./Support/SupportDetail";
import SupportWrite from "./Support/SupportWrite";
import {useRecoilState, useRecoilValue} from "recoil";
import {userState} from "../../../recoil/user/userState";
import {bbsPageState, noticeTapState, supportMenuKeyState,} from "../../../recoil/bbs/bbsState";
import {loadSupportListApi} from "../../../api/support/supportApi";
import Loader from "../../common/Loader";
import useAxiosInterceptor from "../../../axios/axios";

const UserSupportContent = ({ tab = "" }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.01.26 [shiningtrue]: 상세 ID
  const detailId = useLocation().state?.id;
  // 2024.01.26 [shiningtrue]: 등록 화면인지 확인하기 위한 값
  const isWrite = useLocation().state?.isWrite;
  // 2024.01.26 [shiningtrue]: 페이징 처리 하기 위함
  const [page, setPage] = useRecoilState(bbsPageState);
  // 2024.01.26 [shiningtrue]: 검색 탭 처리 하기 위함
  const [isActive, setIsActive] = useRecoilState(noticeTapState);
  // 2024.01.26 [shiningtrue]: 새로고침시 클릭한 메뉴 유지 하기 위함
  const [supportMenuKey, setSupportMenuKey] =
    useRecoilState(supportMenuKeyState);

  // 2024.01.26 [shiningtrue]: 리스트 화면 데이터 세팅 하기 위함
  const [data, setData] = useState({
    key: tab,
    title: "",
    filter: [],
    data: [],
  });
  // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
  const [inputValues, setInputValues] = useState("");
  // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
  const [loading, setLoading] = useState(true);


  // 2024.02.28 [shiningtrue]: data.key는 클릭한 메뉴의 값 이므로 해당 값에 따라 새로고침시 메뉴 유지 및 초기 메뉴 설정을 위한 세팅
  useEffect(() => {
    if (data.key === "" && supportMenuKey.menu === "") {
      data.key = "notice";
    } else if (supportMenuKey.menu !== "") {
      // 2024.02.28 [shiningtrue]: supportMenuKey.menu는 클릭한 레프트 메뉴의 값을 가지고 있는 Recoil data
      data.key = supportMenuKey.menu;
    }
    // 2024.02.28 [shiningtrue]: 리스트 호출
    dataList(data.key);
  }, [page.pageNumber, page.pageSize, isActive, setData]);

  const loadSupportListSuccessCallback = (data, menuCondition) => {
    setData((prevDataList) => {
      if (
        JSON.stringify(data.data.data.content) !== JSON.stringify(prevDataList)
      ) {
        return {
          key: menuCondition.uri,
          title: menuCondition.title,
          filter: menuCondition.filter,
          data: data.data.data.content,
        };
      } else {
        return {
          key: menuCondition.uri,
          title: menuCondition.title,
          filter: menuCondition.filter,
          data: [],
        };
      }
      return prevDataList;
    });

    // 2024.02.28 [shiningtrue]: 페이징 처리 값 세팅
    setPage((prevPage) => {
      const newPage = {
        totalElements: data.data.data.totalElements,
        totalPages: data.data.data.totalPages,
        numberOfElements: data.data.data.numberOfElements,
        pageNumber: data.data.data.pageable.pageNumber,
        pageSize: data.data.data.pageable.pageSize,
      };

      if (JSON.stringify(prevPage) !== JSON.stringify(newPage)) {
        return newPage;
      }
      return prevPage;
    });
  }

  const loadSupportListThenCallback = () => {
    setLoading(false);
  }

  // 2024.02.28 [shiningtrue]: 리스트 호출 함수
  const dataList = async (type) => {
    // 리스트 조회
    var uri = "";
    var title = "";
    var filter = "";
    uri = type;
    if (type === "notice") {
      title = "공지사항";
      filter = ["전체", "서비스", "작업"];
    } else if (type === "faq") {
      title = "자주묻는 질문";
      filter = ["전체", "FAQ"];
    } else if (type === "inquiry") {
      title = "1:1 문의";
      filter = [];
    }
    let menuCondition = {
      uri: uri,
      title: title,
      filter: filter,
    };

    let pageNumber = page.pageNumber;

    let params = {
      pageNumber: pageNumber,
      size: page.pageSize,
      searchAll: isActive.all,
      searchService: isActive.service,
      searchWork: isActive.work,
      searchText: inputValues.searchText,
      adminYn: userSession.adminYn,
      userId: userSession.id,
    };
    await loadSupportListApi(
      axios,
      params,
      uri,
      menuCondition,
      loadSupportListSuccessCallback,
        loadSupportListThenCallback,
    );

  };

  // 2024.02.28 [shiningtrue]: 검색 텍스트 박스 값 세팅
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // 2024.02.28 [shiningtrue]: 검색 텍스트 박스 엔터 처리
  const handleKeyDown = (event) => {
    // Enter 키의 키 코드는 13입니다.
    if (event.keyCode === 13) {
      dataList(data.key)
        .then((response) => {})
        .catch((error) => {
          console.error('Error in dataList("notice")', error);
        });
    }
  };

  // 2024.02.28 [shiningtrue]: 레프트 메뉴 클릭 함수
  const onMenuClick = (value) => {
    setIsActive({
      all: true,
      service: false,
      work: false,
    });

    dataList(value).then((r) => "");
    setSupportMenuKey((prevValues) => ({
      ...prevValues,
      menu: value,
    }));
  };

  // 2024.02.28 [shiningtrue]: 레프트 메뉴 클릭 class 변경 및 onClick 처리 함수
  const SupportMenu = () => {
    const Text = ({ value, text }) => {
      const activeKey = data.title === text ? "Body4_B g900" : "Body4_R g500";
      return (
        <Link to="/support">
          <p
            className={"mt24 mb24 " + activeKey}
            onClick={() => onMenuClick(value)}
          >
            {text}
          </p>
        </Link>
      );
    };

    return (
      <>
        <Text key="notice" value="notice" text="공지사항" />
        <Text key="faq" value="faq" text="자주묻는 질문" />
        {userSession.id ? (
            <Text key="inquiry" value="inquiry" text="1:1 문의" />
        ) : null}
      </>
    );
  };

  const SupportSearch = ({ className }) => {
    return (
      <>
        {!detailId && data.key !== "oneToOne" && !isWrite && (
          <Input
            className={`support-search-input ${className} `}
            placeholder="Search..."
            name="searchText"
            onChange={(e) => {
              onChangeInput(e);
            }}
            onKeyDown={handleKeyDown}
            prefix={
              <Image
                className="mr9"
                path={`${process.env.PUBLIC_URL}/projectList`}
                name={"ic_search_24"}
                size={"md"}
              />
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      {/* Loader 실행 div */}
      {loading && (
        <>
          <div className="dim-overlay" />
          <div className="loader-container">
            <Loader />
          </div>
        </>
      )}
      <div className="support-body">
        <div className="support-menu">
          <div>
            <p className="support-name">고객지원</p>
            <SupportMenu />
          </div>
        </div>
        <div className="support-content">
          <div>
            <div className="support-content-header">
              <div className="support-content-title">{data.title}</div>
              <p className="support-name tablet">고객지원</p>
              <div className="support-tablet-menu">
                <SupportMenu />
              </div>
              <SupportSearch className={"support-search-desktop"} />
            </div>
            <SupportSearch className={"support-search-tablet"} />
            <hr className="mt14 bt-g900 bb-g900" />
            {detailId ? (
              <SupportDetail
                data={{
                  key: data.key,
                  ...data.data.find((v) => {
                    return v.id === detailId;
                  }),
                }}
                dataListCall={dataList}
              />
            ) : isWrite ? (
              /* 2024.02.28 [shiningtrue]:
               * data: 해당 리스트 Object 값을 넘겨줌
               * dataListCallBack: 다른 화면에서 리스트 조회 함수를 호출 하기 위한 callBack변수 */
              <SupportWrite data={data} dataListCallBack={dataList} />
            ) : (
              <SupportTable data={data} loading={loading} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSupportContent;
