import React from "react";
import { Layout } from "antd";
import Header from "../layout/projectList/Header";
import Footer from "../layout/projectList/Footer";
import Content from "../layout/projectList/Content";
import Sider from "../layout/manual/Sider";

const Manual = () => {
  return (
    <Layout className="manual">
      <Header isHamburger={true} />
      <Layout>
        <Sider />
        <Content name={"manual"}/>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default Manual;
