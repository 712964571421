import React, {useContext, useEffect, useRef, useState} from "react";
import {AutoComplete, Form, Input} from "antd";
import {useRecoilState} from "recoil";
import {commonUserVariableListState} from "../../recoil/project/projectState";
import {selectedApiState, tabOpenListState} from "../../recoil/api/apiState";
import {findRequestBodyParentNode} from "../../recoil/request/requestState";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  commonVariable = false,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const [commonUserVariableList] = useRecoilState(commonUserVariableListState);
  const [inputValue, setInputValue] = useState("");
  let chooseCommonVariableFlag = false;
  const [selectedApi] = useRecoilState(selectedApiState);
  const [tabOpenList] = useRecoilState(tabOpenListState);
  let requestItem = tabOpenList.find((item) => item.id === selectedApi.id);

  const [keyDisabled, setKeyDisabled] = useState(false);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);


  useEffect(() => {
    if (record?.type === 'BODY') {
      if (requestItem?.requestBody) {
        const parent = findRequestBodyParentNode(requestItem?.requestBody, record?.upperId);
        if (parent?.dataType === 'ARRAY') {
          setKeyDisabled(true);
          return;
        }
      }
    }

    setKeyDisabled(false);
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      let newRecord;

      if (values.keyValue.startsWith("${") && values.keyValue.endsWith("}")) {
        newRecord = record;
      } else {
        const { mappingManagement, ...removeMappingManagementRecord } = record;
        newRecord = removeMappingManagementRecord;
      }

      toggleEdit();
      handleSave({
        ...newRecord,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }

    setTimeout(() => {
      let firstCellInput = document.querySelector(
        `.ant-tabs-tabpane-active .editable-row[data-row-key="${record.key}"] .ant-table-cell[name="${dataIndex}"] div.editable-cell-value-wrap`,
      );
      if (firstCellInput === null) {
        firstCellInput = document.querySelector(
          `.editable-row[data-row-key="${record.key}"] .ant-table-cell[name="${dataIndex}"] div.editable-cell-value-wrap`,
        );
      }
      if (firstCellInput) {
        let tdsWithNameAttributeList = document.querySelector(
          `.ant-tabs-tabpane-active .editable-row[data-row-key="${record.key}"]`,
        );

        if (tdsWithNameAttributeList == null) {
          tdsWithNameAttributeList = document.querySelector(
            `.editable-row[data-row-key="${record.key}"]`,
          );
        }
        tdsWithNameAttributeList =
          tdsWithNameAttributeList.querySelectorAll("td[name]");

        const tdsLength = tdsWithNameAttributeList.length;

        for (let currentIndex = 0; currentIndex < tdsLength; currentIndex++) {
          const data = tdsWithNameAttributeList[currentIndex];
          const customData = data.querySelector("div.editable-cell-value-wrap");

          // firstCellInput과 같은 값을 가진 인덱스 찾기
          if (customData === firstCellInput) {
            // currentIndex가 tdsLength - 1보다 작은 경우에만 처리
            if (currentIndex < tdsLength - 1) {
              // customData 다음 인덱스 처리
              const nextData = tdsWithNameAttributeList[currentIndex + 1];
              const nextCustomData = nextData.querySelector(
                "div.editable-cell-value-wrap",
              );
              // 여기에 customData 다음 인덱스에 대한 처리 추가
              nextCustomData.click();
            }
          }
        }
      }
    }, 0);
  };

  const blur = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  /**
   * 2024.05.12 [energysteel]: 공통변수를 선택 후 저장
   *  - keyValue에 ${VALUE} 형태로 저장
   * @param data
   */
  const commonVariableSave = (data) => {
    try {
      toggleEdit();
      handleSave({
        ...record,
        keyValue: "${" + data.value + "}",
        mappingManagement: {
          mappingId: data.mappingId,
          key: data.value,
        },
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };


  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      save();
    }
  };

  /**
   * 2024.05.12 [energysteel]: inputValue state Input 입력한 값으로 변경
   * $ 입력 전: Input
   * $ 입력 후: AutoComplete
   * @param event
   */
  const handleOnChangeInputValue = (event) => {
    setInputValue(event.target.value);
  }

  /**
   * 2024.05.12 [energysteel]: AutoComplete 내 공통변수 선택 Event
   * @param inputValue 입력 값
   * @param data 공통변수
   */
  const handleOnChangeCommonVariable = (inputValue, data) => {
    setInputValue(inputValue);
    if (Object.keys(data).length > 0) {
      commonVariableSave(data);
    }

    chooseCommonVariableFlag = true;
  }

  /**
   * 2024.05.12 [energysteel]: AutoComplete 내 에서 Blur
   *  - inputValue가 ${ 로 시작하지 않는 경우
   *    ㄴ 공통변수가 아닌 것으로 판단
   *  - inputValue가 ${ 로 시작하는 경우
   *    ㄴ 공통변수 list 내 $, {} 을 제거한 value와 동일한 값이 있는지 판단
   *      ㄴ 있는 경우 공통변수로 판단
   *      ㄴ 없는 경우 공통변수가 아닌것으로 판단
   * @param event
   */
  const handleAutoCompleteOnBlur = (event) => {
    const value = event.target.value;
    // 2024.05.12 [energysteel]: 일반 입력 시
    if (!value.startsWith("${")) {
      blur();

      return;
    }

    const removeBracketAndDollarSign = /\${([^}]*)}/g;
    const variable = commonUserVariableList.find(variable =>
      variable.name === value.replace(removeBracketAndDollarSign, "$1"));

    // 2024.05.12 [energysteel]: Dollar와 Bracket을 지운 값이 공통변수에 존재하는 경우
    if (variable) {
      commonVariableSave({
        mappingId: variable.key,
        value: variable.name,
        data: variable.value,
      })
    } else {
      blur();
    }
  }


  /**
   * 2024.05.12 [energysteel]: AutoComplete 내 에서 Keyboard Enter
   * @param event
   */
  const handleAutoCompleteOnKeyDown = (event) => {
    // 2024.05.12 [energysteel]: 중복 Enter 방지
    if (event.isComposing || event.keyCode === 229) {
      return;
    }

    if (event.key === 'Enter') {
      if (event.target.value.startsWith('${') && event.target.value.endsWith('}')) {
        handleAutoCompleteOnBlur(event);
      }
    }
  }

  const autoCompleteOptions = commonUserVariableList.map(variable => {
    return { mappingId: variable.key, value: variable.name, data: variable.value};
  })

  let childNode = children;

  const handleFilterOption = (inputValue, option) => {
    const inputValueUpperCase = inputValue.toUpperCase();
    const optionValueUpperCase = option.value.toUpperCase();

    let indexOfValue;

    if (inputValueUpperCase.startsWith("${")) {
      indexOfValue = inputValueUpperCase.substring(2, inputValueUpperCase.endsWith("}") ? inputValue.length - 1 : undefined);
    } else if (inputValueUpperCase.startsWith("$")) {
      indexOfValue = inputValueUpperCase.substring(1, inputValueUpperCase.endsWith("}") ? inputValue.length - 1 : undefined);
    }

    return optionValueUpperCase.indexOf(indexOfValue) !== -1;
  }

  if (editable) {
    childNode = (editing && commonVariable) ?
      (
        <div>
          <Form.Item style={{margin: 0}} name={dataIndex}>
            {inputValue.startsWith("$") ?
              <AutoComplete
                className="Body6_R g900"
                ref={(ref) => ref?.focus()}
                defaultOpen={true}
                style={{
                  width: '100%',
                }}
                placeholder={"Key를 입력해 주세요."}
                onBlur={handleAutoCompleteOnBlur}
                onKeyDown={handleAutoCompleteOnKeyDown}
                value={inputValue}
                onChange={handleOnChangeCommonVariable}
                options={autoCompleteOptions}
                filterOption={handleFilterOption}
              />
              :
              <Input
                ref={(ref) => ref?.focus()}
                onChange={handleOnChangeInputValue}
                onPressEnter={save}
                onBlur={blur}
                defaultValue={record[dataIndex] || ""}
                placeholder={children[1]?.props.children.props.children}
                className="Body6_R g900"
              />
            }
          </Form.Item>
        </div>
      ) :
      (editing && !keyDisabled) ? (
        <Form.Item style={{margin: 0}} name={dataIndex}>
          <Input
            ref={inputRef}
            onKeyDown={onKeyDown}
            onPressEnter={save}
            onBlur={blur}
            defaultValue={record[dataIndex] || ""}
            placeholder={children[1]?.props.children.props.children}
            className="Body6_R g900"
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }


  return (
    <>
        <td {...restProps} name={dataIndex}
          style={{
            backgroundColor: (keyDisabled && record.type === 'BODY' && dataIndex === 'keyValue') ? "rgb(169 165 165 / 5%)" : "",
          }}
        >
          {childNode}
        </td>
    </>
  )
}

const envEditableCell = ({
                           title,
                           editable,
                           children,
                           dataIndex,
                           record,
                           handleSave,
                           ...restProps
                         }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editing, setEditing] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = useContext(EditableContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (editing) {
      //   inputRef.current.value = record[dataIndex];
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }

    setTimeout(() => {
      let firstCellInput = document.querySelector(
        `.ant-tabs-tabpane-active .editable-row[data-row-key="${record.key}"] .ant-table-cell[name="${dataIndex}"] div.editable-cell-value-wrap`,
      );
      if (firstCellInput === null) {
        firstCellInput = document.querySelector(
          `.editable-row[data-row-key="${record.key}"] .ant-table-cell[name="${dataIndex}"] div.editable-cell-value-wrap`,
        );
      }
      if (firstCellInput) {
        let tdsWithNameAttributeList = document.querySelector(
          `.ant-tabs-tabpane-active .editable-row[data-row-key="${record.key}"]`,
        );

        if (tdsWithNameAttributeList == null) {
          tdsWithNameAttributeList = document.querySelector(
            `.editable-row[data-row-key="${record.key}"]`,
          );
        }
        tdsWithNameAttributeList =
          tdsWithNameAttributeList.querySelectorAll("td[name]");

        const tdsLength = tdsWithNameAttributeList.length;

        for (let currentIndex = 0; currentIndex < tdsLength; currentIndex++) {
          const data = tdsWithNameAttributeList[currentIndex];
          const customData = data.querySelector("div.editable-cell-value-wrap");

          // firstCellInput과 같은 값을 가진 인덱스 찾기
          if (customData === firstCellInput) {
            // currentIndex가 tdsLength - 1보다 작은 경우에만 처리
            if (currentIndex < tdsLength - 1) {
              // customData 다음 인덱스 처리
              const nextData = tdsWithNameAttributeList[currentIndex + 1];
              const nextCustomData = nextData.querySelector(
                "div.editable-cell-value-wrap",
              );
              // 여기에 customData 다음 인덱스에 대한 처리 추가
              nextCustomData.click();
            }
          }
        }
      }
    }, 0);
  };

  const blur = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      save();
    }
  };

  let childNode = children;
  if (editable) {
    // 길이 제한을 설정할 변수
    let maxLength = 0; // 기본값

    // record.name 값에 따라 길이 제한을 조정
    if (record.name === "AES128") {
      maxLength = 16;
    } else if (record.name === "AES192") {
      maxLength = 24;
    } else if (record.name === "AES192") {
      maxLength = 32;
    }
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          ref={inputRef}
          onKeyDown={onKeyDown}
          onPressEnter={save}
          onBlur={blur}
          defaultValue={record[dataIndex] || ""}
          placeholder={children[1]?.props.children.props.children}
          className="Body6_R g900"
          maxLength={maxLength} // 길이 제한 설정
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return (
    <td {...restProps} name={dataIndex}>
      {childNode}
    </td>
  );
};

export { EditableRow, EditableCell, envEditableCell };
