import React from "react";

const URIChild = ({ clazz, setManualKey }) => {
  return (
    <div className={clazz}>
      <p>URI 기본 정보 설정과 변경 방법에 대한 설명입니다.</p>
      <br/>
      <div className="">
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_uri_1.png`}
            alt={"example_head"}
            width={792}
            height={453}
          />
          <br/>
          <br/>
          <p>1. API의 요청 메소드를 설정 합니다.(GET, POST, PATCH, PUT, DELETE)</p>
          <br/>
          <p>2. Content Type: application/json으로 고정입니다.(향후 확대 예정)</p>
          <br/>
          <p>3. Delay : 해당 API의 응답 지연 시간을 설정합니다.(Timeout 테스트에 활용)</p>
          <br/>
          <p>4. 암호화 방식 : API의 파마미터중 암호화가 필요한 경우 설정 합니다.(AES 128, 192, 256 지원)</p>
          <p>암호화에 사용할 키는 <a href={'#4-1-1'} onClick={() => setManualKey("4-1")}>
              공통변수
            </a> 메뉴 <a href={'#4-1-2'} onClick={() => setManualKey("4-2")}>
              환경변수
            </a>
            에서 등록할 수 있습니다.
          </p>
          <br/>
          <p>5. 설명 : API의 설명을 입력합니다.</p>

          <br/>
          <br/>
          <p>다음은 <a href={'#2-3-1'} onClick={() => setManualKey("2-3")}>Request</a> 등록에 대해 알아보겠습니다.</p>
          <br/>
          <br/>
        </div>
        <br/>
      </div>
    </div>
  )
}

export default URIChild;