import React, {useEffect, useState} from "react";
import Image from "../atoms/Image";
import {Divider, Dropdown, Input, Space, theme} from "antd";
import {selectedApiState} from "../../recoil/api/apiState";
import {useRecoilState} from "recoil";
import _ from "lodash";

const { useToken } = theme;

const httpMethodList = {
  GET: <span className="get">GET</span>,
  POST: <span className="post">POST</span>,
  PUT: <span className="put">PUT</span>,
  PATCH: <span className="patch">PATCH</span>,
  DELETE: <span className="delete">DEL</span>,
  HEAD: <span className="head">HEAD</span>,
  OPTIONS: <span className="options">OPT</span>,
};

const TabHeader = ({
  tabOpenList,
  onClickTab,
  handleTabClose,
  useTabClearDropdownHandle,
}) => {
  const { token } = useToken();
  const [inputValue, setInputValue] = useState("");
  // 2024.03.07 [energysteel]: API Tree에서 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.04.30 [energysteel]: 우측 Tab 리스트 Dropdown render 용
  const [viewTabList, setViewTabList] = useState({});

  /**
   * 2024.04.30 [energysteel]: Tab Dropdown item list render 함수
   * @param tab 탭
   * @param http HTTP Method
   * @param title API 이름
   * @param isActive selected 여부
   * @return {Element}
   */
  const itemsRender = (tab, http, title, isActive) => {
    return (
      <div
        className={`tab-act tab-drop update`}
        onClick={() => onClickTab(tab.id)}
      >
        <div className="tab-http">{httpMethodList[http]}</div>
        <div className={`tab-title Body6_B ${isActive ? "tab-active" : ""}`}>{title}</div>
        <span className="content jc-c">
          <Image
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_close"}
            onClick={(event) => handleTabClose(event, tab)}
          />
          {
            (
              tab.uriEditing ||
              tab.requestEditing ||
              tab.responseHeaderEditing ||
              tab.responseParameterEditing ||
              tab.responseCaseEditing
            )
              && <div className="interaction act" />}
        </span>
      </div>
    );
  };

  // 2024.04.30 [energysteel]: 우측 Tab 리스트 Dropdown 데이터용
  const tabList = tabOpenList?.map((tab, index) => ({
    key: index,
    apiName: tab.api.apiName,
    label: itemsRender(tab, tab.uri.httpMethod, tab.api.apiName, tab.isActive),
  }));

  useEffect(() => {
    setViewTabList(tabList);
  }, [tabOpenList]);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setViewTabList(tabList.filter((tab) => tab.apiName.toLowerCase().indexOf(value.toLowerCase()) !== -1));
  };

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingBottom: "16px",
  };

  const menuStyle = {
    boxShadow: "none",
  };

  /**
   * 상단 클릭한 API 리스트 탭
   * @param {item} selectedApi
   * @param {onClick} 탭 변경 clickEvent
   */
  const Tab = ({ item, selectedApi, handleTabClose, onClick }) => {
    let isActiveKey = item?.id === selectedApi.id;
    const styles = isActiveKey ? "g900" : "g400";

    return (
      <div
        className={"tab-act tabs Body6_B " + styles}
        style={{
          width: `calc(100% / ${tabOpenList.length})`,
        }}
        onClick={() => onClick(item?.id)}
        id={`active-${isActiveKey}`}
      >
        <div className="tabs-content">
          <div className={`tabs-content-act ${isActiveKey && "bb-brand500"}`}>
            <span className={`tabs-content-act-http ${isActiveKey && "mb-1"}`}>
              {httpMethodList[item?.uri.httpMethod]}
            </span>
            <span className={`tabs-content-act-title ${isActiveKey && "mb-1"}`}>
              {item?.uriEditing ||
              item?.requestEditing ||
              item?.responseHeaderEditing ||
              item?.responseParameterEditing ||
              item?.responseCaseEditing ? (
                item?.api.apiName
              ) : (
                <>{item?.api.apiName ?? selectedApi.title}</>
              )}
            </span>
            <span className="content jc-c">
              <Image
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_close"}
                onClick={(event) => handleTabClose(event, item)}
              />
              {item?.uriEditing ||
              item?.requestEditing ||
              item?.responseHeaderEditing ||
              item?.responseParameterEditing ||
              item?.responseCaseEditing ? (
                <div className="interaction act" />
              ) : (
                <></>
              )}
            </span>
          </div>
        </div>
        <div className="right-bar" />
      </div>
    );
  };

  useEffect(() => {
    const long = document.getElementById("tab-mid");
    if (long.firstChild.clientWidth < 113) {
      long.parentElement.className = "tab-header overflow";
    } else if (long.firstChild.clientWidth < 186) {
      long.parentElement.className = "tab-header overflow-more";
    } else {
      long.parentElement.className = "tab-header";
    }
    setTimeout(() => {
      const rect = document
        ?.getElementById("active-true")
        ?.getBoundingClientRect();
      const x = (rect?.left ?? 0) + window.scrollX;
      if (x > 1764) {
        scrollTabs("all");
      }
    }, 20);
  }, [tabOpenList]);


  const handleThrottleScroll = _.throttle((e) => {
    window.requestAnimationFrame(() => {
      if (e.deltaY > 0 || e.deltaX > 0) {
        scrollTabs("right");
      } else if (e.deltaY < 0 || e.deltaX < 0) {
        scrollTabs("left");
      }
    });

  }, 100);


  const scrollTabs = (direction) => {
    const container = document.querySelector(".tab-buttons");
    if (container) {
      const scrollAmount =
        direction === "left" ? -224 : 224;
      const targetScrollLeft = container.scrollLeft + scrollAmount;
      const start = container.scrollLeft;
      const change = targetScrollLeft - start;
      const duration = 1500;
      let startTime;

      const animate = (currentTime) => {
        if (!startTime) {
          startTime = currentTime;
        }
        const elapsedTime = currentTime - startTime;
        container.scrollLeft = easeOutQuart(elapsedTime, start, change, duration);
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
        }
      }

      requestAnimationFrame(animate);
    }
  }

  const easeOutQuart = (time, begin, change, duration) => {
    time /= duration;
    time--;
    return -change * (time * time * time * time - 1) + begin;
  }

  
  return (
    <div className="tab-header"
       onWheel={handleThrottleScroll}>
      <div className="tab-over left" onClick={() => scrollTabs("left")}>
        <Image
          path={`${process.env.PUBLIC_URL}/content`}
          name={"ic_arrow_left"}
        />
      </div>
      <div id="tab-mid" className="tab-buttons">
        {tabOpenList.map((item, index) => {
          return (
            <Tab
              key={index}
              item={item}
              selectedApi={selectedApi}
              handleTabClose={handleTabClose}
              onClick={onClickTab}
            />
          );
        })}

        {tabOpenList && (
          <div className="more content jc-c ml12 mt4">
            <Dropdown
              // eslint-disable-next-line react-hooks/rules-of-hooks
              menu={useTabClearDropdownHandle()}
              overlayStyle={{ width: "156px", height: "82px" }}
              trigger={["click"]}
            >
              <Space>
                <Image
                  className="cur"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={"ic_more"}
                />
              </Space>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="tab-over right" onClick={() => scrollTabs("right")}>
        <Image
          path={`${process.env.PUBLIC_URL}/content`}
          name={"ic_arrow_right"}
        />
      </div>
      <div className="tab-over right-close">
        <Dropdown
          // eslint-disable-next-line react-hooks/rules-of-hooks
          menu={useTabClearDropdownHandle()}
          overlayStyle={{ width: "156px", height: "82px" }}
          trigger={["click"]}
        >
          <Space>
            <Image
              className="cur"
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_more"}
            />
          </Space>
        </Dropdown>
      </div>
      <div className="tab-drop-btn" onWheel={(e) => e.stopPropagation()}>
        <Dropdown
          menu={{ items: viewTabList }}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              <Input
                variant="borderless"
                className="Body6_R g300"
                style={{ width: 320, height: 36, color: "black", border: 0 }}
                placeholder="Search tabs"
                value={inputValue}
                onChange={handleChange}
              />
              <Divider style={{ margin: 0 }} />
              <div
                className="ant-dropdown-menu-not-border"
                style={{
                  maxHeight: "340px",
                  overflow: "scroll",
                }}
              >
                {React.cloneElement(menu, { style: menuStyle })}
              </div>
            </div>
          )}
          trigger={["click"]}
        >
          <Space>
            <Image
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_arrow_down"}
            />
          </Space>
        </Dropdown>
      </div>
    </div>
  );
};

export default TabHeader;
