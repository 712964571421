import React, {useEffect, useState} from "react";
import {AutoComplete, ConfigProvider, Table} from "antd";
import Image from "../../../atoms/Image";
import ModalSelect from "../../../atoms/ModalSelect";
import TooltipImage from "../../../atoms/TooltipImage";
import {EditableCell, EditableRow} from "../../../atoms/Editable";
import DataDetailSettingModal from "../../modals/DataDetailSettingModal";
import {TestButton} from "../../../atoms/Button";
import {useRecoilState} from "recoil";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  helpIconMessageState,
  selectedApiState,
  tabOpenListState,
} from "../../../../recoil/api/apiState";
import useAxiosInterceptor from "../../../../axios/axios";
import RequiredText from "../../../atoms/RequiredText";
import {loadRequestApi, saveRequestJsonApi} from "../../../../api/request/requestApi";
import {isApiAuthority, projectState} from "../../../../recoil/project/projectState";
import {headers} from "../../../../autocomplete/header";
import Loader from "../../../common/Loader";
import {findRequestBodyParentNode, isEmptyDetailDataType} from "../../../../recoil/request/requestState";
import {findAllChildrenKeys, removeDuplicatedExpandedKey} from "../../../../recoil/common/utils";


const ApiRequestContent = ({ openFileDialog, saveJsonApiFailCallback }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  const [isModalOpen, setIsModalOpen] = useState([]);
  // 2024.02.29 [shiningtrue]: 클릭한 API ID
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.07 [energysteel]: 열려있는 Tab 리스트 및 Tab Content 관리
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  let requestItem = tabOpenList.find((item) => item.id === selectedApi.id);
  useEffect(() => {
    requestItem = tabOpenList.find((item) => item.id === selectedApi.id);
  }, [selectedApi.id]);

  const [activeSubTab, setActiveSubTab] = useState(
    (selectedApi?.httpMethod === 'GET' || selectedApi?.api?.httpMethod === 'GET') ?
      'PARAMETER' :
      'BODY'
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // 2024.04.05 [energysteel]: 현재 project 정보
  const [project] = useRecoilState(projectState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [loading, setLoading] = useState(false);

  // 2024.03.29 [energysteel]: DataType Min/Max 최대 값
  const numberRange = {
    STRING: {
      MIN: '-9223372036854775808',
      MAX: '9223372036854775807',
    },
    LONG: {
      MIN: '-9223372036854775808',
      MAX: '9223372036854775807',
    },
    INT: {
      MIN: '-2147483648',
      MAX: '2147483647',
    },
    SHORT: {
      MIN: '-32768',
      MAX: '32767',
    },
  }

  const showModal = (record) => {
    setIsModalOpen(prev => {
      if (prev.length === 0) {
        return [{ id: record.key, open: true }];
      }

      return prev.map((modal) => {
        if (modal.id === record.key) {
          return {
            ...modal,
            open: true,
          };
        } else {
          return {
            id: record.key,
            open: true,
          };
        }
      });
    });
  };


  const addRequestItem = (object) => {
    const entries = Object.entries(object);

    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          const updatedTab = { ...tab };
          entries.forEach(([key, value]) => {
            updatedTab[key] = value;
          });
          return updatedTab;
        }
        return tab;
      });
    });

    modifyApiRequestTab();
  };
  // 2024.03.11 [shiningtrue]: request recoil object 상태 변수 수정
  const setRequestData = (newData, tabType, name) => {
    if (tabType === "HEADER") {
      setTabOpenList((prev) => {
        return prev.map((tab) => {
          if (tab.id === selectedApi.id) {
            return {
              ...tab,
              requestHeader: [...tab.requestHeader, newData],
            };
          }
          return tab;
        });
      });
    } else {
      setTabOpenList((prev) => {
        return prev.map((tab) => {
          if (tab.id === selectedApi.id) {
            return {
              ...tab,
              [name]: [...tab[name], newData],
            };
          }
          return tab;
        });
      });
    }
    modifyApiRequestTab();
  };

  // 2024.03.11 [shiningtrue]: request 수정 여부 상태 변수 변경
  const modifyApiRequestTab = () => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            requestEditing: true,
          };
        }
        return tab;
      });
    });
  };

  /* 2024.03.11 [shiningtrue]: list field Object 찾는 재귀 함수 */
  const findKeyInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.key === key) {
        // 해당 key를 찾았을 경우
        return { item };
      }

      if (item.children && item.children.length > 0) {
        // children 배열이 있을 경우 재귀 호출
        const foundInChildren = findKeyInChildren(item.children, key);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null; // 찾지 못한 경우
  };

  /* 2024.03.11 [shiningtrue]: setRequestHeaderDataSource, Body Object의 값을 변경하기 위한 재귀 함수 */
  const setNewData = (items, data) => {
    return items.map((item) => {
      if (item.key === data.key) {
        return { ...item, ...data };
      }

      if (item.children && item.children.length > 0) {
        const updatedChildren = setNewData(item.children, data);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  const addQueryString = (name, updatedData) => {
    const uri = {...requestItem.uri};
    const queryString = `${updatedData.map(data => data.keyValue).join('=&')}=`

    if (queryString === '=') {
      const newUri = uri.uri?.substring(0, uri.uri.indexOf('?') !== -1 ?
        uri.uri?.indexOf('?') :
        uri.uri?.length);

      const data = {
        [name]: updatedData,
        uri: {
          ...uri,
          uri: newUri,
        },
        uriEditing: true,
      }
      addRequestItem(data);

      return;
    }

    if (uri.uri?.indexOf(queryString) !== -1) {
      addRequestItem({[name]: updatedData});
    } else {
      const queryStringAddUri = `${uri.uri?.substring(0, uri.uri.indexOf('?') !== -1 ?
        uri.uri?.indexOf('?') :
        uri.uri?.length)}?${queryString}`;
      const data = {
        [name]: updatedData,
        uri: {
          ...uri,
          uri: queryStringAddUri,
        },
        uriEditing: true,
      }
      addRequestItem(data);
    }
  }

  // 2024.03.07 [shiningtrue]: list field 저장 함수
  const handleSave = (row, tabType, name) => {
    let newData = [];
    if (tabType === "HEADER") {
      newData = [...requestItem?.requestHeader];
    } else {
      newData = [...requestItem[name]];
    }
    const updatedData = setNewData(newData, row);

    if (tabType === "HEADER") {
      addRequestItem({"requestHeader": updatedData});
    } else if (tabType === "PARAMETER") {
      addQueryString(name, updatedData);
    } else {
      addRequestItem({[name]: updatedData});
    }
  };

  const findAndDeleteItem = (items, row) => {
    const updatedItems = items.filter((item) => item.key !== row.key);

    return updatedItems.map((item) => {
      if (item.children && item.children.length > 0) {
        const updatedChildren = findAndDeleteItem(item.children, row);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  /* 2024.03.05 [shiningtrue]: row 삭제 버튼 */
  const handleDelete = (row, tabType, name) => {
    let newData = [];

    if (tabType === "HEADER") {
      newData = requestItem?.requestHeader.filter(
        (item) => item.key !== row.key,
      );
      addRequestItem({"requestHeader": newData});
    } else if (tabType === "PARAMETER") {
      newData = [...requestItem[name]];
      addQueryString(name, findAndDeleteItem(newData, row));
    } else {
      newData = [...requestItem[name]];
      addRequestItem({[name]: findAndDeleteItem(newData, row)});
    }
  };

  // 2024.03.07 [shiningtrue]: list row 추가 함수
  const handleAdd = (tabType, name) => {
    if (!requestItem) return;

    const newDataIndex = tabType === 'HEADER' ?
      requestItem.requestHeader.length :
      requestItem[name].length;

    const newData = {
      key: `new-${newDataIndex}`,
      keyValue: "",
      dataType: "STRING",
      requiredYn: false,
      description: null,
      children: [],
      apiId: selectedApi.id,
      upperId: 0,
      type: tabType,
      requestDetail: {
        requestId: undefined,
        isEncryption: false,
        min: undefined,
        max: undefined,
        regular: undefined,
        requestEnums: [],
      }
    };

    setRequestData(newData, tabType, name);
  };

  // 2024.03.07 [shiningtrue]: body list에 keyValue field가 object거나 array일 때 하위 추가 함수
  const bodyHandleAddMiddle = (record, tabType, name) => {
    const addNewChild = (items) => {
      return items.map((item) => {
        if (item.key === record.key) {
          let dataType = 'STRING';

          if (record.dataType === "ARRAY" && record.children?.length > 0) {
            dataType = record.children[0].dataType;
          }

          const newChild = {
            id: "",
            upperId: item.key,
            key: `${item.key}-${item.children.length}`,
            keyValue: "",
            apiId: selectedApi.id,
            type: tabType,
            dataType: dataType,
            requiredYn: false,
            description: null,
            children: [],
          };

          return {
            ...item,
            children: [...item.children, newChild],
          };
        }

        if (item.children && item.children.length > 0) {
          const updatedChildren = addNewChild(item.children);
          return {
            ...item,
            children: updatedChildren,
          };
        }

        return item;
      });
    };

    const updatedDataSource = addNewChild(requestItem[name]);

    // 상태 업데이트
    addRequestItem({[name]: updatedDataSource});
  };

  // 주어진 key에 해당하는 요소와 해당 요소의 부모를 찾는 재귀 함수
  const findItemAndParent = (items, parent, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.key === key) {
        return { item, parent };
      }
      if (item.children && item.children.length > 0) {
        const result = findItemAndParent(item.children, item, key);
        if (result) {
          return result;
        }
      }
    }
    return { item: null, parent }; // 요소를 찾지 못한 경우 null 대신 기본값 반환
  };

  // 2024.03.07 [shiningtrue]: header 기존 list row 생성 함수
  const headerDefaultColumns = [
    {
      title: (
        <>
          {isApiAuthority(project.authority) && 
            <div
              className="content jc-c h36"
              onClick={() => handleAdd("HEADER")}
            >
              <TooltipImage
                title={<div className="Body7_R">parameter 추가</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_add_bg"}
              />
            </div>
          }
        </>
      ),
      dataIndex: "delete",
      width: "60px",
      render: (_, record) =>
        requestItem?.requestHeader.length >= 1 ? (
          <div
            className="content jc-c h36"
            onClick={() => handleDelete(record, "HEADER")}
          >
            {isApiAuthority(project.authority) && 
              <TooltipImage
                className={"mr0 cur"}
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
              />
            }
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Key"} />,
      dataIndex: "keyValue",
      width: "45%",
      render: (_, record) =>
        requestItem?.requestHeader.length >= 1 ? (
          <>
            <AutoComplete
              style={{
                width: '100%',
              }}
              defaultValue={_}
              onBlur={(event) =>
                handleAutoCompleteChange(record, event.target.value)
              }
              placeholder={'Key를 입력해 주세요.'}
              options={headers}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              />
          </>
        ) : null,
    },
    {
      title: <div style={{ textAlign: "center" }}>Mandatory</div>,
      dataIndex: "requiredYn",
      width: "7%",
      render: (_, record) =>
        requestItem?.requestHeader.length >= 1 ? (
          <div
            className="content jc-c"
            onClick={() => isApiAuthority(project.authority) ?
              requiredCheckClick(record, "HEADER") :
              {}
            }
          >
            <Image
              path={`${process.env.PUBLIC_URL}/content`}
              name={
                getRecordRequiredYn(record, "HEADER")
                  ? "ic_checkbox_on"
                  : "ic_checkbox_off"
              }
              tagName={`required_HEADER${record.key}`}
              size={"sm"}
            />
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "*",
      editable: isApiAuthority(project.authority),
      render: (_, record) =>
        requestItem?.requestHeader.length >= 1 ? (
          <>
            {record.description ? (
              <p className="break-spaces-mw1200">{record.description}</p>
            ) : (
              <p className="g300">설명을 입력해주세요.</p>
            )}
          </>
        ) : null,
    },
    {
      title: <p style={{ width: 7 }} />,
      dataIndex: "key",
      width: "28px",
      render: (_, record) =>
        isApiAuthority(project.authority) && requestItem?.requestHeader.length >= 1 && !record.upperId ? (
          <Image
            className="content jc-c mr-4 ml-4"
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_move"}
          />
        ) : null,
    },
  ];

  const headerColumns = headerDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row, "HEADER"),
      }),
    };
  });

  // 2024.03.07 [shiningtrue]: 필수 여부 체크 저장 함수
  const requiredCheckClick = (row, tabType, name) => {
    const requiredName = `required_${tabType}${row.key}`;
    const targetElement = document.querySelector(`[name="${requiredName}"]`);
    const checkImageAlt = targetElement
      ? targetElement.getAttribute("alt")
      : "";
    const requiredYn = checkImageAlt === "ic_checkbox_off";
    let newData = [];
    if (tabType === "HEADER") {
      newData = [...requestItem?.requestHeader];
    } else {
      newData = [...requestItem[name]];
    }

    const updatedData = setNewData(newData, { ...row, requiredYn: requiredYn });

    if (tabType === "HEADER") {
      addRequestItem({"requestHeader": updatedData});
    } else {
      addRequestItem({[name]: updatedData});
    }
  };

  // 2024.04.15 [energysteel]: AutoComplete 값 세팅 추가
  const handleAutoCompleteChange = (row, optionValue) => {
    const newData = [...requestItem?.requestHeader];

    const updatedData = setNewData(newData, {
      ...row,
      keyValue: optionValue,
    });

    addRequestItem({"requestHeader": updatedData});
  };

  // 2024.03.07 [shiningtrue]: list field DataType 저장 함수
  const handleDataTypeChange = (row, key, optionValue, tabType, name) => {
    let newData = [];
    if (tabType === "HEADER") {
      newData = [...requestItem?.requestHeader];
    } else {
      newData = [...requestItem[name]];
    }
    const dataType = optionValue.toUpperCase();

    let updatedData = setNewData(newData, {
      ...row,
      [key]: optionValue,
      children: optionValue === 'ARRAY' ?
        [
          createArrayChildNode(row, tabType)
        ] :
        [],
      requestDetail: {
        ...row.requestDetail,
        min: minMaxChange(dataType, 'min', row?.requestDetail?.min),
        max: minMaxChange(dataType, 'max', row?.requestDetail?.max),
      }
    });

    // Object 하위를 초기화 하기 위함
    if (dataType !== "OBJECT" && dataType !== 'ARRAY') {
      const currentRow = findKeyInChildren(updatedData, row.key)?.item;
      if (currentRow) {
        currentRow.children = [];
      }
    }

    if (tabType === "HEADER") {
      addRequestItem({"requestHeader": updatedData});
    } else {
      addRequestItem({[name]: updatedData});
    }
  };

  const createArrayChildNode = (data, tabType) => {
    return {
      id: "",
      upperId: data.key,
      key: `${data.key}-${data.children.length}`,
      keyValue: "-",
      apiId: selectedApi.id,
      type: tabType,
      dataType: 'ANY',
      requiredYn: false,
      description: null,
      children: [],
    };

  }

  const minMaxChange = (dataType, name, value) => {
    if (dataType === 'STRING' || dataType === 'LONG' || dataType === 'INT' || dataType === 'SHORT') {
      if (Number(value) < Number(numberRange[dataType].MIN) || Number(value) > Number(numberRange[dataType].MAX)) {
        return numberRange[dataType][name.toUpperCase()];
      } else {
        return value;
      }
    }
  }

  const findDataTypeInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        return item.dataType;
      }

      if (item.children && item.children.length > 0) {
        const dataTypeInChildren = findDataTypeInChildren(item.children, key);
        if (dataTypeInChildren) {
          return dataTypeInChildren;
        }
      }
    }

    return null; // Return null if the key is not found
  };

  const getRecordDataType = (record, name) => {
    const dataType =
      requestItem[name].length >= 1
        ? findDataTypeInChildren(requestItem[name], record.key)
        : null;

    return dataType
      ? dataType.charAt(0).toUpperCase() + dataType.slice(1).toLowerCase()
      : "String";
  };

  const findRequiredYnInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        return item.requiredYn;
      }

      if (item.children && item.children.length > 0) {
        const requiredYnInChildren = findRequiredYnInChildren(
          item.children,
          key,
        );
        if (requiredYnInChildren !== undefined) {
          return requiredYnInChildren;
        }
      }
    }

    return undefined; // Return undefined if the key is not found
  };

  const getRecordRequiredYn = (record, tabType, name) => {
    let requiredYn = ""
    if (tabType === "HEADER") {
      requiredYn = requestItem?.requestHeader.length >= 1
          ? findRequiredYnInChildren(requestItem?.requestHeader, record.key)
          : undefined;
    } else {
      requiredYn = requestItem[name].length >= 1
          ? findRequiredYnInChildren(requestItem[name], record.key)
          : undefined;
    }

    return requiredYn !== undefined ? requiredYn : false; // Default to false if undefined
  };

  // 2024.03.07 [shiningtrue]: body 기존 list row 생성 함수
  const bodyDefaultColumns = (tabType, name) => [
    {
      title: (
        <>
          {isApiAuthority(project.authority) &&
            <div
              className="content jc-c h36"
              onClick={() => handleAdd(tabType, name)}
            >
              <TooltipImage
                title={<div className="Body7_R">parameter 추가</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_add_bg"}
              />
            </div>
          }
        </>
      ),
      dataIndex: "delete",
      width: "60px",
      render: (_, record) =>
        requestItem[name].length >= 1 ? (
          <div className="content jc-c h36">
                {isEmptyDetailDataType(record?.dataType) &&
                  <div>
                    <TooltipImage
                      className={"mr4 cur"}
                      title={<div className="Body7_R">Data 상세 설정</div>}
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_detail_edit"}
                      onClick={() => showModal(record)}
                    />
                    {isModalOpen?.find((modal) => modal.id === record.key)?.open ===
                      true && (
                      <DataDetailSettingModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        record={record}
                        numberRange={numberRange}
                        minMaxChange={minMaxChange}
                        name={name}
                        tabType={tabType}
                      />
                    )}
                  </div>
                }
                {(isApiAuthority(project.authority) &&
                  findRequestBodyParentNode(requestItem.requestBody, record.upperId)?.dataType !== 'ARRAY')
                  &&
                    <TooltipImage
                        className={"mr0 cur"}
                        title={<div className="Body7_R">삭제</div>}
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_delete"}
                        onClick={() => handleDelete(record, tabType, name)}
                    />
                }
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Key"} />,
      dataIndex: "keyValue",
      width: "45%",
      render: (_, record) =>
        requestItem[name].length >= 1 ? (
          <>
            {record.keyValue ? (
              <span
                className={"break-spaces-mw575"}
                style={{
                  color:
                    record.dataType === 'ARRAY' ? "#098658"
                      :
                    record.dataType === 'OBJECT' ? "#B81515"
                      :
                    "",
                  fontWeight:
                    record.dataType === 'ARRAY' || record.dataType === 'OBJECT' ? 600
                      :
                    400,
                }}
              >
                {record.keyValue}
              </span>
              ) :
              (findRequestBodyParentNode(requestItem.requestBody, record.upperId)?.dataType === 'ARRAY') ?
              (<span className="g300 break-spaces-mw575" style={{ color: 'black' }}>-</span>)
                :
            (
              <span className="g300">Key를 입력해 주세요.</span>
            )}
          </>
        ) : null,
      editable: (_, record) => isApiAuthority(project.authority),
    },
    {
      title: "Data Type",
      dataIndex: "dataType",
      width: "13%",
      render: (_, record) =>
        requestItem[name].length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              options={record.type === "BODY" ?
                [
                  { key: "STRING", value: "String" },
                  { key: "INT", value: "Int" },
                  { key: "LONG", value: "Long" },
                  { key: "SHORT", value: "Short" },
                  { key: "DOUBLE", value: "Double" },
                  { key: "FLOAT", value: "Float" },
                  { key: "BOOLEAN", value: "Boolean" },
                  { key: "FILE", value: "File" },
                  { key: "OBJECT", value: "Object" },
                  { key: "ARRAY", value: "Array" },
                  { key: "Any", value: "Any" },
                ] :
                [
                  { key: "STRING", value: "String" },
                ]
              }
              value={getRecordDataType(record, name)}
              handleChange={(e, v) =>
                isApiAuthority(project.authority) ?
                  handleDataTypeChange(record, 'dataType', v.value.toUpperCase(), tabType, name) :
                  {}
              }
              width="100%"
              height={28}
            />
          </div>
        ) : null,
    },
    {
      title: <div style={{ textAlign: "center" }}>Mandatory</div>,
      dataIndex: "requiredYn",
      width: "7%",
      render: (_, record) =>
        requestItem[name].length >= 1 ? (
          <div
            className="content jc-c"
            onClick={() => isApiAuthority(project.authority) ?
              requiredCheckClick(record, tabType, name) :
              {}
            }
          >
            <Image
              path={`${process.env.PUBLIC_URL}/content`}
              name={
                getRecordRequiredYn(record, tabType, name)
                  ? "ic_checkbox_on"
                  : "ic_checkbox_off"
              }
              tagName={`required_${tabType}${record.key}`}
              size={"sm"}
            />
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "*",
      editable: isApiAuthority(project.authority),
      render: (_, record) =>
        requestItem[name].length >= 1 ? (
          <>
            {record.description ? (
              <span className={"break-spaces-mw813"}>{record.description}</span>
            ) : (
              <span className="g300">설명을 입력해주세요.</span>
            )}
          </>
        ) : null,
    },
    {
      title: <p style={{ width: 7 }} />,
      dataIndex: "key",
      width: "28px",
      render: (_, record) =>
        isApiAuthority(project.authority) && requestItem[name].length >= 1 ? (
          !record.upperId ? (
            <Image
              className="content jc-c mr-4 ml-4"
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_move"}
            />
          ) : null
        ) : null,
    },
  ];

  const bodyColumns = (tabType, name) => {
    return bodyDefaultColumns(tabType, name).map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row) => handleSave(row, tabType, name),
        }),
      };
    });
  }

  // 2024.03.27 [shiningtrue]: 파일 선택 시 실행되는 이벤트 핸들러
  const handleFileChange = (event, tabType, name) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      setHelpIconMessage({
        code: "error",
        message: "파일을 선택하세요.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return;
    }
    
    const splitFileName = selectedFile.name.split(".");
    const extension = splitFileName[1];
    if (extension !== 'json') {
      setHelpIconMessage({
        code: "error",
        message: "json 확장자만 업로드 할 수 있습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return;
    }

    const newFileName = `upload.${extension ?? "json"}`;
    const uploadFile = new File([selectedFile], newFileName, { type: selectedFile.type });

    const params = {
      tabType: tabType,
      file: uploadFile,
      apiId: selectedApi.id,
    }

    setLoading(true);

    saveRequestJsonApi(
      axios,
      params,
      name,
      saveJsonApiSuccessCallback,
      (message) => {
        saveJsonApiFailCallback(message);
        setLoading(false);
      },
    );
  };

  const saveJsonApiSuccessCallback = (data, tabType, name) => {
    const params = {
      tabType: tabType,
      apiId: selectedApi.id,
    }
    loadRequestApi(axios, params, name, loadRequestApiSuccessCallback)
  };

  const loadRequestApiSuccessCallback = (data, tabType, name) => {
    setLoading(false);

    setTabOpenList((prev) => {
      const existingTab = prev.find((v) => v.id === selectedApi.id);
      if (existingTab) {
        return prev.map((tab) =>
          tab.id === selectedApi.id
            ? {
                ...tab,
                [tabType === "HEADER" ? "requestHeader" : name]: data.data.data,
              }
              : tab,
        );
      }
    });
  };

  // 2024.03.07 [shiningtrue]: header list 생성 함수
  const HeaderTabsItem = () => {
    const moveRow = (dragIndex, hoverIndex) => {
      const dragRow = requestItem.requestHeader[dragIndex];
      const newDataSource = [...requestItem.requestHeader];
      newDataSource.splice(dragIndex, 1);
      newDataSource.splice(hoverIndex, 0, dragRow);
      addRequestItem({"requestHeader": newDataSource});
    };

    const handleDragRow = (record, index) => ({
      index,
      draggable: true, // 드래그 가능하도록 설정
      onDragStart: (e) => {
        if (expandedRowKeys.length > 0) {
          setHelpIconMessage({
            code: "error",
            message: "열려 있는 Object를 닫은 후 이동해 주세요.",
          });
          alertHelpIcon(setAlertHelpIconOpen);

          return false;
        }
        e.dataTransfer.setData("text/plain", index); // 드래그된 요소의 인덱스 전달
      },
      onDragOver: (e) => {
        e.preventDefault(); // 드래그한 요소가 올바른 대상 위에 있을 때 드롭을 허용
      },
      onDrop: (e) => {
        e.preventDefault();
        const dropIndex = parseInt(e.dataTransfer.getData("text/plain")); // 드롭한 요소의 인덱스 가져오기
        if (dropIndex !== index) {
          moveRow(dropIndex, index); // 드래그 앤 드롭으로 인한 요소 위치 변경
        }
      },
    });

    return (
      <>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={requestItem?.requestHeader}
          columns={headerColumns}
          onRow={handleDragRow}
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => <></>,
            expandIconColumnIndex: 1,
            indentSize: 40,
          }}
        />
      </>
    );
  };

  // 2024.03.07 [shiningtrue]: body list 생성 함수
  const BodyTabsItem = ({ tabType, name }) => {
    const moveRow = (record, dragIndex, hoverIndex) => {
      const dragRow = requestItem[name][dragIndex];
      if (dragRow === undefined) {
        return false;
      }

      if (dragRow.upperId !== 0) {
        setHelpIconMessage({
          code: "error",
          message: "최상위 Row만 이동 가능합니다.",
        });
        alertHelpIcon(setAlertHelpIconOpen);

        return false;
      }
      const newDataSource = [...requestItem[name]];
      newDataSource.splice(dragIndex, 1);
      newDataSource.splice(hoverIndex, 0, dragRow);
      addRequestItem({[name]: newDataSource});
    };

    const handleDragRow = (record, index) => ({
      index,
      draggable: true, // 드래그 가능하도록 설정
      onDragStart: (e) => {
        if (expandedRowKeys.length > 0) {
          setHelpIconMessage({
            code: "error",
            message: "열려 있는 Object를 닫은 후 이동해 주세요.",
          });
          alertHelpIcon(setAlertHelpIconOpen);

          return false;
        }
        e.dataTransfer.setData("text/plain", index); // 드래그된 요소의 인덱스 전달
      },
      onDragOver: (e) => {
        e.preventDefault(); // 드래그한 요소가 올바른 대상 위에 있을 때 드롭을 허용
      },
      onDrop: (e) => {
        e.preventDefault();
        const dropIndex = parseInt(e.dataTransfer.getData("text/plain")); // 드롭한 요소의 인덱스 가져오기
        if (dropIndex !== index && record.upperId === 0) {
          moveRow(record, dropIndex, index); // 드래그 앤 드롭으로 인한 요소 위치 변경
        }
      },
    });

    return (
      <>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={requestItem ? requestItem[name] : ''}
          columns={bodyColumns(tabType, name)}
          onRow={handleDragRow}
          pagination={false}
          expandedRowKeys={expandedRowKeys}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => (
              <>
                {(record.dataType.toUpperCase() === "OBJECT" || record.dataType.toUpperCase() === "ARRAY") ? (
                  <>
                    {(isApiAuthority(project.authority) && record.dataType.toUpperCase() === "OBJECT") &&
                      <Image
                        className="mr4 cur mb-2"
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_plus"}
                        onClick={(e) => {
                          e.stopPropagation();
                          bodyHandleAddMiddle(record, tabType, name);
                          setExpandedRowKeys([record.key, ...expandedRowKeys]);
                        }}
                      />
                    }
                    {expanded ? (
                      <Image
                        className="cur mr4 mb-2"
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_arrow_down"}
                        onClick={(e) => {
                          setExpandedRowKeys(() => {
                            return expandedRowKeys.filter(
                              (key) => key !== record.key,
                            );
                          });
                          e.stopPropagation();
                          onExpand(record, e);
                        }}
                      />
                    ) : (
                      <Image
                        className="cur mr4 mb-2"
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_arrow_right"}
                        onClick={(e) => {
                          const childrenExpandedRows = findAllChildrenKeys(record);
                          const uniqueExpandedRows = removeDuplicatedExpandedKey([record.key, ...expandedRowKeys, ...childrenExpandedRows]);
                          setExpandedRowKeys(uniqueExpandedRows);
                          e.stopPropagation();
                          onExpand(record, e);
                        }}
                      />
                    )}
                  </>
                ) : record.upperId !== 0 ? (
                  "┗ "
                ) : null}
              </>
            ),
            expandIconColumnIndex: 1,
            indentSize: 40,
          }}
        />
      </>
    );
  };

  // 2024.03.07 [shiningtrue]: tab 및 list 생성 함수
  const tabList = [
    {
      key: "HEADER",
      label: <span className="subtab-title">Header</span>,
      children: <HeaderTabsItem />,
    },
    {
      key: "PARAMETER",
      label: <span className="subtab-title">Parameter</span>,
      children: <BodyTabsItem name={"requestParameter"} tabType={"PARAMETER"} />,
    },
    {
      key: "BODY",
      label: <span className="subtab-title">Body</span>,
      children: <BodyTabsItem name={"requestBody"} tabType={"BODY"} />,
    },
  ];

  return (
    <>
      {loading &&
        <div className="loader-container">
          <Loader/>
        </div>
      }
      <div className="Body7_R g900 mb10">
        API Request Parameter 정보를 설정합니다.
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#131418",
              itemHoverColor: "#131418",
              itemColor: "#949eb7",
              inkBarColor: "#7c60ff",
            },
            Table: {
              headerColor: "#636c83",
              headerBg: "#ffffff",
              cellPaddingBlock: 0,
              cellPaddingInline: 10,
              cellFontSize: 13,
              borderColor: "#dde2ee",
              headerBorderRadius: 3,
            },
          },
        }}
      >
        <div className="content jc-sb">
          <div className="mt6" style={{ height: 31 }}>
            {tabList.map((v) => {
              return (
                <div
                  className={`api-content-subtab ${activeSubTab === v.key}`}
                  onClick={() => setActiveSubTab(v.key)}
                  key={v.key}
                >
                  {v.label}
                </div>
              );
            })}
          </div>
          <div>
            <input
              type="file"
              id={
                activeSubTab === "HEADER" ? "fileHeaderInput" : "fileBodyInput"
              }
              style={{ display: "none" }}
              onChange={(e) => handleFileChange(
                e,
                activeSubTab,
                activeSubTab === 'PARAMETER' ?
                  'requestParameter' :
                  'requestBody'
              )}
            />
            {(isApiAuthority(project.authority) && activeSubTab !== "PARAMETER") && (
              <TestButton
                className={"ml4 mb8"}
                text={"Json 업로드"}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_upload"}
                onClick={() => openFileDialog(activeSubTab)}
              />
            )}
          </div>
        </div>
        <div className="table-overflow-auto -true">
          {
            tabList.find((value) => {
              return value.key === activeSubTab;
            })?.children
          }
        </div>
      </ConfigProvider>
    </>
  );
};

export default ApiRequestContent;
