import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Tooltip } from "antd";
import RequiredText from "../../atoms/RequiredText";
import { SquareButton } from "../../atoms/Button";
import Image from "../../atoms/Image";
import { useAuth } from "../../../AuthContext";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../recoil/user/userState";
import {
  loadUserInfoApi,
  modifyNameApi,
  modifyPasswordApi,
  modifyWithdrawalApi,
} from "../../../api/account/accountApi";
import useAxiosInterceptor from "../../../axios/axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { loadHistoriesApi } from "../../../api/history/historyApi";
import ConfirmModal from "../modals/ConfirmModal";

const AccountContent = () => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  const [isInput, setIsInput] = useState(false);
  const userSession = useRecoilValue(userState); //사용자 세션 정보
  const userId = userSession.id;
  const [user, setUser] = useRecoilState(userState);
  //비밀번호 정규식
  const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

  // 2024.04.29 [energysteel]: 이번달 API 사용 제한 건수
  const [limitOfMonth, setLimitOfMonth] = useState(0);
  // 2024.04.29 [energysteel]: 이번달 API 사용 이용 건수
  const [totalOfMonth, setTotalOfMonth] = useState(0);
  // 2024.04.29 [energysteel]: API 이용 건수 리스트
  const [histories, setHistories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // 2024.04.29 [energysteel]: 유저 API 이용건수 조회 API
  useEffect(() => {
    loadHistoriesApi(
      axios,
      createHistoriesRequest(),
      loadHistoriesApiSuccessCallback
    );
  }, []);

  /**
   * 2024.04.29 [energysteel]: 유저 API 이용건수 Request 생성
   *  - Date: yyyy-MM-dd
   *  - startDate: 이번달 첫날
   *  - endDate: 이번달 마지막날
   * @return {{endDate: string, userId: *, startDate: string}}
   */
  const createHistoriesRequest = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const lastDay = new Date(year, month, 0)
      .getDate()
      .toString()
      .padStart(2, "0");

    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${lastDay}`;

    return { startDate, endDate, userId };
  };

  /**
   * 2024.04.29 [energysteel]: 유저 API 이용건수 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const loadHistoriesApiSuccessCallback = (response) => {
    setHistories(response.data.data.contents);
    setTotalOfMonth(response.data.data.totalOfMonth);
    setLimitOfMonth(response.data.data.limitOfMonth);
  };

  const UserInfo = () => {
    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState({ name: "" });

    // 2024.02.02 [shiningtrue]: 사용자 이름 변수
    const [userName, setUserName] = useState("");

    // 2024.03.26 [shiningtrue]: 등록 프로젝트 관리 상태 변수
    const [projectCountState, setProjectCountState] = useState({
      projectName: null,
      projectCount: null,
    });

    // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
    const [loading, setLoading] = useState(true);

    // 2024.03.21 [shiningtrue]: API KEY 보기 상태 변수
    const [showKey, setShowKey] = useState(false);
    useEffect(() => {
      loadUserInfo();
    }, []);

    // 2024.03.21 [shiningtrue]: API KEY 보기 클릭 함수
    const handleApiKeyClick = () => {
      if (!showKey) {
        setShowKey(!showKey);
      } else {
        const tempInput = document.createElement("input");
        tempInput.value = userSession.apiKey;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        toast.success("클립보드에 복사되었습니다.");
      }
    };

    // 2024.01.30 [shiningtrue]: input onChange시 값 세팅
    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    const loadUserInfoApiSuccessCallback = (data) => {
      setUserName(data.data.data.name);
      setProjectCountState({
        projectName: data.data.data.projectName,
        projectCount: data.data.data.projectCount,
      });
    };

    const loadUserInfoApiThenCallback = () => {
      setLoading(false);
    };

    const loadUserInfo = async () => {
      const params = {
        id: userSession.id,
      };
      loadUserInfoApi(
        axios,
        params,
        loadUserInfoApiSuccessCallback,
        loadUserInfoApiThenCallback
      );
    };

    const modifyNameApiSuccessCallback = useCallback((data) => {
      setUserName(data.data.data.name);
      setUser((prevUser) => ({
        ...prevUser, // 이전 상태를 복사
        name: data.data.data.name, // 변경할 부분만 업데이트
      }));
      toast.success("수정 되었습니다.");
      setIsInput((prev) => !prev);
    }, []);

    const modifyName = async () => {
      console.log(inputValues);
      const params = {
        id: userSession.id,
        name: inputValues.name,
      };
      modifyNameApi(axios, params, modifyNameApiSuccessCallback);
    };
    useEffect(() => {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        name: userSession.name,
      }));
    }, [userSession.name]);

    const toggleIsInput = () => {
      setIsInput((prev) => !prev);
    };

    return (
      <>
        <table className="g500 Body6_R" width={"100%"}>
          <colgroup>
            <col width={"20%"} />
            <col width={"80%"} />
          </colgroup>
          <tbody className="Body3_R g900">
            <tr height={65}>
              <td className="Body3_B">아이디</td>
              <td className="Body4_R">{userSession.email}</td>
            </tr>
            <tr height={65}>
              <td className="Body3_B">사용자 이름</td>
              <td>
                {isInput ? (
                  <div className="content">
                    <Input
                      className="Body4_R g900 bg-white br4 mr12"
                      style={{ height: "48px", width: "400px" }}
                      name="name"
                      onChange={(e) => {
                        onChangeInput(e);
                      }}
                      defaultValue={userSession.name}
                    />
                    <SquareButton
                      className={"btn-pri"}
                      text={"저장"}
                      size="xs"
                      onClick={modifyName}
                    />
                  </div>
                ) : (
                  <div className="content">
                    <span className="Body4_R ellipsis-mw100">{userName}</span>
                    <Image
                      className="ml4"
                      path={process.env.PUBLIC_URL + "/content"}
                      name={"ic_account_edit"}
                      onClick={toggleIsInput}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr height={65}>
              <td className="Body3_B">API KEY</td>
              <td className="Body4_R">
                {showKey
                  ? userSession.apiKey
                  : userSession.apiKey.substring(
                      0,
                      userSession.apiKey.length - 20
                    ) + "*".repeat(20)}
                <a>
                  <span
                    className="Body6_B g500 b-g300 bg-g100 ml16 br3"
                    style={{ padding: "4px 16px" }}
                    onClick={handleApiKeyClick}
                  >
                    {showKey ? "복사" : "보기"}
                  </span>
                </a>
              </td>
            </tr>
            <tr height={65}>
              <td className="Body3_B">등록 프로젝트</td>
              <td className="Body4_R">
                {projectCountState.projectName === null ? (
                  "등록된 프로젝트가 존재하지 않습니다."
                ) : projectCountState.projectCount === 0 ? (
                  projectCountState.projectName
                ) : (
                  <>
                    <p className={"ellipsis-mw600"}>
                      {projectCountState.projectName}
                    </p>
                    <p
                      className={"ellipsis"}
                      style={{
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {` 외 ${projectCountState.projectCount}건`}
                    </p>
                  </>
                )}
                <Tooltip
                  className="Body6_B g500"
                  placement="bottom"
                  title={"Project 관리 화면으로 이동"}
                >
                  <Link to="/main">
                    <span
                      className="Body6_B g500 b-g300 bg-g100 ml16 br3"
                      style={{ padding: "4px 16px" }}
                    >
                      + 더보기
                    </span>
                  </Link>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="bt-g200" />
      </>
    );
  };

  const UpdatePw = () => {
    // 2024.01.26 [shiningtrue]: 패스워드 형식 체크
    const [password, setPassword] = React.useState("");

    // 2024.01.26 [shiningtrue]: 패스워드 더블 체크
    const [passwordChk, setPasswordChk] = React.useState("");

    // 2024.01.26 [shiningtrue]: errorMsg show hide용
    const [errorMassageStyle, setErrorMsgStyle] = useState({
      newPassword: false,
      newPasswordDoubleCheck: false,
    });

    // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
    const [inputValues, setInputValues] = useState("");

    // 2024.02.28 [shiningtrue]: 간편 회원가입 사용자는 변경하기버튼 숨기기 위한 상태 변수
    const [modifyPasswordButtonHide, setModifyPasswordButtonHide] =
      useState(false);

    useEffect(() => {
      if (userSession.registerPath === "02") {
        setModifyPasswordButtonHide(true);
      }
    }, []);

    // 2024.01.26 [shiningtrue]: input값 담기 위함
    const onChangeInput = (e) => {
      const { name, value } = e.target;
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };

    // 2024.01.26 [shiningtrue]: 에러 메세지 show & hide
    const errorMsgStyle = (name, type) => {
      setErrorMsgStyle((prevState) => {
        return { ...prevState, [name]: type };
      });
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 정규식 체크
    const passwordCheck = (password) => {
      setPassword(password);
      passwordDoubleCheck(password, passwordChk);
      if (password.match(passwordRegEx) === null) {
        //형식에 맞지 않을 경우 아래 콘솔 출력
        errorMsgStyle("newPassword", true);
        return;
      } else {
        // 맞을 경우 출력
        errorMsgStyle("newPassword", false);
      }
    };

    // 2024.01.26 [shiningtrue]: 비밀번호 재입력 체크
    const passwordDoubleCheck = (password, passwordChk) => {
      setPasswordChk(passwordChk);
      if (password !== passwordChk) {
        errorMsgStyle("newPasswordDoubleCheck", true);
        return;
      } else {
        errorMsgStyle("newPasswordDoubleCheck", false);
      }
    };

    const modifyPasswordApiSuccessCallback = useCallback((data) => {
      toast.success("변경 되었습니다.");
      setInputValues("");
    }, []);

    const modifyPassword = () => {
      if (
        !inputValues.nowPassword ||
        !inputValues.newPassword ||
        !inputValues.newPasswordDoubleCheck
      ) {
        toast.error("비밀번호를 입력해 주십시오.");
        return;
      }

      if (
        errorMassageStyle.newPasswordDoubleCheck ||
        errorMassageStyle.newPassword
      ) {
        toast.error("새 비밀번호를 확인해 주십시오.");
        return;
      }

      const params = {
        id: userSession.id,
        nowPassword: inputValues.nowPassword,
        newPassword: inputValues.newPassword,
      };
      modifyPasswordApi(axios, params, modifyPasswordApiSuccessCallback);
    };

    return (
      <>
        <table className="account-content-updatepw g500 Body6_R">
          <colgroup>
            <col className="updatepw-first" />
            <col className="updatepw-last" />
          </colgroup>
          <tbody className="Body3_R g900">
            <tr height={65}>
              <td className="Body3_B">
                <RequiredText text={"현재 비밀번호"} />
              </td>
              <td>
                <Input
                  className="Body4_R g900 bg-white br4"
                  style={{ height: "48px" }}
                  placeholder="현재 비밀번호를 입력해주세요."
                  type={"password"}
                  onChange={(e) => {
                    onChangeInput(e);
                  }}
                  name="nowPassword"
                  value={inputValues.nowPassword}
                />
              </td>
            </tr>
            <tr height={65}>
              <td className="Body3_B">
                <RequiredText text={"새 비밀번호"} />
              </td>
              <td>
                <Input
                  className="Body4_R g900 bg-white br4"
                  style={{ height: "48px" }}
                  placeholder="새 비밀번호를 입력해주세요."
                  type={"password"}
                  onChange={(e) => {
                    onChangeInput(e);
                    passwordCheck(e.target.value);
                  }}
                  name="newPassword"
                  value={inputValues.newPassword}
                />
                {errorMassageStyle.newPassword ? (
                  <div className="error" style={errorMassageStyle}>
                    비밀번호는 영문,숫자,특수문자 조합 8자리 이상으로
                    입력해주십시오.
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr height={65}>
              <td className="Body3_B">
                <RequiredText text={"새 비밀번호 확인"} />
              </td>
              <td>
                <Input
                  className="Body4_R g900 bg-white br4"
                  style={{ height: "48px" }}
                  placeholder="새 비밀번호를 다시 입력해주세요."
                  type={"password"}
                  onChange={(e) => {
                    onChangeInput(e);
                    passwordDoubleCheck(password, e.target.value);
                  }}
                  name="newPasswordDoubleCheck"
                  value={inputValues.newPasswordDoubleCheck}
                />
                {errorMassageStyle.newPasswordDoubleCheck ? (
                  <div className="error" style={errorMassageStyle}>
                    비밀번호가 동일하지 않습니다.
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="content jc-c mt6 bt-g200" style={{ padding: "40px 0" }}>
          <Tooltip
            className="Body6_B g500"
            placement="bottom"
            title={"Project 관리 화면으로 이동"}
          >
            {/*<Link to="/project">
              <SquareButton className={"g600 bg-g150 "} text={"취소"} />
            </Link>*/}
          </Tooltip>
          {!modifyPasswordButtonHide ? (
            <SquareButton
              className={"btn-pri ml6"}
              text={"변경하기"}
              onClick={modifyPassword}
            />
          ) : (
            <span style={{ color: "red" }}>
              간편 회원가입 사용자이므로 비밀번호를 변경 할 수 없습니다.
            </span>
          )}
        </div>
      </>
    );
  };
  const navigate = useNavigate();
  const { authContextLogout } = useAuth();

  const modifyWithdrawalApiSuccessCallback = useCallback((data) => {
    toast.success("탈퇴 되었습니다.");
    authContextLogout();
    navigate("/signin");
  }, []);

  const secession = () => {
    setModalOpen(true);
  };

  const handleWithdrawOk = () => {
    setModalOpen(false);

    const params = {
      id: userSession.id,
    };
    modifyWithdrawalApi(axios, params, modifyWithdrawalApiSuccessCallback);
  };

  const Withdraw = () => {
    return (
      <>
        <div className="Body4_B g900 ta mt40 ">
          서비스 탈퇴를 원하시면 [탈퇴] 버튼을 클릭해 주세요
        </div>
        <div className="content jc-c mt24">
          <Tooltip
            className="Body6_B g500"
            placement="bottom"
            title={"Project 관리 화면으로 이동"}
          >
            {/*<Link to="/project">
              <SquareButton className={"g600 bg-g150 "} text={"취소"} />
            </Link>*/}
          </Tooltip>
          <SquareButton
            className={"btn-pri ml6"}
            text={"탈퇴하기"}
            onClick={secession}
          />

          <ConfirmModal
            open={modalOpen}
            title={"회원탈퇴"}
            message={"회원탈퇴 하시겠습니까?"}
            textOk={"네"}
            textCancel={"아니오"}
            handleOk={handleWithdrawOk}
            handleCancel={() => setModalOpen(false)}
          />
        </div>
      </>
    );
  };

  const [useCountData, setUseCountData] = useState("0-0");

  const UseCount = () => {
    const SubCountList = ({ project }) => {
      const [act, setAct] = useState(["0-0"]);
      const apiGroupClick = (api) => {
        if (
          act?.find((v1) => {
            return v1 === api.key;
          })
        ) {
          setAct(
            act?.filter((v1) => {
              return v1 !== api.key;
            })
          );
        } else {
          setAct([...act, api.key]);
        }
      };
      return (
        <>
          {project?.contents.map((api) => {
            return (
              <React.Fragment key={api.key}>
                {api?.contents?.length > 0 ? (
                  //API Group 일 때
                  <>
                    <div
                      className="cur"
                      style={{ height: 48, padding: "13px 23px" }}
                      onClick={() => apiGroupClick(api)}
                    >
                      <Image
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={
                          act?.find((v1) => {
                            return v1 === api.key;
                          })
                            ? "ic_arrow_down"
                            : "ic_arrow_up"
                        }
                      />
                      <span className={"Body4_R g500 ml8 ellipsis-mw220"}>
                        {api.title}
                      </span>
                      <span className="account-sepbar" />
                      <span
                        className={"Body4_R g500 ellipsis"}
                        style={{ whiteSpace: "pre" }}
                      >
                        {` ${api.count}건 사용`}
                      </span>
                    </div>
                    {act?.find((v1) => {
                      return v1 === api.key;
                    }) &&
                      api.contents.map((subApi, i) => {
                        return (
                          <div
                            key={i}
                            className="Body4_R g500"
                            style={{ height: 48, padding: "13px 51px" }}
                          >
                            <span className={"Body4_R g500 ml8 ellipsis-mw220"}>
                              {subApi.title}
                            </span>
                            <span className="account-sepbar" />
                            <span
                              className={"ellipsis"}
                              style={{ whiteSpace: "pre" }}
                            >
                              {` ${subApi.count}건 사용`}
                            </span>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  //API Group 아닐 때
                  <div
                    className="Body4_R g500"
                    style={{ height: 48, padding: "13px 23px" }}
                  >
                    <span className={"Body4_R g500 ml8 ellipsis-mw220"}>
                      {api.title}
                    </span>
                    <span className="account-sepbar" />
                    <span className={"ellipsis"} style={{ whiteSpace: "pre" }}>
                      {` ${api.count}건 사용`}
                    </span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </>
      );
    };
    return (
      <>
        <div className="account-usecount-pt Body4_R">
          <span className="brand500">
            사용 {numberWithCommas(totalOfMonth)}건
          </span>
          <span
            className="br-g200 ml10 mr10"
            style={{ height: "12px", display: "inline-block" }}
          />
          <span className="g500">전체 {numberWithCommas(limitOfMonth)}건</span>
        </div>
        <div className="account-usecount-content">
          <div
            className="br-g200"
            style={{
              width: "332px",
              overflow: "auto",
            }}
          >
            {histories.map((v, i) => {
              return (
                <div
                  key={i}
                  onClick={() => setUseCountData(v.key)}
                  style={{ height: "48px", padding: "13px 24px" }}
                  className={`${useCountData === v.key ? "Body4_B bg-brand100 brand500 cur" : "Body4_R g500 cur"}`}
                >
                  <span className={"ellipsis-mw220"}>{v.title}</span>
                  <span className="account-sepbar" />
                  <span className={"ellipsis"} style={{ whiteSpace: "pre" }}>
                    {` ${v.count}건 사용`}
                  </span>
                </div>
              );
            })}
          </div>
          <div
            style={{
              minWidth: "calc(100% - 332px)",
              overflow: "auto",
            }}
          >
            <SubCountList
              project={histories.find((v) => {
                return v.key === useCountData;
              })}
            />
          </div>
        </div>
      </>
    );
  };

  const dataType = [
    {
      key: "userInfo",
      title: "회원정보",
      content: <UserInfo />,
    },
    {
      key: "updatePw",
      title: "비밀번호 변경",
      content: <UpdatePw />,
    },
    {
      key: "withdraw",
      title: "회원탈퇴",
      content: <Withdraw />,
    },
    {
      key: "useCount",
      title: "이용건수",
      content: <UseCount />,
    },
  ];
  const [data, setData] = useState(dataType[0]);
  const onMenuClick = (v) => {
    setData(
      dataType.find((value) => {
        return v === value.title;
      })
    );
  };

  const numberWithCommas = (number) => {
    return number.toLocaleString("ko-KR");
  };

  const Text = ({ value }) => {
    const activeKey = data.title === value ? "Body4_B g900" : "Body4_R g500";
    return (
      <Link to="/account">
        <p
          className={"mt24 mb24 " + activeKey}
          onClick={() => onMenuClick(value)}
        >
          {value}
        </p>
      </Link>
    );
  };

  return (
    <div className="account-body">
      <div className="account-menu">
        <div>
          <p className="account-name">계정관리</p>
          {dataType.map((v) => {
            return <Text key={v.title} value={v.title} />;
          })}
        </div>
      </div>
      <div className="account-content">
        <div>
          <div className="account-content-header">
            <div className="account-content-title">{data.title}</div>
            <p className="account-name tablet">계정관리</p>
            <div className="account-tablet-menu">
              {dataType.map((v) => {
                return <Text key={v.title} value={v.title} />;
              })}
            </div>
            {data.key === "useCount" && (
              <div className="account-usecount Body4_R">
                <span className="brand500">
                  사용 {numberWithCommas(totalOfMonth)}건
                </span>
                <span
                  className="br-g200 ml10 mr10"
                  style={{ height: "12px", display: "inline-block" }}
                />
                <span className="g500">
                  전체 {numberWithCommas(limitOfMonth)}건
                </span>
              </div>
            )}
          </div>
          <hr className="mt14 bt-g900 bb-g900" />
          {
            dataType.find((v) => {
              return v.key === data.key;
            }).content
          }
        </div>
      </div>
    </div>
  );
};

export default AccountContent;
