import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userState} from "../recoil/user/userState";
import {useAuth} from "../AuthContext";

const useAxiosInterceptor = () => {
    const { authContextLogout } = useAuth();
    const navigate = useNavigate();
    const userSession = useRecoilValue(userState);

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_PATH,
        timeout: 30000,
        headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("accessToken")
        },
    });

    axiosInstance.interceptors.request.use(
        (request) => {
            return request;
        },
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            // 2024.03.04[holywater]: error가 400번일때
            if (error.response && error.response.status >= 401 && error.response.status <= 499) {
                // 2024.03.04[holywater]: refreshToken Code
                if (error.response.data.code === '9001') {
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_PATH}/jwt/refresh/${userSession.id}`, { }, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: localStorage.getItem("refreshToken"),
                            },
                        });
                        // 2024.03.04[holywater]: 새로운 액세스 토큰을 저장하고 이전 요청을 재시도
                        sessionStorage.setItem("accessToken", response.data.data.accessToken);
                        localStorage.setItem("refreshToken", response.data.data.refreshToken);
                        error.config.headers.Authorization = sessionStorage.getItem('accessToken');
                        return axios(error.config);
                    } catch (refreshError) {
                        // 2024.03.04[holywater]: refreshToken Error 시, main 화면으로 이동
                        authContextLogout();
                        navigate('/signin');
                        return Promise.reject(refreshError);
                    }
                } else {
                    // 2024.03.04[holywater]: refreshToken이 아닌 400대 에러
                    authContextLogout();
                    navigate('/signin');
                }
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxiosInterceptor;