import React from "react";

const Image = ({ path, name, className = "", size = "xm", onClick, id, tagName, width, height, style,onMouseOver, onMouseLeave }) => {
  const imgSize =
    size === "xm"
      ? 16
      : size === "sm"
        ? 20
        : size === "md"
          ? 24
          : size === "xmd"
            ? 28
            : size === "lg"
              ? 32
              : 48;
  return (
    <img
      id={id}
      className={className}
      name={tagName}
      src={`${path}/${name}.png`}
      alt={name}
      width={width ? width : imgSize}
      height={height ? height : imgSize}
      onClick={onClick}
      style={style}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default Image;
