import React, {useEffect, useState} from "react";
import useAxiosInterceptor from "../../../axios/axios";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  commonErrorCodeListState,
  commonResponseFormBodyListState,
  commonResponseFormHeaderListState, isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import {loadCommonErrorCodeApi, saveCommonErrorCodeApi,} from "../../../api/commonErrorCode/commonErrorCodeApi";
import {
  commonErrorCodeCurrentTimeState,
  commonErrorEditingRecoilState,
  validateErrorCode,
} from "../../../recoil/commonErrorCode/commonErrorCodeState";
import {SquareButton} from "../../atoms/Button";
import CommonResponseContent from "./CommonResponseContent";
import CommonErrorCodeContent from "./CommonErrorCodeContent";
import {
  loadCommonResponseFormApi,
  saveCommonResponseFormApi,
} from "../../../api/commonResponseForm/commonResponseFormApi";
import {alertHelpIcon, alertHelpIconOpenState, helpIconMessageState,} from "../../../recoil/api/apiState";
import _ from "lodash";
import {commonDuplicationSaveCheckApi} from "../../../api/project/projectApi";
import ConfirmModal from "../modals/ConfirmModal";
import {userState} from "../../../recoil/user/userState";

const CommonErrorContent = () => {
  const axios = useAxiosInterceptor();
  // 2024.04.16 [shiningtrue]: 열려있는 Tab (1: Response, 2: 에러 코드)
  const [activeSubTab, setActiveSubTab] = useState("1");
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  const userId = useRecoilValue(userState).id;
  // 2024.03.21 [shiningtrue]: 관리자 여부
  const isManager =
    project.authority === "OWNER" || project.authority === "ADMIN";
  // 2024.04.16 [shiningtrue]: 수정 여부 상태 관리 변수
  const [commonErrorEditingState, setCommonErrorEditingState] = useRecoilState(
    commonErrorEditingRecoilState,
  );
  // 2024.03.22 [shiningtrue]: Response List 상태 관리 변수 Header
  const [
    commonResponseFormHeaderRecoilState,
    setCommonResponseFormHeaderRecoilState,
  ] = useRecoilState(commonResponseFormHeaderListState);
  // 2024.03.22 [shiningtrue]: Response List 상태 관리 변수 Body
  const [
    commonResponseFormBodyRecoilState,
    setCommonResponseFormBodyRecoilState,
  ] = useRecoilState(commonResponseFormBodyListState);
  // 2024.03.18 [shiningtrue]: 에러 코드 List 상태 관리 변수
  const [commonErrorCodeRecoilState, setCommonErrorCodeRecoilState] =
    useRecoilState(commonErrorCodeListState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [currentTime, setCurrentTime] = useRecoilState(
    commonErrorCodeCurrentTimeState,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState("");

  /**
   * 2024.05.10 [shiningtrue]: 화면 load 시점에 CurrentTime을 담음
   */
  useEffect(() => {
    if (currentTime.response === "") {
      setCurrentTime((prev) => ({
        ...prev,
        response: new Date(),
      }));
    }

    if (currentTime.errorCode === "") {
      setCurrentTime((prev) => ({
        ...prev,
        errorCode: new Date(),
      }));
    }
  }, []);

  /**
   * 2024.04.16 [shiningtrue]: 키보드 저장 이벤트
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        if (isProjectOwnerOrAdminAuthority(project.authority)) {
          handleAllSaveThrottled();
        }
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [commonErrorEditingState]);

  const responseReLoad = () => {
    // 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Header
    loadCommonResponseFormApi(
      axios,
      project.id,
      "HEADER",
      loadCommonResponseFormHeaderApiSuccessCallback,
    );

    // 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Body
    loadCommonResponseFormApi(
      axios,
      project.id,
      "BODY",
      loadCommonResponseFormBodyApiSuccessCallback,
    );

    setCurrentTime((prev) => ({
      ...prev,
      response: new Date(),
    }));
  };

  const errorCosdeReLoad = () => {
    // 2024.03.18 [shiningtrue]: 공통 에러코드 조회
    loadCommonErrorCodeApi(
      axios,
      project.id,
      loadCommonErrorCodeApiSuccessCallback,
    );

    setCurrentTime((prev) => ({
      ...prev,
      errorCode: new Date(),
    }));
  };

  // 2024.03.25 [shiningtrue]: 저장 버튼
  const handleAllSave = () => {
    if (
      commonErrorEditingState.responseHeaderEditing ||
      commonErrorEditingState.responseBodyEditing ||
      commonErrorEditingState.errorCodeEditing
    ) {
      const duplicationParams = {
        userId: userId,
        projectId: project.id,
        view: "commonError",
      };

      // 2024.05.10 [shiningtrue]: 다른 사용자가 저장한 이력이 있는지 조회
      commonDuplicationSaveCheckApi(
        axios,
        duplicationParams,
        duplicationSaveCheckApiSuccessCallback,
      );
    }
  };

  const handleAllSaveThrottled = _.debounce(handleAllSave, 150);

  const save = () => {
    // Response 저장
    if (
      commonErrorEditingState.responseHeaderEditing ||
      commonErrorEditingState.responseBodyEditing
    ) {
      const params = {
        commonResponseHeaderFormObject: commonResponseFormHeaderRecoilState,
        commonResponseBodyFormObject: commonResponseFormBodyRecoilState,
        headerEditing: commonErrorEditingState.responseHeaderEditing,
        bodyEditing: commonErrorEditingState.responseBodyEditing,
        projectId: project.id,
      };

      saveCommonResponseFormApi(
        axios,
        params,
        saveCommonResponseFormApiSuccessCallback,
      );
    } else {
      responseReLoad();
    }

    // 에러 코드 저장
    if (commonErrorEditingState.errorCodeEditing) {
      // 벨리데이션 체크
      if (validateErrorCode(commonErrorCodeRecoilState, setHelpIconMessage)) {
        const params = {
          commonErrorCodeObject: commonErrorCodeRecoilState,
          projectId: project.id,
        };

        saveCommonErrorCodeApi(
          axios,
          params,
          saveCommonErrorCodeApiSuccessCallback,
        );
      } else {
        alertHelpIcon(setAlertHelpIconOpen);
        return false;
      }
    } else {
      errorCosdeReLoad();
    }

    setConfirmModalMessage("");
  };

  const duplicationSaveCheckApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      let newMessage = "";
      const responseUpdate = response.data.data.responseUpdateDate;
      const errorCodeUpdate = response.data.data.errorCodeUpdateDate;
      const responseUpdateDate = new Date(responseUpdate?.updateDate);
      const errorCodeUpdateDate = new Date(errorCodeUpdate?.updateDate);

      // Response 탭의 수정 시간 or 에러코드 탭의 수정 시간을 가지고와서 비교
      if (
        (responseUpdateDate > currentTime.response &&
          (commonErrorEditingState.responseHeaderEditing ||
            commonErrorEditingState.responseBodyEditing)) ||
        (errorCodeUpdateDate > currentTime.errorCode &&
          commonErrorEditingState.errorCodeEditing)
      ) {
        if (responseUpdateDate > currentTime.response) {
          if (
            commonErrorEditingState.responseHeaderEditing ||
            commonErrorEditingState.responseBodyEditing
          ) {
            newMessage += `<span style="color:red;">[${responseUpdate.createUserName}]</span>사용자가 <span style="color:red;">[공통에러]</span>메뉴 <span style="color:red;">[Response]</span> Tab을 수정 하였습니다.\n\n`;
          }
        }
        if (errorCodeUpdateDate > currentTime.errorCode) {
          if (commonErrorEditingState.errorCodeEditing) {
            newMessage += `<span style="color:red;">[${errorCodeUpdate.createUserName}]</span>사용자가 <span style="color:red;">[공통에러]</span>메뉴 <span style="color:red;">[에러코드]</span> Tab을 수정 하였습니다.\n\n`;
          }
        }
        setConfirmModalMessage(
          newMessage + "내가 수정한 내용으로 덮어 씌우시겠습니까?",
        );
        setModalOpen(true);
      } else {
        save();
      }
    }
  };

  /**
   * 탭 onClick Event (Response, 에러 코드)
   * @param key Tab Key
   * @see subApiList
   */
  const onClickSubTab = (key) => {
    setActiveSubTab(key);
    // 2024.03.18 [shiningtrue]: Tab 변경시 에러 코드 search 전부 true로 변경 함수
    const updatedCommonErrorCodeRecoilState = commonErrorCodeRecoilState.map(
      (item) => ({
        ...item,
        search: true,
      }),
    );

    setCommonErrorCodeRecoilState(updatedCommonErrorCodeRecoilState);
  };

  /**
   * 2024.04.16 [shiningtrue]: Sub 탭
   *  - Response, 에러 코드
   */
  const subApiList = [
    {
      key: "1",
      title: "Response",
      content: <CommonResponseContent />,
    },
    { key: "2", title: "에러 코드", content: <CommonErrorCodeContent /> },
  ];

  /**
   * Response, 에러 코드 탭
   * @param {onClick} 탭 변경 clickEvent
   */
  const SubTab = ({ item, onClick }) => {
    const isActiveKey = item.key === activeSubTab;
    return (
      <div
        className={`common-error-tab ${isActiveKey} Body6_B `}
        onClick={() => onClick(item.key)}
      >
        <span className="content ml16">{item.title}</span>
      </div>
    );
  };

  // 2024.03.22 [shiningtrue]: 저장 완료시 editing 처리
  const modifyCommonErrorSuccess = () => {
    console.log("여기탓어");
    setCommonErrorEditingState((prevState) => ({
      ...prevState, // 이전 상태를 가져옴
      responseHeaderEditing: false,
      responseBodyEditing: false,
      errorCodeEditing: false,
    }));

    setHelpIconMessage({
      code: "success",
      message: "저장 완료되었습니다.",
    });
    alertHelpIcon(setAlertHelpIconOpen);
  };

  // 2024.03.25 [shiningtrue]: response 저장 완료
  const saveCommonResponseFormApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorEditingState((prevState) => ({
        ...prevState, // 이전 상태를 가져옴
        responseHeaderEditing: false,
        responseBodyEditing: false,
      }));

      setHelpIconMessage({
        code: "success",
        message: "저장 완료되었습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      responseReLoad();
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.18 [shiningtrue]: 에러 코드 List 조회 callback
  const loadCommonErrorCodeApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorCodeRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }
  };

  // 2024.03.22 [shiningtrue]: Header List 조회 callback
  const loadCommonResponseFormHeaderApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonResponseFormHeaderRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.22 [shiningtrue]: Body List 조회 callback
  const loadCommonResponseFormBodyApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonResponseFormBodyRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.18 [shiningtrue]: 에러 코드 저장 callback
  const saveCommonErrorCodeApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      loadCommonErrorCodeApi(
        axios,
        project.id,
        loadCommonErrorCodeApiSuccessCallback,
      );

      setCommonErrorEditingState((prevState) => ({
        ...prevState, // 이전 상태를 가져옴
        errorCodeEditing: false,
      }));

      setHelpIconMessage({
        code: "success",
        message: "저장 완료되었습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      errorCosdeReLoad();
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }
  };

  const handleResponseSaveModalOk = () => {
    save();
    setModalOpen(false);
  };
  return (
    <>
      <div className="common-error">
        <div>
          <div className="common-error-tabs">
            {subApiList.map((item, index) => {
              return <SubTab key={index} item={item} onClick={onClickSubTab} />;
            })}
            <div className="common-error-btn">
              {isManager ? (
                <>
                  <SquareButton
                    className={`btn-pri Body6_B ${
                      !commonErrorEditingState.responseHeaderEditing &&
                      !commonErrorEditingState.responseBodyEditing &&
                      !commonErrorEditingState.errorCodeEditing &&
                      "disable"
                    }`}
                    size={"sm-s"}
                    onClick={handleAllSaveThrottled}
                    text={"저장"}
                    isButtonDisabled={
                      !commonErrorEditingState.responseHeaderEditing &&
                      !commonErrorEditingState.responseBodyEditing &&
                      !commonErrorEditingState.errorCodeEditing &&
                      true
                    }
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="table-overflow-auto">
          {
            subApiList.find((value) => {
              return value.key === activeSubTab;
            })?.content
          }
        </div>
      </div>
      <ConfirmModal
        open={modalOpen}
        title={"중복 저장 알림"}
        message={
          <div dangerouslySetInnerHTML={{ __html: confirmModalMessage }} />
        }
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleResponseSaveModalOk}
        handleCancel={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default CommonErrorContent;
