import React from "react";

const ResponseError = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
                <p>MockBot의 Response Error는 API 처리 중 발생된 오류의 규격을 정의하여 애플리케이션에서 <b>표준화된 Error 처리</b>를 구현할 수 있도록 도와줍니다.</p><br/>
                <p>Response Error의 규격을 정의하는 것은 여러 장점을 제공합니다.</p><br/>
                <p>1. 일관성과 안정성: 일관된 오류 규격을 사용하면 API의 일관성을 유지할 수 있습니다.</p><br/>
                <p>2. 디버깅과 유지보수성: 오류 규격을 정의하면 개발자들이 오류를 신속하게 식별하고 디버깅할 수 있습니다.</p>
                <p>오류 메시지의 일관된 형식은 문제 해결및 가독성 높은 코드 작성을 도와줍니다.</p><br/>
                <p>3. 보안강화: 오류 규격을 통해서 중요한 정보를 노출하지 않고도 API 보안을 강화하고 적절한 오류 코드와 메시지를 통해</p>
                <p>보안문제를 효과적으로 관리할 수 있습니다.</p><br/>
            </div>
        </>
    )
}
export default ResponseError;