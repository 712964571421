import {Button, Tooltip} from "antd";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import PrivacyPolicy from "./terms/PrivacyPolicy";
import TermOfUse from "./terms/TermOfUse";

const Term = () => {
  const location = useLocation();
  const type = location.state.type;


  return (
    <div className="account-body">
      <div className="account-content">
        <div>
          <hr className="mt14 bt-g900 bb-g900"/>

          {type === 'privacyPolicy' ?
            <PrivacyPolicy/>
            :
            <TermOfUse />
          }

          <div className="content jc-c mt24">
            <Tooltip
              className="Body6_B g500"
              placement="bottom"
              title={"홈으로 이동"}
            >
              <Link to="/main">
                <Button
                  className="btn-pri Body4_B mt36 br5"
                  style={{
                    width: "132px",
                    height: "36px",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                  type="primary"
                >
                  홈으로 이동
                </Button>
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Term;