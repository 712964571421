import React from "react";
import { Button } from "antd";

const Footer = ({ textCancel, textOk, handleCancel, handleOk, disabled = false }) => {
  return (
    <div className="content jc-c mt28 mb4">
      <Button
        className="btn-sec Body6_B br3"
        style={{ width: "132px", height: "36px" }}
        onClick={handleCancel}
      >
        {textCancel}
      </Button>
      <Button
        className="btn-pri Body6_B br3"
        style={{
          width: "132px",
          height: "36px",
          marginLeft: "4px",
        }}
        disabled={disabled}
        type="primary"
        onClick={handleOk}
      >
        {textOk}
      </Button>
    </div>
  );
};

export default Footer;
