// 파일 리스트 API
import { toast } from "react-toastify";

export const loadFileListApi = (axios, fileListParams, successCallback) => {
  axios
    .get("/file", {
      params: {
        fileGroupId: fileListParams.fileGroupId,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response, fileListParams.type);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 파일 다운로드 API
export const fileDownloadApi = (axios, params, successCallback) => {
  axios
    .get("/file/downLoadFile", {
      params: {
        savePath: params.savePath,
      },
      responseType: "arraybuffer",
    })
    .then(function (response) {
      successCallback(response, params.originalName);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
