import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import ModalSelect from "../../../atoms/ModalSelect";
import SupportWriteContent from "../../../molecules/SupportWriteContent";
import { useLocation } from "react-router-dom";
import {
  loadSupportModifyDetailApi,
} from "../../../../api/support/supportApi";
import {
  loadFileListApi
} from "../../../../api/common/commonApi";
import useAxiosInterceptor from "../../../../axios/axios";
const SupportWrite = ({ data, dataListCallBack }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.02.28 [shiningtrue]: 게시물이 등록 or 수정 확인 하기 위함
  const location = useLocation();
  // 2024.02.28 [shiningtrue]: 게시물 등록 or 수정시 유형 값 상태 변수
  const [category, setCategory] = useState();

  // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
  const [inputValues, setInputValues] = useState("");

  // 2024.02.28 [shiningtrue]: 파일 리스트를 담기 위한 상태 변수
  const [fileListData, setFileListData] = useState({
    fileData: [],
  });

  // 2024.02.28 [shiningtrue]: 파일 그룹ID를 담기 위한 상태 변수
  const [fileGroupId, setFileGroupId] = useState();

  // 2024.02.28 [shiningtrue]: 카테고리 리스트 세팅 상태 변수
  const [optionsType, setOptionsType] = useState();

  useEffect(() => {
    if (data.key === "notice") {
      setOptionsType([
        { value: "A", label: "서비스" },
        { value: "B", label: "작업" },
      ]);
    } else if (data.key === "faq") {
      setOptionsType([
        { value: "A", label: "FAQ" },
      ]);
    } else if (data.key === "inquiry") {
      setOptionsType([{ value: "A", label: "문의" }]);
    }

    // 2024.02.28 [shiningtrue]: 수정 화면 이동시 데이터 세팅 하기 위함
    if (location.state.writeType === "modify") {
      detailData(location.state.detailId);
    }
  }, []);

  useEffect(() => {}, [fileListData]);

  const loadFileListSuccessCallback = useCallback((data) => {
    if (data.data.data.length > 0) {
      setFileListData({
        fileData: data.data.data,
      });
    }
  }, []);

  const loadSupportDetailSuccessCallback = useCallback((data) => {
    // 상세 데이터를 setDetailData에 넣기
    setInputValues({
      title: data.data.data.title || "",
      content: data.data.data.content || "",
    });
    setCategory(data.data.data.type);

    // 파일 리스트
    if (data.data.data.fileGroupId != null && data.data.data.fileGroupId != 0) {
      setFileGroupId(data.data.data.fileGroupId);
      fileList(data.data.data.fileGroupId);
    }
  }, []);

  // 2024.02.28 [shiningtrue]: 등록 폼 상세 데이터 세팅 함수
  const detailData = (id) => {
    let params = {
      id: id,
    };
    loadSupportModifyDetailApi(axios, params, data.key, loadSupportDetailSuccessCallback);
  };

  // 2024.02.28 [shiningtrue]: 파일 리스트 조회 함수
  const fileList = async (groupId) => {
    let fileListParams = {
      fileGroupId: groupId,
    };
    loadFileListApi(axios, fileListParams, loadFileListSuccessCallback);
  };

  // 2024.02.28 [shiningtrue]: 카테고리 세팅 함수
  const categoryChange = (value) => {
    setCategory(value);
  };

  // 2024.02.28 [shiningtrue]: Text 데이터 세팅 함수
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="Body4_R g900 mt16">
        <ModalSelect
          placeholder={"유형을 선택해 주세요."}
          width={"100%"}
          height={48}
          value={category}
          options={optionsType}
          handleChange={categoryChange}
        />
        <Input
          className="bg-white br3 mt8 mb8"
          style={{ height: "48px" }}
          placeholder="제목을 입력해 주세요."
          name="title"
          value={inputValues.title}
          onChange={(e) => {
            onChangeInput(e);
          }}
        />
      </div>
      <SupportWriteContent
        data={data}
        title={inputValues.title}
        contentValue={inputValues.content}
        fileDetailList={fileListData}
        fileGroupId={fileGroupId}
        updateContentValue={onChangeInput}
        category={category}
        dataListCallBack={dataListCallBack}
      />
    </>
  );
};

export default SupportWrite;
