import React from "react";
import {ManualButton} from "../../../atoms/Button";
import Image from "../../../atoms/Image";
import {Link} from "react-router-dom";

const ManualGuide = ({ clazz, setManualKey }) =>{
    return (
      <>
          <div className={clazz}>
              <p>MockBot에서 REST API 등록과 API 연동을 쉽고 빠르게 사용할 수 있도록 자세한 설명을 제공합니다.</p>
          </div>

          <div className="guide-btn">
              <Link to={"/faq"}>
                  <ManualButton
                    text={"자주하는 질문"}
                    icon={
                        <Image
                          path={`${process.env.PUBLIC_URL}/content`}
                          name={"ic_empty_list"}
                        />
                    }
                  />
              </Link>
              <Link to={"/#inquiry"}>
                  <ManualButton
                    text={"온라인 문의"}
                    pos={"right"}
                    icon={
                        <Image
                          path={`${process.env.PUBLIC_URL}/content`}
                          name={"ic_empty_list"}
                        />
                    }
                  />
              </Link>
          </div>
      </>
    )
}
export default ManualGuide;