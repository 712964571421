import React from "react";
import Image from "../../atoms/Image";
import {Link} from "react-router-dom";
import {Button} from "antd";

const ErrorContent = ({error, resetErrorBoundary}) => {
  if (error !== undefined) {
    console.log(error.message);
  }
  return (
    <div className={"m300"}>
      <div
        style={{textAlign: "center"}}
      >
        {
          resetErrorBoundary === undefined || error === undefined
            ?
            <Image
              className="ml6 mb-2"
              path={`${process.env.PUBLIC_URL}/error`}
              name={"404"}
              width={"240"}
              height={"120"}
            />
            :
            <p>아직 이미지 없음.</p>
        }
      </div>

      <div>
        <h2
          className="Roboto_B mt24 mb6"
          style={{
            color: "#636C83",
            fontSize: "20px",
            textAlign: "center",
          }}>
          요청하신 페이지를 찾을 수 없습니다.
        </h2>
        <p
          className="Body4_R"
          style={{
            color: "#949EB7",
            fontSize: "12px",
            textAlign: "center",
          }}>
          {
            resetErrorBoundary === undefined || error === undefined
              ?
              <>
                잘못된 경로로 접근했거나, 페이지의 주소가 변경 또는 삭제되어 찾을 수 없습니다.<br/>
                올바른 주소를 입력해 주세요.
              </>
              :
              <>
                {error.message}
              </>
          }

        </p>
        <Link to="/">
          <div
            style={{
              textAlign: "center",
            }}>
            {
              resetErrorBoundary === undefined || error === undefined
                ?
                <Button
                  className="btn-pri Body4_B mt36 br5"
                  style={{
                    width: "132px",
                    height: "36px",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                  type="primary"
                >
                  홈으로 이동
                </Button>
                :
                <Button
                  className="btn-pri Body4_B mt36 br5"
                  style={{
                    width: "132px",
                    height: "36px",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                  onClick={resetErrorBoundary}
                  type="primary"
                >
                  홈으로 이동
                </Button>
            }
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ErrorContent;
