import React from "react";
import Image from "./Image";

const MainTemplate = ({ idx, title, subTitle, content }) => {
  return (
    <div className="not-btn mt40 mb40">
      <h3 id={idx} className="H4_B mb4">
        {title}
      </h3>
      <div id={`content${idx}`} className="item">
        <p className="Body5_R g500 mb16">{subTitle}</p>
        {content}
      </div>
    </div>
  );
};

const SubMainTemplate = ({ idx, title, content }) => {
  return (
    <div className="mt40 mb40">
      <h3 id={idx} className="Body1_B mb16">
        {title}
      </h3>
      <div id={`content${idx}`} className="item">
        {content}
      </div>
    </div>
  );
};

const ImageTemplate = ({ image, desc }) => {
  return (
    <div className="mt36 mb36">
      <div className="bg-g400 br4 mb8" style={{ height: 325 }}>
        {image}
      </div>
      <p className="Body5_R g500">{desc} </p>
    </div>
  );
};

const WarnTemplate = ({ content, path }) => {
  return (
    <div
      className="not-btn error mt36 mb36 br4"
      style={{
        backgroundColor: "rgba( 255, 87, 87, 0.1 )",
        padding: "21px 24px",
      }}
    >
      <div className="mb4">
        <Image className="mb-2" path={path} name={"ic_warning"} />
        <span className="Body5_B ml4">주의 사항</span>
      </div>
      <p className="Body5_R">{content}</p>
    </div>
  );
};

const ReferenceTemplate = ({ content }) => {
  return (
    <div
      className="not-btn brand600 bg-brand100 mt36 mb36 br4"
      style={{
        padding: "21px 24px",
      }}
    >
      <div className="mb4">
        <Image className="mb-2" path={`${process.env.PUBLIC_URL}/manual`} name={"ic_reference"} />
        <span className="Body5_B ml4">참고</span>
      </div>
      <p className="Body5_R">{content}</p>
    </div>
  );
};

const TableDataTemplate = () => {
  const data = [
    { code: "200", message: "OK", desc: "요청 성공" },
    {
      code: "201",
      message: "Created",
      desc: "요청의 결과로 새로운 리소스가 생성됨",
    },
    {
      code: "202",
      message: "Accepted",
      desc: "요청이 수락되었지만 아직 완료되지 않음",
    },
  ];
  return (
    <>
      <div className="Body2_B">테이블 데이터 입력</div>
      <div className="Body4_R">
        서브 목차가 필요한 경우 제목을 작성하고 해당 내용을 작성합니다.
        <br /> 서브 목차 역시 책갈피를 적용합니다.
      </div>
      <div className="manual-table-data">
        <table
          className="b-g200 w-full"
          style={{ borderSpacing: "0", borderCollapse: "collapse" }}
        >
          <colgroup>
            <col width="33%" />
            <col width="33%" />
            <col width="33%" />
          </colgroup>
          <tbody>
            <tr className="Body6_B g500 bg-g150">
              <td>HTTP 상태 코드</td>
              <td>메시지</td>
              <td>설명</td>
            </tr>
            {data.map((value, i) => {
              return (
                <tr className="Body6_R g000">
                  <td>{value.code}</td>
                  <td>{value.message}</td>
                  <td>{value.desc}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const SourceCodeTemplate = () => {
  return (
    <>
      <div className="Body2_B">소스코드 입력</div>
      <div className="Body4_R">
        서브 목차가 필요한 경우 제목을 작성하고 해당 내용을 작성합니다. <br />
        서브 목차 역시 책갈피를 적용합니다.
      </div>
      <div className="b-g200">
        <p className="bb-g200 bg-g150 g500 Body6_B" style={{ height: 36 }}>
          <p
            style={{
              width: "calc(100% - 64px)",
              display: "inline-block",
              padding: "9px 16px",
            }}
          >
            JSON
          </p>
          <p
            className="bl-g200"
            style={{ width: 64, display: "inline-block", padding: "9px 16px" }}
          >
            Copy
          </p>
        </p>
        <p className="bg-g100 Body6_R g900" style={{ padding: "14px 16px" }}>
          {"{"}
          <p>
            &emsp;code : “AUTH001“ <br />
            &emsp;message : “회원인증에 실패했습니다.”
          </p>
          {"}"}
          <br />
          {"{"}
          <p>
            &emsp; code : “AUTH001“ <br />
            &emsp; message : “회원인증에 실패했습니다.”
          </p>
          {"}"};
        </p>
      </div>
    </>
  );
};

export {
  MainTemplate,
  SubMainTemplate,
  ImageTemplate,
  WarnTemplate,
  ReferenceTemplate,
  TableDataTemplate,
  SourceCodeTemplate,
};
