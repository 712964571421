import React from "react";

const ProjectNewChild = ({ clazz, setManualKey }) => {
    return (
          <div className={clazz}>
              <p>MockBot 서비스에 Mock API를 등록하기 위해서는 반드시 프로젝트가 생성되어야 합니다. Mock API는 프로젝트 단위로 등록되고 관리됩니다. 먼저 프로젝트가 없을 경우
                  프로젝트 목록화면에서 신규 프로젝트를 생성합니다.</p>
              <br/>
              <div className={clazz}>
                  <div style={{width: 786}}>
                      <img
                          src={`${process.env.PUBLIC_URL}/manual/manual_project_1.png`}
                          alt={"example_head"}
                          width={792}
                          height={448}
                      />
                      <br/>
                      <div>
                          <img
                              src={`${process.env.PUBLIC_URL}/manual/manual_project_button_1.png`}
                              width={122}
                              height={30}
                          />
                          <span>버튼을 클릭 합니다.</span>
                      </div>
                  </div>
              </div>
          </div>
    )
}

const ProjectNewDetailChild = ({ clazz, setManualKey }) => {
    return (
        <div className={`${clazz}`}>
            <p>MockBot 서비스에 Mock API를 등록하기 위해서는 반드시 프로젝트가 생성되어야 합니다. Mock API는 프로젝트 단위로 등록되고 관리됩니다. 먼저 프로젝트가 없을 경우
                프로젝트 목록화면에서 신규 프로젝트를 생성합니다.</p>
            <br/>
            <div className="">
                <div style={{width: 786}}>
                <img
                    src={`${process.env.PUBLIC_URL}/manual/manual_project_3.png`}
                    alt={"example_head"}
                    width={495}
                    height={387}
                />
                <br/>
                <br/>
                <p>1. Project 명 : 등록할 프로젝트의 이름을 입력합니다.</p><br/>
                <p>2. Context : 애플리케이션에의 컨텍스트 명을 입력합니다. <b>공백은 허용되지 않습니다.</b></p><br/>
                <p>3. 접근 권한 : Public은 <b>초대 없이도 누구나 접근</b>이 가능합니다.</p>
                <p style={{textIndent: 90}}>Private은 프로젝트 <b>소유자로 부터 초대를 받아야만</b> 접근이 가능 합니다.</p><br/>
                <p>4. 설명 : 프로젝트의 설명을 입력합니다.</p>
            </div>
        </div>
    </div>
    )
}

const ProjectInviteChild = ({clazz, setManualKey}) => {
    return (
        <div className={`${clazz}`}>
            <p>MockBot 서비스에 생성된 프로젝트는 다른 사용자를 초대하여 공유할 수 있습니다. 초대를 받은 사용자는 초대메일을 수신하고 초대를 수락할 수 있습니다.
                초대를 수락한 사용자는 초대 권한에 맞게 프로젝트 내의 <a>API</a>를 사용할 수 있습니다. 다른 사용자를 프로젝트로 초대하는 방법은 다음과 같습니다.</p>
            <br/>
            <div className="">
                <img
                    src={`${process.env.PUBLIC_URL}/manual/manual_project_4.png`}
                    alt={"프로젝트 화면"}
                    width={792}
                    height={255}
                />
                <br/>
                <br/>
                <p>프로젝트 목록에서 햄버거 메뉴를 누르면 프로젝트의 다양한 기능을 활용할 수 있습니다.</p>
                <br/>
                <p>1. Project 설정 : 생성된 프로젝트의 정보를 확인하고 변경할 수 있습니다.</p>
                <br/>
                <p>2. 사용자 초대 : <b>MockBot에 등록된 사용자</b>에게 초대 메일을 발송합니다.</p>
                <br/>
                <p>3. Export : 생성된 프로젝트의 정보를 JSON 파일 형식으로 내보낼 수 있습니다.</p>
                <br/>
                <p>4. 삭제 : 프로젝트를 삭제합니다.</p>
                <br/>
                <br/>
                <img
                    src={`${process.env.PUBLIC_URL}/manual/manual_project_5.png`}
                    alt={"프로젝트 초대"}
                    width={495}
                    height={426}
                />
                <br/>
                <br/>
                <p>초대를 받을 사용자의 email 주소와 초대 권한을 설정한 후 <b>+</b> 아이콘으로 추가합니다. 초대 대상자는 중복 초대를 받을 수 없으며 초대메일은 한번에 30건씩
                    발송가능합니다.</p>
                <br/>
                <p>1. Admin 권한 : 소유자와 <b>프로젝트 삭제 권한</b>을 제외한 모든 권한이 동일하게 부여됩니다.</p>
                <br/>
                <p>2. Agent 권한 : 초대받은 프로젝트에서 <b>API를 생성/수정/삭제</b>할 수 있습니다.</p>
                <br/>
                <p>3. User 권한: 초대받은 프로젝트에서 <b>API 를 생성/수정/삭제</b>할 수 없는 읽기전용 권한입니다.</p>
            </div>
    </div>
    )
}

const ProjectSettingChild = ({clazz, setManualKey}) => {
    return (
        <div className={`${clazz}`}>
            <p>MockBot 서비스에 생성된 프로젝트의 정보를 확인하고 수정할 수 있습니다.Project Key를 제외한 나머지 항목은 프로젝트 생성시 입력한 항목입니다.
                Project Key는 애플리케이션에서 MockBot API 호출 시 Header에 반드시 입력해야 하는 값입니다. <b>수정이 불가능한 값입니다.</b></p>
            <br/>
            <div className="">
                <div>
                    <img
                        src={`${process.env.PUBLIC_URL}/manual/manual_project_7.png`}
                        alt={"프로젝트 설정"}
                        width={495}
                        height={426}
                    />
                    <br/>
                </div>
            </div>
        </div>
    )
}

export {ProjectNewChild, ProjectNewDetailChild, ProjectInviteChild, ProjectSettingChild};