import React from "react";
import { Layout } from "antd";
import ProjectContent from "../../organisms/content/ProjectContent";
import ManualContent from "../../organisms/content/Manual/ManualContent";
import UserSupportContent from "../../organisms/content/UserSupportContent";
import SigninContent from "../../organisms/content/SigninContent";
import SignupContent from "../../organisms/content/SignupContent";
import AccountContent from "../../organisms/content/AccountContent";
import RootContent from "../../organisms/content/RootContent";
import LoginCallback from "../../organisms/content/LoginCallback";
import ErrorContent from "../../organisms/content/ErrorContent";
import Term from "../../pages/Term";

const { Content: AntdContent } = Layout;

const Content = ({ name, error, resetErrorBoundary }) => {
  const contentList = [
    { key: "error", content: <ErrorContent error={error} resetErrorBoundary={resetErrorBoundary}/> },
    { key: "root", content: <RootContent /> },
    { key: "main", content: <ProjectContent /> },
    { key: "manual", content: <ManualContent /> },
    { key: "support", content: <UserSupportContent tab={"notice"} /> },
    { key: "faq", content: <UserSupportContent tab={"faq"}/> },
    { key: "signin", content: <SigninContent /> },
    { key: "signup", content: <SignupContent /> },
    { key: "account", content: <AccountContent /> },
    { key: "loginCallback", content: <LoginCallback /> },
    { key: "term", content: <Term /> },
  ];
  return (
    <AntdContent className="content jc-c">
      {contentList.find((v) => v.key === name).content}
    </AntdContent>
  );
};

export default Content;
