import React from "react";

const UserVariable = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
                <p>MockBot의 사용자 변수는 자주 사용되는 API Response Parameter 를 공통변수로 등록하여 손쉽게 사용할 수 있습니다.
                공통변수 등록은 사용자변수와 환경변수로 구분되어 있습니다.</p>
            </div>
        </>
    )
}
export default UserVariable;