export const headers = [
  {
    value: 'projectKey'
  },
  {
    value: 'apiKey'
  },
  {
    value: 'Accept'
  },
  {
    value: 'Accept-Charset'
  },
  {
    value: 'Accept-Encoding'
  },
  {
    value: 'Accept-Language'
  },
  {
    value: 'Access-Control-Request-Headers'
  },
  {
    value: 'Access-Control-Request-Method'
  },
  {
    value: 'Authorization'
  },
  {
    value: 'Cache-Control'
  },
  {
    value: 'Content-MD5'
  },
  {
    value: 'Content-Length'
  },
  {
    value: 'Content-Transfer-Encoding'
  },
  {
    value: 'Content-Type'
  },
  {
    value: 'Cookie'
  },
  {
    value: 'Cookie 2'
  },
  {
    value: 'Date'
  },
  {
    value: 'Expect'
  },
  {
    value: 'From'
  },
  {
    value: 'Host'
  },
  {
    value: 'If-Match'
  },
  {
    value: 'If-Modified-Since'
  },
  {
    value: 'If-None-Match'
  },
  {
    value: 'If-Range'
  },
  {
    value: 'If-Unmodified-Since'
  },
  {
    value: 'Keep-Alive'
  },
  {
    value: 'Max-Forwards'
  },
  {
    value: 'Origin'
  },
  {
    value: 'Pragma'
  },
  {
    value: 'Proxy-Authorization'
  },
  {
    value: 'Range'
  },
  {
    value: 'Referer'
  },
  {
    value: 'TE'
  },
  {
    value: 'Trailer'
  },
  {
    value: 'Transfer-Encoding'
  },
  {
    value: 'Upgrade'
  },
  {
    value: 'User-Agent'
  },
  {
    value: 'Via'
  },
  {
    value: 'Warning'
  },
  {
    value: 'X-Requested-With'
  },
  {
    value: 'X-Do-Not-Track'
  },
  {
    value: 'DNT'
  },
]