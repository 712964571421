import { Input as AntdInput } from "antd";

import React from "react";

export const SignInput = ({
  title,
  placeholder,
  suffix,
  error = false,
  mb = "8",
  mt = "0",
  name,
  type,
  defaultValue,
  onChange,
  onKeyDown,
  errorMsg,
  errorMsgStyle
}) => {
  return (
    <>
      <div className={`Body5_R g500 mt${mt}`}>{title}</div>
      {type === "password" ? (
        <AntdInput.Password
          className={
            "Body4_R g900 bg-white br8 mt8 mb" + mb + (error ? " b-error" : "")
          }
          style={{ height: "48px" }}
          placeholder={placeholder}
          suffix={suffix}
          name={name}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
        />
      ) : (
        <AntdInput
          className={"Body4_R g900 bg-white br8 mt8 mb" + mb}
          style={{ height: "48px" }}
          placeholder={placeholder}
          suffix={suffix}
          name={name}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
        />
      )}
      <div className="error" style={errorMsgStyle}>
        {errorMsg}
      </div>
    </>
  );
};
