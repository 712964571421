/* 2024.03.18 [shiningtrue]: 공통 에러 코드 Constant 조회 */
export const loadCommonErrorCodeConstantApi = (axios, successCallback) => {
    axios
        .get(`/commonErrorCode/loadConstant`)
        .then(function (response) {
            successCallback(response);
        })
        .catch((error) => {
            // 오류발생시 실행
        })
        .then(() => {
            // 항상 실행
        });
};

/* 2024.03.18 [shiningtrue]: 공통 에러 코드 조회 */
export const loadCommonErrorCodeApi = (axios, projectId, successCallback) => {
  axios
    .get(`/commonErrorCode/load`, {
      params: {
        projectId: projectId,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

/* 2024.03.18 [shiningtrue]: 공통 에러 코드 저장 */
export const saveCommonErrorCodeApi = async (axios, params, successCallback) => {
  axios
    .post(`/commonErrorCode/save`, params)
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
