import React, {useState} from "react";
import InviteProjectUserTable from "../../molecules/tables/InviteProjectUserTable";
import {useRecoilState, useRecoilValue} from "recoil";
import {invitedUserListState, selectedUserState, useInviteItems} from "../../../recoil/invite/inviteState";
import {inviteModifyAuthorityApi, inviteRejectApi} from "../../../api/invite/inviteApi";
import {useParams} from "react-router-dom";
import {updateInviteUserListState, userState} from "../../../recoil/user/userState";
import useAxiosInterceptor from "../../../axios/axios";
import {projectState} from "../../../recoil/project/projectState";
import {toast} from "react-toastify";
import {alertHelpIcon, alertHelpIconOpenState, helpIconMessageState} from "../../../recoil/api/apiState";
import ConfirmModal from "../modals/ConfirmModal";

/**
 * Project SideTab - User
 */
const UserSubSider = ({
  userListGroupByAuthority,
  setUserListGroupByAuthority,
  filteringTreeData,
}) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 유저 ID
  const userId = useRecoilValue(userState).id;
  // 2024.03.06 [energysteel]: 현재 프로젝트 ID
  const { projectId } = useParams();
  // 2024.03.06 [energysteel]: 초대 완료, 초대 중인 전체 유저 리스트
  const [invitedUserList, setInvitedUserList] = useRecoilState(invitedUserListState);
  const [, setSelectedUser] = useRecoilState(selectedUserState);
  const [project, setProject] = useRecoilState(projectState);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [userRejectModalOpen, setUserRejectModalOpen] = useState(false);
  const [userDelegateOwnerModalOpen, setUserDelegateOwnerModalOpen] = useState(false);
  const [rejectUsers, setRejectUsers] = useState([]);
  const [delegate, setDelegate] = useState({});

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown handler
   *  - useInviteItems: Dropdown
   *    - currentAuthority: 대상 User의 현재 Authority
   *    - (): 대상 유저의 권한 수정 onClick Event
   *    - (): 대상 유저 삭제 onClick Event
   * @param targetUser 대상 유저
   * @param currentAuthority 대상 유저의 현재 Authority
   * @returns {{items: *}}
   */
  const useInvitedUserHandle = (targetUser, currentAuthority) => {
    return useInviteItems(
      targetUser,
      project.authority,
      (event) => {
        const changingAuthority = event.target.textContent;
        return handleDropdownChangeAuthorityConfirm(projectId, targetUser.guestUserId, currentAuthority, changingAuthority);
      },
      () => handleDropdownRejectConfirm(projectId, targetUser),
    );
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 권한 변경 > 최종 확인 Confirm
   * @param projectId 현재 프로젝트 ID
   * @param guestUserId 권한 변경 대상 유저 ID
   * @param currentAuthority 현재 권한
   * @param changingAuthority 변경할 권한
   */
  const handleDropdownChangeAuthorityConfirm = (projectId, guestUserId, currentAuthority, changingAuthority) => {
    if (changingAuthority !== "Owner") {
      handleDropdownChangeAuthority(
        projectId,
        guestUserId,
        currentAuthority,
        changingAuthority,
      );

      return;
    }

    setDelegate({
      projectId,
      guestUserId,
      currentAuthority,
      changingAuthority,
    })

    setUserDelegateOwnerModalOpen(true);
  }

  const handleDelegateRole = () => {
    handleDropdownChangeAuthority(
      delegate.projectId,
      delegate.guestUserId,
      delegate.currentAuthority,
      delegate.changingAuthority,
    );

    setUserDelegateOwnerModalOpen(false);
    setDelegate({});
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 권한 변경 API
   * @param projectId 현재 프로젝트 ID
   * @param guestUserId 권한 변경 대상 유저 ID
   * @param currentAuthority 현재 권한
   * @param changingAuthority 변경할 권한
   */
  const handleDropdownChangeAuthority = (projectId, guestUserId, currentAuthority, changingAuthority) => {
    // 2024.03.06 [energysteel]: 현재 권한과 변경할 권한이 동일하다면 return
    if (currentAuthority.toUpperCase() === changingAuthority.toUpperCase()) return;

    const modifyAuthorityRequest = {
      userId: userId,
      guestUserId: guestUserId,
      authority: changingAuthority.toUpperCase(),
    }

    inviteModifyAuthorityApi(
        axios,
        projectId,
        modifyAuthorityRequest,
        inviteModifyAuthorityApiSuccessCallback,
        () => {},
    )
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 권한 변경 API 성공 Callback
   *  - 권한 변경 성공 시 선택된 User가 초대 중인 경우 '초대 중' 문구를 보여주기 위한 state 수정
   * @param response API 응답 값
   */
  const inviteModifyAuthorityApiSuccessCallback = (response) => {
    const data = response.data.data;

    const targetUser = invitedUserList.find(user => user.guestUserId === data.guestUserId);
    if (!targetUser) {
      return;
    }

    if (data.authority === 'OWNER') {
      setProject(prev => ({
        ...prev,
        authority: "ADMIN",
      }))
    }

    setSelectedUser(prev => {
      return {
        ...data,
        authority: data.authority,
      }
    })

    setInvitedUserList(prev => {
      return prev.map((value) => {
        if (value.guestUserId === targetUser.guestUserId) {
          return updateInviteUserListState(data, userId);
        }

        if (value.guestUserId === userId && data.authority === 'OWNER') {
          return {
            ...value,
            authority: 'ADMIN',
          }
        }
        return value;
      });
    });

    if (data.guestUserId === project.userId && (data.authority === 'AGENT' || data.authority === 'USER')) {
      window.location.reload();
    }
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 삭제 > 최종 확인 Confirm
   * @param projectId 현재 프로젝트 ID
   * @param user 삭제 대상 유저 ID
   */
  const handleDropdownRejectConfirm = (projectId, user) => {
    const userIds = [user.guestUserId, ...(user.children || [])
      .map(child => child.guestUserId)]
      .filter(id => id !== undefined);

    if (userIds.length === 0) {
      return;
    }

    if (userIds.includes(userId)) {
      setHelpIconMessage({
        code: "error",
        message: "권한을 삭제할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return;
    }

    setRejectUsers(userIds);
    setUserRejectModalOpen(true);
  }

  const handleRejectOk = () => {
    handleDropdownReject(projectId, rejectUsers);
    setRejectUsers([]);
    setUserRejectModalOpen(false);
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 삭제 API
   * @param projectId 현재 프로젝트 ID
   * @param userIds 삭제 대상 유저 ID
   */
  const handleDropdownReject = (projectId, userIds) => {
    inviteRejectApi(
        axios,
        projectId,
        userIds,
        rejectSuccessCallback,
        () => {},
    )
  }

  /**
   * 2024.03.06 [energysteel]: SideTab > 유저 리스트 > Dropdown > 삭제 API 성공 Callback
   * @param response API 응답 값
   */
  const rejectSuccessCallback = (response) => {
    const responseData = response.data.data;
    setInvitedUserList(prev => {
      return prev.filter(value =>
        !responseData.some(item => item.guestUserId === value.guestUserId))
    })
    setSelectedUser('');

    toast.success("사용자를 삭제했습니다.")
  }


  return (
    <>
      <InviteProjectUserTable
        name={"user"}
        userList={invitedUserList}
        useInviteUserHandle={useInvitedUserHandle}
        userListGroupByAuthority={userListGroupByAuthority}
        setUserListGroupByAuthority={setUserListGroupByAuthority}
        filteringTreeData={filteringTreeData}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        handleDropdownChangeAuthority={handleDropdownChangeAuthority}
        project={project}
      />
      <ConfirmModal
        open={userRejectModalOpen}
        title={"사용자 삭제"}
        message={"프로젝트에서 유저를 삭제하시겠습니까?"}
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleRejectOk}
        handleCancel={() => {
          setRejectUsers([]);
          setUserRejectModalOpen(false);
        }}
      />
      <ConfirmModal
        open={userDelegateOwnerModalOpen}
        title={"권한 변경"}
        message={"해당 사용자에게 Owner 권한을 위임하시겠습니까?\n위임 후 본인은 Admin 권한으로 변경 됩니다."}
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleDelegateRole}
        handleCancel={() => {
          setUserDelegateOwnerModalOpen(false);
          setDelegate({});
        }}
      />
    </>
  )
};

export default UserSubSider;
