// 회원정보 조회 API
import { toast } from "react-toastify";

export const loadUserInfoApi = async (
  axios,
  params,
  successCallback,
  thenCallback,
) => {
  await axios
    .post(`/user/loadUserInfo`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
      thenCallback();
    });
};

// 회원정보 이름 변경 API
export const modifyNameApi = async (axios, params, successCallback) => {
  await axios
    .patch(`/user/modifyUserName`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 비밀번호 변경 API
export const modifyPasswordApi = async (axios, params, successCallback) => {
  axios
    .patch(`/user/modifyPassword`, params)
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};

// 회원탈퇴 API
export const modifyWithdrawalApi = async (axios, params, successCallback) => {
  axios
    .delete(`/user`, {
      data: {
        id: params.id,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
