import { recoilPersist } from 'recoil-persist';
import {atom} from "recoil";
const { persistAtom } = recoilPersist();

export const userState = atom({
  key: "userState",
  default: {
    id: null,
    name: null,
    email: null,
    adminYn: null,
    registPath: null,
    apiKey: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const userLastLoginState = atom({
  key: "userLastLoginState",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

/**
 * 2024.03.06 [energysteel]: 유저 리스트에서 '초대 중' 문구를 보여주기 위한 state 수정
 * @param user 유저 객체
 * @param index 유저 리스트 중 현재 유저의 index
 * @returns {{guestUserId: *, guestUserName: *, inviteStatus: *, authority: *, guestUserEmail: *, type: string, key}}
 */
export const updateInviteUserListState = (user, index) => {
  let type = "";

  // 2024.03.06 [energysteel]: InviteStatus가 초대중 (PENDING) 이면 초대중으로 문구 변경
  if (user.inviteStatus === 'PENDING') {
    type = '초대중';
  } else {
    type = user.authority.substring(0, 1).toUpperCase() +
      user.authority.substring(1, user.authority.length).toLowerCase();
  }

  return {
    key: index,
    guestUserId: user.guestUserId,
    guestUserName: user.guestUserName,
    guestUserEmail: user.guestUserEmail,
    authority: user.authority,
    inviteStatus: user.inviteStatus,
    authorityLabel: type,
  }
}
