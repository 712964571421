import {atom} from "recoil";
import React from "react";
import {recoilPersist} from "recoil-persist";

const { persistAtom } = recoilPersist();

export const isApiCopyState = atom({
    key: "isApiCopyState",
    default: false,
})

export const tabOpenListState = atom({
    key: "tabOpenListState",
    default: [],
})

export const apiTreeDataNotEmptyState = atom({
    key: "apiTreeDataNotEmptyState",
    default: false,
})

export const selectedApiState = atom({
    key: "selectedApiState",
    default: {},
})

export const searchApiDataState = atom({
    key: "searchApiDataState",
    default: "",
})

const deleteDataInterface = {
    projectId: "",
    id: "",
}

export const deleteShowModalState = atom({
    key: "deleteShowModalState",
    default: false,
})

export const deleteApiRequestState = atom({
    key: "deleteDataState",
    default: deleteDataInterface,
})

export const helpIconState = atom({
    key: "helpIconState",
    default: {
        icon: "success",
        text: "",
        move: "",
    },
})

export const alertHelpIconOpenState = atom({
    key: "alertHelpIconOpenState",
    default: false,
})

export const helpIconMessageState = atom({
    key: "helpIconMessageState",
    default: {},
})

export const apiTabRecoilPersistState = atom({
    key: "apiTabRecoilPersistState",
    default: {},
    effects_UNSTABLE: [persistAtom],
});

export const apiCurrentTimeState = atom({
    key: "apiCurrentTimeState",
    default: {},
})

let alertTimeout;
export const alertHelpIcon = (setAlertHelpIconOpen) => {
    clearTimeout(alertTimeout);
    setAlertHelpIconOpen(true);

    alertTimeout = setTimeout(() => {
        setAlertHelpIconOpen(false);
    }, 5000);
}
export const useHttpMethods = (httpMethod, handleHttpMethodsChange) => {
    const items = [
        {
            key: '1',
            selected: true,
            label: (
                <div
                  className="get Body6_B g500"
                  onClick={(e) => handleHttpMethodsChange(e)}
                >
                GET
                </div>
            ),
            httpMethod: 'GET',
            color: '#01803c',
        },
        {
            key: '2',
            selected: false,
            label: (
                <div
                  className="post Body6_B g500"
                  onClick={(e) => handleHttpMethodsChange(e)}
                >
                POST
                </div>
            ),
            httpMethod: 'POST',
            color: '#b87723',
        },
        {
            key: '3',
            selected: false,
            label: (
                <div
                  className="put Body6_B g500"
                  onClick={(e) => handleHttpMethodsChange(e)}
                >
                PUT
                </div>
            ),
            httpMethod: 'PUT',
            color: '#0057b3',
        },
        {
            key: '4',
            selected: false,
            label: (
                <div
                  className="patch Body6_B g500"
                  onClick={(e) => handleHttpMethodsChange(e)}
                >
                PATCH
                </div>
            ),
            httpMethod: 'PATCH',
            color: '#683593',
        },
        {
            key: '5',
            selected: false,
            label: (
                <div
                  className="delete Body6_B g500"
                  onClick={(e) => handleHttpMethodsChange(e)}
                >
                DELETE
                </div>
            ),
            httpMethod: 'DELETE',
            color: '#9d0713',
        },
        {
            key: '6',
            selected: false,
            label: (
              <div
                className="head Body6_B g500"
                onClick={(e) => handleHttpMethodsChange(e)}
              >
                  HEAD
              </div>
            ),
            httpMethod: 'HEAD',
            color: '#46900c',
        },
        {
            key: '7',
            selected: false,
            label: (
              <div
                className="options Body6_B g500"
                onClick={(e) => handleHttpMethodsChange(e)}
              >
                  OPTIONS
              </div>
            ),
            httpMethod: 'OPTIONS',
            color: '#b70065',
        },
    ];

    return { items: items };
}

export const useApiDropdown = (value, makeNew, changeName, apiCopy, deleteApi) => {
    const isGroup = true;
    const items = [
        {
            label: (
                <div className="Body6_B g500" onClick={() => makeNew(value, !isGroup)}>
                API 추가
                </div>
            ),
            key: "1",
            authority: ["OWNER", "ADMIN", "AGENT"],
        },
        {
            label: (
                <div className="Body6_B g500" onClick={() => makeNew(value, isGroup)}>
                API Group 추가
                </div>
            ),
            key: "2",
            authority: ["OWNER", "ADMIN", "AGENT"],
        },
        {
            label: (
                <div className="Body6_B g500" onClick={() => changeName()}>
                이름 변경
                </div>
            ),
            key: "3",
            authority: ["OWNER", "ADMIN", "AGENT"],
        },
        {
            label: (
              <div className="Body6_B g500" onClick={() => apiCopy()}>
                  API 복사
              </div>
            ),
            key: "4",
            authority: ["OWNER", "ADMIN", "AGENT"],
        },
        {
            label: (
              <div className="Body6_B g500" onClick={() => deleteApi()}>
                  삭제
              </div>
            ),
            key: "5",
            authority: ["OWNER", "ADMIN", "AGENT"],
        },
    ];

    return filteredDropdown(items, value);
}

const filteredDropdown = (items, value) => {
    let filteredItems = items.filter(item => {
        if (value.packageYn) {
            // 1 : API 추가 제거 && API 복사 제거
            return item.key !== "2" && item.key !== "4";
        } else {
            // 2 : API Group 추가 제거
            return item.key !== "1" && item.key !== "2";
        }
    });

    return { items: filteredItems };
}

export const useTabClearDropdown = (allTabClear, tabClear) => {
    return {
        items: [
            {
                label: (
                    <div className="Body6_B g500" onClick={allTabClear}>
                        전체 Tab 닫기
                    </div>
                ),
                key: "1",
            },
            {
                label: (
                    <div
                        className="Body6_B g500"
                        onClick={tabClear}
                    >
                        현재 Tab 닫기
                    </div>
                ),
                key: "2",
            },
        ],
    };
}