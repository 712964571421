import React from "react";

const TermOfUse = () => {
  return (
    <>
      <h1>
        보이저소프트 서비스 이용약관
      </h1>
      <div
        className="g900 Body4_R bb-g200 term-content"
          style={{
            display: "flex",
            overflow: "scroll",
            height: "600px",
          }}>
        <div>
          <p>
            제1조 (목적)<br/>
            본 약관은 보이저소프트(이하 "회사"라 함)가 제공하는 모든 서비스(이하 "서비스"라 함)의 이용과 관련하여<br/>
            회사와 이용자(이하 "회원"이라 함) 간의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br/>
          </p>
          <br/>

          <p>
            제2조 (용어의 정의)<br/>
            "서비스"라 함은 회사가 제공하는 모든 온/오프라인 서비스를 의미합니다.<br/>
            "회원"이라 함은 회사의 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다.<br/>
            "아이디(ID)"라 함은 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.<br/>
            "비밀번호"라 함은 회원이 부여받은 아이디와 일치된 회원임을 확인하고 회원의 권익을 보호하기 위하여<br/>
            회원 자신이 설정한 문자 또는 숫자의 조합을 의미합니다.<br/>
          </p>
          <br/>

          <p>
            제3조 (약관의 효력 및 변경)<br/>
            본 약관은 회원에게 공지함으로써 효력을 발생합니다.<br/>
            회사는 필요하다고 인정되는 경우, 관련 법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있습니다.<br/>
            변경된 약관은 적용일자 및 개정사유를 명시하여 현행 약관과 함께<br/>
            회사의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.<br/>
            회원은 변경된 약관에 동의하지 않을 권리가 있으며, 동의하지 않을 경우 서비스 이용을 중단하고 탈퇴할 수 있습니다.<br/>
            단, 공지 기간 내에 거부 의사를 표시하지 않고 서비스를 계속 이용할 경우 약관의 변경에 동의한 것으로 간주됩니다.<br/>
          </p>
          <br/>

          <p>
            제4조 (서비스의 제공 및 변경)<br/>
            회사는 회원에게 아래와 같은 서비스를 제공합니다:<br/>
            온라인 콘텐츠 제공 서비스<br/>
            기타 회사가 자체 개발하거나 다른 회사와의 협력 계약 등을 통해 회원에게 제공할 일체의 서비스<br/>
            회사는 서비스의 내용이 변경될 경우, 변경 사유 및 내용을 제7조에서 정한 방법으로 회원에게 통지합니다.<br/>
          </p>
          <br/>

          <p>
            제5조 (서비스의 중단)<br/>
            회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
            회사는 제1항의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 회원 또는 제3자가 입은 손해에 대하여<br/>
            고의 또는 중과실이 없는 한 책임을 지지 않습니다.<br/>
          </p>
          <br/>

          <p>
            제6조 (회원가입)<br/>
            회원가입은 회원이 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br/>
            회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다:<br/>
            가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br/>
            허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br/>
            기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
          </p>
          <br/>

          <p>
            제7조 (회원의 아이디 및 비밀번호에 대한 의무)<br/>
            아이디와 비밀번호에 관한 관리 책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.<br/>
            회원은 아이디 및 비밀번호가 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하고 회사의 안내에 따라야 합니다.<br/>
          </p>
          <br/>

          <p>
            제8조 (회원의 의무)<br/>
            회원은 다음 행위를 하여서는 안 됩니다:<br/>
            신청 또는 변경 시 허위 내용의 등록<br/>
            타인의 정보 도용<br/>
            회사가 게시한 정보의 변경<br/>
            회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
            회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
            회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
            외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br/>
            기타 불법적이거나 부당한 행위<br/>
          </p>
          <br/>

          <p>
            제9조 (서비스 이용제한 및 계약해지)<br/>
            회원이 다음 각 호의 사유에 해당하는 경우, 회사는 사전 통지 없이 서비스 이용계약을 해지하거나 서비스 이용을 제한할 수 있습니다:<br/>
            제8조 회원의 의무를 위반한 경우<br/>
            기타 서비스 운영을 고의로 방해한 경우<br/>
            회사는 전항에도 불구하고, 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원의 정보 보호 및 운영의 효율성을 위해 이용계약을 해지할 수 있습니다.<br/>
          </p>
          <br/>

          <p>
            제10조 (책임의 제한)<br/>
            회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br/>
            회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br/>
            회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 책임을 지지 않습니다.<br/>
          </p>
          <br/>

          <p>
            제11조 (준거법 및 재판관할)<br/>
            회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.<br/>
            회사와 회원 간 발생한 분쟁에 관한 소송은 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.<br/>
          </p>
          <br/>
        </div>
      </div>
    </>
  )
}

export default TermOfUse;