// 2024.03.07 [energysteel]: API 리스트 조회 API
export const loadProjectApisApi = async (axios, projectId, successCallback) => {
    axios
        .get(`/project/${projectId}/apis`)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.07 [energysteel]: API 저장 API
export const saveApiApi = async (axios, projectId, saveApiRequest, successCallback, failCallback) => {
    axios
        .post(`/project/${projectId}/api`, saveApiRequest)
        .then(response => {
            if (response.data.code === "0001") {
              successCallback(response);
            } else {
              failCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.04.16 [energysteel]: API Copy API
export const copyApiApi = async (axios, projectId, saveApiRequest, successCallback) => {
  axios
    .post(`/project/${projectId}/api/copy`, saveApiRequest)
    .then(response => {
      if (response.data.code === "0001") {
        successCallback(response);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
}

// 2024.03.08 [energysteel]: API 수정 API
export const modifyApiApi = async (axios, projectId, modifyApiRequest, successCallback) => {
    axios
        .patch(`/project/${projectId}/api/${modifyApiRequest.id}`, modifyApiRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.08 [energysteel]: API Order 순서 변경 API
export const modifyApiOrderApi = async (axios, projectId, modifyApiOrderRequest, successCallback, failCallback) => {
    axios
        .patch(`/project/${projectId}/api/order`, modifyApiOrderRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            } else {
              failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
            failCallback();
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.08 [energysteel]: API 삭제 API
export const deleteApiApi = async (axios, projectId, deleteApiRequest, successCallback, failCallback) => {
    axios
        .delete(`/project/${projectId}/api/${deleteApiRequest.apiId}`, deleteApiRequest)
        .then(response => {
          if (response.data.code === "0001") {
            successCallback(response);
          } else {
            failCallback(response.data.message);
          }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.13 [shiningtrue]: API Content 조회
export const loadApiContentApi = async (axios, projectId, apiId, successCallback, failCallback) => {
    axios
        .get(`/project/${projectId}/api/${apiId}/content`)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response.data.data);
            } else {
                failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.13 [shiningtrue]: API Content 조회
export const loadApiContentApi2 = async (axios, projectId, apiId) => {
    return axios
        .get(`/project/${projectId}/api/${apiId}/content`)
        .then(response => {
            return response; // 처리된 데이터 반환
        })
        .catch((error) => {
            // 오류 발생 시 실행
            console.error(error);
            throw error; // 오류를 상위로 전달
        });
}