import React, {useEffect, useState} from "react";
import {Input} from "antd";
import NonProjectList from "../../molecules/NonProjectList";
import ProjectList from "../../molecules/ProjectList";
import Image from "../../atoms/Image";
import {useRecoilState} from "recoil";
import {
  pageState,
  projectCountState,
  projectListState,
  searchWordState,
  useProjectRecoilReset,
} from "../../../recoil/project/projectState";
import {
  loadProjectCategoriesCount,
  loadProjectsApi,
  loadProjectsApiSuccessCallback, loadProjectsApiThenCallback
} from "../../../api/project/projectApi";
import useAxiosInterceptor from "../../../axios/axios";

const ProjectContent = () => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 유저가 열람 가능한 프로젝트 리스트 (프로젝트 Owner/초대 완료/초대 중)
  const [projectList, setProjectList] = useRecoilState(projectListState);
  /**
   * 2024.03.06 [energysteel]: 프로젝트 리스트 페이징 객체
   * pageNumber: 0페이지부터 시작
   * pageSize: 한 페이지 최대 노출 수 3개로 제한
   */
  const [page, setPage] = useRecoilState(pageState);
  // 2024.03.06 [energysteel]: 프로젝트 검색 조건 (프로젝트 이름/Context)
  const [searchWord, setSearchWord] = useRecoilState(searchWordState);
  //2024.03.15 [shiningtrue]: project 내부 recoil 초기화 함수
  const projectRecoilReset = useProjectRecoilReset();
  const [projectCount, setProjectCount] = useRecoilState(projectCountState);
  // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
  const [loading, setLoading] = useState(true);
  // 2024.03.06 [energysteel]: 랜더링 방지로 검색 조건 입력을 담을 일반 변수
  let inputValue = "";
  const [categories] = useState(['전체', '내 프로젝트', '초대']);

  const [searchCategory, setSearchCategory] = useState({
    ALL: true,
    MY_PROJECT: false,
    INVITE_PROJECT: false,
  })

  const filterTrueKeys = (obj) => {
    return Object.keys(obj).filter((key) => obj[key] === true);
  }

  const handleProjectRecoilReset = () => {
    projectRecoilReset();
  };

  // 2024.03.15 [shiningtrue]: project 내부에서 사용 하는 recoil 초기화
  useEffect(() => {
    handleProjectRecoilReset();
  }, []);

  // 2024.03.06 [energysteel]: 프로젝트 조회 객체
  const loadProjectRequest = {
    pageNumber: page.pageNumber,
    pageSize: page.pageSize,
    searchWord: searchWord,
    searchCategories: filterTrueKeys(searchCategory).join(","),
  };

  useEffect(() => {
    loadProjectCategoriesCount(
      axios,
      loadProjectRequest.searchWord,
      loadProjectCategoriesCountSuccessCallback,
    )
  }, [projectList])

  /**
   * 2024.04.23 [energysteel]: Project 카테고리 별 건수 API 성공 Callback
   * @param response API 응답 값
   */
  const loadProjectCategoriesCountSuccessCallback = (response) => {
    setProjectCount(response.data.data);
  }

  useEffect(() => {
    /* 2024.03.06 [energysteel]: 프로젝트 전체 조회 API */
    loadProjectsApi(
      axios,
      loadProjectRequest,
      (response) => loadProjectsApiSuccessCallback(response, setProjectList, setPage),
        loadProjectsApiThenCallback,
    );
  }, [page.pageNumber, searchWord, loadProjectRequest.searchCategories.length]);

  const loadProjectsApiThenCallback = () => {
    setLoading(false);
  }

  /**
   * 2024.03.06 [energysteel]: 프로젝트 검색 Input onChange Event
   * 입력값이 있으면 랜더링 방지로 일반 변수에 값 저장
   * 입력값이 없으면 전체 조회
   */
  const handleSearchOnChange = (event) => {
    const value = event.target.value;
    inputValue = value;

    if (value.length === 0) {
      setSearchWord("");
    }
  };

  /**
   * 2024.03.06 [energysteel]: 프로젝트 검색 Input onPress Event
   * Enter 키 입력 시 검색 조건 최신화
   * 입력값이 없으면 Enter 입력을 하지 않아도 onChange 단계에서 검색
   */
  const handleSearchOnPressEnter = () => {
    setSearchWord(inputValue.replace(/ /gi, ""));
  };

  /**
   * 2024.04.23 [energysteel]: Project 카테고리 onClick Event
   * @param name
   */
  const filterClick = (name) => {
    // 2024.05.07 [energysteel]: 최소 1개의 카테고리는 선택해야 함
    const isAllUnClicked =
      filterTrueKeys(searchCategory).length === 1 &&
      filterTrueKeys(searchCategory)[0] === name;

    if (isAllUnClicked) {
      return;
    }

    setSearchCategory(prev => {
      if (name === 'ALL' && !prev['ALL']) {
        return { ALL: true };
      } else {
        return { ...prev, [name]: !prev[name], ALL: false };
      }
    })

    setPage((prev) => ({
      ...prev,
      pageNumber: 0,
    }))
  }

  /**
   * 2024.04.23 [energysteel]: 카테고리 필터 버튼
   * @param categories 카테고리 목록
   * @return {Element}
   * @constructor
   */
  const FilterButton = ({ categories }) => {
    return (
      <div>
        <button
          type="button"
          className={`br100 cur btn ${searchCategory.ALL ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
          style={{
            padding: "9px 20px",
            border: `${searchCategory.ALL ? "0" : "sec"}`,
          }}
          onClick={() => filterClick("ALL")}
        >
          {`${categories[0]} ${projectCount.allProject ?? 0}`}
        </button>
        <button
          type="button"
          className={`br100 cur btn ${searchCategory.MY_PROJECT ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
          style={{
            padding: "9px 20px",
            border: `${searchCategory.MY_PROJECT ? "0" : "sec"}`,
          }}
          onClick={() => filterClick("MY_PROJECT")}
        >
          {`${categories[1]} ${projectCount.myProject ?? 0}`}
        </button>
        <button
          type="button"
          className={`br100 cur btn ${searchCategory.INVITE_PROJECT ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
          style={{
            padding: "9px 20px",
            border: `${searchCategory.INVITE_PROJECT ? "0" : "sec"}`,
          }}
          onClick={() => filterClick("INVITE_PROJECT")}
        >
          {`${categories[2]} ${projectCount.inviteProject ?? 0}`}
        </button>
      </div>
    );
  };

  return (
    <div className="main-body">
      <div>
        <h3 className="main-body-title">All–In–One API 서비스 MockBot</h3>
        <p className="main-body-subtitle">
          MockBot 서비스는 인터페이스 중심의 서비스를 쉽고 편리하게
          <br /> 개발할 수 있도록 지원합니다.
        </p>
      </div>
      <div className="main-body-category">
        <FilterButton categories={categories} />
        <Input
          className="main-body-search"
          placeholder="Search..."
          onChange={handleSearchOnChange}
          onPressEnter={handleSearchOnPressEnter}
          prefix={
            <Image
              className="mr9"
              path={`${process.env.PUBLIC_URL}/projectList`}
              name={"ic_search_24"}
              size={"md"}
            />
          }
        />
      </div>

      {page.totalElements > 0 ? <ProjectList /> : <NonProjectList loading={loading} />}
    </div>
  );
};

export default ProjectContent;
