import React, {useRef} from "react";
import {Card, ConfigProvider} from "antd";
import ModalSelect from "../../atoms/ModalSelect";
import {useRecoilState} from "recoil";
import {
  currentResponseCaseState,
  editedCustomTestCaseState,
  editorHighlight
} from "../../../recoil/response/responseState";
import {json, jsonParseLinter} from "@codemirror/lang-json";
import {linter, lintGutter} from "@codemirror/lint";
import {vscodeDark} from "@uiw/codemirror-theme-vscode";
import CodeMirror, {EditorSelection, EditorState} from "@uiw/react-codemirror";
import Image from "../../atoms/Image";
import {Prec} from "@codemirror/state";

const ErrorCode = ({
  title,
  subTitle,
  handleChange,
  isOpen,
  setIsOpen,
  commonErrorCodeList,
  isCommonError,
  setTestCaseError,
}) => {
  // 2024.03.26 [energysteel]: 상세 Modal을 실행한 Response Case 정보 (WRITE)
  const [currentResponseCase, setCurrentResponseCase] = useRecoilState(currentResponseCaseState);
  const [, setEditedCustomTestCase] = useRecoilState(editedCustomTestCaseState);
  const ref = useRef();


  const handleRefreshTestCase = () => {
    // 2024.04.27 [energysteel]: 정상 응답 기본 값
    const defaultData = {};

    // 2024.04.27 [energysteel]: Editor 재생성
    createEditor(defaultData);

    setCurrentResponseCase(prev => {
      return {
        ...prev,
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          testCase: defaultData,
        }
      }
    });

    setEditedCustomTestCase(prev => {
      return defaultData;
    });

    setTestCaseError(false);
  }

  /**
   * 2024.03.26 [energysteel]: JSON Editor onChange Event
   * @param jsonString String 형태의 JSON
   */
  const handleJsonEditorOnChange = (jsonString) => {
    if (jsonString === "") {
      handleRefreshTestCase();
      return;
    }

    try {
      setEditedCustomTestCase(JSON.parse(jsonString));
      setTestCaseError(false);
    } catch (e) {
      // 2024.04.27 [energysteel]: JSON parse 오류 시 저장 버튼 disabled
      setTestCaseError(true);
    }
  }

  /**
   * 2024.04.27 [energysteel]: JSON Editor 재생성
   * @param data Editor에 노출할 내용
   */
  const createEditor = (data) => {
    ref?.current?.view?.setState(
      EditorState.create({
        doc: JSON.stringify(data, null, 2),
        selection: EditorSelection.cursor(0),
      })
    );
  }

  /**
   * ref 생성
   * @param editor
   */
  const refCallback = (editor) => {
    if (!ref?.current && editor?.editor && editor?.state && editor?.view) {
      ref.current = editor; // store it
    }
  }

  return (
    <div className="mt12">
      <div className="Body6_B g500 mb12 err">{title}</div>
      <div className="Body7_R g400 mb12 err">{subTitle}</div>
      <ConfigProvider
        theme={{
          components: {
            Card: {
              headerHeightSM: 44,
            },
          },
        }}
      >
        <Card
          size="small"
          className="bg-g100 b-g200"
          bodyStyle={{
            padding: 0,
          }}
          title={<p className="Body6_B g500">공통 에러 유형 </p>}
          extra={
            <div className="content">
            <ModalSelect
              width={isOpen ? 770 : 320}
              color="#ff5757"
              marginRight={10}
              value={!isCommonError ? '사용자 직접 입력' :
                currentResponseCase?.responseCaseDetail?.commonErrorCode?.field?.label ??
                "공통 에러코드를 생성해주세요."
              }
              options={commonErrorCodeList}
              handleChange={handleChange}
            />

            <button
              className={"content btn-line Body6_B br3 mr4"}
              style={{ padding: "6px" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Image
                path={`${process.env.PUBLIC_URL}/content`}
                name={isOpen ? "ic_minimize" : "ic_maximize"}
              />
            </button>
            </div>
          }
        >
          <div className="Body6_R g900">
            <CodeMirror
              ref={refCallback}
              className='CodeMirror'
              lineNumber={true} // 줄옆에 숫자
              value={
                isCommonError ?
                JSON.stringify(
                  currentResponseCase?.responseCaseDetail?.commonErrorCode?.data,
                  null,
                  2,
                )
                :
                JSON.stringify(
                  currentResponseCase?.responseCaseDetail?.customErrorCode,
                  null,
                  2,
                )
              }
              extensions={[
                // 2024.04.27 [energysteel]: JSON 색상
                json(),
                // 2024.04.27 [energysteel]: JSON Lint
                linter(jsonParseLinter()),
                // 2024.04.27 [energysteel]: JSON 에러구문
                lintGutter(),
                Prec.highest(
                  editorHighlight(
                    {
                      color: "#c3e607"
                    }
                  )
                ),
              ]}
              onChange={handleJsonEditorOnChange}
              theme={vscodeDark}
              height={"500px"}
              gutter={true}
              readOnly={isCommonError}
            />
          </div>
        </Card>
      </ConfigProvider>
    </div>
  );
};

export { ErrorCode };
