import {atom} from "recoil";
import React from "react";
import Project from "../../components/organisms/content/Manual/ManualProject";
import API from "../../components/organisms/content/Manual/ManualAPI";
import URI from "../../components/organisms/content/Manual/ManualURI";
import Request from "../../components/organisms/content/Manual/ManualRequest";
import Response from "../../components/organisms/content/Manual/ManualResponse";
import ResponseError from "../../components/organisms/content/Manual/ManualCommonResponseError";
import EnvVariable from "../../components/organisms/content/Manual/ManualCommonEnvVariable";
import UserVariable from "../../components/organisms/content/Manual/ManualCommonUserVariable";
import ErrorCode from "../../components/organisms/content/Manual/ManualCommonErrorCode";
import ManualButtonContent from "../../components/organisms/content/Manual/children/ManualButtonContent";
import APIChild from "../../components/organisms/content/Manual/children/ManualAPIChild";
import URIChild from "../../components/organisms/content/Manual/children/ManualURIChild";
import {
    ProjectNewChild,
    ProjectNewDetailChild,
    ProjectInviteChild,
    ProjectSettingChild,
} from "../../components/organisms/content/Manual/children/ManualProjectChild";
import {
    RequestDataDetailChild,
    RequestHeaderChild,
    RequestParameterChild
} from "../../components/organisms/content/Manual/children/ManualRequestChild";
import {
    ResponseHeaderChild,
    ResponseParameterChild,
    ResponseTestCaseChild,
    ResponseTestCaseResultChild
} from "../../components/organisms/content/Manual/children/ManualResponseChild";
import {
    ResponseErrorBody,
    ResponseErrorHeader
} from "../../components/organisms/content/Manual/children/ManualCommonResponseErrorChild";
import {
    BusinessErrorCode,
    HttpErrorCode,
    ValidationErrorCode
} from "../../components/organisms/content/Manual/children/ManualCommonErrorCodeChild";
import UserVariableChild from "../../components/organisms/content/Manual/children/ManualCommonUserVariableChild";
import EnvVariableChild from "../../components/organisms/content/Manual/children/ManualCommonEnvVariableChild";
import ManualGuide from "../../components/organisms/content/Manual/ManualGuideContent";


export const manualKeyState = atom({
    key: "manualKeyState",
    default: "0",
})

export const manualActiveIdState = atom({
    key: "manuAlActiveIdState",
    default: "content0",
});


export const manualAllNavList = (clazz, headerClazz, setManualKey) => [
    {
        idx: '0',
        title: <div className={`${clazz} ${headerClazz}`}><h3>사용 가이드</h3></div>,
        plainTitle: '사용 가이드',
        content: <ManualGuide clazz={clazz} setManualKey={setManualKey} />,
    },
    {
        idx: '1-1',
        title: <div className={`${clazz} ${headerClazz}`}><h3>프로젝트 생성</h3></div>,
        plainTitle: '프로젝트 생성',
        content: <Project clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '1-1-1',
                title: <div className={`${clazz} ${headerClazz}`}>신규 프로젝트 생성</div>,
                plainTitle: '신규 프로젝트 생성',
                content: <ProjectNewChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '1-1-2',
                title: <div className={`${clazz} ${headerClazz}`}>프로젝트 생성에 필요한 내용</div>,
                plainTitle: '프로젝트 생성에 필요한 내용',
                content: <ProjectNewDetailChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '1-1-3',
                title: <div className={`${clazz} ${headerClazz}`}>프로젝트 초대하기</div>,
                plainTitle: '프로젝트 초대하기',
                content: <ProjectInviteChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '1-1-4',
                title: <div className={`${clazz} ${headerClazz}`}>프로젝트 설정</div>,
                plainTitle: '프로젝트 설정',
                content: <ProjectSettingChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 HOME"}
          onNextText={"다음 API 추가"}
          onPrevClick={() => setManualKey('0')}
          onNextClick={() => setManualKey('2-1')}
        />,
    },
    {
        idx: '2-1',
        title: <div className={`${clazz} ${headerClazz}`}><h3>API 추가</h3></div>,
        plainTitle: 'API 추가',
        content: <API clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '2-1-1',
                title: <div className={`${clazz} ${headerClazz}`}>프로젝트 API 추가</div>,
                plainTitle: '프로젝트 API 추가',
                content: <APIChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 프로젝트 생성"}
          onNextText={"다음 URI 등록"}
          onPrevClick={() => setManualKey("1-1")}
          onNextClick={() => setManualKey("2-2")}
        />,
    },
    {
        idx: '2-2',
        title: <div className={`${clazz} ${headerClazz}`}><h3>URI 등록</h3></div>,
        plainTitle: 'URI 등록',
        content: <URI clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '2-2-1',
                title: <div className={`${clazz} ${headerClazz}`}>API URI 정보 확인</div>,
                plainTitle: 'API URI 정보 확인',
                content: <URIChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 API 추가"}
          onNextText={"다음 Request 등록"}
          onPrevClick={() => setManualKey("2-1")}
          onNextClick={() => setManualKey("2-3")}
        />,
    },
    {
        idx: '2-3',
        title: <div className={`${clazz} ${headerClazz}`}><h3>Request 등록</h3></div>,
        plainTitle: 'Request 등록',
        content: <Request clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '2-3-1',
                title: <div className={`${clazz} ${headerClazz}`}>API Request Header 설정</div>,
                plainTitle: 'API Request Header 설정',
                content: <RequestHeaderChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '2-3-2',
                title: <div className={`${clazz} ${headerClazz}`}>API Request Parameter 설정</div>,
                plainTitle: 'API Request Parameter 설정',
                content: <RequestParameterChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '2-3-3',
                title: <div className={`${clazz} ${headerClazz}`}>Request Data 상세 설정</div>,
                plainTitle: 'Request Data 상세 설정',
                content: <RequestDataDetailChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 URI 등록"}
          onNextText={"다음 Response 등록"}
          onPrevClick={() => setManualKey("2-2")}
          onNextClick={() => setManualKey("2-4")}
        />,
    },
    {
        idx: '2-4',
        title: <div className={`${clazz} ${headerClazz}`}><h3>Response 등록</h3></div>,
        plainTitle: 'Response 등록',
        content: <Response clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '2-4-1',
                title: <div className={`${clazz} ${headerClazz}`}>API Response Header 설정</div>,
                plainTitle: 'API Response Header 설정',
                content: <ResponseHeaderChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '2-4-2',
                title: <div className={`${clazz} ${headerClazz}`}>API Response Parameter 설정</div>,
                plainTitle: 'API Response Parameter 설정',
                content: <ResponseParameterChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '2-4-3',
                title: <div className={`${clazz} ${headerClazz}`}>API Response Test Case 설정</div>,
                plainTitle: 'API Response Test Case 설정',
                content: <ResponseTestCaseChild clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '2-4-4',
                title: <div className={`${clazz} ${headerClazz}`}>Test Case 응답 유형 설정</div>,
                plainTitle: 'Test Case 응답 유형 설정',
                content: <ResponseTestCaseResultChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 Request 등록"}
          onNextText={"다음 Response 에러 등록"}
          onPrevClick={() => setManualKey("2-3")}
          onNextClick={() => setManualKey("3-1")}
        />,
    },
    {
        idx: '3-1',
        title: <div className={`${clazz} ${headerClazz}`}><h3>Response 에러 등록</h3></div>,
        plainTitle: 'Response 에러 등록',
        content: <ResponseError clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '3-1-1',
                title: <div className={`${clazz} ${headerClazz}`}>Response Error Header 설정</div>,
                plainTitle: 'Response Error Header 설정',
                content: <ResponseErrorHeader clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '3-1-2',
                title: <div className={`${clazz} ${headerClazz}`}>API Response Error Body 설정</div>,
                plainTitle: 'API Response Error Body 설정',
                content: <ResponseErrorBody clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 Response 등록"}
          onNextText={"다음 에러 코드 등록"}
          onPrevClick={() => setManualKey("2-4")}
          onNextClick={() => setManualKey("3-2")}
        />,
    },
    {
        idx: '3-2',
        title: <div className={`${clazz} ${headerClazz}`}><h3>에러 코드 등록</h3></div>,
        plainTitle: '에러 코드 등록',
        content: <ErrorCode clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '3-2-1',
                title: <div className={`${clazz} ${headerClazz}`}>Business 에러 코드 설정</div>,
                plainTitle: 'Business 에러 코드 설정',
                content: <BusinessErrorCode clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '3-2-2',
                title: <div className={`${clazz} ${headerClazz}`}>Validation 에러 코드 설정</div>,
                plainTitle: 'Validation 에러 코드 설정',
                content: <ValidationErrorCode clazz={clazz} setManualKey={setManualKey} />,
            },
            {
                idx: '3-2-3',
                title: <div className={`${clazz} ${headerClazz}`}>Http 에러 코드 설정</div>,
                plainTitle: 'Http 에러 코드 설정',
                content: <HttpErrorCode clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 Response 에러 등록"}
          onNextText={"다음 사용자변수 등록"}
          onPrevClick={() => setManualKey("3-1")}
          onNextClick={() => setManualKey("4-1")}
        />,
    },
    {
        idx: '4-1',
        title: <div className={`${clazz} ${headerClazz}`}><h3>사용자변수 등록</h3></div>,
        plainTitle: '사용자변수 등록',
        content: <UserVariable clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '4-1-1',
                title: <div className={`${clazz} ${headerClazz}`}>사용자 변수 설정</div>,
                plainTitle: '사용자 변수 설정',
                content: <UserVariableChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 에러 코드 등록"}
          onNextText={"다음 환경변수 등록"}
          onPrevClick={() => setManualKey("3-2")}
          onNextClick={() => setManualKey("4-2")}
        />,
    },
    {
        idx: '4-2',
        title: <div className={`${clazz} ${headerClazz}`}><h3>환경변수 등록</h3></div>,
        plainTitle: '환경변수 등록',
        content: <EnvVariable clazz={clazz} setManualKey={setManualKey} />,
        children: [
            {
                idx: '4-2-1',
                title: <div className={`${clazz} ${headerClazz}`}>환경변수 등록</div>,
                plainTitle: '환경변수 등록',
                content: <EnvVariableChild clazz={clazz} setManualKey={setManualKey} />,
            },
        ],
        button: <ManualButtonContent
          onPrevText={"이전 사용자변수 등록"}
          onNextText={"다음 HOME"}
          onPrevClick={() => setManualKey("4-1")}
          onNextClick={() => setManualKey("0")}
        />,
    },
]