import React, {useEffect} from "react";
import {Input, Table} from "antd";
import TooltipImage from "../../atoms/TooltipImage";
import {EditableCell, EditableRow} from "../../atoms/Editable";
import ModalSelect from "../../atoms/ModalSelect";
import TableWrapper from "../../molecules/TableWrapper";
import useAxiosInterceptor from "../../../axios/axios";
import {useRecoilState} from "recoil";
import {
  commonErrorCodeListState,
  isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import {loadCommonErrorCodeApi, loadCommonErrorCodeConstantApi,} from "../../../api/commonErrorCode/commonErrorCodeApi";
import Image from "../../atoms/Image";
import {
  commonErrorCodeConstantListState,
  commonErrorEditingRecoilState,
} from "../../../recoil/commonErrorCode/commonErrorCodeState";
import RequiredText from "../../atoms/RequiredText";
import {alertHelpIcon, helpIconMessageState, alertHelpIconOpenState} from "../../../recoil/api/apiState";

const CommonErrorCodeContent = ({ data }) => {
  const axios = useAxiosInterceptor();
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.18 [shiningtrue]: List 상태 관리 변수
  const [commonErrorCodeRecoilState, setCommonErrorCodeRecoilState] =
    useRecoilState(commonErrorCodeListState);
  // 2024.03.18 [shiningtrue]: constant 상태 관리 변수
  const [
    commonErrorCodeConstantRecoilState,
    setCommonErrorCodeConstantRecoilState,
  ] = useRecoilState(commonErrorCodeConstantListState);
  // 2024.03.18 [shiningtrue]: 수정 여부 상태 관리 변수
  const [commonErrorCodeEditingState, setCommonErrorCodeEditingState] =
    useRecoilState(commonErrorEditingRecoilState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.03.21 [shiningtrue]: 관리자 여부
  const isManager =
    project.authority === "OWNER" || project.authority === "ADMIN";

  // 2024.03.18 [shiningtrue]: List 상태 관리 함수
  const handleCommonErrorCodeData = (newData) => {
    setCommonErrorCodeRecoilState((prev) => [...prev, newData]);
  };

  // 2024.03.15 [shiningtrue]: 수정 여부 상태 변수 변경
  const modifyCommonErrorCode = (editing) => {
    setCommonErrorCodeEditingState((prevState) => ({
      ...prevState, // 이전 상태를 가져옴
      errorCodeEditing: editing, // 변경된 값을 설정
    }));
  };


  // 2024.03.18 [shiningtrue]: List 조회 callback
  const loadCommonErrorCodeApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorCodeRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.18 [shiningtrue]: constant 조회 callback
  const loadCommonErrorCodeConstantApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorCodeConstantRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  useEffect(() => {
    // 2024.03.18 [shiningtrue]: 공통 에러코드 조회
    if (commonErrorCodeRecoilState.length === 0) {
      loadCommonErrorCodeApi(
        axios,
        project.id,
        loadCommonErrorCodeApiSuccessCallback,
      );
    }

    // 2024.03.18 [shiningtrue]: 상수값 load
    if (commonErrorCodeConstantRecoilState.length === 0) {
      loadCommonErrorCodeConstantApi(
        axios,
        loadCommonErrorCodeConstantApiSuccessCallback,
      );
    }
  }, [project.id]); // project 내부에서 header의 drop&down으로 프로젝트 이동시 재조회

  // 2024.03.18 [shiningtrue]: row별 input값 수정시 저장 함수
  const handleSave = (row) => {
    const newData = [...commonErrorCodeRecoilState];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setCommonErrorCodeRecoilState(newData);
    modifyCommonErrorCode(true);
  };

  // 2024.03.18 [shiningtrue]: row 삭제
  const handleDelete = (key) => {
    const deleteData = commonErrorCodeRecoilState.find(
      (item) => item.key === key,
    );

    if (!deleteData.canDelete) {
      setHelpIconMessage({
        code: "error",
        message: "기본 에러코드는 삭제 할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    const newData = commonErrorCodeRecoilState.filter(
      (item) => item.key !== key,
    );

    setCommonErrorCodeRecoilState(newData);
    modifyCommonErrorCode(true);
  };

  // 2024.03.18 [shiningtrue]: row 추가
  const handleAdd = () => {
    const newDataIndex = commonErrorCodeRecoilState.length;
    const newData = {
      key: `new-${newDataIndex}`,
      name: "",
      code: "",
      message: "",
      type: "BUSINESS",
      typeDetail: "NONE",
      canDelete: true,
      httpCode: "OK",
      search: true,
    };
    handleCommonErrorCodeData(newData);
    modifyCommonErrorCode(true);
  };

  // 2024.03.18 [shiningtrue]: row selectBox update 함수
  const handleSelectBoxChange = (row, value, property) => {
    setCommonErrorCodeRecoilState((prev) => {
      return prev.map((item) => {
        if (item.key === row.key) {
          return { ...item, [property]: value };
        }
        return item;
      });
    });
    modifyCommonErrorCode(true);
  };

  const handleSelectBoxValue = (record, type) => {
    let matchingOption = "";
    let valueToShow = "";

    if (type === "httpCode") {
      matchingOption = commonErrorCodeConstantRecoilState.httpCodes.find(
        (option) => option.name === record.httpCode,
      );
      valueToShow = matchingOption ? matchingOption.value : "200";
    }

    return valueToShow;
  };

  const defaultColumns = [
    {
      title: (
        <div className="content jc-c h36">
          {isProjectOwnerOrAdminAuthority(project.authority) && (
            <TooltipImage
              title={<div className="Body7_R">코드 추가</div>}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_add_bg"}
              onClick={handleAdd}
            />
          )}
        </div>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        commonErrorCodeRecoilState.length >= 1 ? (
          <div className="content jc-c h36">
            {isProjectOwnerOrAdminAuthority(project.authority) && (
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record.key)}
              />
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Error Name"} />,
      dataIndex: "name",
      width: "24%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonErrorCodeRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.name ? (
              <span className={"break-spaces-mw525"}>{record.name}</span>
            ) : (
              <span className="g300">Error Name 입력</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Error Code"} />,
      dataIndex: "code",
      width: "24%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonErrorCodeRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.code ? (
              <span className={"break-spaces-mw525"}>{record.code}</span>
            ) : (
              <span className="g300">Error Code입력</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Error Message"} />,
      dataIndex: "message",
      width: "34%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonErrorCodeRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.message ? (
              <span className={"break-spaces-mw525"}>{record.message}</span>
            ) : (
              <span className="g300">Error Message입력</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <div className="ml7">Http Code</div>,
      dataIndex: "httpCode",
      width: "18%",
      render: (_, record) =>
        commonErrorCodeRecoilState.length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              value={handleSelectBoxValue(record, "httpCode")}
              options={commonErrorCodeConstantRecoilState.httpCodes}
              handleChange={(e, v) =>
                isProjectOwnerOrAdminAuthority(project.authority)
                  ? handleSelectBoxChange(record, v.name, "httpCode")
                  : {}
              }
              width="100%"
              height={28}
            />
          </div>
        ) : null,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  // 2024.03.18 [shiningtrue]: search가 true인 object 추출 (검색 하기 위함)
  const commonErrorCodeList = () => {
    const filtered = commonErrorCodeRecoilState.filter(
      (item) => item.search === true && item.type === "BUSINESS",
    );
    return filtered;
  };

  // 2024.03.18 [shiningtrue]: 검색 input
  const handleSearch = (e) => {
    const searchData = e.target.value.trim().toLowerCase(); // 입력된 검색어
    const filtered = commonErrorCodeRecoilState.map((item) => {
      if (item.type === "BUSINESS") {
        const nameMatch = item.name.toLowerCase().includes(searchData);
        const codeMatch = item.code.toLowerCase().includes(searchData);
        const messageMatch = item.message.toLowerCase().includes(searchData);
        const search = nameMatch || codeMatch || messageMatch; // 검색 결과 여부
        return { ...item, search }; // 검색 결과 여부를 포함한 객체 반환
      } else {
        return item;
      }
    });
    setCommonErrorCodeRecoilState(filtered);
    commonErrorCodeList();
  };

  return (
    <div className="table-overflow-auto">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="Body7_R g900">
          업무별 발생할 수 있는 에러 코드를 정의합니다.
        </div>

        <div style={{ marginLeft: "auto" }}>
          <Input
            className="Body6_R g300 bg-white b-g200 br3"
            placeholder="Search..."
            onChange={handleSearch}
            style={{ width: "252px", height: "32px" }}
            prefix={
              <Image
                className="mr9"
                path={`${process.env.PUBLIC_URL}/projectList`}
                name={"ic_search_24"}
              />
            }
          />
        </div>
      </div>
      <div className="table-overflow-auto -true">
        <TableWrapper >
          <Table
            className="mt16"
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={commonErrorCodeList()}
            columns={columns}
            pagination={false}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default CommonErrorCodeContent;
