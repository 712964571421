import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { AttachButton, SquareButton } from "../atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAnswerApi, saveApi } from "../../api/support/supportApi";
import useAxiosInterceptor from "../../axios/axios";
import Loader from "../common/Loader";
import {toast} from "react-toastify";

const { TextArea } = Input;

const SupportWriteContent = ({
  data,
  title,
  category, //유형 값
  dataListCallBack, //callback될 정보를 담은 변수
  contentValue, //상세에서 넘겨준 내용
  updateContentValue, //상세에서 넘겨준 내용을 처리하기 위한 함수
  fileDetailList, //상세에서 넘겨준 파일 리스트
  fileGroupId = null, //상세에서 넘겨준 fileGroupId
  isInquiryAnswer = false, //1:1문의 답변하기 클릭 여부
  answerModify = false, //1:1문의 답변하기 수정 클릭 여부
  answerDetail, //1:1문의 답변 상세 data
  answerDetailData, //1:1문의 답변 상세 데이터 load
  settingIsAnswer, //답변등록, 수정 성공시 화면 동적 변경하기위함
}) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.02.28 [shiningtrue]: 등록 or 수정시 새로 추가될 첨부파일을 담기 위한 상태 변수
  const [fileList, setFileList] = useState([]);

  // 2024.02.28 [shiningtrue]: 상세 화면 에서 넘어온 첨부파일을 담기 위한 상태 변수
  const [modifyFileList, setModifyFileList] = useState([]);

  // 2024.02.28 [shiningtrue]: 상세 화면 에서 넘어온 첨부파일중 삭제된 파일을 담기 위한 상태 변수
  const [deleteFileList, setDeleteFileList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  // 2024.01.26 [shiningtrue]: input에서 value를 담기 위한 state 생성
  const [inputValues, setInputValues] = useState({ content: "" });

  const fileInputRef = useRef(null);

  // 2024.03.04 [shiningtrue]: Loader 활성화 상태 변수
  const [loading, setLoading] = useState(false);

  // 2024.03.04 [shiningtrue]: 등록 버튼 클릭시 버튼 다중 클릭 방지
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    // 2024.02.28 [shiningtrue]: 상세에서 수정화면으로 들어왔을때 첨부파일 세팅
    if (fileDetailList !== undefined) {
      if (fileDetailList.fileData.length > 0) {
        setModifyFileList((prevFiles) => [
          ...prevFiles,
          ...fileDetailList.fileData,
        ]);
      }
    }
  }, [fileDetailList]);

  // 2024.02.28 [shiningtrue]: 취소 버튼 함수
  const onCancelClick = () => {
    if (isInquiryAnswer) {
      answerDetailData(data.id);
      settingIsAnswer(false); //1:1문의 화면 답변폼 show & hide 처리하기 위함
    } else {
      navigate(-1);
    }
  };

  // 2024.01.26 [shiningtrue]: input값 담기 위함
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    updateContentValue(e);
  };

  const saveApiSuccessCallback = useCallback((response) => {
    if (location.state.writeType === "modify") {
      //수정 일때
      toast.success("수정 되었습니다.");
    } else {
      toast.success("등록 되었습니다.");
    }

    dataListCallBack(data.key); //리스트 화면 호출
    navigate(-1);
  }, []);

  // 2024.02.28 [shiningtrue]: 게시물 등록 버튼 함수
  const onSaveClick = async () => {
    setLoading(true);
    setIsButtonDisabled(true);
    const formData = new FormData();
    let uri = "";

    // 일반 데이터 추가
    if (
      location.state.detailId !== null &&
      location.state.detailId !== "" &&
      location.state.detailId !== undefined
    ) {
      formData.append("id", location.state.detailId);
    }

    if (location.state.writeType === "modify") {
      //수정 일때
      uri = `/${data.key}/modify`;
      inputValues.content = contentValue;
    } else {
      uri = `/${data.key}`;
    }

    formData.append("type", category);
    formData.append("title", title);
    formData.append("content", inputValues.content);

    if (fileGroupId !== null) {
      formData.append("fileGroupId", fileGroupId);
    }

    // 새로 추가된 파일 데이터 추가
    fileList.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    // 삭제된 파일
    deleteFileList.forEach((file, index) => {
      const fileData = JSON.parse(file);
      formData.append(`deleteFiles[${index}].id`, fileData.id);
      formData.append(
        `deleteFiles[${index}].fileGroupId`,
        fileData.fileGroupId,
      );
      formData.append(
        `deleteFiles[${index}].originalName`,
        fileData.originalName,
      );
      formData.append(`deleteFiles[${index}].savePath`, fileData.savePath);
      formData.append(`deleteFiles[${index}].saveName`, fileData.saveName);
    });

    let params = {
      uri: uri,
      formData: formData,
    };
    await saveApi(axios, params, saveApiSuccessCallback);
    setLoading(false);
    setIsButtonDisabled(false);

  };

  const saveAnswerApiSuccessCallback = useCallback((response) => {
    if (answerModify) {
      //수정 일때
      toast.success("수정 되었습니다.");
    } else {
      toast.success("등록 되었습니다.");
    }
    settingIsAnswer(false); //1:1문의 화면 답변폼 show & hide 처리하기 위함
    answerDetailData(data.id);
  }, []);

  // 2024.02.28 [shiningtrue]: 1:1문의 답변 등록 버튼 함수
  const onAnswerSave = async (answerModify) => {
    setLoading(true);
    setIsButtonDisabled(true);
    const formData = new FormData();
    let uri = "";

    if (answerModify) {
      //수정 일때
      // 수정할 문의 답변ID
      formData.append("id", answerDetail.id);
      uri = `${process.env.REACT_APP_PATH}/${data.key}/answerModify`;
      inputValues.content = contentValue;
    } else {
      // 답변할 문의ID
      formData.append("inquiryId", data.id);
      uri = `${process.env.REACT_APP_PATH}/${data.key}/answer`;
    }

    formData.append("content", inputValues.content);

    if (fileGroupId !== null) {
      formData.append("fileGroupId", fileGroupId);
    }

    // 새로 추가된 파일 데이터 추가
    fileList.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    // 삭제된 파일
    deleteFileList.forEach((file, index) => {
      const fileData = JSON.parse(file);
      formData.append(`deleteFiles[${index}].id`, fileData.id);
      formData.append(
        `deleteFiles[${index}].fileGroupId`,
        fileData.fileGroupId,
      );
      formData.append(
        `deleteFiles[${index}].originalName`,
        fileData.originalName,
      );
      formData.append(`deleteFiles[${index}].savePath`, fileData.savePath);
      formData.append(`deleteFiles[${index}].saveName`, fileData.saveName);
    });

    let params = {
      uri: uri,
      formData: formData,
    };
    await saveAnswerApi(axios, params, saveAnswerApiSuccessCallback);
    setLoading(false);
    setIsButtonDisabled(false);
  };

  // 2024.02.28 [shiningtrue]: 새로운 파일 추가 함수
  const handleFileInputChange = () => {
    const newFiles = Array.from(fileInputRef.current.files);

    // 2024.02.28 [shiningtrue]: 현재 파일 목록에 새로운 파일들 추가
    setFileList((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // 2024.02.28 [shiningtrue]: 새로등록된 파일 삭제 함수
  const handleRemoveFile = (index) => {
    // 선택된 파일 목록에서 해당 인덱스의 파일 삭제
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1);
    // 파일 목록 업데이트
    setFileList(updatedFileList);
    fileInputRef.current.value = "";
  };

  // 2024.02.28 [shiningtrue]: 상세에서 넘어온 파일 리스트 삭제 함수
  const handleRemoveDetailFile = (index) => {
    // 삭제한 파일 배열에 저장
    setDeleteFileList((prevFiles) => [
      ...prevFiles,
      JSON.stringify(modifyFileList[index]),
    ]);
    // 선택된 파일 목록에서 해당 인덱스의 파일 삭제
    const updatedFileList = [...modifyFileList];
    updatedFileList.splice(index, 1);
    // 파일 목록 업데이트
    setModifyFileList(updatedFileList);
  };

  return (
    <>
      {/* Loader 실행 div */}
      {loading && (
        <>
          <div className="dim-overlay" />
          <div className="loader-container">
            <Loader />
          </div>
        </>
      )}
      <div className="Body4_R g900">
        <TextArea
          className="bg-white br3"
          placeholder="내용을 입력해주세요."
          name="content"
          onChange={(e) => {
            onChangeInput(e);
          }}
          value={contentValue}
          autoSize={{ minRows: 11, maxRows: 11 }}
        />
      </div>
      <div className="mt16 mb12">
        <div style={{ display: "inline-block" }}>
          <input
            type="file"
            accept=".pdf, .ppt, .jpg, .png, .doc, .docx" // 허용할 파일 유형을 지정할 수 있습니다.
            multiple
            onChange={handleFileInputChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <button
            type="file"
            className={"content jc-c Body4_B br5 bg-g500 white"}
            style={{
              border: 0,
              width: 132,
              height: 48,
            }}
            onClick={() => fileInputRef.current.click()}
          >
            파일추가
          </button>
        </div>
        <span className="Body4_R g400 ml16">
          최대3개(10mb 이내), doc, ppt, pdf, jpg, png 만 가능합니다.
        </span>
      </div>
      {modifyFileList && modifyFileList.length > 0 && (
        <>
          {modifyFileList.map((file, i) => (
            <AttachButton
              key={i}
              index={i}
              text={file.originalName}
              onFileRemoveClick={handleRemoveDetailFile} // 파일 삭제 핸들러 전달
            />
          ))}
        </>
      )}
      {fileList && fileList.length > 0 && (
        <>
          {fileList.map((file, i) => (
            <AttachButton
              key={i}
              index={i}
              text={file.name}
              onFileRemoveClick={handleRemoveFile} // 파일 삭제 핸들러 전달
            />
          ))}
        </>
      )}
      <div className="content jc-c mt40">
        <SquareButton
          className={"btn-sec"}
          text={"취소"}
          onClick={() => onCancelClick()}
        />
        {!isInquiryAnswer ? (
          <SquareButton
            className={"btn-pri ml6"}
            text={"등록"}
            isButtonDisabled={isButtonDisabled}
            onClick={() => onSaveClick()}
          />
        ) : (
          <SquareButton
            className={"btn-pri ml6"}
            text={"답변등록"}
            isButtonDisabled={isButtonDisabled}
            onClick={() => onAnswerSave(answerModify)}
          />
        )}
      </div>
    </>
  );
};

export default SupportWriteContent;
