import {atom} from "recoil";

export const requestState = atom({
    key: "requestState",
    default: {},
})

export const isEmptyDetailDataType = (dataType) => {
    if (!dataType) return false;

    return dataType !== 'OBJECT' &&
              dataType !== 'ARRAY' &&
              dataType !== 'FILE' &&
              dataType !== 'ANY'
}

/**
 * 2024.05.24 [energysteel]: Request Body의 부모 노드 찾기
 * @param requestBody Request Body
 * @param upperId 상위 노드 ID
 * @return {*|null}
 */
export const findRequestBodyParentNode = (requestBody, upperId) => {
    for (const request of requestBody) {
        if (request.key === upperId) {
            return request;
        }
        if (request.children) {
            const parent = findRequestBodyParentNode(request.children, upperId);
            if (parent) {
                return parent;
            }
        }
    }
    return null;
}

export const validateRequest = (
  requestHeaderContent,
  requestParameterContent,
  requestBodyContent,
  setHelpIconMessage,
  apiName,
) => {
    let isValid = true;
    let type = '';
    let name = '';
    const emptyHeaderKeyValueObjects = requestHeaderContent.filter(header => header.keyValue === "");
    if (emptyHeaderKeyValueObjects.length > 0) {
        isValid = false;
        type = 'Header';
        name = 'Key';
    }

    const emptyParameterKeyValueObjects = requestParameterContent.filter(parameter => parameter.keyValue === "");
    if (emptyParameterKeyValueObjects.length > 0) {
        isValid = false;
        type = 'Parameter';
        name = 'Key';
    }

    let emptyBodyKeyValueObjects = requestBodyContent.filter(body => body.keyValue === "");

    if (emptyBodyKeyValueObjects.length === 0) {
        for (const requestBody of requestBodyContent) {
            if (requestBody.children) {
                const findData = requestBody.children.filter(body => body.keyValue === "");

                for (const data of findData) {
                    const parentNode = findRequestBodyParentNode(requestBodyContent, data.upperId);
                    if (parentNode.dataType !== 'ARRAY') {
                        emptyBodyKeyValueObjects.push(data);
                        break;
                    }
                }
            }
        }
    }

    if (emptyBodyKeyValueObjects.length > 0) {
        isValid = false;
        type = 'Body';
        name = 'Key';
    }

    if (!isValid) {
        setHelpIconMessage({
            code: "error",
            message: `${apiName} Request ${type}의 ${name}을(를) 입력해주세요.`,
        });
    }

    return isValid;
}
