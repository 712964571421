import React, {useEffect, useRef, useState} from "react";
import {Input} from "antd";
import {SquareButton} from "../../atoms/Button";
import ApiUrlContent from "./Api/ApiUrlContent";
import ApiRequestContent from "./Api/ApiRequestContent";
import ApiResponseContent from "./Api/ApiResponseContent";
import NoApiContent from "./Api/NoApiContent";
import SelectApiContent from "./Api/SelectApiContent";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  apiCurrentTimeState,
  apiTabRecoilPersistState,
  apiTreeDataNotEmptyState,
  helpIconMessageState,
  isApiCopyState,
  selectedApiState,
  tabOpenListState,
  useTabClearDropdown,
} from "../../../recoil/api/apiState";
import {useRecoilState, useRecoilValue} from "recoil";
import {saveUriApi} from "../../../api/uri/uriApi";
import useAxiosInterceptor from "../../../axios/axios";
import {saveRequestApi} from "../../../api/request/requestApi";
import {commonEnvironmentVariableListState, isApiAuthority, projectState,} from "../../../recoil/project/projectState";
import {validateUri} from "../../../recoil/uri/uriState";
import {loadApiContentApi, loadApiContentApi2, modifyApiApi,} from "../../../api/api/apiApi";
import {saveResponseCaseApi, saveResponseHeaderApi, saveResponseParameterApi,} from "../../../api/response/responseApi";
import {userState} from "../../../recoil/user/userState";
import {validateRequest} from "../../../recoil/request/requestState";
import {validateResponse, validateResponseCase,} from "../../../recoil/response/responseState";
import Rightbar from "./Api/Rightbar";
import TabHeader from "../../molecules/TabHeader";
import _ from "lodash";
import ConfirmModal from "../modals/ConfirmModal";
import {apiDuplicationSaveCheckApi} from "../../../api/project/projectApi";
import Loader from "../../common/Loader";

const ApiContent = ({ treeData, isSubSiderOpen, setTreeData, treeFlag, setTreeFlag }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.07 [energysteel]: API Tree 데이터 여부
  const [apiTreeDataNotEmpty] = useRecoilState(apiTreeDataNotEmptyState);
  // 2024.03.07 [energysteel]: API Tree에서 선택한 API 정보
  const [selectedApi, setSelectedApi] = useRecoilState(selectedApiState);
  // 2024.03.07 [energysteel]: 열려있는 Tab 리스트
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.04.29 [shiningtrue]: 기존에 열어둔탭 order 용
  const [tabOpenListOrder, setTabOpenListOrder] = useState([]);
  // 2024.03.07 [energysteel]: 열려있는 SubTab (1: URI 설정, 2: Request, 3: Response 탭)
  const [activeSubTab, setActiveSubTab] = useState("1");
  // 2024.03.11 [energysteel]: API 이름 수정 Flag
  const [apiNameEditable, setApiNameEditable] = useState(false);
  // 2024.03.11 [energysteel]: API 이름 Mouse Hover
  const [isMouseHover, setIsMouseHover] = useState(false);
  // 2024.04.08 [evahong]: rightbar open 여부
  const [isRightbar, setIsRightbar] = useState({ open: true, active: "code" });
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  // 2024.04.17 [shiningtrue]: API Tab 브라우저 종료 시에도 데이터 관리하기 위한 Recoil
  const [tabRecoilPersistState, setTabRecoilPersistState] = useRecoilState(
    apiTabRecoilPersistState,
  );
  // 2024.04.24 [energysteel]: List 환경변수 상태 관리 변수
  const [commonEnvironmentVariableList] = useRecoilState(
    commonEnvironmentVariableListState,
  );
  const inputRef = useRef(null);
  const uriRefs = useRef({});

  const [isApiCopy, setIsApiCopy] = useRecoilState(isApiCopyState);
  const [tabAllClearModalOpen, setTabAllClearModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [tabClearModalOpen, setTabClearModalOpen] = useState(false);
  const [tabCloseModalOpen, setTabCloseModalOpen] = useState(false);
  const [closeTab, setCloseTab] = useState("");
  const [modalOpenDuplicationCallbackQueue, setModalOpenDuplicationCallbackQueue] = useState([]);
  const [apiSaveLoader, setApiSaveLoader] = useState(false);
  const [currentTime, setCurrentTime] = useRecoilState(apiCurrentTimeState,);

  let tabOpenListItem = tabOpenList.find((item) => item.id === selectedApi.id);
  useEffect(() => {
    tabOpenListItem = tabOpenList.find((item) => item.id === selectedApi.id);
  }, [selectedApi.id]);

  /**
   * 2024.05.13 [energysteel]: 덮어씌울 데이터인지 확인
   *  - 수정자의 API Load 시간 이후 수정자 이외 유저의 API 저장 이력이 있는 경우 덮어씌우기
   *  - 아닌 경우 일반 저장로직 수행
   * @param response API 응답 값 (API 저장 시간, 마지막 수정자)
   * @param queue (일반 저장로직 수행 Queue)
   * @param duplicateQueue (덮어씌우기 로직 수행 Queue)
   * @param tabName Tab 명
   * @param callback 저장 API Callback
   * @return {Promise<void>}
   */
  const handleOverwriteCheck = async (response, queue, duplicateQueue, tabName, callback) => {
    const updateDate = new Date(response.data.data?.updateDate)
    if (updateDate > currentTime) {
      duplicateQueue.push({
        message:
          <>
            <span style={{ color: 'red' }}>
              [{response.data.data.createUserName}]
            </span>
            님이&nbsp;
            <span style={{ color: 'red' }}>
              [{tabName}]
            </span>
            &nbsp;메뉴를 수정하였습니다.
            <br/>
            <br/>
            내가 수정한 내용으로 덮어 씌우시겠습니까?
          </>
        ,
        func: callback,
      });
    } else {
      queue.push(callback);
    }
  }

  /**
   * 2024.05.13 [energysteel]: 덮어씌우기 Modal 확인 버튼
   *  - 덮어씌우기 Queue에서 dequeue하여 callback 실행
   * @return {Promise<void>}
   */
  const handleOverwrite = async () => {
    setModalOpen(false);

    const queue = modalOpenDuplicationCallbackQueue;
    const nextFunc = queue[0];
    if (nextFunc) {
      const sliceQueue = queue.slice(1);
      setModalOpenDuplicationCallbackQueue(sliceQueue);
      nextFunc.func(sliceQueue, sliceQueue.length === 0);
    }
  }

  /**
   * 2024.05.13 [energysteel]: 덮어씌우기 Modal 취소 버튼
   *  - queue가 아직 남아있을 경우 modal 재실행
   * @return {Promise<void>}
   */
  const handleOverwriteCancel = () => {
    setModalOpen(false);

    const sliceModalOpenFun = modalOpenDuplicationCallbackQueue.slice(1);
    setModalOpenDuplicationCallbackQueue(sliceModalOpenFun);
    if (sliceModalOpenFun.length > 0) {
      setModalOpen(true);
    }
  }

  const saveApiContent = async () => {
    const currentTab = tabOpenList.find((tab) => tab.id === selectedApi.id);
    const apiName = createApiName(currentTab);

    const queue = [];
    const duplicateQueue = [];

    // 2024.03.07 [shiningtrue]: API 헤더 저장 버튼
    if (currentTab?.uriEditing) {
      if (currentTab.packageYn) {
        handleModifyPackageApiTitle(currentTab.api.apiName);
      } else {
        if (
          validateUri(
            currentTab.uri,
            commonEnvironmentVariableList,
            setHelpIconMessage,
            apiName,
          )
        ) {
          const duplicationParams = {
            userId: userSession.id,
            projectId: project.id,
            view: "uri",
          };

          await apiDuplicationSaveCheckApi(
            axios,
            duplicationParams,
            async(response) => {
              await handleOverwriteCheck(
                response,
                queue,
                duplicateQueue,
                "URI",
                handleSaveOrModifyUri,
              );
            }
          );
        } else {
          onClickSubTab("1");
          alertHelpIcon(setAlertHelpIconOpen);
          return false;
        }
      }
    }
    if (currentTab?.requestEditing) {
      if (
          validateRequest(
              currentTab.requestHeader,
              currentTab.requestParameter,
              currentTab.requestBody,
              setHelpIconMessage,
              apiName,
          )
      ) {
        const duplicationParams = {
          userId: userSession.id,
          apiId: currentTab.id,
          view: "request",
        };

        await apiDuplicationSaveCheckApi(
          axios,
          duplicationParams,
          async (response) => {
            await handleOverwriteCheck(
              response,
              queue,
              duplicateQueue,
              "Request",
              handleSaveOrModifyRequest,
            );
          }
        );
      } else {
        onClickSubTab("2");
        alertHelpIcon(setAlertHelpIconOpen);
        return false;
      }
    }
    if (currentTab?.responseHeaderEditing) {
      if (
          validateResponse(
              currentTab.responseHeader,
              "Header",
              setHelpIconMessage,
              apiName,
          )
      ) {
        const duplicationParams = {
          userId: userSession.id,
          apiId: currentTab.id,
          view: "responseHeader",
        };

        await apiDuplicationSaveCheckApi(
          axios,
          duplicationParams,
          async(response) => {
            await handleOverwriteCheck(
              response,
              queue,
              duplicateQueue,
              "Response Header",
              handleSaveOrModifyResponseHeader,
            );
          }
        );
        //responseHeader
      } else {
        onClickSubTab("3");
        alertHelpIcon(setAlertHelpIconOpen);
        return false;
      }
    }
    if (currentTab?.responseParameterEditing) {
      if (
          validateResponse(
              currentTab.responseParameter,
              "Parameter",
              setHelpIconMessage,
              apiName,
          )
      ) {
        const duplicationParams = {
          userId: userSession.id,
          apiId: currentTab.id,
          view: "responseParameter",
        };

        await apiDuplicationSaveCheckApi(
          axios,
          duplicationParams,
          async(response) => {
            await handleOverwriteCheck(
              response,
              queue,
              duplicateQueue,
              "Response Parameter",
              handleSaveOrModifyResponseParameter,
            );
          }
        );

        //responseParameter
      } else {
        onClickSubTab("3");
        alertHelpIcon(setAlertHelpIconOpen);
        return false;
      }
    }
    if (currentTab?.responseCaseEditing) {
      if (
          validateResponseCase(
              currentTab.responseCase,
              setHelpIconMessage,
              apiName,
          )
      ) {
        //responseCase
        const duplicationParams = {
          userId: userSession.id,
          apiId: currentTab.id,
          view: "responseCase",
        };

        await apiDuplicationSaveCheckApi(
          axios,
          duplicationParams,
          async(response) => {
            await handleOverwriteCheck(
              response,
              queue,
              duplicateQueue,
              "Response Case",
              handleSaveOrModifyResponseCase,
            );
          }
        );

      } else {
        onClickSubTab("3");
        alertHelpIcon(setAlertHelpIconOpen);
        return false;
      }
    }

    if (duplicateQueue.length > 0) {
      setModalOpenDuplicationCallbackQueue(duplicateQueue);
      setModalOpen(true);
    }

    if (queue.length > 0) {
      saveApi(queue);
    }
  };

  /**
   * 2024.05.13 [energysteel]: API 일반저장 로직
   *  - queue의 마지막 실행 시 completeFlag 전달하여 helpIcon 노출
   * @param queue
   */
  const saveApi = async (queue) => {
    // const func = [...queue];
    for (let i = 0; i < queue.length; i++) {
      const completeFlag = queue.length - 1 === i;
      await queue[i](null, completeFlag);
    }
  }

  // 2024.05.19 [shiningtrue]: throttle
  const saveApiContentThrottled = _.debounce(saveApiContent, 250);

  /**
   * 2024.04.18 [energysteel]: API 붙여넣기 시 API 상세 정보 저장 (URI, Request, Response, TestCase)
   */
  useEffect(() => {
    if (isApiCopy) {
      saveApiContentThrottled();
    }
  }, [isApiCopy]);

  /**
   * 2024.04.08 [shiningtrue]: 키보드 저장 이벤트
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        if (isApiAuthority(project.authority)) {
          // saveApiContent 함수 실행
          saveApiContentThrottled();
        }
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [tabOpenListItem]);

  /**
   * 2024.04.17 [shiningtrue]: 기존에 열어둔 탭 활성화
   */
  useEffect(() => {
    if (
        !treeFlag &&
        treeData.length > 0 &&
        tabRecoilPersistState[project.id] !== undefined
    ) {
      // 프로미스 배열을 생성합니다.
      const promises = [];

      // treeData 배열의 각 요소에 대해 작업을 수행하여 하나의 프로미스 배열로 펼칩니다.
      treeData.forEach((treeItem) => {
        if (treeItem.children.length > 0) {
          treeItem.children.forEach((childItem) => {
            const foundItem = findIdInChildren(
                childItem,
                tabRecoilPersistState[project.id]
            );
            if (foundItem && tabRecoilPersistState[project.id].includes(foundItem.id)) {
              promises.push(loadApiContentApi2(
                  axios,
                  project.id,
                  foundItem.id
              ).then((response) => response.data));
            }
          });
        } else {
          if (tabRecoilPersistState[project.id].includes(treeItem.id)) {
            promises.push(loadApiContentApi2(
                axios,
                project.id,
                treeItem.id
            ).then((response) => response.data));
          }
        }
      });

      // 모든 프로미스를 병렬로 실행하고 결과를 처리합니다.
      Promise.all(promises)
          .then((responses) => {
            responses.forEach((response) => {
              if (response && response.code === "0001") {
                loadApiContentApiSuccessCallback2(response.data);
              } else {
                loadApiContentFailCallback();
              }
            });
          })
          .catch((error) => {
            // 오류 발생 시 실행
            console.error(error);
          })
          .finally(() => {
            // 항상 실행
            setTreeFlag(true);
          });
    }
  }, [treeData]);

  useEffect(() => {
    if (
      treeData.length > 0 &&
      tabRecoilPersistState[project.id] !== undefined &&
      tabOpenListOrder.length > 0
    ) {
      if (tabRecoilPersistState[project.id].length > 0) {
        let selectedFlag = true;

        const sortedObjects = [];
        tabRecoilPersistState[project.id].forEach((id, index) => {
          const foundTab = tabOpenListOrder.find(obj => obj.id === id);

          // 2024.04.30 [energysteel]: tab이 있고, sortedObjects 에서 중복되지 않은 데이터라면
          const isNewData = foundTab && !sortedObjects.find(obj => obj.id === foundTab.id);

          if (isNewData) {
            sortedObjects.push({
              ...foundTab,
              // 2024.04.30 [energysteel]: 첫번째 Tab이 Active (우측 API Tab Dropdown에서 사용하는 field)
              isActive: index === 0,
            });
          }
        });

        if (sortedObjects.length > 0) {
          setTabOpenList((prev) => {
            const sortedObjectIds = sortedObjects.map(obj => obj.id);
            const hasDuplicate = prev.some(tab => sortedObjectIds.includes(tab.id));

            if (hasDuplicate) {
              return prev;
            } else {
              return [
                ...prev,
                ...sortedObjects,
              ]
            }
          });
        }

        //기존에 열어둔 탭에서 첫번째 탭을 선택탭으로 지정
        if (tabRecoilPersistState[project.id].length > 0 && selectedFlag) {
          setSelectedApi({ ...sortedObjects[0] });
        }

      }
    }
  }, [tabOpenListOrder]);

  // treeItem 객체와 탐색할 id를 전달받아서 해당 id를 찾는 재귀 함수
  const findIdInChildren = (item, id) => {
    // 현재 항목의 id가 일치하면 true를 반환
    if (id.includes(item.id)) {
      return item;
    }
    // 현재 항목에 children 배열이 있을 경우
    if (item.children && item.children.length > 0) {
      // children 배열을 반복하면서 각 항목에 대해 재귀적으로 검색
      for (const child of item.children) {
        // 해당 항목에서 id를 찾았다면 true를 반환
        const foundItem = findIdInChildren(child, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    // 모든 하위 항목을 확인했지만 일치하는 id가 없는 경우 false를 반환
    return null;
  };

  /**
   * 2024.03.07 [energysteel]: API 상단 탭 Dropdown Handler
   * @returns {{items: [{label, key: string},{label, key: string}]}}
   */
  const useTabClearDropdownHandle = () => {
    return useTabClearDropdown(allTabClear, tabClear);
  };

  const reset = () => {
    setTabOpenList([]);
    // 열려있는 탭 관리 상태 변수 초기화
    setTabRecoilPersistState((prev) => {
      const updatedState = { ...prev };
      delete updatedState[project.id];
      return updatedState;
    });
    setSelectedApi({});
  };

  /**
   * 2024.03.07 [energysteel]: API 상단 탭 전체 Tab 닫기 기능
   */
  const allTabClear = () => {
    const isAllEditingTrue = tabOpenList.some(
      (item) =>
        item.requestEditing === true ||
        item.responseHeaderEditing === true ||
        item.responseParameterEditing === true ||
        item.responseCaseEditing === true ||
        item.uriEditing === true,
    );
    if (isAllEditingTrue) {
      setTabAllClearModalOpen(true);
    } else {
      reset();
    }
  };

  const handleTabAllClearNoSaveOk = () => {
    setTabAllClearModalOpen(false);
    reset();
  }


  const removeTabAndPersistState = (item) => {
    const id = item?.id ?? selectedApi?.id;

    const clickTabRemove = [
      ...tabOpenList.filter((v) => {
        return v.id !== id;
      }),
    ];

    const lastOpenTab = selectedApiAfterDelete(id);

    setTabOpenList(clickTabRemove);
    setSelectedApi(lastOpenTab);
    setTabRecoilPersistState((prev) => ({
      ...prev,
      [project.id]: tabRecoilPersistState[project.id].filter(
        (item) => item !== id,
      ),
    }));
  };

  /**
   * 2024.03.07 [energysteel]: API 상단 탭 현재 Tab 닫기 기능
   */
  const tabClear = async () => {
    const objectWithSelectedApi = tabOpenList.find(
      (item) => item.id === selectedApi.id,
    );

    if (
      objectWithSelectedApi.requestEditing ||
      objectWithSelectedApi.responseHeaderEditing ||
      objectWithSelectedApi.responseParameterEditing ||
      objectWithSelectedApi.responseCaseEditing ||
      objectWithSelectedApi.uriEditing
    ) {
      setTabClearModalOpen(true);
    } else {
      removeTabAndPersistState();
    }
  };

  const handleTabClearNoSaveOk = () => {
    setTabClearModalOpen(false);
    saveApiContentThrottled();
    removeTabAndPersistState();
  }

  /**
   * 2024.03.07 [energysteel]: 상단 탭 선택
   */
  useEffect(() => {
    // 2024.03.07 [energysteel]: 선택한 API가 없으면 탭을 활성화하지 않음
    if (Object.keys(selectedApi).length === 0 || selectedApi.packageYn) {
      return;
    }

    const tab = tabOpenList?.find((tab) => tab.id === selectedApi.id);

    if (!tab) {
      loadApiContentApi(
        axios,
        project.id,
        selectedApi.id,
        loadApiContentApiSuccessCallback,
        loadApiContentFailCallback,
      );
    }
  }, [selectedApi.id]);

  /**
   * 2024.03.13 [energysteel]: API Content 조회 API 성공 Callback
   * @type {(function(*): void)|*}
   */
  const loadApiContentApiSuccessCallback = (data) => {
    const uri = loadUriSetup(data.api, data.uri);
    setTabOpenList((prev) => {
      const existingTab = prev.find((v) => v.id === selectedApi.id);
      if (existingTab) {
        return prev.map((tab) =>
          tab.id === selectedApi.id
            ? {
                ...tab,
                id: data.id,
                api: data.api,
                uri: uri,
                packageYn: selectedApi.packageYn,
                requestHeader: data.requestHeader,
                requestParameter: data.requestParameter,
                requestBody: data.requestBody,
                responseHeader: data.responseHeader,
                responseParameter: data.responseParameter,
                responseCase: data.responseCase,
              }
            : tab,
        );
      } else {
        return [
          ...prev,
          {
            id: data.id,
            api: data.api,
            uri: uri,
            packageYn: selectedApi.packageYn,
            requestHeader: data.requestHeader,
            requestParameter: data.requestParameter,
            requestBody: data.requestBody,
            responseHeader: data.responseHeader,
            responseParameter: data.responseParameter,
            responseCase: data.responseCase,
          },
        ];
      }
    });

    // 2024.04.17 [shiningtrue]: 열려 있는 탭 브라우저 종료시에도 유지 시키기 위함
    setTabRecoilPersistState((prev) => {
      const existingIds = prev[project.id] || []; // 이전 데이터의 id 리스트를 가져옵니다.
      // 새로운 데이터의 id가 이미 리스트에 있는지 확인합니다.
      const isDuplicate = existingIds.includes(data.id);
      // 중복되지 않으면 새로운 데이터를 추가하고, 중복된 경우에는 기존 데이터를 그대로 반환합니다.
      return {
        ...prev,
        [project.id]: isDuplicate ? existingIds : [...existingIds, data.id],
      };
    });

    setCurrentTime(new Date());
  };

  /**
   * 2024.04.26 [shiningtrue]: API 열려있는 Tab callback 사용 위함
   * @type {(function(*): void)|*}
   */
  const loadApiContentApiSuccessCallback2 = (data) => {
    const uri = loadUriSetup(data.api, data.uri);
    setTabOpenListOrder((prev) => [
      ...prev,
      {
        id: data.id,
        api: data.api,
        uri: uri,
        packageYn: selectedApi.packageYn,
        requestHeader: data.requestHeader,
        requestParameter: data.requestParameter,
        requestBody: data.requestBody,
        responseHeader: data.responseHeader,
        responseParameter: data.responseParameter,
        responseCase: data.responseCase,
      },
    ]);

    setCurrentTime(new Date());
  };

  /**
   * 2024.03.13 [energysteel]: URI API 조회하여 조회 되면 반환, 안되면 아직 생성안된것으로 기본 값 부여
   *  - title은 서버에서 조회하지 않고, selectedApi에서 조회
   *  - 조회 결과 없는 경우 selectedApi에서 id 조회
   * @param uri API 응답 값 URI 정보
   * @returns {(*&{title})|{save: boolean, description: string, id, title, httpMethod: string, uri: string, contentType: string, delay: number, encryptionMethod: string}}
   */
  const loadUriSetup = (api, uri) => {
    const title = selectedApi.title ? selectedApi.title : api.apiName;
    const defaultUri = {
      uri: "",
      id: selectedApi.id,
      title: title,
      delay: 0,
      httpMethod: "GET",
      description: "",
      encryptionMethodId: undefined,
      contentType: "APPLICATION_JSON",
      save: true,
    };

    if (uri) {
      return {
        ...uri,
        title: title,
      };
    } else {
      return defaultUri;
    }
  };

  /**
   * 2024.03.13 [energysteel]: API Content 조회 API 실패 Callback
   * @type {(function(): void)|*}
   */
  const loadApiContentFailCallback = () => {};

  /**
   * API 이름 onChange Event
   * @param event Input onChange Event
   */
  const handleChangeTitle = (event) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            api: {
              apiName: event.target.value,
            },
          };
        }
        return tab;
      });
    });
  };

  /**
   * 탭 onClick Event
   * @param id API ID
   */
  const onClickTab = (id) => {
    setSelectedApi(tabOpenList.find((open) => open.id === id));

    setTabOpenList(prev => {
      return prev.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isActive: true,
          }
        } else {
          return {
            ...item,
            isActive: false,
          }
        }
      })
    })
  };

  /**
   * 서브 탭 onClick Event (URI 설정, Request, Response)
   * @param key Tab Key
   * @see subApiList
   */
  const onClickSubTab = (key) => {
    setActiveSubTab(key);
  };

  /**
   * 2024.03.27 [energysteel]: Tab 종료 시 가장 마지막 탭을 활성화
   *  - 열린 탭이 없으면 비활성화
   * @param event onClick Event
   * @param item API ID
   */
  const handleTabClose = async (event, item) => {
    // 2024.03.27 [energysteel]: Tab onClick Event 전파 중지
    event.stopPropagation();

    if (
      item?.requestEditing ||
      item?.responseHeaderEditing ||
      item?.responseParameterEditing ||
      item?.responseCaseEditing ||
      item?.uriEditing
    ) {
      setTabCloseModalOpen(true);
      setCloseTab(item);
    } else {
      removeTabAndPersistState(item);
    }
  };

  const handleTabCloseNoSaveOk = () => {
    setCloseTab("");
    setTabCloseModalOpen(false);

    saveApiContentThrottled();
    removeTabAndPersistState(closeTab);
  }

  /**
   * 2024.04.18 [energysteel]: API 삭제 후 선택해야 하는 탭을 반환
   *  - 선택되어있는 탭을 제거한 경우
   *    - 첫번째 탭인 경우 삭제되고 첫번째가 될 탭을 반환
   *    - 그외 탭인 경우 -1 번째 탭을 반환
   *    - Tab이 존재하지 않을 경우 빈 Object 반환
   *  - 미선택된 탭을 제거한 경우
   *    - 기존 선택된 Tab을 유지
   *    - Tab이 존재하지 않을 경우 빈 Object 반환
   * @param id
   * @return {{}|*}
   */
  const selectedApiAfterDelete = (id) => {
    if (selectedApi.id === id) {
      const index = tabOpenList.findIndex((tab) => tab.id === id);
      if (index === 0 && tabOpenList.length > 1) {
        return tabOpenList[1];
      } else if (index > 0) {
        let lastOpenTabIndex = index - 1;
        return tabOpenList[lastOpenTabIndex];
      } else {
        return {};
      }
    } else {
      if (tabOpenList.length > 0) {
        return selectedApi;
      } else {
        return {};
      }
    }
  };

  /**
   * URI 설정, Request, Response 탭
   * @param {item} subApiList
   * @param {onClick} 탭 변경 clickEvent
   */
  const SubTab = ({ item, onClick, isSubSiderOpen }) => {
    const isActiveKey = item.key === activeSubTab;
    return (
      <div
        className={`Body6_B api-subtab ${isActiveKey} isSubSiderOpen-${isSubSiderOpen}`}
        onClick={() => onClick(item.key)}
      >
        <span className="content jc-sb ml16" style={{ width: "136px" }}>
          {item.title}
        </span>
      </div>
    );
  };

  // 2024.03.27 [shiningtrue]: 파일 선택창 열기
  const openFileDialog = (tabType) => {
    if (tabType === "HEADER") {
      document.getElementById("fileHeaderInput").value = "";
      document.getElementById("fileHeaderInput").click();
    } else {
      document.getElementById("fileBodyInput").value = "";
      document.getElementById("fileBodyInput").click();
    }
  };

  const saveJsonApiFailCallback = (message) => {
    setHelpIconMessage({
      code: "error",
      message: message,
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }


  /**
   * 2024.03.07 [energysteel]: Sub 탭
   *  - URI 설정, Request, Response 탭
   * @type {[{title: string, key: string, content: React.JSX.Element}]}
   */
  const subApiList = [
    {
      key: "1",
      title: "URI",
      content: <ApiUrlContent
        isSubSiderOpen={isSubSiderOpen}
        uriRefs={uriRefs}
      />,
    },
    { key: "2",
      title: "Request",
      content: <ApiRequestContent
        openFileDialog={openFileDialog}
        saveJsonApiFailCallback={saveJsonApiFailCallback}
      />
    },
    { key: "3", title: "Response", content: <ApiResponseContent openFileDialog={openFileDialog} saveJsonApiFailCallback={saveJsonApiFailCallback} /> },
  ];

  // 2024.03.07 [shiningtrue]: 수정된 request list field callBack
  const saveRequestApiSuccessCallback = async (response, modalOpenFun, completeFlag) => {
    if (response.data.code === "0001") {
      modifyEditingClear({
        requestEditing: false,
        requestHeader: response.data.data.requestHeader,
        requestParameter: response.data.data.requestParameter,
        requestBody: response.data.data.requestBody,
      });

      if (modalOpenFun?.length > 0) {
        setModalOpen(true);
      }

      if (completeFlag) {
        setCurrentTime(new Date());
        showCompleteHelpIcon();
      }

    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.19 [shiningtrue]: 수정 여부 상태 변수 변경
  // 2024.05.14 [energysteel]: 저장한 신규 데이터 변경
  const modifyEditingClear = (updatedData) => {
    setTabOpenList((prev) => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
          return {
            ...item,
            ...updatedData,
          };
        }
        return item;
      });
    });
  };

  const createApiName = (currentTab) => {
    let apiName = "[";

    if (findTreeDataById(treeData, currentTab?.api?.id)?.upperId) {
      apiName += treeData.find(
        (tree) => tree.id === currentTab.api.upperId,
      ).title;
      apiName += " / ";
    }

    apiName += currentTab?.api?.apiName;
    apiName += "]";

    return apiName;
  };

  const handleModifyPackageApiTitle = (title) => {
    const modifyApiRequest = {
      apiName: title,
      projectId: project.id,
      upperId: selectedApi.upperId,
      id: selectedApi.id,
    };

    modifyApiApi(axios, project.id, modifyApiRequest, modifyApiSuccessCallback);
  };

  /**
   * 2024.03.26 [energysteel]: 저장/수정 API 성공 Callback
   * @param response API 응답 값
   */
  const modifyApiSuccessCallback = (response) => {
    const data = response.data.data;
    setSelectedApi({ ...data, title: data.apiName });

    modifyEditingClear({
      uriEditing: false,
    })
  };

  /**
   * 2024.04.24 [energysteel]: mouse hover를 통한 API 이름 수정 API 성공 Callback
   * @param response API 응답 값
   * @param api Tree API 정보
   * @param updateValue 수정될 API 명
   */
  const modifyApiContentSuccessCallback = (response, api, updateValue) => {
    api.title = updateValue;
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            uri: {
              ...tab.uri,
              title: updateValue,
            },
          };
        }
        return tab;
      });
    });
  };

  /**
   * 2024.03.11 [shiningtrue]: request 저장/수정 API
   */
  const handleSaveOrModifyRequest = async (modalOpenFun, completeFlag) => {
    setApiSaveLoader(true);

    const params = {
      requestHeaderDataSource: tabOpenListItem.requestHeader,
      requestParameterDataSource: tabOpenListItem.requestParameter,
      requestBodyDataSource: tabOpenListItem.requestBody,
      apiId: selectedApi.id,
    };

    await saveRequestApi(
      axios,
      params,
      saveRequestApiSuccessCallback,
      () => setApiSaveLoader(false),
      modalOpenFun,
      completeFlag,
    );
  };

  // 2024.04.12 [shiningtrue]: 수정된 responseHeader list field callBack
  const saveResponseHeaderApiSuccessCallback = async (response, modalOpenFun, completeFlag) => {
    if (response.data.code === "0001") {
      modifyEditingClear({
        responseHeaderEditing: false,
        responseHeader: response.data.data.responseHeader,
      });

      if (modalOpenFun?.length > 0) {
        setModalOpen(true);
      }

      if (completeFlag) {
        setCurrentTime(new Date());
        showCompleteHelpIcon();
      }

    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.19 [shiningtrue]: 수정된 responseParameter list field callBack
  const saveResponseApiSuccessCallback = async (response, modalOpenFun, completeFlag) => {
    if (response.data.code === "0001") {
      modifyEditingClear({
        responseParameterEditing: false,
        responseParameter: response.data.data.responseParameter,
      });

      if (modalOpenFun?.length > 0) {
        setModalOpen(true);
      }

      if (completeFlag) {
        setCurrentTime(new Date());
        showCompleteHelpIcon();
      }

    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  /**
   * 2024.04.12 [shiningtrue]: responseHeader 저장/수정 API
   */
  const handleSaveOrModifyResponseHeader = async (modalOpenFun, completeFlag) => {
    setApiSaveLoader(true);

    const params = {
      responseHeaderObject: tabOpenListItem.responseHeader,
      type: "HEADER",
      apiId: selectedApi.id,
      projectId: project.id,
    };

    await saveResponseHeaderApi(
      axios,
      params,
      saveResponseHeaderApiSuccessCallback,
      () => setApiSaveLoader(false),
      modalOpenFun,
      completeFlag,
    );
  };

  /**
   * 2024.03.19 [shiningtrue]: responseParameter 저장/수정 API
   */
  const handleSaveOrModifyResponseParameter = async (modalOpenFun, completeFlag) => {
    const params = {
      responseParameterObject: tabOpenListItem.responseParameter,
      type: "PARAMETER",
      apiId: selectedApi.id,
      projectId: project.id,
    };

    await saveResponseParameterApi(
      axios,
      params,
      saveResponseApiSuccessCallback,
      () => setApiSaveLoader(false),
      modalOpenFun,
      completeFlag,
    );
  };

  // 2024.03.19 [shiningtrue]: 수정된 responseCase list field callBack
  const saveResponseCaseApiSuccessCallback = async (response, modalOpenFun, completeFlag) => {
    if (response.data.code === "0001") {
      modifyEditingClear({
        responseCaseEditing: false,
        responseCase: response.data.data,
      });

      if (modalOpenFun?.length > 0) {
        setModalOpen(true);
      }

      if (completeFlag) {
        setCurrentTime(new Date());
        showCompleteHelpIcon();
      }

    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  /**
   * 2024.03.19 [shiningtrue]: responseCase 저장/수정 API
   */
  const handleSaveOrModifyResponseCase = async (modalOpenFun, completeFlag) => {
    setApiSaveLoader(true);

    const params = {
      responseCaseObject: tabOpenListItem.responseCase,
      sessionUserId: userSession.id,
      apiId: selectedApi.id,
      projectId: project.id,
    };

    await saveResponseCaseApi(
      axios,
      params,
      saveResponseCaseApiSuccessCallback,
      () => setApiSaveLoader(false),
      modalOpenFun,
      completeFlag,
    );
  };

  /**
   * 2024.03.07 [energysteel]: URI 저장/수정 API
   *  - save: URI 정보가 있는 API는 true (MODIFY), 아직 없는 API는 false (SAVE)
   */
  const handleSaveOrModifyUri = async (modalOpenFun, completeFlag) => {
    setApiSaveLoader(true);

    const request = {
      apiId: selectedApi.id,
      projectId: project.id,
      uri: tabOpenListItem.uri.uri,
      title: tabOpenList.find((tab) => tab.id === selectedApi.id).api.apiName,
      delay: tabOpenListItem.uri.delay,
      httpMethod: tabOpenListItem.uri.httpMethod
        ? tabOpenListItem.uri.httpMethod
        : "GET",
      description: tabOpenListItem.uri.description,
      encryptionMethodId: tabOpenListItem.uri.encryptionMethodId,
      contentType: tabOpenListItem.uri.contentType,
    };

    await saveUriApi(
      axios,
      project.id,
      request,
      saveOrModifyApiUriApiSuccessCallback,
      saveApiUriApiFailCallback,
      () => setApiSaveLoader(false),
      modalOpenFun,
      completeFlag,
    );
  };

  /**
   * 2024.03.07 [energysteel]: URI 저장/수정 API 성공 Callback
   * @param response API 응답 값
   */
  const saveOrModifyApiUriApiSuccessCallback = async (response, modalOpenFun, completeFlag) => {
    if (response.data.code !== "0001") {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    const updatedUriContent = {
      ...tabOpenListItem.uri,
      id: response.data.data.apiId,
      save: false,
      contentType: response.data.data.contentType,
      encryptionMethodId: response.data.data.encryptionMethodId,
      title: response.data.data.title,
      httpMethod: response.data.data.httpMethod,
    };

    if (updatedUriContent.title !== selectedApi.title) {
      setSelectedApi((prev) => {
        return {
          ...prev,
          title: updatedUriContent.title,
        };
      });
    }

    // 2024.03.07 [energysteel]: 상단 탭 내용 변경
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === response.data.data.apiId) {
          return {
            ...tab,
            uri: updatedUriContent,
            uriEditing: false,
          };
        }
        return tab;
      });
    });

    // 2024.03.07 [energysteel]: API Tree > API 이름 변경
    setTreeData((prev) => {
      return prev.map((treeNode) => {
        if (treeNode.id === response.data.data.apiId) {
          return {
            ...treeNode,
            title: updatedUriContent.title,
            httpMethod: updatedUriContent.httpMethod,
          };
        } else if (treeNode.children.length > 0) {
          const updatedChildren = treeNode.children.map((child) => {
            if (child.id === response.data.data.apiId) {
              return {
                ...child,
                title: updatedUriContent.title,
                httpMethod: updatedUriContent.httpMethod,
              };
            } else {
              return child;
            }
          });
          return {
            ...treeNode,
            children: updatedChildren,
          };
        } else {
          return treeNode;
        }
      });
    });

    if (modalOpenFun?.length > 0) {
      setModalOpen(true);
    }

    if (completeFlag) {
      setCurrentTime(new Date());
      showCompleteHelpIcon();
    }
  };

  const showCompleteHelpIcon = () => {
    // setIsApiCopy(false);
    setHelpIconMessage({
      code: "success",
      message: "저장 완료되었습니다.",
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }

  /**
   * 2024.03.07 [energysteel]: URI 저장/수정 API 실패 Callback
   * @param error API 실패 응답 값
   */
  const saveApiUriApiFailCallback = async (error) => {
    setApiSaveLoader(false);

    setHelpIconMessage({
      code: "error",
      message: error,
    });
    alertHelpIcon(setAlertHelpIconOpen);
  };

  /**
   * 2024.03.11 [energysteel]: 기존과 다른 값으로 수정하면 Tab에 `*` 처리
   *  - TreeData에 있는 Title과 Content 영역의 Title 비교
   * @param event onBlur Event
   */
  const handleCheckDifferenceTitle = (event) => {
    const api = findTreeDataById(treeData, selectedApi.id);
    const updateValue = event.target.value;

    if (api && api.title !== updateValue) {
      const modifyApiRequest = {
        apiName: updateValue,
        projectId: project.id,
        upperId: api.upperId,
        id: api.id,
      };

      modifyApiApi(axios, project.id, modifyApiRequest, (response) =>
        modifyApiContentSuccessCallback(response, api, updateValue),
      );
    }
    setApiNameEditable(false);
  };

  /**
   * 2024.03.11 [energysteel]: ID를 기준으로 TreeData의 값 찾기
   * @param apis API 리스트
   * @param id API ID
   * @returns {*|null} API | NULL
   */
  const findTreeDataById = (apis, id) => {
    for (let i = 0; i < apis.length; i++) {
      const api = apis[i];
      if (api.id === id) {
        return api;
      }
      if (api.children && api.children.length > 0) {
        const foundApi = findTreeDataById(api.children, id);
        if (foundApi) {
          return foundApi;
        }
      }
    }
    return null;
  };

  const [allRendered, setAllRendered] = useState(false);

  // 모든 렌더링이 완료된 후에 실행되는 useEffect
  useEffect(() => {
    setAllRendered(true);
  }, []);

  return (
    <div className={`project-api-content isSubSiderOpen-${isSubSiderOpen}`}>
      {tabOpenList?.length === 0 &&
          (tabRecoilPersistState[project.id] === undefined ||
            tabRecoilPersistState[project.id].length === 0) ? (
          apiTreeDataNotEmpty ? (
            <SelectApiContent />
          ) : (
            <NoApiContent />
          )
        ) : (
          allRendered && (
            <div className="content h-full" style={{ position:"relative" }}>
              <div className={`project-api-content-right isRightbarOpen-${isRightbar.open}`}>
                {apiSaveLoader &&
                  <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}>
                    <Loader/>
                  </div>
                }

                <TabHeader
                  tabOpenList={tabOpenList}
                  onClickTab={onClickTab}
                  handleTabClose={handleTabClose}
                  useTabClearDropdownHandle={useTabClearDropdownHandle}
                />
                <div style={{height:"calc(100% - 48px - 64px)"}}>
                  <div
                    style={{ height: "64px" }}
                    className="input-B content jc-sb bt-g200 pl16 pr16 mt-1"
                  >
                    <div>
                      <span className="Body6_B g900">{project.name} /&nbsp;</span>
                      <span
                        style={{
                          background:
                            isMouseHover && !apiNameEditable ? "#dde2ee" : "",
                        }}
                        className="Body6_B g900"
                        onClick={isApiAuthority(project.authority) ?
                          () => setApiNameEditable(true)
                            :
                          () => {}
                        }
                        onMouseEnter={
                          isApiAuthority(project.authority) ?
                            () => setIsMouseHover(true)
                              :
                            () => {}
                        }
                        onMouseOut={() => setIsMouseHover(false)}
                        onBlur={handleCheckDifferenceTitle}
                      >
                      {apiNameEditable ? (
                        <Input
                          ref={inputRef}
                          style={{ display: "inline-block", width: 283 }}
                          className="Body7_R g900 bg-white ml6"
                          value={
                            tabOpenList.find((value) => {
                              return value.id === selectedApi.id;
                            })?.api.apiName
                          }
                          onChange={handleChangeTitle}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              handleCheckDifferenceTitle(event);
                            }
                          }}
                          onBlur={() => setApiNameEditable(false)}
                        />
                      ) : (
                        <span className="Body7_R">
                          {
                            tabOpenList.find((value) => {
                              return value.id === selectedApi.id;
                            })?.api.apiName
                          }
                        </span>
                      )}
                    </span>
                    </div>
                    {isApiAuthority(project.authority) ? (
                      <SquareButton
                        className={`btn-pri Body6_B ${
                          !(
                            tabOpenListItem?.requestEditing ||
                            tabOpenListItem?.responseHeaderEditing ||
                            tabOpenListItem?.responseParameterEditing ||
                            tabOpenListItem?.responseCaseEditing ||
                            tabOpenListItem?.uriEditing
                          ) && "disable"
                        }`}
                        size={"sm-s"}
                        onClick={saveApiContentThrottled}
                        text={"저장"}
                        isButtonDisabled={
                          !(
                            tabOpenListItem?.requestEditing ||
                            tabOpenListItem?.responseHeaderEditing ||
                            tabOpenListItem?.responseParameterEditing ||
                            tabOpenListItem?.responseCaseEditing ||
                            tabOpenListItem?.uriEditing
                          ) && true
                        }
                      />
                    ) : null}
                  </div>
                  <div className="h-full">
                    <div
                      style={{ width: "calc(100% - 170px - 170px -170px)", height:40 }}
                      className="bb-g200 ml16 mr16"
                    >
                      {subApiList.map((item, index) => {
                        return (
                          <SubTab
                            key={index}
                            isSubSiderOpen={isSubSiderOpen}
                            item={item}
                            onClick={onClickSubTab}
                          />
                        );
                      })}
                    </div>
                    <div className="table-overflow-auto" style={{ padding: "24px 16px" }}>
                      {
                        subApiList.find((value) => {
                          return value.key === activeSubTab;
                        })?.content
                      }
                    </div>
                  </div>
                </div>
              </div>
              <Rightbar isRightbar={isRightbar} setIsRightbar={setIsRightbar} />

              <ConfirmModal
                open={tabAllClearModalOpen}
                title={"전체 닫기"}
                message={"저장 되지 않은 데이터가 존재합니다.\n그래도 닫으시겠습니까?"}
                textOk={"네"}
                textCancel={"아니오"}
                handleOk={handleTabAllClearNoSaveOk}
                handleCancel={() => setTabAllClearModalOpen(false)}
              />
              <ConfirmModal
                open={tabClearModalOpen}
                title={"닫기"}
                message={"변경된 데이터가 존재합니다.\n데이터를 저장 하시겠습니까?"}
                textOk={"네"}
                textCancel={"아니오"}
                handleOk={handleTabClearNoSaveOk}
                handleCancel={() => {
                  setTabClearModalOpen(false);
                  removeTabAndPersistState();
                }}
              />
              <ConfirmModal
                open={tabCloseModalOpen}
                title={"닫기"}
                message={"변경된 데이터가 존재합니다.\n데이터를 저장 하시겠습니까?"}
                textOk={"네"}
                textCancel={"아니오"}
                handleOk={handleTabCloseNoSaveOk}
                handleCancel={() => {
                  removeTabAndPersistState(closeTab);
                  setTabCloseModalOpen(false);
                  setCloseTab("");
                }}
              />
              <ConfirmModal
                open={modalOpen}
                title={"중복 저장 알림"}
                message={modalOpenDuplicationCallbackQueue[0]?.message}
                textOk={"네"}
                textCancel={"아니오"}
                handleOk={handleOverwrite}
                handleCancel={handleOverwriteCancel}
              />
            </div>
          )
        )
      }
    </div>
  );
};


export default ApiContent;
