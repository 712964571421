export const findAllChildrenKeys = (record) => {
  let expandedRows = [];

  if (record.children && record.children.length > 0) {
    expandedRows = record.children.map(child => child.key);

    record.children.forEach(child => {
      expandedRows = expandedRows.concat(findAllChildrenKeys(child));
    });
  }

  return expandedRows;
}

export const removeDuplicatedExpandedKey = (expandedRows) => {
  return expandedRows.filter((el, index) =>
    expandedRows.indexOf(el) === index).filter(key => key !== '' && key !== undefined);
}