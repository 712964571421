// 2024.03.07 [energysteel]: URI 단건 조회 API
import {toast} from "react-toastify";

export const loadApiUriApi = async (axios, projectId, apiId, successCallback, failCallback) => {
    axios
        .get(`/project/${projectId}/api/${apiId}/uri`)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response.data.data);
            } else {
                failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.07 [energysteel]: URI 저장 API
export const saveUriApi = async (
  axios,
  projectId,
  saveApiRequest,
  successCallback,
  failCallback,
  finallyCallback,
  modalOpenFun,
  completeFlag,
) => {
    axios
        .post(`/project/${projectId}/api/${saveApiRequest.apiId}/uri`, saveApiRequest)
        .then(response => {
          successCallback(response, modalOpenFun, completeFlag);
        })
        .catch(e => {
          failCallback(e);
        })
        .then(() => {
          finallyCallback();
        });
}

// 2024.03.07 [energysteel]: URI 수정 API
export const modifyUriApi = async (axios, projectId, modifyApiRequest, successCallback, failCallback) => {
    axios
        .patch(`/project/${projectId}/api/${modifyApiRequest.apiId}/uri`, modifyApiRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch(e => {
            failCallback(e);
        })
        .then(() => {
            // 항상 실행
        });

}

// 2024.03.07 [energysteel]: Content-Type 조회 API
export const loadContentTypeApi = async (axios, successCallback) => {
    axios
        .get(`/uri/content-type`)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.07 [energysteel]: 암호화 방식 조회 API
export const loadEncryptMethodApi = async (axios, projectId, successCallback) => {
    axios
        .get(`/uri/encryption-method`, {
                params: {
                    projectId: projectId
                },
            })
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response)
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}