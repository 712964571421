import {atom} from "recoil";


export const queryStringState = atom ({
    key: "queryStringState",
    default: {},
})


// 2024.03.10 [energysteel]: URI 저장/수정 Validation
export const validateUri = (uriContent, commonEnvironmentVariableList, setHelpIconMessage, apiName) => {
    let isValid = true;
    let name = '';
    if (!uriContent.httpMethod) {
        isValid = false;
        name = 'httpMethod';
    }

    if (!uriContent.uri) {
        isValid = false;
        name = 'uri';
    }

    if (!uriContent.contentType) {
        isValid = false;
        name = 'contentType';
    }

    if (uriContent.delay < 0 || uriContent.delay > 60000) {
        isValid = false;
        name = 'delay';
    }

    if (uriContent?.encryptionMethodId) {
        const encryptionMethod = commonEnvironmentVariableList.find(
          item => item.key === parseInt(uriContent?.encryptionMethodId)
        );

        if (encryptionMethod !== undefined) {
            const isAllZeros = encryptionMethod.value.split('').every(char => char === '0');
            if (isAllZeros) {
                setHelpIconMessage({
                    code: "error",
                    message: "공통변수 > 환경변수에서 암호화 키 value를 설정해 주십시오.",
                });

                return false;
            }
        }
    }

    if (!isValid) {
        setHelpIconMessage({
            code: "error",
            message: `${apiName} ${name}을(를) 입력해주세요.`,
        });
    }

    return isValid;
}
