import React, { useEffect, useState } from "react";
import {Button, ConfigProvider, Input, Table, Tabs} from "antd";
import TooltipImage from "../../atoms/TooltipImage";
import { EditableCell, EditableRow } from "../../atoms/Editable";
import ModalSelect from "../../atoms/ModalSelect";
import TableWrapper from "../../molecules/TableWrapper";
import useAxiosInterceptor from "../../../axios/axios";
import { useRecoilState } from "recoil";
import {
  commonErrorCodeListState,
  isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import {
  loadCommonErrorCodeApi,
  loadCommonErrorCodeConstantApi,
  saveCommonErrorCodeApi,
} from "../../../api/commonErrorCode/commonErrorCodeApi";
import Image from "../../atoms/Image";
import {
  commonErrorCodeConstantListState,
  commonErrorCodeEditingRecoilState,
} from "../../../recoil/commonErrorCode/commonErrorCodeState";
import RequiredText from "../../atoms/RequiredText";
import { toast } from "react-toastify";
import { SquareButton } from "../../atoms/Button";
import HeaderTable from "../../molecules/tables/HeaderTable";
import ParameterTable from "../../molecules/tables/ParameterTable";
import ApiTestcaseContent from "./Api/ApiTestcaseContent";
import CommonErrorCodeBusinessContent from "./CommonErrorCodeBusinessContent";
import CommonErrorCodeValidationContent from "./CommonErrorCodeValidationContent";
import CommonErrorCodeHttpContent from "./CommonErrorCodeHttpContent";
import CommonErrorCodeMockBotContent from "./CommonErrorCodeMockBotContent";
import TabsWrapper from "../../molecules/TabsWrapper";

const CommonErrorCodeContent = ({ data }) => {
    useRecoilState(commonErrorCodeListState);
  const [activeSubTab, setActiveSubTab] = useState("1");

  const items = [
    {
      key: "1",
      label: <span className="subtab-title">Business</span>,
      children: <CommonErrorCodeBusinessContent />,
    },
    {
      key: "2",
      label: <span className="subtab-title">Validation</span>,
      children: <CommonErrorCodeValidationContent />,
    },
    {
      key: "3",
      label: <span className="subtab-title">Http</span>,
      children: <CommonErrorCodeHttpContent />,
    },
  ];

  return (
    <TabsWrapper>
      {/* <Tabs defaultActiveKey="1" items={items} /> */}
      <div className="common-error-content">
        {items.map((v) => {
          return (
            <div
              className={`common-error-content-subtab ${activeSubTab === v.key}`}
              onClick={() => setActiveSubTab(v.key)}
              key={v.key}
            >
              {v.label}
            </div>
          );
        })}
      </div>
      {
        items.find((value) => {
          return value.key == activeSubTab;
        })?.children
      }
    </TabsWrapper>
  );
};

export default CommonErrorCodeContent;
