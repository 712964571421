import React, {useEffect, useState} from "react";
import {Card, ConfigProvider, Modal} from "antd";
import ModalHeadline from "../../atoms/ModalHeadline";
import Footer from "../../molecules/modals/Footer";
import ModalSelect from "../../atoms/ModalSelect";
import SettingTestCaseTable from "../../molecules/tables/SettingTestCaseTable";
import {ErrorCode,} from "../../molecules/modals/ResponseAll";
import {useRecoilState} from "recoil";
import {
  currentResponseCaseState,
  editedCustomTestCaseState,
  editedTestCaseState, jsonErrorStructureConvert
} from "../../../recoil/response/responseState";
import {selectedApiState, tabOpenListState} from "../../../recoil/api/apiState";
import {commonResponseFormBodyListState, isApiAuthority, projectState} from "../../../recoil/project/projectState";
import useAxiosInterceptor from "../../../axios/axios";
import AlertFooter from "../../molecules/modals/AlertFooter";
import {loadResponseCaseDetailApi} from "../../../api/response/responseApi";
import Image from "../../atoms/Image";


const SettingTestCaseModal = ({
  isModalOpen,
  setIsModalOpen,
  record,
  responseItem,
  jsonDefaultConvert,
  commonErrorCodeList,
}) => {
  const axios = useAxiosInterceptor();
  const [editedTestCase] = useRecoilState(editedTestCaseState);
  const [editedCustomTestCase] = useRecoilState(editedCustomTestCaseState);
  // 2024.03.26 [energysteel]: Test 응답 유형
  const responseTypeList = [
    {key: "SUCCESS", value: "정상 응답"},
    {key: "ERROR", value: "에러 응답"},
  ];

  // 2024.03.26 [energysteel]: 에러 메시지 설정 방법
  const errorMessageTypeList = [
    {key: "COMMON", value: "공통 에러 코드 적용"},
    {key: "CUSTOM", value: "사용자 직접 설정"},
  ];

  // 2024.03.26 [energysteel]: 현재 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.26 [energysteel]: 탭 정보
  const [, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.03.26 [energysteel]: 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.26 [energysteel]: 상세 Modal을 실행한 Response Case 정보 (WRITE)
  const [currentResponseCase, setCurrentResponseCase] = useRecoilState(currentResponseCaseState);
  // 2024.03.26 [energysteel]: TestCase JSON 포맷을 잘못 입력했을 때 버튼 비활성화 Flag
  const [testCaseError, setTestCaseError] = useState(false);
  // 2024.03.26 [energysteel]: 상세 Modal을 실행한 Response Case DB 정보 (READ)
  let responseCase = responseItem.responseCase.find(resCase => resCase.key === record.key);
  // 2024.03.28 [energysteel]: 공통 응답규격 Body
  const [commonResponseFormBody, setCommonResponseFormBody,] = useRecoilState(commonResponseFormBodyListState);
  // 2024.05.29 [evahong]: 테스트케이스 확장 
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (responseCase.key) {
      const responseType = responseTypeList.find(responseType => responseType.key === responseCase.responseType);
      const errorMessageType = errorMessageTypeList.find(errorMessage => errorMessage.key === responseCase.errorMessageType);

      setCurrentResponseCase(
        {
          responseType: responseType,
          errorMessageType: errorMessageType,
          responseCaseDetail: {
            ...responseCase.responseCaseDetail,
            testCase: loadSuccessTestCase(responseType.key, responseCase.responseCaseDetail),
            mappingManagement: loadCommonErrorCodeMapping(responseCase.responseCaseDetail),
            commonErrorCode: loadCommonErrorCode(responseCase.responseCaseDetail),
            customErrorCode: loadCustomErrorCode(responseCase.responseCaseDetail),
          }
        });
      return;
    }

    loadResponseCaseDetailApi(
      axios,
      responseCase.key,
      loadResponseCaseDetailApiSuccessCallback,
    );
  }, [record]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        if (isApiAuthority(project.authority)) {
          handleTestCaseSaveOk();
        }

        event.preventDefault();
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, true);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [currentResponseCase, editedTestCase, editedCustomTestCase]);

  const loadResponseCaseDetailApiSuccessCallback = (response) => {
    const responseType = responseTypeList.find(responseType => responseType.key === responseCase.responseType);
    const errorMessageType = errorMessageTypeList.find(errorMessage => errorMessage.key === responseCase.errorMessageType);

    setCurrentResponseCase(
      {
        responseType: responseType,
        errorMessageType: errorMessageType,
        mappingManagement: response?.data.data?.mappingManagement,
        commonErrorCode: response?.data.data?.commonErrorCode,
            responseCaseDetail: {
              ...response?.data.data,
              testCase: loadSuccessTestCase(responseType.key, response.data.data),
              mappingManagement: loadCommonErrorCodeMapping(response.data.data),
              commonErrorCode: loadCommonErrorCode(response.data.data),
              customErrorCode: loadCustomErrorCode(response.data.data),
            }
      })
  }

  /**
   * 2024.03.28 [energysteel]: Modal 진입 시 성공 TestCase 확인 후 성공 TestCase 반환
   * @param changeResponseType 변경하려는 ResponseType : SUCCESS/ERROR
   * @param responseCaseDetail 테스트케이스 상세
   * @returns {{}|*}
   */
  const loadSuccessTestCase = (changeResponseType, responseCaseDetail) => {
    if (changeResponseType === 'SUCCESS') {
      if (responseCase?.responseType === 'SUCCESS') {
        return responseCaseDetail?.testCase ?? jsonDefaultConvert(responseItem.responseParameter);
      } else {
        return jsonDefaultConvert(responseItem.responseParameter);
      }
    }
  }

  /**
   * 2024.03.28 [energysteel]: Modal 진입 시 공통 에러코드 사용여부 확인 후 공통 에러코드 반환
   *  - Mapping 정보
   * @param responseCaseDetail 테스트케이스 상세
   * @returns {{code: *, message: *, value: *, key: *}|{code: string, message: string, value: string, key: string}|(*&{value: *})}
   */
  const loadCommonErrorCodeMapping = (responseCaseDetail) => {
    if (responseCase.errorMessageType === 'COMMON') {
      return {
        mappingManagement: {
          mappingId: responseCaseDetail?.mappingManagement?.mappingId,
          mappingType: 'COMMON_ERROR_CODE',
          targetId: undefined,
          targetType: 'RESPONSE_CASE',
          projectId: project.id,
        }
      }
    }
  }

  /**
   * 2024.03.28 [energysteel]: Modal 진입 시 공통 에러코드 사용여부 확인 후 공통 에러코드 반환
   *  - Mapping 정보를 통한 Error Code
   * @param responseCaseDetail 테스트케이스 상세
   * @returns {{code: *, message: *, value: *, key: *}|{code: string, message: string, value: string, key: string}|(*&{value: *})}
   */
  const loadCommonErrorCode = (responseCaseDetail) => {
    // 2024.03.28 [energysteel]: 이미 공통 에러코드를 사용해 저장한 경우
    if (responseCaseDetail?.mappingManagement?.mappingId ||
      responseCaseDetail?.commonErrorCode?.id) {
      let errorCode = {}

      if (responseCaseDetail?.mappingManagement?.mappingId) {
        errorCode = commonErrorCodeList.find(code => code.value === responseCaseDetail?.mappingManagement?.mappingId);
      } else if (responseCaseDetail?.commonErrorCode?.id) {
        errorCode = {
          value: responseCaseDetail?.commonErrorCode?.value,
          label: responseCaseDetail?.commonErrorCode?.label,
          code: responseCaseDetail?.commonErrorCode?.code,
          message: responseCaseDetail?.commonErrorCode?.message,
        }
      }

      return {
        structure: JSON.stringify(commonResponseFormBody),
        field: errorCode,
        data: jsonErrorStructureConvert(commonResponseFormBody, errorCode),
      };
      // 2024.03.28 [energysteel]: 공통 에러코드를 사용하지 않았고,
      // 공통 응답 규격이 있으며, Req Validation을 제외한 공통 에러코드가 하나 이상 있는 경우
    }  else if (commonResponseFormBody.length > 0 && commonErrorCodeList?.length > 0) {
      return {
        structure: JSON.stringify(commonResponseFormBody),
        field: {
          value: commonErrorCodeList[0]?.value,
          label: commonErrorCodeList[0]?.label,
          code: commonErrorCodeList[0]?.code,
          message: commonErrorCodeList[0]?.message,
        },
        data: jsonErrorStructureConvert(commonResponseFormBody, commonErrorCodeList[0]),
      }
      // 2024.03.28 [energysteel]: 공통 에러코드를 사용하지 않았고,
      // 공통 응답 규격은 없고, Req Validation을 제외한 공통 에러코드가 있는 경우
    } else if (commonResponseFormBody.length === 0 && commonErrorCodeList?.length > 0) {
      return {
        structure: {},
        field: {
          value: commonErrorCodeList[0]?.value,
          label: commonErrorCodeList[0]?.label,
          code: commonErrorCodeList[0]?.code,
          message: commonErrorCodeList[0]?.message,
        },
        data: {},
      }
      // 2024.03.28 [energysteel]: 공통 에러코드를 사용하지 않았고,
      // 공통 응답 규격과 Req Validation을 제외한 공통 에러코드가 없는 경우
    } else if (commonResponseFormBody.length === 0 && !commonErrorCodeList?.length > 0) {
      return {
        structure: undefined,
        field: undefined,
        data: undefined,
      }
    }
  }

  /**
   * 2024.03.28 [energysteel]: Modal 진입 시 사용자 직접 설정 사용여부 확인 후 사용자 직접 설정 에러코드 반환
   * @param responseCaseDetail 테스트케이스 상세
   * @returns {{}|{code: (*|string), message: (*|string)}}
   */
  const loadCustomErrorCode = (responseCaseDetail) => {
    if (responseCase.errorMessageType === 'CUSTOM') {
      return responseCaseDetail?.testCase;
    } else {
      return {}
    }
  }

  /**
   * 2024.03.26 [energysteel]: Modal 저장 버튼 onClick Event
   */
  const handleTestCaseSaveOk = () => {
    if (currentResponseCase?.responseType?.key === 'SUCCESS') {
      saveSuccessCase();
    } else if (currentResponseCase?.errorMessageType?.key === 'COMMON') {
      saveCommonCodeCase();
    } else {
      saveCustomCodeCase();
    }

    modalClose();
  };

  /**
   * 2024.03.28 [energysteel]: Modal 저장 버튼 onClick Event 정상 응답 임시저장
   */
  const saveSuccessCase = () => {
    setCurrentResponseCase(prev => {
      return {
        ...prev,
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          customErrorCode: editedTestCase,
        }
      }
    });

    setTabOpenList(prev => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
          return {
            ...item,
            responseCaseEditing: true,
            responseCase: item.responseCase.map(caseData => {
              if (caseData.key === record.key) {
                const { responseCaseDetail } = caseData;
                const { etc } = responseCaseDetail || {};
                return {
                  ...caseData,
                  responseType: currentResponseCase?.responseType?.key,
                  errorMessageType: currentResponseCase?.errorMessageType?.key,
                  responseCaseDetail: {
                    ...etc,
                    testCase: editedTestCase,
                  }
                }
              }

              return caseData;
            })
          }
        } else {
          return item;
        }
      })
    })
  }

  /**
   * 2024.03.28 [energysteel]: Modal 저장 버튼 onClick Event 에러 응답 - 공통 응답코드 임시저장
   */
  const saveCommonCodeCase = () => {
    setTabOpenList(prev => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
          return {
            ...item,
            responseCaseEditing: true,
            responseCase: item.responseCase.map(caseData => {
              if (caseData.key === record.key) {
                return {
                  ...caseData,
                  responseType: currentResponseCase?.responseType?.key,
                  errorMessageType: currentResponseCase?.errorMessageType?.key,
                  responseCaseDetail: {
                    ...caseData.responseCaseDetail,
                    testCase: {},
                    mappingManagement: {
                      mappingId: currentResponseCase?.responseCaseDetail?.commonErrorCode?.field?.value,
                      mappingType: 'COMMON_ERROR_CODE',
                      targetId: undefined,
                      targetType: 'RESPONSE_CASE',
                      projectId: project.id,
                    }
                  }
                }
              }
              return caseData;
            })
          }
        } else {
          return item;
        }
      })
    })
  }

  /**
   * 2024.03.28 [energysteel]: Modal 저장 버튼 onClick Event - 에러 응답 - 사용자 직접 설정 임시저장
   */
  const saveCustomCodeCase = () => {
    setCurrentResponseCase(prev => {
      return {
        ...prev,
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          commonErrorCode: editedCustomTestCase,
        }
      }
    });

    setTabOpenList(prev => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
          return {
            ...item,
            responseCaseEditing: true,
            responseCase: item.responseCase.map(caseData => {
              if (caseData.key === record.key) {
                const { responseCaseDetail } = caseData;
                const { etc } = responseCaseDetail || {};
                return {
                  ...caseData,
                  responseType: currentResponseCase?.responseType?.key,
                  errorMessageType: currentResponseCase?.errorMessageType?.key,
                  responseCaseDetail: {
                    ...etc,
                    testCase: editedCustomTestCase,
                  }
                }
              }
              return caseData;
            })
          }
        } else {
          return item;
        }
      })
    })
  }

  /**
   * 2024.03.26 [energysteel]: Modal 닫기 onClick Event state 초기화
   */
  const handleCancel = () => {
    setTestCaseError(false);

    setIsModalOpen(prev => {
      return prev.map(modal => {
        if (modal.id === record.key) {
          return {
            ...modal,
            open: false,
          }
        } else {
          return modal;
        }
      })
    });
  };

  /**
   * 2024.03.26 [energysteel]: Test 응답 유형 select onChange Event
   * @param value Test 응답 유형 value
   * @see responseTypeList
   */
  const handleResponseTypeChange = (value) => {
    const responseType = responseTypeList.find(responseType => responseType.value === value);
    setCurrentResponseCase(prev => {
      return {
        ...prev,
        responseType: responseType,
        errorMessageType: prev.errorMessageType ?? errorMessageTypeList[0],
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          testCase: loadSuccessTestCase(responseType.key, responseCase.responseCaseDetail),
        }
      }
    })
  };

  /**
   * 2024.03.26 [energysteel]: 에러 응답 전문 에러 유형 select onChange Event
   * @param value onChange Value
   */
  const handleErrorTypeChange = (value) => {
    if (value === 'CUSTOM') {
      setCurrentResponseCase(prev => {
        return {
          ...prev,
          errorMessageType: errorMessageTypeList.find(type => type.key === 'CUSTOM'),
        }
      })

      return;
    }

    const errorCode = commonErrorCodeList.find(commonErrorCode => commonErrorCode.value === value);
    setCurrentResponseCase(prev => {
      return {
        ...prev,
        errorMessageType: errorMessageTypeList.find(type => type.key === 'COMMON'),
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          commonErrorCode: {
            ...prev.responseCaseDetail.commonErrorCode,
            field: {
              value: errorCode?.value ?? '',
              label: errorCode?.label ?? '',
              code: errorCode?.code ?? '',
              message: errorCode?.message ?? '',
            },
            data: jsonErrorStructureConvert(commonResponseFormBody, errorCode),
          }
        }

      }
    })
  };

  /**
   * 2024.03.28 [energysteel]: Modal 종료
   */
  const modalClose = () => {
    setIsModalOpen(prev => {
      if (prev.length === 0) {
        return [{id: record.key, open: false}];
      }

      return prev.map((modal) => {
        if (modal.id === record.key) {
          return {
            ...modal,
            open: false,
          };
        } else {
          return {
            id: record.key,
            open: false,
          };
        }
      });
    });
  }

  const subTitle = (
    <>
      응답 규격에 맞는 Parameter 값을 직접 입력하세요 <br/>
      규격 변경은 ‘API 관리 {">"}
      <span
        className="Body7_B g400 cur"
        style={{textDecoration: "underline"}}
      >
        Response Tab’
      </span>{" "}
      메뉴 에서 수정해 주세요
    </>
  );

  const errSubTitle = (
    <>
      응답 하고자 하는 에러 유형을 선택해 주세요.
      <br/>
      규격 변경은{" "}
      <span
        className="Body7_B g400 cur"
        style={{textDecoration: "underline"}}
      >
        ‘공통 에러 응답’
      </span>{" "}
      메뉴 에서 수정해 주세요
    </>
  );


  return (
    <Modal
      className={`modal open-${isOpen}`}
      title={
        <>
          <span
            className="Body3_B g900"
            style={{ color: "#ff6c37" }}
          >
            {`{${responseCase.name}} `}
          </span>
          <span>Sample Data</span>
          <div className="Body7_R g400 mt10 mb14">
            Test 시 응답 받을 Parameter 값을 작성하세요.
          </div>
        </>
      }
      open={isModalOpen?.find(modal => modal.id === record.key)?.open}
      onCancel={handleCancel}
      centered
      footer={isApiAuthority(project.authority) ?
        <Footer
          textCancel={"닫기"}
          textOk={"저장"}
          handleCancel={handleCancel}
          handleOk={handleTestCaseSaveOk}
          disabled={testCaseError}
        />
        :
        <AlertFooter
          textOk={"확인"}
          handleOk={handleCancel}
        />
      }
    >
      <ModalHeadline open={isOpen}/>
      <table className="dds w-full">
        <colgroup>
          <col width="118px"/>
          <col width="calc(100% - 118px)"/>
        </colgroup>
        <tbody>
          <tr>
            <td className="Body6_B g500 ">Test 응답 유형</td>
            <td>
              <ModalSelect
                color="#ff5757"
                width="100%"
                value={currentResponseCase.responseType?.value}
                options={responseTypeList}
                handleChange={handleResponseTypeChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {(currentResponseCase.responseType?.key === 'SUCCESS') ? (
        <div className="mt12">
          <div className="Body6_B g500 mb6 err">응답전문</div>
          <div className="Body7_R g400 mb12 err">{subTitle}</div>
          <ConfigProvider
            theme={{
              components: {
                Card: {
                  headerHeightSM: 44,
                },
              },
            }}
          >
            <Card
              size="small"
              className="bg-g100 b-g200 br3"
              bodyStyle={{
                padding: 0,
              }}
              extra={
                <div className="content">
                  <button
                    className={"content btn-line Body6_B br3 mr4"}
                    style={{ padding: "6px" }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={isOpen ? "ic_minimize" : "ic_maximize"}
                    />
                  </button>
                </div>
              }
            >
              <div
                style={{
                  minHeight: 216,
                  overflow: "auto",
                }}
              >
                <SettingTestCaseTable
                  setTestCaseError={setTestCaseError}
                />
              </div>
            </Card>
          </ConfigProvider>
        </div>
      ) : currentResponseCase.responseType?.key === 'ERROR' && (
        <ErrorCode
          title={"응답전문"}
          subTitle={errSubTitle}
          handleChange={handleErrorTypeChange}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isCommonError={currentResponseCase.errorMessageType.key === 'COMMON'}
          setTestCaseError={setTestCaseError}
          commonErrorCodeList={[...commonErrorCodeList,
            {
              value: "CUSTOM",
              label: "사용자 직접 입력",
            }
          ]}
        />
      )}
    </Modal>
  );
};

export default SettingTestCaseModal;
