import React, {useEffect, useRef, useState} from "react";
import {ConfigProvider, Dropdown, Input, Space, Tree} from "antd";
import Image from "../../atoms/Image";
import {DownOutlined} from "@ant-design/icons";
import {deleteApiApi, modifyApiApi, modifyApiOrderApi, saveApiApi} from "../../../api/api/apiApi";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  apiTabRecoilPersistState,
  deleteApiRequestState,
  deleteShowModalState,
  helpIconMessageState,
  selectedApiState,
  tabOpenListState,
  useApiDropdown
} from "../../../recoil/api/apiState";
import {useRecoilState} from "recoil";
import {isApiAuthority, projectState} from "../../../recoil/project/projectState";
import {createModifyRequest, deepCopy, findDifferent} from "./ApiTree";
import ApiDeleteModal from "../modals/ApiDeleteModal";
import useAxiosInterceptor from "../../../axios/axios";
import _ from "lodash";


const APISubSider = ({
  newData,
  setNewData,
  isGroup,
  treeData,
  setTreeData,
  filteringTreeData,
  inputValue,
  apiCopy,
  apiPaste,
  copyApiStack,
  setCopyApiStack,
  checkedKey,
  setCheckedKey,
}) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.08 [energysteel]: API 삭제 Modal
  const [deleteShowModal, setDeleteShowModal] = useRecoilState(deleteShowModalState);
  // 2024.03.08 [energysteel]: API 삭제 API Request
  const [deleteApiRequest, setDeleteApiRequest] = useState(deleteApiRequestState);
  // 2024.03.08 [energysteel]: 선택한 API
  const [selectedApi, setSelectedApi] = useRecoilState(selectedApiState);
  // 2024.03.08 [energysteel]: 선택한 프로젝트
  const [project] = useRecoilState(projectState);
  // 2024.03.08 [energysteel]: 탭 리스트
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.03.08 [energysteel]: Tree 확장할 노드의 Key 리스트
  const [expandedKeys, setExpandedKeys] = useState([]);
  // 2024.03.08 [energysteel]: API 이름 변경 Input value
  const [inputKey, setInputKey] = useState("");
  // 2024.04.18 [energysteel]: Tree Ref
  const treeRef = useRef(null);
  // 2024.05.01 [energysteel]: API Tab 브라우저 종료 시에도 데이터 관리하기 위한 Recoil
  const [, setTabRecoilPersistState] = useRecoilState(
    apiTabRecoilPersistState,
  );
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  // 2024.05.07 [energysteel]: Order 수정 중
  const [orderModifyFlag, setOrderModifyFlag] = useState(false);
  // 2024.03.08 [energysteel]: API Tree 라인 설정 (true면 showLeafIcon 노출)
  const showLine = true;
  const showLeafIcon = <div></div>;


  useEffect(() => {
    setCheckedKey([loadTreeDataByApiId(treeData, selectedApi.id)?.key]);
  }, [selectedApi.id, treeData])

  /**
   * 2024.03.08 [energysteel]: API 저장 API
   * @param api 저장 대상 API
   * @param key 저장 대상 API Key
   */
  const handleSaveApi = (api, key) => {
    let packageYn = isPackage(api);
    // 2024.03.08 [energysteel]: Parent Key: 0-0, Children Key: 0-0-0
    const isChild = key.split("-").length >= 3;

    const saveApiRequest = {
      apiName: inputKey,
      packageYn: packageYn,
      isLeaf: !packageYn,
      isChild: isChild,
      projectId: project.id,
      update: false,
      upperId: treeData.find(v => v.key === findParentKey(key))?.id,
    };

    saveApiApi(
      axios,
      project.id,
      saveApiRequest,
      (response) => saveOrModifyApiSuccessCallback(response, api),
      () => {
      },
    );
  }

  // 2024.05.19 [shiningtrue]: throttle
  const handleSaveApiThrottled = _.throttle((api, key) => {
    handleSaveApi(api, key);
  }, 150);

  /**
   * 2024.03.08 [energysteel]: API 수정 API
   * @param api 수정 대상 API
   */
  const handleModifyApi = (api) => {
    const modifyApiRequest = {
      apiName: inputKey,
      projectId: project.id,
      upperId: api.upperId,
      id: api.id,
    }

    modifyApiApi(
      axios,
      project.id,
      modifyApiRequest,
      (response) => saveOrModifyApiSuccessCallback(response, api),
    );
  }

  // 2024.05.19 [shiningtrue]: throttle
  const handleModifyApiThrottled = _.throttle((api) => {
    handleModifyApi(api);
  }, 150);

  /**
   * 2024.03.07 [energysteel]: API 우측 Dropdown Handler
   *  - apiDropdownClickAddChildApi: Package인 경우만 선택 가능
   * @param clickApi Dropdown을 클릭한 API 정보
   * @returns {{items: *}}
   */
  const useApiDropdownHandle = (clickApi) => {
    return useApiDropdown(
      clickApi,
      () => apiDropdownClickAddChildApi(clickApi),
      () => apiDropdownClickChangeName(clickApi.key),
      () => apiCopy(),
      () => apiDropdownClickDelete(clickApi),
    );
  }

  /**
   * 2024.03.07 [energysteel]: API 우측 Dropdown > API 추가 onClick Event
   * @param clickApi 현재 클릭한 Package API (Parent)
   */
  const apiDropdownClickAddChildApi = (clickApi) => {
    const newApi = {
      key: findChildrenKey(clickApi.key),
      title: '',
      // 2024.03.07 [energysteel]: API Depth는 최대 2로, Children API는 Package가 될 수 없음
      packageYn: false,
      isLeaf: true,
      // 2024.03.07 [energysteel]: 생성 후 이름 수정을 위한 Input 활성화
      update: true,
      newData: true,
      search: true,
      children: [],
    };

    // 2024.03.07 [energysteel]: Children API가 새롭게 추가되는 Parent에게 Expanded 설정
    setExpandedKeys(prev => {
      return prev.map(key => {
        return [...prev, clickApi.key];
      })
    })

    // 2024.03.07 [energysteel]: 신규 API를 Parent API의 Children로 추가
    const newTreeData = treeData.map(api => {
      if (api.key === clickApi.key) {
        return {
          ...api,
          children: [
            ...api.children,
            {
              ...newApi,
              upperId: api.id,
            }
          ],
        };
      }
      return api;
    });

    setTreeData(newTreeData);
  }

  /**
   * 2024.03.07 [energysteel]: Parent의 Key를 확인하여 새롭게 추가되는 Children API의 Key를 부여
   *  - if  : ParentKey가 TreeData 안에 존재하고 이미 Children Property가 있다면
   *          Key 값을 `0-0-0` 형태로 마지막 숫자에 Children 수 만큼의 값을 부여
   *  - else: ParentKey가 TreeData 안에 존재하지 않거나 Children Property가 없다면
   *          Key 값을 `0-0-0` 형태로 마지막 숫자에 첫번째 Children의 의미로 0을 부여
   * @param parentKey 클릭한 API의 Key (추가 될 API의 ParentKey)
   * @returns {string}
   */
  const findChildrenKey = (parentKey) => {
    let children = treeData?.find(v => v.key === parentKey)?.children;
    if (children) {
      const childrenTree = children[children.length - 1];

      if (!childrenTree) {
        return `${parentKey}-0`;
      }

      const lastOrder = Number(childrenTree.key.split("-").pop()) + 1;
      return `${parentKey}-${lastOrder}`;
    } else {
      return `${parentKey}-0`;
    }
  }

  /**
   * 2024.03.07 [energysteel]: API 우측 Dropdown > 이름 변경 onClick Event
   * @param clickApiKey 클릭한 API Key
   */
  const apiDropdownClickChangeName = (clickApiKey) => {
    setTreeData(prev => {
      return enableUpdate(prev, clickApiKey);
    })
  }

  /**
   * 2024.03.07 [energysteel]: API 목록에서 자신의 Key를 통해
   *                           재귀적으로 전체 Tree 순회를 하며 Input 활성화
   * @param apis API 목록
   * @param key API 자기 자신의 Key
   * @returns {*}
   */
  const enableUpdate = (apis, key) => {
    return apis.map(value => {
      if (value.key === key) {
        return {...value, update: true};
      } else if (value.children) {
        return {...value, children: enableUpdate(value.children, key)};
      }
      return value;
    });
  }

  /**
   * 2024.03.07 [energysteel]: 클릭한 API의 삭제 API Request 객체를 생성하고, 삭제 Modal 생성
   * @param clickApi 삭제 대상 API
   *
   * @see ApiDeleteModal#handleOk
   * @desc ApiDeleteModal#handleOk 삭제 Modal의 삭제 버튼 onClick Event
   */
  const apiDropdownClickDelete = (clickApi) => {
    const deleteApiRequest = {
      apiId: clickApi.prevApiId ?? clickApi.id,
      projectId: project.id,
    }

    setDeleteApiRequest(deleteApiRequest);
    setDeleteShowModal(true);
  }

  /**
   * 2024.05.01 [energysteel]: 붙여넣기 한 API 삭제
   * @param clickApi 삭제 대상 API
   *
   */
  const apiPasteUndo = (clickApi) => {
    const deleteApiRequest = {
      apiId: clickApi.id,
      projectId: project.id,
    }

    setDeleteApiRequest(deleteApiRequest);
    deleteApiApi(
      axios,
      project.id,
      deleteApiRequest,
      deleteApiApiSuccessCallbackForUndo,
      deleteApiApiFailCallback,
    );
  }


  const deleteApiApiSuccessCallback = (response) => {
    const copyTreeData = deepCopy(treeData);
    const newTree = handleIdCheck(treeData, response.data.data.id);

    const deletedNode = loadTreeDataByApiId(treeData, response.data.data.id);
    const filteredTab = tabOpenList.filter(tab =>
      tab?.id !== response?.data?.data?.id && tab?.id !== deletedNode?.id &&
      !deletedNode?.children.some(child => tab?.id === child?.id)
    );

    setTabOpenList(prev => {
      return filteredTab;
    })

    const projectId = project.id;

    setTabRecoilPersistState(prev => {
      if (filteredTab != null && filteredTab.length > 0) {
        return {
          ...prev,
          [projectId]: filteredTab.map(tab => tab.id),
        }
      } else {
        const persistTab = {...prev};
        delete persistTab[projectId];

        return persistTab;
      }

    })

    setDeleteShowModal(false);
    setSelectedApi(filteredTab[filteredTab.length - 1] ?? {});
    setCheckedKey([loadTreeDataByApiId(treeData, filteredTab[filteredTab.length - 1]?.id)?.key] ?? []);

    rotateTree(newTree);
    handleModifyApiOrder(
      copyTreeData,
      newTree,
    );
  }

  const deleteApiApiFailCallback = () => {
    setHelpIconMessage({
      code: "error",
      message: "이미 삭제된 API입니다.",
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }

  const deleteApiApiSuccessCallbackForUndo = (response) => {
    deleteApiApiSuccessCallback(response);
    const stack = [...copyApiStack];
    stack.pop();

    setCopyApiStack(stack);
  }

  const handleIdCheck = (data, id) => {
    const filteredData = data.filter(item => item.id !== id);
    filteredData.forEach((item) => {
      if (item?.children?.length > 0) {
        item.children = handleIdCheck(item.children, id);
      }
    });
    return filteredData;
  };

  /**
   * 2024.03.08 [energysteel]: API 저장/수정 onKeyDown(Keyboard Enter), onBlur Event Handler
   *  - if  :
   *      case1: TreeData 내 API가 존재하지 않는다면 저장 (상단 + 버튼을 통한 추가)
   *      case2: TreeData 내 API가 존재하지만 신규 데이터인 경우 저장 (API Dropdown을 통한 추가)
   *    else: TreeData 내 이미 존재하는 API라면 수정
   * @param event Event
   * @param key 저장/수정 대상 API Key
   */
  const handleSaveOrModify = (event, key) => {
    event.stopPropagation();

    if (inputKey === "") {
      setNewData(false);
      const data = removeObjectByKey(key);
      setTreeData(data);
      return;
    }

    let api = loadTreeDataByApiKey(key);

    if (!api || api?.newData) {
      handleSaveApiThrottled(api, key);
    } else {
      handleModifyApiThrottled(api);
    }

    // 2024.03.08 [energysteel]: + 버튼을 통한 API 등록 Input 비활성화
    setNewData(false);
    setInputKey("");
  }

  const removeObjectByKey = (keyToRemove) => {
    const filterData = (items) => {
      return items.filter(item => {
        if (item.key === keyToRemove) {
          return false;
        } else if (item.children && item.children.length > 0) {
          item.children = filterData(item.children);
        }
        return true;
      });
    };

    return filterData(treeData);
  };


  /**
   * 2024.03.08 [energysteel]: API Key를 통해 TreeData 내 API를 조회
   * @param key 저장/수정 대상 API Key
   * @returns {*}
   */
  const loadTreeDataByApiKey = (key) => {
    // 2024.03.08 [energysteel]: key 찾기
    let api = treeData.find(v => v.key === key);
    // 2024.03.08 [energysteel]: 없는 경우 자식 노드에서 key 찾기
    if (!api) {
      const parentNode = treeData.find(v => v.key === findParentKey(key));
      api = parentNode?.children.find(v => v.key === key);
    }
    return api;
  }


  /**
   * 2024.03.08 [energysteel]: API ID를 통해 TreeData 내 API를 조회
   *  - API ID 가 없는 경우도 리턴
   * @param apis 전체 API
   * @param id 대상 API ID
   * @returns {*}
   */
  const loadTreeDataByApiId = (apis, id) => {
    for (let i = 0; i < apis.length; i++) {
      if (!apis[i].id || apis[i].id === id) {
        return apis[i];
      }
      if (apis[i].children) {
        const result = loadTreeDataByApiId(apis[i].children, id);
        if (result !== undefined) {
          return result;
        }
      }
    }
  }

  /**
   * 2024.03.08 [energysteel]: Parent API의 Key 조회
   *  - Children Key `0-0-0` 의 앞부분 `0-0` 이 Parent Key
   * @param childrenKey API의 Key
   * @returns {string}
   */
  const findParentKey = (childrenKey) => {
    const lastIndex = childrenKey.lastIndexOf("-");
    return childrenKey.substring(0, lastIndex);
  }

  /**
   * 2024.03.08 [energysteel]: package 여부 확인
   * @param api 대상 API
   * @returns {boolean}
   */
  const isPackage = (api) => {
    let packageYn = isGroup;
    if (api?.newData) {
      packageYn = false;
    }
    return packageYn;
  }

  /**
   * 2024.03.08 [energysteel]: 저장/수정 API 성공 Callback
   * @param response API 응답 값
   * @param api API Object 있으면 Update, 없으면 Insert
   */
  const saveOrModifyApiSuccessCallback = (response, api) => {
    const data = response.data.data;
    const search = data.apiName.replace(/ /g, "").toLowerCase().includes(inputValue);

    setSelectedApi(prev => {
      if (data.packageYn) {
        return {
          ...prev,
          title: data.apiName,
          search: search,
        }
      }
      return {
        ...data,
        title: data.apiName,
        search: search,
      }
    })

    setTabOpenList(prev => {
      return prev.map(tab => {
        if (tab.id === data.id) {
          return {
            ...tab,
            api: {
              ...tab.api,
              apiName: data.apiName
            },
            uri: {
              ...tab.uri,
              search: search,
            },
          }
        }

        return tab;
      })
    })

    setTreeData(prev => {
      if (!api) {
        return [
          ...prev,
          {
            id: data.id,
            key: data.upperId ?
              `0-${treeData.find(tree => tree.id === data.upperId).key.split("-").pop()}-${data.order}` :
              `0-${data.order}`,
            title: data.apiName,
            packageYn: data.packageYn,
            isLeaf: !data.packageYn,
            update: false,
            search: search,
            upperId: data.upperId,
            children: [],
          },
        ];
      } else {
        return disableUpdate(prev, data.id, data.upperId ?
          `0-${treeData.find(tree => tree.id === data.upperId).key.split("-").pop()}-${data.order}` :
          `0-${data.order}`
        );
      }
    })
  }

  /**
   * 2024.03.08 [energysteel]: 저장/수정 API 성공 후 Input 비활성화
   *  - API Dropdown을 통한 저장/수정인 경우
   * @param apis TreeData 내 API 목록
   * @param id API ID
   * @param key 조회할 Key
   * @returns {*}
   */
  const disableUpdate = (apis, id, key) => {
    return apis.map(api => {
      if (api.key === key) {
        return {...api, id: id, title: inputKey, newData: false, update: false,};
      } else if (api.children) {
        return {...api, children: disableUpdate(api.children, id, key)};
      }
      return api;
    });
  }

  const alertError = () => {
    setHelpIconMessage({
      code: "error",
      message: "잠시 후 다시 시도해주세요.",
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }

  /**
   * 2024.03.08 [energysteel]: Drag & Drop Event
   * @param info Drag & Drop 상태
   */
  const handleDragAndDrop = (info) => {
    if (orderModifyFlag) {
      alertError();

      return;
    }

    setOrderModifyFlag(true);

    const apis = [...treeData];
    const copyTreeData = deepCopy(treeData);

    // 2024.03.08 [energysteel]: Drag & Drop 실행 이상 발생 시 종료
    if (!dragAndDropAndFailExit(info, apis)) {
      alertError();
      setOrderModifyFlag(false);

      return;
    }

    rotateTree(apis);
    refreshExpanded(apis, info);
    handleModifyApiOrder(
      copyTreeData,
      apis,
      modifyApiOrderApiSuccessCallback,
      modifyApiOrderApiFailCallback,
    );
  }

  const modifyApiOrderApiSuccessCallback = () => {
    setOrderModifyFlag(false);
  }

  const modifyApiOrderApiFailCallback = () => {
    setOrderModifyFlag(false);
  }

  /**
   * 2024.03.08 [energysteel]: spliceStartIndex에 맞는 위치에 Drag 노드 삽입
   * @param apis 전체 API 데이터
   * @param dragNode Drag 노드 정보
   * @param dropNode Drop 노드 정보
   * @param spliceStartIndex splice 시작 Index
   * @param nodeMoveToTop 최상단 API로 이동하는지 여부
   */
  const insertNodeAtPosition = (apis, dragNode, dropNode, spliceStartIndex, nodeMoveToTop) => {
    if (nodeMoveToTop) {
      dragNode.key = dropNode.key;
    } else {
      spliceStartIndex++;
    }

    const newDragNode = {...dragNode};
    newDragNode.upperId = dropNode.upperId;
    apis.splice(spliceStartIndex, 0, newDragNode);
  }

  /**
   * 2024.03.08 [energysteel]: Drag & Drop 실행. 이상 있는 경우 함수 종료
   * @param info Drag, Drop 정보
   * @param apis API Tree에 노출되는 API 리스트
   * @returns {boolean}
   */
  const dragAndDropAndFailExit = (info, apis) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    let findNodeCallback = findNodeAndCallback();

    // 2024.03.08 [energysteel]: apis 배열에서 Drag 노드 제거 후 반환한 임시 저장 변수
    let dragNode = extractDragNode(findNodeCallback, apis, dragKey);

    // 2024.03.08 [energysteel]: Package 최상단으로 이동 한 경우
    let isNodeMoveToParentTop = !info.dropToGap;
    if (isNodeMoveToParentTop) {
      // 2024.03.08 [energysteel]: Drag 노드를 Parent 노드의 최상위 노드로 이동, 실패 시 함수 종료
      if (nodeMoveToParentTopAndFailExit(findNodeCallback, info, dragNode, apis)) {
        // 2024.03.08 [energysteel]: 잘못된 이동을 한 경우 Drag 노드 원복
        restoreDeletedDragNode(apis, dragNode);
        return false;
      }
    } else {
      // 2024.04.09 [energysteel]: 패키지 하위에 패키지 넣는것을 막음
      if (dragNode.packageYn && info.node.upperId) {
        return false;
      }
      findNodeCallback(apis, dropKey, (dropNode, index, arr) => {
        // 2024.03.08 [energysteel]: 최상단 노드로 이동한 경우
        let nodeMoveToTop = dropPosition === -1;
        insertNodeAtPosition(arr, dragNode, dropNode, index, nodeMoveToTop);
      });
    }

    return true;
  }

  /**
   * 전체 Array에서 Drag 노드를 삭제하고 (Callback), Drag 노드를 반환
   * @param loop 전체 Array에서 대상을 찾는 함수
   * @param arr 전체 Array
   * @param dragKey 전체 Array에서 찾을 대상 Key (Drag 노드)
   * @param #dragNodeDeleteCallback.node Drag 노드
   * @param #dragNodeDeleteCallback.index Array 내의 Drag 노드 Index
   * @param #dragNodeDeleteCallback.arr TreeData
   * @returns {*}
   */
  const extractDragNode = (loop, arr, dragKey) => {
    let dragNode;

    const dragNodeDeleteCallback = (node, index, arr) => {
      arr.splice(index, 1);
      dragNode = node;
    }

    loop(arr, dragKey, dragNodeDeleteCallback);
    return dragNode;
  }

  /**
   * 2024.03.08 [energysteel]: Array에서 Key를 기준으로 Node를 찾은 후 Callback 실행
   * @param #loop.arr 전체 Data
   * @param #loop.key 찾으려는 Node Key
   * @param #loop.callback Node를 찾은 후 수행될 Callback
   * @returns {(function(*, *, *): (*|undefined))|*}
   */
  const findNodeAndCallback = () => {
    const loop = (arr, key, callback) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === key) {
          return callback(arr[i], i, arr);
        }
        if (arr[i].children) {
          loop(arr[i].children, key, callback, arr);
        }
      }
    };
    return loop;
  }

  /**
   * 2024.03.08 [energysteel]: Drag 노드를 Parent 노드의 최상위 노드로 이동
   *  - API Tree Depth는 `Parent - Children` 구조로 2 Depth 까지만 허용
   * @param info Drag Drop 정보
   * @param dragNode Drag 노드
   * @param loop 전체 Array에서 대상을 찾는 함수
   * @param apis 전체 Tree Data
   * @returns {boolean} Package가 Package 하위로 이동 시 false (Drag & Drop 종료)
   */
  const nodeMoveToParentTopAndFailExit = (loop, info, dragNode, apis) => {
    let terminateFlag = true;
    const dropKey = info.node.key;

    // 2024.03.08 [energysteel]: Drop 노드를 찾은 후 Drag 노드를 children으로 등록
    loop(apis, dropKey, (dropNode) => {
        dropNode.children = dropNode.children || [];

        // 2024.03.08 [energysteel]: Package로 이동한 경우 0번 노드에 추가
        // Drag 노드는 패키지일 수 없고, Drop 노드는 패키지여야 추가
        if (dragNode && !dragNode.packageYn && dropNode.packageYn) {
          dragNode.upperId = dropNode.id;
          dragNode.key = `${info.node.pos}-0`;

          dropNode.children.unshift(dragNode);
          terminateFlag = false;
          // 2024.03.08 [energysteel]: Package 하위에 또 다른 Package로써 이동 불가
        } else {
          terminateFlag = true;
        }
      }
    );

    return terminateFlag;
  }

  /**
   * 2024.03.08 [energysteel]: 비정상적인 이동을 한 경우 이미 삭제된 Drag 노드 원상 복구
   *  - if   : Drag 노드가 Children이 아닌 경우
   *    else : Drag 노드가 Children인 경우
   * @param apis 전체 API 목록
   * @param dragNode Drag 노드
   */
  const restoreDeletedDragNode = (apis, dragNode) => {
    const dragPos = dragNode.key.split("-");
    if (dragPos.length === 2) {
      apis.splice(dragPos.pop(), 0, dragNode);
    } else {
      const preLastValue = dragPos[dragPos.length - 2];
      apis[preLastValue].children.splice(dragPos.pop(), 0, dragNode);
    }
  }

  /**
   * 2024.03.08 [energysteel]: 정렬되어있는 순번을 기준으로 Key 재조립
   * @param apis 전체 API Tree 데이터
   */
  const rotateTree = (apis) => {
    apis.forEach((value, index) => {
      const newApi = {...value, key: `0-${index}`};
      if (value.children && value.children.length > 0) {
        newApi.children = value.children.map((childValue, childIndex) => ({
          ...childValue,
          key: `0-${index}-${childIndex}`
        }));
      }
      apis[index] = newApi;
    });
  }

  /**
   * 2024.03.15 [energysteel]: Tree Data 위치 변경에 따른 확장 대상 Key 변경
   * @param apis API Tree 전체 데이터
   * @param info Drag & Drop 정보
   */
  const refreshExpanded = (apis, info) => {
    let updatedExpandedKeys = apis.filter(api => api.expanded)
      .map(api => api.key);

    if (!info.dropToGap) {
      const api = apis.find(api => api.id === info.node.id);
      updatedExpandedKeys = [...updatedExpandedKeys, api?.key];
    } else {
      const api = apis.find(api => api.id === info.node.upperId)
      if (api) {
        updatedExpandedKeys = [...updatedExpandedKeys, api?.key];
      }
    }

    expandTree(apis, [...new Set(updatedExpandedKeys)]);
  }

  /**
   * 2024.03.08 [energysteel]: API Tree Order 변경 Handler
   * @param originalTreeData Tree 원본 데이터
   * @param modifyTreeData Drag & Drop 후 변경 된 데이터
   * @param successCallback API 성공 Callback
   * @param failCallback API 실패 Callback
   */
  const handleModifyApiOrder = (
    originalTreeData,
    modifyTreeData,
    successCallback = () => {},
    failCallback = () => {},
  ) => {
    const differentTreeData = findDifferent(originalTreeData, modifyTreeData);
    const modifyApiRequest = createModifyRequest(differentTreeData);

    setTreeData(modifyTreeData);

    if (modifyApiRequest.length === 0) {
      setOrderModifyFlag(false);
      return;
    }

    modifyApiOrderApi(
      axios,
      project.id,
      modifyApiRequest,
      successCallback,
      failCallback,
    );
  }

  /**
   * 2024.03.08 [energysteel]: API Tree onClick Event
   *  - 선택한 API 정보 수집
   *  - 선택한 API가 Package인 경우 기존 선택한 API는 유지한채로 Package인것만을 수집
   * @param event onClick Event (미사용)
   * @param api 클릭한 API 정보
   */
  const handleSelectedApi = (event, api) => {
    if (!api.packageYn) {
      setSelectedApi(api);

      setTabOpenList(prev => {
        return prev.map(item => {
          if (item.id === api.id) {
            return {
              ...item,
              isActive: true,
            }
          } else {
            return {
              ...item,
              isActive: false,
            }
          }
        })
      })

      return;
    }
    
    setSelectedApi(prev => {
      return {
        ...prev,
        packageYn: true,
        prevApiId: treeData.find(tree => tree.id === api.id)?.id,
      }
    })

    setExpandedKeys(prev => {
      return prev.includes(api.key) ?
        prev :
        [...prev, api.key];
    });
  }

  /**
   * 2024.03.08 [energysteel]: API 이름 수정 onChange Event
   * @param changeTitle 변경중인 API 이름
   */
  const handleModifyApiNameOnChange = (changeTitle) => {
    setInputKey(changeTitle);
  }

  /**
   * 2024.03.08 [energysteel]: Package API 좌측 `v` 버튼을 클릭하여
   * Tree 확장/축소 Event (API 추가로 인한 확장은 해당하지 않음)
   * @param expandedKeys 확장되어있는 API Key
   */
  const handleExpand = (expandedKeys) => {
    expandTree(treeData, expandedKeys);
  }

  const expandTree = (apis, expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setTreeData(() => {
      return apis.map(tree => {
        if (expandedKeys.includes(tree.key)) {
          return {
            ...tree,
            expanded: true,
          }
        }
        return tree;
      })
    })
  }

  const handleOnSelect = (checkedKeys) => {
    setCheckedKey(prev => {
      if (checkedKeys.length === 0) {
        return prev;
      } else {
        return checkedKeys;
      }
    });
  }

  /**
   * 2024.04.18 [energysteel]: API Tree onKeyDown Event
   *  1. API 복사, 붙여넣기 onKeyDown Event (단건만 가능)
   *    - Ctrl + C (Command + C): 복사
   *    - Ctrl + V (Command + V): 붙여넣기
   *    - Ctrl + Z (Command + Z): 붙여넣기 Undo
   *  2. API 이름 수정
   *    - F2
   *  3. API 삭제 Modal
   *    - Delete
   * @param event
   * @return {boolean}
   */
  const handleKeyboardEvent = (event) => {
    const copyKey = "c";
    const pasteKey = "v";
    const undoKey = "z";
    const renameKey = "F2";
    const deleteKey = "Delete";

    handleApiAction(
      ((event.ctrlKey || event.metaKey) && event.key === copyKey) && !selectedApi.packageYn,
      () => apiCopy(event),
    );

    handleApiAction(
      (event.ctrlKey || event.metaKey) && event.key === pasteKey,
      () => apiPaste(event),
    );

    handleApiAction(
      event.key === renameKey,
      () => apiDropdownClickChangeName(checkedKey[0]),
    );

    handleApiAction(
      event.key === deleteKey,
      () => apiDropdownClickDelete(selectedApi),
    );

    handleApiAction(
      (event.ctrlKey || event.metaKey) && event.key === undoKey,
      () => {
        if (copyApiStack.length > 0) {
          const apiId = copyApiStack[copyApiStack.length - 1];
          const api = loadTreeDataByApiId(treeData, apiId);
          if (api) {
            apiPasteUndo(api);
          }
        }
      },
    );
  }

  const handleApiAction = (condition, action) => {
    if (condition && isApiAuthority(project.authority)) {
      action();
    }
  }

  /**
   * 2024.05.02 [energysteel]: API Double Click Event
   *  - API 이름 Input으로 변경 (이름 변경)
   * @return {string}
   */
  const handleDoubleClickEvent = () => {
    apiDropdownClickChangeName(checkedKey[0]);
  }

  /**
   * 2024.05.02 [energysteel]:
   * @return {string}
   */
  const findLastTreeOrder = () => {
    const tree = treeData[treeData.length - 1];
    if (!tree) {
      return "0-0";
    }

    const lastOrder = Number(tree.key.split("-").pop()) + 1;
    return `0-${lastOrder}`
  }

  /**
   * 2024.04.18 [energysteel]: Tree 최하단으로 Scroll
   * @param timeout Timeout 시간 (ms)
   */
  useEffect(() => {
    if (treeRef.current) {
      setTimeout(() => {
        treeRef.current?.scrollTo(
          {
            key: treeData[treeData.length - 1]?.key,
            align: "top",
          }
        );
      }, 500)
    }
  }, [treeData.length])

  /**
   * 2024.05.02 [energysteel]: API save, update Keyboard Event (Enter, Escape)
   * @param event event
   * @param key API Key
   */
  const handleKeyboardEnter = (event, key) => {
    event.stopPropagation();

    // 2024.05.08 [energysteel]: 한글 특성 상 문자 조합으로 인한 중복 방지
    if (event.isComposing || event.keyCode === 229) {
      return;
    }

    if (event.key === 'Enter' || event.key === 'Escape') {
      handleSaveOrModify(event, key)
    }
  }


  /**
   * 2024.03.08 [energysteel]: API Tree 수정 시 Input 전환, Dropdown 렌더링
   * @param api 단건 API 정보
   * @returns {Element}
   */
  const titleRender = (api) => {
    return (
      <>
        {inputKey !== api.key &&
          <div
            tabIndex={0}
            className="sider jc-sb"
            onKeyDown={handleKeyboardEvent}
            onDoubleClick={handleDoubleClickEvent}
          >
            {api.newData
              ?
              <div>
                <div
                  className="content Body6_R g500"
                  style={{
                    height: 32,
                    width: "100%",
                    padding: "7px 30px",
                    marginLeft: -5,
                  }}
                >
                  <div
                    className={`api-title-get`}
                    style={{verticalAlign: "middle", marginLeft: "-24px"}}
                  >
                    GET
                  </div>
                  <Input
                    ref={(updateInputRef) => updateInputRef?.focus()}
                    style={{
                      display: "inline-block",
                      width: 160,
                      height: 28,
                      caretColor: "#7c60ff",
                      paddingLeft: 5,
                      marginLeft: -2,
                    }}
                    defaultValue={() => {
                      setInputKey(api.title);
                      return api.title
                    }}
                    placeholder={"입력해주세요."}
                    onKeyDown={(event) => handleKeyboardEnter(event, api.key)}
                    onBlur={(event) => handleSaveOrModify(event, api.key)}
                    className="Body6_R g500 bg-white br3 b-g300"
                    onChange={(event) => handleModifyApiNameOnChange(event.target.value)}
                  />
                </div>
              </div>
              :
              api.update ?
                <div>
                  <Input
                    ref={(updateInputRef) => updateInputRef?.focus()}
                    style={{
                      display: "inline-block",
                      width: 160,
                      height: 28,
                      caretColor: "#7c60ff",
                      paddingLeft: 5,
                    }}
                    defaultValue={() => {
                      setInputKey(api.title);
                      return api.title
                    }}
                    placeholder={"API 이름을 입력해주세요."}
                    onKeyDown={(event) => handleKeyboardEnter(event, api.key)}
                    onBlur={(event) => handleSaveOrModify(event, api.key)}
                    className="Body6_R g500 bg-white br3 b-g300"
                    onChange={(event) => handleModifyApiNameOnChange(event.target.value)}
                  />
                </div>
                :
                <div>
                  {api.packageYn ?
                    <div
                      className={`ellipsis api-title`}
                      style={{verticalAlign: "middle"}}
                    >
                      <Image
                        className="mb-2"
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_pkg"}
                      />
                    </div>
                    :
                    <div
                      className={`ellipsis api-title-${api.httpMethod?.toLowerCase() ?? "get"}`}
                      style={{verticalAlign: "middle"}}
                    >
                      {api.httpMethod === "OPTIONS"
                        ? "OPT"
                        : api.httpMethod === "DELETE"
                          ? "DEL"
                          : api.httpMethod ?? "GET"}
                    </div>
                  }
                  <div
                    className={`ellipsis-mw${api.upperId ? 140 : 182}`}
                    style={{verticalAlign: "middle"}}
                  >
                    {api.title}
                  </div>
                </div>
            }
            {/* 2024.05.01 [energysteel]:
            API 우측 Dropdown 메뉴
            AGENT, ADMIN, OWNER 권한만 &&
            신규 데이터 생성중에 보이지 않게
             */}
            {isApiAuthority(project.authority) &&
              (!api.newData && !newData) &&
              <div className="IconTest">
                <Dropdown
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  menu={useApiDropdownHandle(api)}
                  placement="bottomRight"
                  overlayStyle={{
                    width: "156px",
                    height: "146px",
                  }}
                  onClick={(e) => e.stopPropagation()}
                  trigger={["click"]}
                >
                  <Space style={{lineHeight: "16px"}}>
                    <Image
                      className="mb-4"
                      path={`${process.env.PUBLIC_URL}/header`}
                      name={"ic_more"}
                    />
                  </Space>
                </Dropdown>
              </div>
            }
          </div>
        }
      </>
    );
  }


  return (
    <>
      {newData && (
        <div>
          <div
            className="content Body6_R g500"
            style={{
              height: 32,
              width: "100%",
              padding: "7px 30px",
            }}
          >
            {isGroup ?
              <Image
                className="mb-2"
                style={{verticalAlign: "middle", marginLeft: "35px"}}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_pkg"}
              />
              :
              <div
                className={`api-title-get`}
                style={{verticalAlign: "middle", marginLeft: "12px"}}
              >
              GET
              </div>
            }
            <Input
              ref={(saveInputRef) => saveInputRef?.focus()}
              style={{
                display: "inline-block",
                width: 206,
                height: 28,
                caretColor: "#7c60ff",
                paddingLeft: 5,
                marginLeft: !isGroup ? -5 : 8,
              }}
              placeholder={!isGroup ? "입력해주세요." : "입력해주세요."}
              onKeyDown={(event) => handleKeyboardEnter(event, findLastTreeOrder())}
              onBlur={(event) => handleSaveOrModify(event, findLastTreeOrder())}
              className="Body6_R g500 bg-white br3 b-g300"
              onChange={(e) => handleModifyApiNameOnChange(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="table-overflow-auto -draggable">
        <ConfigProvider
          theme={{
            components: {
              Tree: {
                nodeSelectedBg: "#dde2ee",
                nodeHoverBg: "none",
                titleHeight: 32,
              },
            },
            token: {
              colorText: "#636c83",
              colorBgContainer: "#eef1f7",
            },
          }}
        >
          <Tree
            ref={treeRef}
            treeData={filteringTreeData(treeData)}
            height={1130}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            selectedKeys={checkedKey}
            onSelect={handleOnSelect}
            className="draggable-tree"
            switcherIcon={<DownOutlined/>}
            blockNode
            draggable={isApiAuthority(project.authority) ?
              {
                icon: (
                  <Image
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_move"}
                  />
                ),
              } :
              false
            }
            titleRender={titleRender}
            onDrop={handleDragAndDrop}
            onClick={handleSelectedApi}
            onBlur={(event) => handleSaveOrModify(event, findLastTreeOrder())}
          >
          </Tree>
        </ConfigProvider>
      </div>
      {
        deleteShowModal &&
        <ApiDeleteModal
          deleteApiRequest={deleteApiRequest}
          setDeleteApiRequest={setDeleteApiRequest}
          deleteApiApiSuccessCallback={deleteApiApiSuccessCallback}
          deleteApiApiFailCallback={deleteApiApiFailCallback}
        />
      }
    </>
  );
};

export default APISubSider;
