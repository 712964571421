import React from "react";


const ResponseHeaderChild = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>API Request Header 설정과 동일합니다.</p><br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_1.png`}
            alt={"example_head"}
            width={792}
            height={512}
          />
          <br/>
          <br/>
        </div>
      </div>
    </>
  )
}

const ResponseParameterChild = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>Response Parameter 는 응답 규격의 Template 을 생성하는 단계입니다.</p>
        <p>사용자는 응답 규격의 Key와 Value의 Data Type을 등록합니다.<br/>
        </p>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_2.png`}
            alt={"example_head"}
            width={792}
            height={433}
          />
          <p>API가 없으면 먼저 프로젝트의 API를 생성합니다.</p>
        </div>
      </div>
    </>
  )
}


const ResponseTestCaseChild = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>등록된 Response Parameter의 실제 응닶을 설정하는 단계 입니다.</p>
        <p>MockBot 이 단계를 Test Case 등록단계로 구분합니다</p>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_3.png`}
            alt={"example_head"}
            width={792}
            height={340}
          />
          <br/>
          <br/>
        </div>
        <p>Test Case는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
        <p>1. Test Case Name: 테스트 케이스의 이름을 입력합니다.</p><br/>
        <p>2. Response Type: 응답 유형을 선택합니다.(정상응답/에러응답 중 선택할 수 있습니다)</p><br/>
        <p>3. Default Response: API요청의 기본 응답을 선택합니다.(한 개만 선택 가능합니다)</p><br/>
        <br/>
      </div>
    </>
  )
}

const ResponseTestCaseResultChild = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>정상 응답 설정</p>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_4.png`}
            alt={"example_head"}
            width={792}
            height={435}
          />
          <br/>
          <br/>
        </div>
        <p>정상 응답에 대한 테스트 케이스 등록입니다.</p><br/>
        <p>- Response Parameter에 등록된 Parameter명으로 자동 생성됩니다.</p><br/>
        <p>- API 호출 후 정상 응답을 통해 받을 값을 입력합니다.</p><br/>
        <p>- 저장 버튼을 클릭하고 API를 요청하면 저장된 값으로 응답받을 수 있습니다.</p><br/>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_5.png`}
            alt={"example_head"}
            width={792}
            height={435}
          />
          <br/>
          <br/>
        </div>
        <p>오류 응답에 공통 에러 코드 적용 테스트 케이스 등록입니다.</p><br/>
        <p>- <a href={'#3-1-1'} onClick={() => setManualKey("3-1")}>공통 에러</a>에서 등록한 에러를 선택하여 등록할 수 있습니다.</p><br/>
        <p>- 저장 버튼을 클릭하고 API를 요청하면 <a href={'#3-1-1'} onClick={() => setManualKey("3-1")}>공통 에러</a>에서 저장된 값으로 응답받을 수
          있습니다.</p><br/>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_response_6.png`}
            alt={"example_head"}
            width={792}
            height={528}
          />
          <br/>
          <br/>
        </div>
        <p>오류 응답에 사용자 정의 에러 코드 적용 테스트 케이스 등록입니다.</p><br/>
        <p>- 화면에 직접 오류 코드와 메시지를 등록합니다.</p><br/>
        <p>- 저장 버튼을 클릭하고 API를 요청하면 저장된 응답 에러로 응답받을 수 있습니다.</p><br/>
        <br/>
        <br/>
      </div>
    </>
  )
}

export { ResponseHeaderChild, ResponseParameterChild, ResponseTestCaseChild, ResponseTestCaseResultChild };