import {Modal} from "antd";
import CreateProjectTable from "../../molecules/tables/CreateProjectTable";
import ModalHeadline from "../../atoms/ModalHeadline";
import Title from "../../molecules/modals/Title";
import {useRecoilState} from "recoil";
import {
  pageState,
  projectListState,
  projectValidationErrorsState,
  saveProjectRequestObject,
  saveProjectRequestState,
} from "../../../recoil/project/projectState";
import {
  importApi,
  loadProjectsApi,
  loadProjectsApiSuccessCallback,
  saveProjectApi
} from "../../../api/project/projectApi";
import useAxiosInterceptor from "../../../axios/axios";
import {toast} from "react-toastify";
import React, {useRef} from "react";
import Dialogs from "../../molecules/modals/Dialogs";
import _ from "lodash";

const CreateProjectModal = ({ isCreateModalOpen, setIsCreateModalOpen, importLoading, setImportLoading }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 프로젝트 저장 객체
  const [saveProjectRequest, setSaveProjectRequest] = useRecoilState(saveProjectRequestState);
  const [page, setPage] = useRecoilState(pageState);
  const [, setValidationErrors] = useRecoilState(projectValidationErrorsState);
  const [, setProjectList] = useRecoilState(projectListState);


  /**
   * 2024.03.06 [energysteel]: 프로젝트 저장
   */
  const handleSaveProject = () => {
    if (!validateField()) {
      return;
    }

    saveProjectApi(
        axios,
        saveProjectRequest,
        saveProjectApiSuccessCallback,
        failCallback,
    );
  };

  // 2024.05.19 [shiningtrue]: throttle
  const handleSaveProjectThrottled = _.debounce(handleSaveProject, 200);

  const validateField = () => {
    if (!saveProjectRequest.name || saveProjectRequest.name?.trim() === '') {
      blankField("name");
      return false;
    }

    if (!saveProjectRequest.context || saveProjectRequest.context?.trim() === '') {
      blankField("context");
      return false;
    }

    if (!saveProjectRequest.accessAuthority || saveProjectRequest.accessAuthority?.trim() === '') {
      blankField("accessAuthority");
      return false;
    }

    return true;
  };

  const blankField = (name) => {
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [name]: true,
    }));
  }

  // 2024.03.06 [energysteel]: 프로젝트 조회 객체
  const loadProjectRequest = {
    pageNumber: 0,
    pageSize: page.pageSize,
    searchWord: "",
    searchCategories: "ALL",
  };


  /**
   * 2024.03.06 [energysteel]: 프로젝트 저장 성공 Callback
   *  - saveProjectRequest 초기화
   *  - 0 페이지로 초기화 (프로젝트 리 랜더링)
   */
  const saveProjectApiSuccessCallback = () => {
    setSaveProjectRequest(saveProjectRequestObject);

    loadProjectsApi(
      axios,
      loadProjectRequest,
      (response) => loadProjectsApiSuccessCallback(response, setProjectList, setPage),
      () => {},
    );

    setIsCreateModalOpen(false);
  }

  /**
   * 2024.03.27 [energysteel]: 프로젝트명 중복
   */
  const failCallback = (response) => {
    toast.error(response.data.data.message);
  }

  /**
   * 2024.03.06 [energysteel]: Modal 노출 여부 확인 (true: 노출)
   */
  const handleOpen = () => {
    return isCreateModalOpen;
  }

  /**
   * 2024.03.06 [energysteel]: Modal 종료
   */
  const handleCancel = () => {
    setIsCreateModalOpen(false);
  }

  const handleProjectImport = () => {
    fileInputRef?.current?.click();
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = ['dat'];

    if (!selectedFile) {
      setIsCreateModalOpen(false);
      setImportLoading(false);

      return;
    }

    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error('dat 파일만 업로드 할 수 있습니다.');
      setIsCreateModalOpen(false);
      setImportLoading(false);

      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setIsCreateModalOpen(false);
    setImportLoading(true);

    importApi(
      axios,
      formData,
      importApiSuccessCallback,
      importApiFailCallback,
    );
  };

  const importApiSuccessCallback = () => {
    loadProjectsApi(
      axios,
      loadProjectRequest,
      (response) => loadProjectsApiSuccessCallback(response, setProjectList, setPage),
      () => {},
    );

    setIsCreateModalOpen(false);
    setImportLoading(false);
    toast.success("Project Import 완료되었습니다.");
  }

  const importApiFailCallback = () => {
    setIsCreateModalOpen(false);
    setImportLoading(false);
  }

  const fileInputRef = useRef();

  return (
    <>
      {importLoading ?
        <></> :
      <Modal
        className="modal"
        title={
          <Title
            title={"Project 생성"}
            subTitle={"생성 Project 정보를 입력해주세요."}
          />
        }
        open={handleOpen()}
        onCancel={handleCancel}
        width={528}
        centered
        footer={
          <>
            <Dialogs
              textLeft={"Import"}
              handleLeft={handleProjectImport}
              textCenter={"취소"}
              handleCenter={handleCancel}
              textRight={"생성하기"}
              handleRight={handleSaveProjectThrottled}
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{display: 'none'}}
            />
          </>

        }
      >
        <ModalHeadline />
        <CreateProjectTable />
      </Modal>
      }
    </>
  );
};

export default CreateProjectModal;
