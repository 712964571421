import { ConfigProvider, Pagination } from "antd";
import { SquareButton } from "../../../atoms/Button";
import { useNavigate } from "react-router-dom";
import Image from "../../../atoms/Image";
import { bbsPageState, noticeTapState } from "../../../../recoil/bbs/bbsState";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../../recoil/user/userState";
import React from "react";

const SupportTable = ({ data, loading }) => {
  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.02.28 [shiningtrue]: 화면 이동하기 위한 변수 세팅
  const navigate = useNavigate();
  /* 2024.02.28 [shiningtrue]:
   * data.key: 현재 메뉴가 1:1문의 라면 isOneToOne true 세팅 */
  const isOneToOne = data.key === "inquiry" ? true : false;
  // 2024.01.26 [shiningtrue]: 페이징 처리 하기 위함
  const [page, setPage] = useRecoilState(bbsPageState);
  // 2024.01.26 [shiningtrue]: 공지사항 검색 탭 처리 하기 위함
  const [isActive, setIsActive] = useRecoilState(noticeTapState);

  // 2024.02.28 [shiningtrue]: 검색 탭 그리기
  const FilterButton = ({ valueList }) => {
    return (
      <div>
        {valueList[0] && (
          <button
            type="button"
            className={`br100 cur btn ${isActive.all ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
            style={{
              padding: "9px 20px",
              border: `${isActive.all ? "0" : "sec"}`,
            }}
            onClick={() => filterClick("all")}
          >
            {valueList[0]}
          </button>
        )}
        {valueList[1] && (
          <button
            type="button"
            className={`br100 cur btn ${isActive.service ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
            style={{
              padding: "9px 20px",
              border: `${isActive.service ? "0" : "sec"}`,
            }}
            onClick={() => filterClick("service")}
          >
            {valueList[1]}
          </button>
        )}
        {valueList[2] && (
          <button
            type="button"
            className={`br100 cur btn ${isActive.work ? "bg-brand400 white Body4_B mr6" : "b-g200 g400 bg-white Body4_B mr6"}`}
            style={{
              padding: "9px 20px",
              border: `${isActive.work ? "0" : "sec"}`,
            }}
            onClick={() => filterClick("work")}
          >
            {valueList[2]}
          </button>
        )}
      </div>
    );
  };

  // 2024.02.28 [shiningtrue]: 검색 탭 클릭 함수
  const filterClick = (name) => {
    setIsActive((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name],
    }));
  };

  // 2024.02.28 [shiningtrue]: 게시물 클릭 함수
  const onRowClick = (value) => {
    navigate("/support", { state: { id: value.id } });
  };

  // 2024.02.28 [shiningtrue]: 게시물 등록 버튼 클릭 함수
  const onWriteClick = () => {
    navigate("/support", { state: { isWrite: true } });
  };

  // 2024.02.28 [shiningtrue]: 페이징 클릭 함수
  const handlePageClick = (newPageNumber) => {
    setPage((prevPage) => ({
      ...prevPage,
      pageNumber: newPageNumber - 1,
    }));
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <div>
            <div className="content jc-sb mt10 mb12">
              {!isOneToOne ? (
                <FilterButton valueList={data.filter} />
              ) : (
                <span className="Body3_R g500">
                  {userSession.name}님의 문의내역
                </span>
              )}
              {userSession.adminYn || data.key === "inquiry" ? (
                <SquareButton
                  className={"btn-pri"}
                  text={"등록"}
                  onClick={() => onWriteClick()}
                />
              ) : null}
            </div>
            {data.data.length > 0 ? (
              <>
                <div>
                  <table
                    className="support-table all g500 Body6_R"
                    width={"100%"}
                    style={{ borderSpacing: "0", borderCollapse: "collapse" }}
                  >
                    <colgroup>
                      <col className="first" />
                      {isOneToOne ? (
                        <>
                          <col className="sec-one" />
                          <col className="thr-one" />
                        </>
                      ) : (
                        <col className="sec" />
                      )}
                      <col className="last" />
                    </colgroup>
                    <tbody>
                      {data.data.map((item, i) => {
                        return (
                          <tr
                            className="cur Body3_R g900"
                            id={item.id}
                            key={i}
                            height="65px"
                            onClick={() => onRowClick(item)}
                          >
                            <td className="Body3_B">{item.type}</td>
                            <td>
                              <div
                                className={`isOneToOne ${isOneToOne} ellipsis`}
                              >
                                {item.title}
                              </div>
                            </td>
                            {isOneToOne && (
                              <td
                                className={
                                  item.status === "답변대기"
                                    ? "g400"
                                    : "brand500"
                                }
                              >
                                {item.status}
                              </td>
                            )}
                            <td className="g400">{item.createDate}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="content jc-c mt36">
                    <ConfigProvider
                      theme={{
                        components: {
                          Pagination: {
                            colorPrimary: "#131418",
                            colorPrimaryBorder: "#ffffff",
                            colorPrimaryHover: "#131418",
                            colorText: "#bfc7d9",
                            lineWidth: 0,
                          },
                        },
                      }}
                    >
                      <div
                        className="content jc-c"
                        style={{
                          height: "59px",
                          marginTop: "12px",
                        }}
                      >
                        <Pagination
                          style={{ textAlign: "center" }}
                          current={page.pageNumber + 1}
                          total={page.totalElements}
                          pageSize={page.pageSize}
                          onChange={handlePageClick}
                          showSizeChanger={false}
                        />
                      </div>
                      {/*<Pagination className="Body4_R" defaultCurrent={1} total={50} />*/}
                    </ConfigProvider>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="content jc-c bt-g200 bb-g200"
                style={{
                  height: "568px",
                }}
              >
                <div className="ta Body2_R g500">
                  <Image
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_empty_list"}
                    size="xl"
                  />
                  <div className="mt28 mb17">
                    {data.key === "notice" ? (
                      <p className="Body1_B g900">
                        등록된 공지사항이 없습니다.
                      </p>
                    ) : data.key === "faq" ? (
                      <p className="Body1_B g900">
                        등록된 자주하는 질문이 없습니다.
                      </p>
                    ) : data.key === "inquiry" ? (
                      <p className="Body1_B g900">등록된 문의가 없습니다.</p>
                    ) : null}
                    {data.key === "inquiry" ? (
                      <>
                        <p>
                          필요하신 문의사항이 있으신 경우 질문 등록을 해주세요.
                        </p>
                        <p>담당자 확인 후 빠르게 답변드리도록 하겠습니다.</p>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SupportTable;
