import React from "react";
import {Input} from "antd";
import Image from "./Image";

const SearchInput = ({
  inputValue,
  onChange,
}) => {

  return (
    <Input
      value={inputValue}
      className="Body6_R g300 bg-white b-g200 br3"
      placeholder="Search..."
      onChange={onChange}
      style={{ width: "252px", height: "32px" }}
      prefix={
        <Image
          className="mr9"
          path={`${process.env.PUBLIC_URL}/projectList`}
          name={"ic_search_24"}
        />
      }
    />
  );
};

export default SearchInput;
