import React from "react";
import { Switch, ConfigProvider } from "antd";

const SwitchInput = ({ text, checked: checked = true, onChange }) => {

  return (
    <div className="content" style={{ height: 36 }}>
      <ConfigProvider
        theme={{
          components: {
            Switch: { trackMinWidthSM: 32 },
          },
          token: {
            colorPrimary: "#7c60ff",
          },
        }}
      >
        <Switch
            checked={checked}
            size="small"
            onChange={onChange} />
        <span className="Body6_R g500 ml8">{text}</span>
      </ConfigProvider>
    </div>
  );
};

export default SwitchInput;
