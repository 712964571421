import React, { useEffect, useState } from "react";
import Image from "../../../atoms/Image";
import { Dropdown, Space } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  commonResponseFormBodyListState,
  contentTypeListState,
  projectState,
} from "../../../../recoil/project/projectState";
import { userState } from "../../../../recoil/user/userState";
import {
  selectedApiState,
  tabOpenListState,
} from "../../../../recoil/api/apiState";
import { toast } from "react-toastify";
import { defaultBinaryImage } from "../../../../recoil/file/fileState";
import { renderToStaticMarkup } from "react-dom/server";
import {jsonErrorStructureConvert} from "../../../../recoil/response/responseState";

const Rightbar = ({ isRightbar, setIsRightbar }) => {
  // code snippet에서 활성화 Index
  const [codeActive, setCodeActive] = useState(0);
  // 2024.04.08 [energysteel]: 현재 프로젝트 정보
  const [project] = useRecoilState(projectState);
  const userSession = useRecoilValue(userState); //사용자 세션 정보
  // 2024.03.11 [energysteel]: Content-Type 정보
  const [contentTypeList, setContentTypeList] =
    useRecoilState(contentTypeListState);
  // 2024.02.29 [shiningtrue]: 클릭한 API ID
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.07 [energysteel]: 열려있는 Tab 리스트 및 Tab Content 관리
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  let dataItem = tabOpenList.find((item) => item.id === selectedApi.id);
  // 2024.06.05 [shiningtrue]:
  const [testCaseResponseData, setTestCaseResponseData] = useState("");
  const [commonResponseFormBody] = useRecoilState(commonResponseFormBodyListState);

  useEffect(() => {
    dataItem = tabOpenList.find((item) => item.id === selectedApi.id);

    if (dataItem !== "" && dataItem !== null && dataItem !== undefined) {
      if (dataItem.responseCase.length > 0) {
        for (let response of dataItem.responseCase) {
          if (response.defaultResponse && response.errorMessageType !== "COMMON") {
            setTestCaseResponseData(response.responseCaseDetail.testCase);
          } else if (response.defaultResponse && response.errorMessageType === "COMMON") {
            console.log(response);
            console.log(response.responseCaseDetail);
            console.log(commonResponseFormBody);
            if (response.responseCaseDetail?.commonErrorCode !== null) {
              setTestCaseResponseData(jsonErrorStructureConvert(commonResponseFormBody,  {
                value: response.responseCaseDetail?.commonErrorCode?.value,
                label: response.responseCaseDetail?.commonErrorCode?.label,
                code: response.responseCaseDetail?.commonErrorCode?.code,
                message: response.responseCaseDetail?.commonErrorCode?.message,
              }));
            } else {
              setTestCaseResponseData({});
            }
          }
        }
      } else {
        setTestCaseResponseData({});
      }
    }
  }, [selectedApi.id, tabOpenList]);

  const ellipsisValue = (json, len) => {
    if (Array.isArray(json)) {
      return json.map((item) => ellipsisValue(item, len));
    }

    if (json !== null && typeof json === "object") {
      const result = {};
      const keys = Object.keys(json);

      for (const key of keys) {
        if (Array.isArray(json[key])) {
          result[key] = json[key].map((item) => ellipsisValue(item, len));
        } else if (json[key] !== null && typeof json[key] === "object") {
          result[key] = ellipsisValue(json[key], len);
        } else if (typeof json[key] === "string" && json[key].length > len) {
          result[key] = `${json[key].substring(0, len)}...`;
        } else {
          result[key] = json[key];
        }
      }
      return result;
    }
    return json;
  };

  // 객체를 기반으로 JSON 문자열을 생성하는 함수
  const generateJsonString = (data) => {
    if (data === undefined) {
      return "{}";
    }

    let jsonString = "{";

    data.forEach((item, index) => {
      jsonString += `"${item.keyValue}": `;

      if (item.dataType === "OBJECT") {
        if (item.children.length > 0) {
          jsonString += generateJsonString(item.children);
        } else {
          jsonString += "{}";
        }
      } else if (item.dataType === "STRING") {
        jsonString += '""';
      } else if (
        item.dataType === "INT" ||
        item.dataType === "LONG" ||
        item.dataType === "SHORT" ||
        item.dataType === "DOUBLE" ||
        item.dataType === "FLOAT"
      ) {
        jsonString += 0;
      } else if (item.dataType === "BOOLEAN") {
        jsonString += false;
      } else if (item.dataType === "FILE") {
        jsonString += `"${defaultBinaryImage}"`;
      } else if (item.dataType === "ARRAY") {
        if (item.children.length > 0) {
          jsonString += "[";
          item.children.forEach((child, childIndex) => {
            if (childIndex < item.children.length && childIndex !== 0) {
              jsonString += ",";
            }
            if (child.dataType === "STRING" || child.dataType === "FILE") {
              jsonString += '""';
            } else if (
              child.dataType === "INT" ||
              child.dataType === "LONG" ||
              child.dataType === "SHORT" ||
              child.dataType === "DOUBLE" ||
              child.dataType === "FLOAT"
            ) {
              jsonString += "0";
            } else if (child.dataType === "BOOLEAN") {
              jsonString += true;
            } else if (child.dataType === "OBJECT") {
              if (child.children.length > 0) {
                jsonString += arrayObjectChildren(child.children);
              } else {
                jsonString += "{}";
              }
            } else if (child.dataType === "ARRAY") {
              if (child.children.length > 0) {
                jsonString += arrayAndArrayChildren(child.children)
              } else {
                jsonString += "[]";
              }
            }
          });
          jsonString += "]";
        } else {
          jsonString += "[]";
        }
      } else {
        jsonString += '""';
      }

      if (index < data.length - 1) {
        jsonString += ",";
      }
    });

    jsonString += "}";

    return jsonString;
  };
  const arrayAndArrayChildren = (data) => {
    let jsonString = "[";
    data.forEach((item, index) => {
      switch (item.dataType) {
        case "STRING":
          jsonString += '""';
          break;
        case "INT":
        case "LONG":
        case "SHORT":
        case "DOUBLE":
        case "FLOAT":
          jsonString += 0;
          break;
        case "BOOLEAN":
          jsonString += false;
          break;
        case "FILE":
          jsonString += `"${defaultBinaryImage}"`;
          break;
        case "OBJECT":
          jsonString += arrayObjectChildren(item.children);
          break;
        case "ARRAY":
          if (item.children.length > 0) {
            jsonString += "[";
            item.children.forEach((child, childIndex) => {
              if (childIndex < item.children.length && childIndex !== 0) {
                jsonString += ",";
              }
              if (child.dataType === "STRING" || child.dataType === "FILE") {
                jsonString += '""';
              } else if (
                  child.dataType === "INT" ||
                  child.dataType === "LONG" ||
                  child.dataType === "SHORT" ||
                  child.dataType === "DOUBLE" ||
                  child.dataType === "FLOAT"
              ) {
                jsonString += "0";
              } else if (child.dataType === "BOOLEAN") {
                jsonString += true;
              } else if (child.dataType === "OBJECT") {
                if (child.children.length > 0) {
                  jsonString += arrayObjectChildren(child.children);

                } else {
                  jsonString += "{}";
                }
              }
            });
            jsonString += "]";
          } else {
            jsonString += "[]";
          }
          break;
        default:
          jsonString += null; // 또는 지원되지 않는 유형에 대한 기본값 설정
      }
      if (index !== data.length - 1) {
        jsonString += ",";
      }
    });
    jsonString += "]";

    return jsonString;
  }

  const arrayObjectChildren = (data) => {
    let jsonString = "{";
    data.forEach((item, index) => {
      jsonString += `"${item.keyValue}": `;
      switch (item.dataType) {
        case "STRING":
          jsonString += '""';
          break;
        case "INT":
        case "LONG":
        case "SHORT":
        case "DOUBLE":
        case "FLOAT":
          jsonString += 0;
          break;
        case "BOOLEAN":
          jsonString += false;
          break;
        case "FILE":
          jsonString += `"${defaultBinaryImage}"`;
          break;
        case "OBJECT":
          jsonString += arrayObjectChildren(item.children);
          break;
        case "ARRAY":
          if (item.children.length > 0) {
            jsonString += "[";
            item.children.forEach((child, childIndex) => {
              if (childIndex < item.children.length && childIndex !== 0) {
                jsonString += ",";
              }
              if (child.dataType === "STRING" || child.dataType === "FILE") {
                jsonString += '""';
              } else if (
                  child.dataType === "INT" ||
                  child.dataType === "LONG" ||
                  child.dataType === "SHORT" ||
                  child.dataType === "DOUBLE" ||
                  child.dataType === "FLOAT"
              ) {
                jsonString += "0";
              } else if (child.dataType === "BOOLEAN") {
                jsonString += true;
              } else if (child.dataType === "OBJECT") {
                if (child.children.length > 0) {
                  jsonString += arrayObjectChildren(child.children);

                } else {
                  jsonString += "{}";
                }
              }
            });
            jsonString += "]";
          } else {
            jsonString += "[]";
          }
          break;
        default:
          jsonString += null; // 또는 지원되지 않는 유형에 대한 기본값 설정
      }
      if (index !== data.length - 1) {
        jsonString += ",";
      }
    });
    jsonString += "}";

    return jsonString;
  };

  const jsonStringRequestHeader = generateJsonString(dataItem?.requestHeader);
  const jsonStringRequestHeaderNoTabs = jsonStringRequestHeader.replace(
    /\t/g,
    "",
  );
  const jsonObjectRequestHeader = JSON.parse(jsonStringRequestHeaderNoTabs);
  const ellipsisRequestHeader = JSON.stringify(
    ellipsisValue(jsonObjectRequestHeader, 10),
    null,
    2,
  );
  const formattedJsonStringRequestHeader = JSON.stringify(
    jsonObjectRequestHeader,
    null,
    2,
  );

  const jsonStringRequestBody = generateJsonString(dataItem?.requestBody);
  const jsonStringRequestBodyNoTabs = jsonStringRequestBody.replace(/\t/g, "");
  const jsonObjectRequestBody = JSON.parse(jsonStringRequestBodyNoTabs);
  const ellipsisRequestBody = JSON.stringify(
    ellipsisValue(jsonObjectRequestBody, 10),
    null,
    2,
  );
  const formattedJsonStringRequestBody = JSON.stringify(
    jsonObjectRequestBody,
    null,
    2,
  );

  const jsonStringResponseParameter = generateJsonString(
    dataItem?.responseParameter,
  );
  const jsonStringResponseParameterNoTabs = jsonStringResponseParameter.replace(
    /\t/g,
    "",
  );
  const jsonObjectResponseParameter = JSON.parse(
    jsonStringResponseParameterNoTabs,
  );
  const ellipsisResponseParameter = JSON.stringify(
    ellipsisValue(jsonObjectResponseParameter, 10),
    null,
    2,
  );
  const formattedJsonStringResponseParameter = JSON.stringify(
    jsonObjectResponseParameter,
    null,
    2,
  );

  const url = `${process.env.REACT_APP_API_PATH}${project.context}${dataItem?.uri?.uri}`;

  const curl = (requestBody) => {
    return [
      <>
        curl --location --request{" "}
        {dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}{" "}
        <span className="brand500">{url}</span> \
      </>,
      <>
        --header{" "}
        <span className="brand500">'apiKey: {userSession.apiKey}'</span> \
        <br />
      </>,
      <>
        --header <span className="brand500">'projectKey: {project.key}'</span> \
        <br />
      </>,
      <>
        --header{" "}
        <span className="brand500">
          'Content-Type:{" "}
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          '
        </span>{" "}
        \
      </>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            --header{" "}
            <span className="brand500">
              '{header.keyValue}: {header.value}'
            </span>{" "}
            \
            <br />
          </div>
        ))}
      </>,
      <>
        --data <span className="brand500">'{requestBody}'</span>
      </>,
    ];
  };
  const swift = (requestBody) => {
    return [
      <>
        <span className="error">let</span> parameters =<span> </span>
        <span className="brand500">"{requestBody}"</span>
        <br />
        <br />
      </>,
      <>
        <span className="error">let</span> postData = parameters.data(using:
        .utf8)
        <br />
      </>,
      <>
        <span className="error">var</span> request = URLRequest(url: URL(string:{" "}
        <span className="brand500">"{url}"</span>)!,timeoutInterval:
        Double.infinity)
        <br />
      </>,
      <>
        request.addValue(
        <span className="brand500">"{userSession.apiKey}"</span>,
        forHTTPHeaderField: <span className="brand500">"apiKey"</span>)
        <br />
      </>,
      <>
        request.addValue(
        <span className="brand500" style={{ display: "inline-block" }}>
          "{project.key}"
        </span>
        , forHTTPHeaderField: <span className="brand500">"projectKey"</span>)
        <br />
      </>,
      <>
        request.addValue(
        <span className="brand500">
          "
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          "
        </span>
        , forHTTPHeaderField: <span className="brand500">"Content-Type"</span>)
        <br />
      </>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            request.addValue(<span className="brand500">"{header.value}"</span>,
            forHTTPHeaderField:{" "}
            <span className="brand500"> "{header.keyValue}"</span>)
            <br />
          </div>
        ))}
        <br />
      </>,
      <>
        request.httpMethod ={" "}
        <span className="brand500">
          "{dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}"
        </span>
        <br />
      </>,
      <>
        request.httpBody = postData
        <br />
      </>,
      <>
        <span className="error">let</span> task =
        URLSession.shared.dataTask(with: request)
        <br />
        {"{ "}
        <br />
        data, response, error <span className="error">in</span> <br />
      </>,
      <>
        <span className="error">guard let</span> data = data{" "}
      </>,
      <>
        <span className="error">else</span> {"{ "}
        <br />
      </>,
      <>
        print(String(describing: error)) <span className="error">return</span>
        {" }"}
        <br />
      </>,
      <>
        print(String(data: data, encoding: .utf8)!)
        <br />
      </>,
      <>
        {" }"}
        <br />
      </>,
      <>task.resume()</>,
    ];
  };

  const javaOkHttp = (requestBody) => {
    // JSON 문자열에서 줄 바꿈 문자와 불필요한 공백 제거
    let compactString = requestBody.replace(/\n/g, "").replace(/\s+/g, " ");
    // 큰따옴표를 이스케이프 처리
    const escapedJsonString = compactString.replace(/"/g, '\\"');
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    let customUrl = "";
    if (dataItem?.uri?.httpMethod === "GET") {
      customUrl = `${url}?${params}`;
    } else {
      customUrl = `${url}`;
    }
    return [
      <>
        OkHttpClient client = <span className="error">new</span>{" "}
        OkHttpClient().newBuilder()
      </>,
      <>
        .build();
        <br />
        <br />
      </>,
      <>
        MediaType mediaType = MediaType.parse(
        <span className="brand500">
          "
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          "
        </span>
        );
        <br />
        <br />
      </>,
      <>
        RequestBody body = RequestBody.create(mediaType,{" "}
        <span className="brand500">"{escapedJsonString}"</span>);
        <br />
        <br />
      </>,
      <>
        Request request = <span className="error">new</span> Request.Builder()
        <br />
      </>,
      <>
        .url(<span className="brand500">"{customUrl}"</span>)
        <br />
      </>,
      <>
        .method(
        <span className="brand500">
          "{dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}"
        </span>
        , body)
        <br />
      </>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            .addHeader(<span className="brand500">"{header.keyValue}"</span>,
            <span className="brand500"> ""</span>)
            <br />
          </div>
        ))}
      </>,
      <>
        .addHeader(
        <span className="brand500">
          "Content-Type", "
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          " )
        </span>
        <br />
      </>,
      <>
        .build();
        <br />
      </>,
      <>Response response = client.newCall(request).execute();</>,
    ];
  };

  const javascriptJquery = (requestBody) => {
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    let customUrl = "";
    if (dataItem?.uri?.httpMethod === "GET") {
      customUrl = `${url}?${params}`;
    } else {
      customUrl = `${url}`;
    }
    return [
      <>
        <span className="error">var</span> settings = {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;
        <span className="brand500">"url": "{customUrl}",</span>
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;
        <span className="brand500">
          "method": "
          {dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}",
        </span>
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;<span className="brand500">"headers":</span> {"{"}
        <span className="brand500">
          <br />
          {dataItem?.requestHeader.map((header, index) => (
            <div key={index}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="brand500">"{header.keyValue}"</span>:
              <span className="brand500"> "",</span>
              <br />
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Content-Type": "
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          "
          <br />
        </span>
        &nbsp;&nbsp;&nbsp;{"},"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;<span className="brand500">"data":</span>{" "}
        <span className="success">{"JSON"}</span>.stringify(
        <span className="brand500">{requestBody}</span>),
        <br />
      </>,
      <>
        {"}"};
        <br />
      </>,
      <>
        $.ajax(settings).done(function (response) {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;console.log(response);
        <br />
      </>,
      <>{"}"});</>,
    ];
  };

  const kotlinOkHttp = (requestBody) => {
    // JSON 문자열에서 줄 바꿈 문자와 불필요한 공백 제거
    let compactString = requestBody.replace(/\n/g, "").replace(/\s+/g, " ");
    // 큰따옴표를 이스케이프 처리
    const escapedJsonString = compactString.replace(/"/g, '\\"');
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    let customUrl = "";
    if (
      dataItem?.uri?.httpMethod === "GET" ||
      dataItem?.uri?.httpMethod === "HEAD"
    ) {
      customUrl = `${url}?${params}`;
      return [
        <>
          <span className="error">val</span> client ={" "}
          <span className="success">OkHttpClient</span>()
          <br />
        </>,
        <>
          <span className="error">val</span> request ={" "}
          <span className="success">Request.Builder</span>()
          <br />
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.url(<span className="brand500">"{customUrl}"</span>
          )
          <br />
        </>,
        <>
          {dataItem?.requestHeader.map((header, index) => (
            <div key={index}>
              &nbsp;&nbsp;&nbsp;.addHeader(
              <span className="brand500">"{header.keyValue}"</span>,
              <span className="brand500"> ""</span>)
              <br />
            </div>
          ))}
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.addHeader(
          <span className="brand500">
            "Content-Type", "
            {contentTypeList.find(
              (contentType) => contentType.value === dataItem?.uri?.contentType,
            )?.label ?? "application/json"}
            " )
          </span>
          <br />
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.build()
          <br />
        </>,
        <>
          <span className="error">val</span> response =
          client.newCall(request).execute()
          <br />
        </>,
        <>
          <br />
        </>,
      ];
    } else {
      customUrl = `${url}`;
      return [
        <>
          <span className="error">val</span> client ={" "}
          <span className="success">OkHttpClient</span>()
          <br />
        </>,
        <>
          <span className="error">val</span> mediaType = "
          <span className="brand500">
            {contentTypeList.find(
              (contentType) => contentType.value === dataItem?.uri?.contentType,
            )?.label ?? "application/json"}
          </span>
          ".toMediaType()
          <br />
        </>,
        <>
          <span className="error">val</span> body ={" "}
          <span className="brand500">"{escapedJsonString}"</span>
          .toRequestBody(mediaType)
          <br />
        </>,
        <>
          <span className="error">val</span> request ={" "}
          <span className="success">Request.Builder</span>()
          <br />
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.url(<span className="brand500">"{customUrl}"</span>
          )
          <br />
        </>,
        <>
          {dataItem?.uri?.httpMethod === "POST" ||
          dataItem?.uri?.httpMethod === "PUT" ||
          dataItem?.uri?.httpMethod === "PATCH" ? (
            <>
              &nbsp;&nbsp;&nbsp;.
              {dataItem?.uri?.httpMethod
                ? dataItem?.uri?.httpMethod.toLowerCase()
                : "get"}
              (body)
              <br />
            </>
          ) : (
            <>
              &nbsp;&nbsp;&nbsp;.method("
              {dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}",
              body)
              <br />
            </>
          )}
        </>,
        <>
          {dataItem?.requestHeader.map((header, index) => (
            <div key={index}>
              &nbsp;&nbsp;&nbsp;.addHeader(
              <span className="brand500">"{header.keyValue}"</span>,
              <span className="brand500"> ""</span>)
              <br />
            </div>
          ))}
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.addHeader(
          <span className="brand500">
            "Content-Type", "
            {contentTypeList.find(
              (contentType) => contentType.value === dataItem?.uri?.contentType,
            )?.label ?? "application/json"}
            " )
          </span>
          <br />
        </>,
        <>
          &nbsp;&nbsp;&nbsp;.build()
          <br />
        </>,
        <>
          <span className="error">val</span> response =
          client.newCall(request).execute()
          <br />
        </>,
      ];
    }
  };

  const dartDio = (requestBody) => {
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    // requestBody에서 앞뒤 중괄호를 제거
    const formattedBody = requestBody.substring(1, requestBody.length - 1);
    let customUrl = "";
    if (dataItem?.uri?.httpMethod === "GET") {
      customUrl = `${url}?${params}`;
    } else {
      customUrl = `${url}`;
    }
    return [
      <>
        <span className="error">var</span> headers = {"{"}
        <br />
      </>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            &nbsp;&nbsp;&nbsp;
            <span className="brand500">'{header.keyValue}'</span>:
            <span className="brand500"> ''</span>,
            <br />
          </div>
        ))}
      </>,
      <>
        <span className="brand500">
          &nbsp;&nbsp;&nbsp;'Content-Type': '
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          '
        </span>
        <br />
      </>,
      <>
        {"};"}
        <br />
      </>,
      <>
        <span className="error">var data</span> = json.encode({"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;<span className="brand500">{formattedBody}</span>
        <br />
      </>,
      <>
        {"}"});
        <br />
      </>,
      <>
        <span className="error">var</span> dio = Dio();
        <br />
      </>,
      <>
        <span className="error">var</span> response = await dio.request(
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;<span className="brand500">'{customUrl}',</span>
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;options: Options(
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; method: '
        {dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}',
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; headers: headers,
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; ),
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;
        <span className="error">data</span>: <span className="error">data</span>
        ,
        <br />
      </>,
      <>
        );
        <br />
      </>,
      <>
        <br />
      </>,
      <>
        <span className="error">if</span> (response.statusCode == 200) {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; print(json.encode(response.
        <span className="error">data</span>));
        <br />
      </>,
      <>
        {"}"}
        <br />
      </>,
      <>
        <span className="error">else</span> {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; print(response.statusMessage);
        <br />
      </>,
      <>{"}"}</>,
    ];
  };

  const dartHttp = (requestBody) => {
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    // requestBody에서 앞뒤 중괄호를 제거
    const formattedBody = requestBody.substring(1, requestBody.length - 1);
    let customUrl = "";
    if (dataItem?.uri?.httpMethod === "GET") {
      customUrl = `${url}?${params}`;
    } else {
      customUrl = `${url}`;
    }
    return [
      <>
        <span className="error">var</span> headers = {"{"}
        <br />
      </>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            &nbsp;&nbsp;&nbsp;
            <span className="brand500">'{header.keyValue}'</span>:
            <span className="brand500"> ''</span>,
            <br />
          </div>
        ))}
      </>,
      <>
        <span className="brand500">
          &nbsp;&nbsp;&nbsp;'Content-Type': '
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          '
        </span>
        <br />
      </>,
      <>
        {"};"}
        <br />
      </>,
      <>
        <span className="error">var</span> request = http.Request(
        <span className="brand500">
          '{dataItem?.uri?.httpMethod ? dataItem?.uri?.httpMethod : "GET"}'
        </span>
        , Uri.parse(<span className="brand500">'{customUrl}'</span>));
        <br />
      </>,
      <>
        request.body = json.encode({"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;<span className="brand500">{formattedBody}</span>
        <br />
      </>,
      <>
        {"}"});
        <br />
      </>,
      <>
        request.headers.addAll(headers);
        <br />
      </>,
      <>
        <br />
      </>,
      <>
        http.StreamedResponse response = await request.send();
        <br />
      </>,
      <>
        <br />
      </>,
      <>
        <span className="error">if</span> (response.statusCode == 200) {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;print(await response.stream.bytesToString());
        <br />
      </>,
      <>
        {"}"}
        <br />
      </>,
      <>
        <span className="error">else</span> {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; print(response.reasonPhrase);
        <br />
      </>,
      <>{"}"}</>,
    ];
  };

  const nodeJsAxios = (requestBody) => {
    // JSON 문자열을 객체로 파싱
    const jsonObject = JSON.parse(requestBody);
    // URL 파라미터 문자열 생성
    const params = new URLSearchParams(jsonObject).toString();
    // requestBody에서 앞뒤 중괄호를 제거
    const formattedBody = requestBody.substring(1, requestBody.length - 1);
    let customUrl = "";
    if (dataItem?.uri?.httpMethod === "GET") {
      customUrl = `${url}?${params}`;
    } else {
      customUrl = `${url}`;
    }
    return [
      <>
        <span className="error">const</span> axios = require('axios');
        <br />
      </>,
      <>
        <span className="error">let</span> data ={" "}
        <span className="success">{"JSON"}</span>.stringify({"{"}
        <br />
      </>,
      <>
        <span className="brand500">&nbsp;&nbsp;&nbsp;{formattedBody}</span>
        <br />
      </>,
      <>
        {"}"});
        <br />
      </>,
      <>
        <br />
      </>,
      <>
        <span className="error">let</span> config = {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; method: '
        {dataItem?.uri?.httpMethod
          ? dataItem?.uri?.httpMethod.toLowerCase()
          : "get"}
        ',
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; maxBodyLength:{" "}
        <span className="success">Infinity</span>,
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; url: <span className="brand500">'{customUrl}'</span>,
        <br />
      </>,
      <>&nbsp;&nbsp;&nbsp; headers: {"{"}</>,
      <>
        {dataItem?.requestHeader.map((header, index) => (
          <div key={index}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="brand500">'{header.keyValue}'</span>:
            <span className="brand500"> ''</span>,
            <br />
          </div>
        ))}
      </>,
      <>
        <span className="brand500">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'Content-Type': '
          {contentTypeList.find(
            (contentType) => contentType.value === dataItem?.uri?.contentType,
          )?.label ?? "application/json"}
          '
        </span>
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp;
        {"}"},
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; data : data
        <br />
      </>,
      <>
        {"}"};
        <br />
      </>,
      <>
        <br />
      </>,
      <>
        axios.request(config)
        <br />
      </>,
      <>
        .then((response) => {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; console.log(<span className="success">{"JSON"}</span>
        .stringify(response.data));
        <br />
      </>,
      <>
        {"}"})
        <br />
      </>,
      <>
        .<span className="error">catch</span>((error) => {"{"}
        <br />
      </>,
      <>
        &nbsp;&nbsp;&nbsp; console.log(error);
        <br />
      </>,
      <>{"}"});</>,
    ];
  };
  const reqHeaderJson = (requestHeader) => {
    return [<>{requestHeader}</>];
  };
  const reqBodyJson = (requestBody) => {
    return [<>{requestBody}</>];
  };

  const respJson = (responseParameter) => {
    return [<>{responseParameter}</>];
  };

  const testCaseRespJson = (responseParameter) => {
    return [<>{
      JSON.stringify(
          ellipsisValue(responseParameter, 10),
          null,
          2,
      )
    }</>];
  };

  // rightbar 가 닫혀있을 떄, 열려있을 때 시작
  const activeRightbar = (e) => {
    if (!isRightbar.open) {
      setIsRightbar(() => {
        return { active: e.target.name, open: true };
      });
    } else {
      setIsRightbar((prev) => {
        return { ...prev, active: e.target.name };
      });
    }
  };

  // 2024.04.11 [shiningtrue]: Code snippet 선택 함수
  const handleCodeClick = (i) => {
    setCodeActive(i);
  };

  // code snippet 리스트
  const codeList = [
    "cURL",
    "Swift - URLSession",
    "Java - OkHttp",
    "Javascript jquery",
    "kotlin - OkHttp",
    "Dart - dio",
    "Dart - http",
    "NodeJs - Axios",
  ];

  // codeList 기준으로 dropdown menu list 구성
  const codeItems = {
    items: codeList.map((v, i) => {
      return {
        label: (
          <div
            className={`Body6_B ${codeActive === i ? "brand500" : "g500"}`}
            onClick={() => handleCodeClick(i)}
          >
            {v}
          </div>
        ),
        key: i,
      };
    }),
  };

  // rightbar의 아이콘 정렬
  const Defaultbar = () => {
    return (
      <div className="default-bar">
        <div
          className={`icon ${isRightbar.open && isRightbar.active === "code" && "active"}`}
        >
          <Image
            path={`${process.env.PUBLIC_URL}/rightbar`}
            name={"ic_code"}
            onClick={activeRightbar}
            tagName={"code"}
          />
        </div>
        <div
          className={`icon ${isRightbar.open && isRightbar.active === "reqResp" && "active"}`}
        >
          <Image
            path={`${process.env.PUBLIC_URL}/rightbar`}
            name={"ic_reqresp"}
            onClick={activeRightbar}
            tagName={"reqResp"}
          />
        </div>
      </div>
    );
  };

  // 2024.03.21 [shiningtrue]: 복사 버튼
  // 2024.05.21 [energysteel]:
  //  ㄴ type:
  //  ㄴ  0 : Code Snippet cURL
  //  ㄴ  1 : Code Snippet Swift
  //  ㄴ  2 : Code Snippet Java - OkHttp
  //  ㄴ  3 : Code Snippet Javascript jquery
  //  ㄴ  4 : Code Snippet kotlin - OkHttp
  //  ㄴ  5 : Code Snippet Dart - dio
  //  ㄴ  6 : Code Snippet Dart - http
  //  ㄴ  7 : Code Snippet NodeJs - Axios
  //  ㄴ  request: Request Body
  //  ㄴ  response: Response Body
  const handleCopyClick = (type) => {
    let codeElements = "";
    if (type === 0) {
      codeElements = curl(formattedJsonStringRequestBody);
    } else if (type === 1) {
      codeElements = swift(formattedJsonStringRequestBody);
    } else if (type === 2) {
      codeElements = javaOkHttp(formattedJsonStringRequestBody);
    } else if (type === 3) {
      codeElements = javascriptJquery(formattedJsonStringRequestBody);
    } else if (type === 4) {
      codeElements = kotlinOkHttp(formattedJsonStringRequestBody);
    } else if (type === 5) {
      codeElements = dartDio(formattedJsonStringRequestBody);
    } else if (type === 6) {
      codeElements = dartHttp(formattedJsonStringRequestBody);
    } else if (type === 7) {
      codeElements = nodeJsAxios(formattedJsonStringRequestBody);
    } else if (type === "request") {
      codeElements = reqBodyJson(formattedJsonStringRequestBody);
    } else if (type === "response") {
      codeElements = testCaseRespJson(testCaseResponseData);
    }

    let htmlString = renderToStaticMarkup(codeElements);
    if (type === 1 || 2 || 3 || 4 || 5 || 6 || 7) {
      htmlString = htmlString.replace(/<br\s*\/?>/gi, "\n");
    }

    const html = new DOMParser().parseFromString(htmlString, "text/html");
    const codeText = html
      .getElementsByTagName("body")[0]
      ?.innerText?.replace(/\\/g, "\\\n");

    navigator.clipboard.writeText(codeText);
    toast.success("클립보드에 복사되었습니다.");
  };

  return (
    <div className={`rightbar ${isRightbar.open ? "active" : "non"}`}>
      {isRightbar.open ? (
        <div className="open">
          <Defaultbar />
          <div className="right-body">
            <div className="header">
              {isRightbar.active === "code" ? (
                <span>Code snippet</span>
              ) : (
                <span>Request/Response</span>
              )}
              <Image
                className="m8 cur"
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_close"}
                onClick={() => setIsRightbar({ open: false, active: "code" })}
              />
            </div>
            {isRightbar.active === "code" ? (
              <div className="right-content">
                <div className="content-header">
                  <div className="code-select">
                    <Dropdown
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      menu={codeItems}
                      placement="bottomRight"
                      overlayStyle={{
                        width: "169px",
                        height: "146px",
                      }}
                      trigger={["click"]}
                    >
                      <Space className="space">
                        {codeList[codeActive]}
                        <Image
                          path={`${process.env.PUBLIC_URL}/content`}
                          name={"ic_arrow_down"}
                        />
                      </Space>
                    </Dropdown>
                  </div>
                  <div className="code-icon">
                    {/*<Image
                    path={`${process.env.PUBLIC_URL}/rightbar`}
                    name={"ic_settings"}
                  />*/}
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_copy"}
                      onClick={() => handleCopyClick(codeActive)}
                    />
                  </div>
                </div>
                {codeActive === 0 ? (
                  <>
                    {curl(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 1 ? (
                  <>
                    {swift(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 2 ? (
                  <>
                    {javaOkHttp(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 3 ? (
                  <>
                    {javascriptJquery(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 4 ? (
                  <>
                    {kotlinOkHttp(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 5 ? (
                  <>
                    {dartDio(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 6 ? (
                  <>
                    {dartHttp(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 7 ? (
                  <>
                    {nodeJsAxios(ellipsisRequestBody).map((v, i) => {
                      return (
                        <div className="code" key={i}>
                          <p className="num" style={{ userSelect: "none" }}>
                            {i}
                          </p>
                          <p className="word" data-id="codeSnipperText">
                            {v}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : codeActive === 8 ? null : null}
              </div>
            ) : (
              <>
                <div className="right-content">
                  <div className="content-header">
                    <span>Request Header</span>
                  </div>
                  {reqHeaderJson(ellipsisRequestHeader).map((v) => {
                    return (
                      <p className="reqresp Body6_R" data-id="requestText">
                        {v}
                      </p>
                    );
                  })}
                </div>
                <div className="right-content">
                  <div className="content-header">
                    <span>Request Body</span>
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_copy"}
                      onClick={() => handleCopyClick("request")}
                    />
                  </div>
                  {reqBodyJson(ellipsisRequestBody).map((v) => {
                    return (
                      <p className="reqresp Body6_R" data-id="requestText">
                        {v}
                      </p>
                    );
                  })}
                </div>
                <div className="right-content">
                  <div className="content-header">
                    <span>Response</span>
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_copy"}
                      onClick={() => handleCopyClick("response")}
                    />
                  </div>
                  {testCaseRespJson(testCaseResponseData).map((v) => {
                    return (
                      <p className="reqresp Body6_R" data-id="responseText">
                        {v}
                      </p>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Defaultbar />
      )}
    </div>
  );
};

export default Rightbar;
