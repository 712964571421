import React, {useEffect, useState} from "react";
import Image from "../../atoms/Image";
import TooltipImage from "../../atoms/TooltipImage";
import {EditableCell, EditableRow} from "../../atoms/Editable";
import {AutoComplete, Table} from "antd";
import {useRecoilState} from "recoil";
import {
  alertHelpIcon,
  helpIconMessageState,
  alertHelpIconOpenState,
  selectedApiState,
  tabOpenListState,
} from "../../../recoil/api/apiState";
import RequiredText from "../../atoms/RequiredText";
import {isApiAuthority, projectState} from "../../../recoil/project/projectState";
import {headers} from "../../../autocomplete/header";

const HeaderTable = () => {
  // 2024.03.07 [energysteel]: API Tree에서 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.20 [energysteel]: 전체 Tab 정보
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  // 2024.03.19 [shiningtrue]: 열려있는 Object
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // 2024.04.05 [energysteel]: 현재 Project 정보
  const [project] = useRecoilState(projectState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.03.19 [shiningtrue]: 화면 load 일 때 Api 탭이 가지고 있는 object load
  let responseItem = tabOpenList.find((item) => item.id === selectedApi.id);
  useEffect(() => {
    responseItem = tabOpenList.find((item) => item.id === selectedApi.id);
  }, [selectedApi.id]);

  // 2024.03.19 [shiningtrue]: response parameter 수정시 editing true처리
  const modifyApiResponse = () => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseHeaderEditing: true,
          };
        }
        return tab;
      });
    });
  };

  // 2024.03.19 [shiningtrue]: response recoil object 상태 변수 수정
  const setResponseData = (newData) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseHeader: [...tab.responseHeader, newData],
          };
        }
        return tab;
      });
    });
    modifyApiResponse();
  };

  // 2024.03.19 [shiningtrue]: row input 저장
  const handleSave = (row) => {
    const newData = [...responseItem?.responseHeader];
    const updatedData = setNewData(newData, row);

    addResponseItem("responseHeader", updatedData);
  };

  /* 2024.03.05 [shiningtrue]: row 삭제 버튼 */
  const handleDelete = (row) => {
    const newData = responseItem.responseHeader.filter(
      (item) => item.key !== row.key,
    );

    addResponseItem("responseHeader", findAndDeleteItem(newData, row));
  };

  // 2024.03.19 [shiningtrue]: 삭제시 해당 row 찾기 위함
  const findAndDeleteItem = (items, row) => {
    const updatedItems = items.filter((item) => item.key !== row.key);

    return updatedItems.map((item) => {
      if (item.children && item.children.length > 0) {
        const updatedChildren = findAndDeleteItem(item.children, row);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  // 2024.03.19 [shiningtrue]: row 추가 함수
  const handleAdd = () => {
    const newData = {
      key: `new-${responseItem.responseHeader.length}`,
      keyValue: "",
      dataType: "STRING",
      description: null,
      upperId: 0,
      encryptionYn: false,
      type: "HEADER",
      children: [],
    };
    setResponseData(newData);
  };

  // 2024.03.19 [shiningtrue]: setTabOpenList 상태 변수 해당 object 변경 함수
  const addResponseItem = (property, object) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            [property]: object,
          };
        }
        return tab;
      });
    });
    modifyApiResponse();
  };

  // 2024.03.19 [shiningtrue]: row 하위 추가 함수
  const handleAddMiddle = (record) => {
    const addNewChild = (items) => {
      return items.map((item) => {
        if (item.key === record.key) {
          const newChild = {
            id: "",
            upperId: item.key,
            key: `${item.key}-${item.children.length}`,
            keyValue: "",
            apiId: selectedApi.id,
            dataType: "STRING",
            description: null,
            encryptionYn: false,
            type: "HEADER",
            children: [],
          };

          return {
            ...item,
            children: [...item.children, newChild],
          };
        }

        if (item.children && item.children.length > 0) {
          const updatedChildren = addNewChild(item.children);
          return {
            ...item,
            children: updatedChildren,
          };
        }

        return item;
      });
    };

    const updatedDataSource = addNewChild(responseItem?.responseHeader);

    // 상태 업데이트
    addResponseItem("responseHeader", updatedDataSource);
  };

  // 2024.03.19 [shiningtrue]: DataType selectBox 값 세팅시 필요 함수
  const findDataTypeInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        return item.dataType;
      }

      if (item.children && item.children.length > 0) {
        const dataTypeInChildren = findDataTypeInChildren(item.children, key);
        if (dataTypeInChildren) {
          return dataTypeInChildren;
        }
      }
    }

    return null; // Return null if the key is not found
  };

  // 2024.03.19 [shiningtrue]: DataType selectBox 값 세팅 함수
  const getRecordDataType = (record) => {
    const dataType =
      responseItem?.responseHeader.length >= 1
        ? findDataTypeInChildren(responseItem?.responseHeader, record.key)
        : null;

    return dataType
      ? dataType.charAt(0).toUpperCase() + dataType.slice(1).toLowerCase()
      : "String";
  };

  // 2024.04.15 [energysteel]: AutoComplete 값 세팅 함수
  const handleChange = (row, key, optionValue) => {
    const newData = [...responseItem?.responseHeader];

    const updatedData = setNewData(newData, {
      ...row,
      [key]: optionValue,
    });

    addResponseItem("responseHeader", updatedData);
  };

  /* 2024.03.11 [shiningtrue]: Object의 값을 변경하기 위한 재귀 함수 */
  const setNewData = (items, data) => {
    return items.map((item) => {
      if (item.key === data.key) {
        return { ...item, ...data };
      }

      if (item.children && item.children.length > 0) {
        const updatedChildren = setNewData(item.children, data);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  const defaultColumns = [
    {
      title: (
        <>
          {isApiAuthority(project.authority) &&
            <div
              className="content jc-c h36"
              onClick={handleAdd}
            >
              <TooltipImage
                title={<div className="Body7_R">parameter 추가</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_add_bg"}
              />
            </div>
          }
        </>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        responseItem?.responseHeader.length >= 1 ? (
          <div className="content jc-c h36">
            {isApiAuthority(project.authority) &&
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record)}
              />
            }
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Key"} />,
      dataIndex: "keyValue",
      width: "40%",
      render: (_, record) =>
        responseItem?.responseHeader.length >= 1 ? (
          <>
            <AutoComplete
              style={{
                width: '100%',
              }}
              defaultValue={_}
              onBlur={(event) =>
                handleChange(record, 'keyValue', event.target.value)
              }
              placeholder={'Key를 입력해 주세요.'}
              options={headers.filter(header => header.value !== 'projectKey' && header.value !== 'apiKey')}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "62%",
      editable: isApiAuthority(project.authority),
      render: (_, record) =>
        responseItem?.responseHeader.length >= 1 ? (
          <>
            {record.description ? (
              <p className={"break-spaces-mw1200"}>{record.description}</p>
            ) : (
              <p className="g300">설명을 입력해주세요.</p>
            )}
          </>
        ) : null,
    },
    {
      title: <p style={{ width: 7 }} />,
      dataIndex: "key",
      width: "28px",
      render: (_, record) =>
        isApiAuthority(project.authority) && responseItem?.responseHeader.length >= 1 && !record.upperId ? (
          <Image
            className="content jc-c mr-4 ml-4"
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_move"}
          />
        ) : null,
    },
  ];

  // 2024.03.19 [shiningtrue]: row 이동 함수
  const moveRow = (record, dragIndex, hoverIndex) => {
    const dragRow = responseItem.responseHeader[dragIndex];
    if (dragRow === undefined) {
      return false;
    }
    if (dragRow.upperId !== 0) {
      setHelpIconMessage({
        code: "error",
        message: "최상위 Row만 이동 가능합니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }
    const newDataSource = [...responseItem.responseHeader];
    newDataSource.splice(dragIndex, 1);
    newDataSource.splice(hoverIndex, 0, dragRow);
    addResponseItem("responseHeader", newDataSource);
  };

  // 2024.03.19 [shiningtrue]: row 이동 함수
  const handleDragRow = (record, index) => ({
    index,
    draggable: true, // 드래그 가능하도록 설정
    onDragStart: (e) => {
      if (expandedRowKeys.length > 0) {
        setHelpIconMessage({
          code: "error",
          message: "열려 있는 Object 또는 Array를 닫은 후 이동해 주세요.",
        });
        alertHelpIcon(setAlertHelpIconOpen);

        return false;
      }
      e.dataTransfer.setData("text/plain", index); // 드래그된 요소의 인덱스 전달
    },
    onDragOver: (e) => {
      e.preventDefault(); // 드래그한 요소가 올바른 대상 위에 있을 때 드롭을 허용
    },
    onDrop: (e) => {
      e.preventDefault();
      const dropIndex = parseInt(e.dataTransfer.getData("text/plain")); // 드롭한 요소의 인덱스 가져오기
      if (dropIndex !== index && record.upperId === 0) {
        moveRow(record, dropIndex, index); // 드래그 앤 드롭으로 인한 요소 위치 변경
      }
    },
  });

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row),
      }),
    };
  });
  return (
    <Table
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }}
      rowClassName={() => "editable-row"}
      bordered
      dataSource={responseItem?.responseHeader}
      columns={columns}
      onRow={handleDragRow}
      pagination={false}
      expandedRowKeys={expandedRowKeys}
      expandable={{
        expandIcon: ({ expanded, onExpand, record }) => (
          <>
            {record.dataType.toUpperCase() === "OBJECT" ? (
              <>
                <Image
                  className="mr4 cur mb-2"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={"ic_plus"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddMiddle(record);
                    setExpandedRowKeys([record.key, ...expandedRowKeys]);
                  }}
                />
                {expanded ? (
                  <Image
                    className="cur mr4 mb-2"
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_arrow_down"}
                    onClick={(e) => {
                      setExpandedRowKeys(() => {
                        return expandedRowKeys.filter(
                          (key) => key !== record.key,
                        );
                      });
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                ) : (
                  <Image
                    className="cur mr4 mb-2"
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_arrow_right"}
                    onClick={(e) => {
                      setExpandedRowKeys([record.key, ...expandedRowKeys]);
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                )}
              </>
            ) : record.upperId !== 0 ? (
              "┗ "
            ) : null}
          </>
        ),
        expandIconColumnIndex: 1,
        indentSize: 40,
      }}
    />
  );
};

export default HeaderTable;
