import React from "react";
import { ConfigProvider } from "antd";

const TabsWrapper = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            itemSelectedColor: "#131418",
            itemHoverColor: "#131418",
            itemColor: "#949eb7",
            inkBarColor: "#7c60ff",
          },
          Table: {
            headerColor: "#636c83",
            headerBg: "#ffffff",
            cellPaddingBlock: 0,
            cellPaddingInline: 10,
            cellFontSize: 13,
            borderColor: "#dde2ee",
            headerBorderRadius: 3,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default TabsWrapper;
