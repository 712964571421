// 2024.03.06 [energysteel]: 초대 완료/초대 중 유저 전체 조회 API
export const loadInviteUsersApi = async (axios, projectId, searchData, successCallback) => {
    axios
        .get(`/invite/project/${projectId}/invited/users`, {
            params: {
                searchData,
            }
        })
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.07 [energysteel]: 프로젝트에 초대 가능한 유저인지 조회 API
export const invitePossibleUserApi = async (axios, projectId, guestUserEmail, successCallback, failCallback) => {
    axios
        .get(`/invite/project/${projectId}/user`, {
            params: {
                guestUserEmail,
            }
        })
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            } else {
                failCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.07 [energysteel]: 프로젝트에 유저 초대 API
export const inviteUserApi = async (axios, projectId, saveUserListRequest, successCallback, failCallback) => {
    axios
        .post(`/invite/project/${projectId}/user`, saveUserListRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            } else {
                failCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}


// 2024.03.07 [energysteel]: 유저 권한 변경 API
export const inviteModifyAuthorityApi = async (axios, projectId, modifyAuthorityRequest, successCallback, finallyCallback) => {
    axios
        .patch(`/invite/project/${projectId}/user/authority`, modifyAuthorityRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response);
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
            finallyCallback();
        });
}

// 2024.03.07 [energysteel]: 초대 수락 API
export const inviteAcceptApi = async (axios, projectId, guestUserId, successCallback, failCallback, finallyCallback) => {
    axios
        .patch(`/invite/project/${projectId}/user/accept`, { guestUserId })
        .then((response) => {
          if (response.data.code === '0001') {
            successCallback(response);
          } else {
            failCallback(response);
          }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
            finallyCallback();
        });
}

// 2024.03.07 [energysteel]: 초대 거절/취소 API
export const inviteRejectApi = async (axios, projectId, guestUserIds, successCallback, finallyCallback) => {
    axios
        .patch(`/invite/project/${projectId}/user/reject`, { guestUserIds })
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
            finallyCallback();
        });
}
