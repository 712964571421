import React from "react";
import Image from "./Image";

const Button = ({ children, className, type = "pri" }) => {
  return (
    <button
      type="button"
      className={"br100 " + className}
      style={{
        padding: "9px 20px",
        border: type === "pri" && 0,
      }}
    >
      {children}
    </button>
  );
};

const ManualButton = ({ text, icon, pos = "left", onClick = () => {} }) => {
  return (
    <button
      type="button"
      className={"content b-g200 bg-white br2 ml12 g500 cur"}
      style={{
        padding: pos === "left" ? "8px 12px 8px 8px" : "8px 8px 8px 12px",
        verticalAlign: "middle",
      }}
      onClick={onClick}
    >
      {pos === "left" && icon}
      <span className="mt2">{text}</span>
      {pos === "right" && icon}
    </button>
  );
};

const SquareButton = ({
  className,
  text,
  onClick,
  type = "pri",
  size = "sm",
  icon,
  link,
  width,
  isButtonDisabled,
}) => {
  const btnsize =
    size === "xs"
      ? { width: 74, height: 48 }
      : size === "sm-s"
        ? { width: 132, height: 36 }
        : size === "sm"
          ? { width: 132, height: 48 }
          : size === "md"
            ? { width: 194, height: 52 }
            : { width: 396, height: 52 };
  if (width) {
    btnsize.width = width;
  }
  return (
    <button
      type="button"
      disabled={isButtonDisabled}
      className={"content jc-c Body4_B br5 " + className}
      style={{
        border: type === "pri" && 0,
        ...btnsize,
      }}
      onClick={onClick}
    >
      {icon && icon}
      {text}
      {link}
    </button>
  );
};
const AttachButton = ({
  key,
  index,
  text,
  onFileRemoveClick,
  deleteFlag = true,
  savePath,
  onClick,
}) => {
  return (
    <div
      key={key}
      className="content b-g200 bg-white Body5_R g500 br100 mr12"
      style={{
        display: "inline-block",
        padding: "9px 12px",
        textAlign: "center",
      }}
      onClick={onClick}
    >
      <Image
        className="mb-2"
        path={`${process.env.PUBLIC_URL}/content`}
        name={"ic_attachment"}
      />
      <span className="ml4 mr12 " style={{ display: "inline-block" }}>
        {text}
      </span>
      {deleteFlag ? (
        <Image
          className="mb-2"
          path={`${process.env.PUBLIC_URL}/content`}
          name={"ic_close"}
          onClick={() => onFileRemoveClick(index)} // 파일 삭제 클릭 핸들러 호출
        />
      ) : null}
    </div>
  );
};
export default Button;

const TestButton = ({ text, path, name, onClick, className }) => {
  return (
    <button
      className={"content btn-line Body6_B br3 " + className}
      style={{ padding: "4px 10px" }}
      onClick={onClick}
    >
      <Image className="mr4" path={path} name={name} />
      <span className="mt2" style={{ display: "inline-block" }}>
        {text}
      </span>
    </button>
  );
};
export { ManualButton, SquareButton, AttachButton, TestButton };
