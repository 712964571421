// 이메일 문의메일 전송
export const emailSendApi = async (axios, params, successCallback, failCallback) => {
  await axios
    .get(`/user/inquiryMail`, {
      params: {
        enterprise: params.enterprise,
        name: params.name,
        phoneNumber: params.phoneNumber,
        email: params.email,
        contents: params.contents,
      },
    })
    .then(function (response) {
      successCallback(response);
    })
    .catch((error) => {
      failCallback();
      // 오류발생시 실행
    })
    .then(() => {
      // 항상 실행
    });
};
