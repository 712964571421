import React, {useEffect, useState} from "react";
import {Input} from "antd";
import Image from "../../../atoms/Image";
import Navigation from "../../../molecules/Navigation";
import {useRecoilState} from "recoil";
import {manualAllNavList, manualKeyState} from "../../../../recoil/manual/manualState";
import {MainTemplate, ReferenceTemplate, SourceCodeTemplate, SubMainTemplate, TableDataTemplate, WarnTemplate} from "../../../atoms/ManualTemplate";
import {renderToStaticMarkup} from "react-dom/server";
import {useLocation} from "react-router-dom";

const ManualContent = () => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [manualKey, setManualKey] = useRecoilState(manualKeyState);

  const location = useLocation();
  const manualAllNavs = manualAllNavList("guide-manual guide-title", "guide-header", setManualKey);
  const [selectGroupNav, setSelectGroupNav] = useState([manualAllNavs[0]]);
  const [contents, setContents] = useState([]);


  useEffect(() => {
    const htmlContent = [];
    parseComponentToHtmlRecursive(manualAllNavs, htmlContent, 1);

    setContents(htmlContent);
  }, [])

  const parseComponentToHtmlRecursive = (manualAllNavs, htmlContent, start) => {
    for (let i = start; i < manualAllNavs?.length; i++) {
      let navString = renderToStaticMarkup(manualAllNavs[i].content);
      // 2024.05.17 [energysteel]: h2 태그 제거
      navString = navString.replace(/<h2\b[^>]*>[\s\S]*?<\/h2>/gi, '');
      // 2024.05.17 [energysteel]: br 태그 space로 변경
      navString = navString.replace(/<br\s*\/?>/gi, ' ');

      const navHtml = new DOMParser().parseFromString(navString, 'text/html');
      const content = navHtml.getElementsByClassName('guide-manual');

      htmlContent.push({
        idx: manualAllNavs[i].idx,
        title: manualAllNavs[i].title,
        plainTitle: manualAllNavs[i].plainTitle,
        content: content,
      });

      parseComponentToHtmlRecursive(manualAllNavs[i].children, htmlContent, 0);
    }
  }

  useEffect(() => {
    // 2024.05.16 [energysteel]: manualKey 변경 시 Content 스크롤 최상단 이동
    const element = document.getElementsByClassName("guide")[0];
    if (element) {
      element.scrollTop = 0;
    }

    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100)
    }

    let findSelectGroupNav = findChild();
    setSelectGroupNav([findSelectGroupNav]);
  }, [location.hash, manualKey])

  const findChild = () => {
    const parent = manualAllNavs.find(nav => nav.idx === manualKey);
    if (parent) {
      return parent;
    }

    for (const nav of manualAllNavs) {
      if (nav.children && nav.children.some(child => child.idx === manualKey)) {
        return nav;
      }
    }

    return manualAllNavs[0];
  }

  const onChange = (e) => {
    const term = e.target.value.toLowerCase();
    if (term === "") {
      setValue("");
      setSearchData([]);
      return;
    }

    const searchData = [];

    findSearchData(contents, term, searchData);

    setSearchData(searchData);
    setValue(e.target.value);
    setOpen(true);
  };

  const findSearchData = (manualAllNavs, term, searchData) => {
    for (let i = 0; i < manualAllNavs.length; i++) {
      for (let j = 0; j < manualAllNavs[i].content.length; j++) {
        const text = manualAllNavs[i].content[j].innerText;
        if (text.toLowerCase().replace(/ /g, '')
          .indexOf(term.toLowerCase().replace(/ /g, '')) !== -1) {

          const words = text.split(" ").filter(element => element !== "");
          const findText = createSentence(words.join(" "), term);

          if (!findText) continue;

          searchData.push({
            idx: manualAllNavs[i].idx,
            title: manualAllNavs[i].title,
            plainTitle: manualAllNavs[i].plainTitle,
            text: findText,
          });
        }
      }
    }
  }

  const createSentence = (words, term) => {
    const index = words.toLowerCase().replace(/ /g, '')
      .indexOf(term.toLowerCase().replace(/ /g, ''));

    if (index === -1) {
      return;
    }

    let sentenceStart = '';
    let sentenceEnd = '';
    let start = index;
    let end = index + 1;

    while (start >= 0 && !words[start].includes(".")) {
      sentenceStart = words[start] + sentenceStart;
      start--;
    }

    while (end < words.length && !words[end - 1].includes(".")) {
      sentenceEnd += words[end];
      end++;
    }

    return sentenceStart + sentenceEnd;
  }

  const handleOnClick = (id) => {
    const keys = id.split("-");
    if (keys.length === 1 || keys.length === 2) {
      setManualKey(id);
    } else {
      setManualKey(`${keys[0]}-${keys[1]}`);
    }

    location.hash = `#${id}`;
  }


  return (
    <div className="manual-body">
      <div className="manual-content">
        <Input
          className="search"
          placeholder="Search..."
          onChange={onChange}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          value={value}
          prefix={
            <Image
              className="mr9"
              path={`${process.env.PUBLIC_URL}/projectList`}
              name={"ic_search_24"}
              size={"md"}
            />
          }
        />
        {open && searchData.length > 0 && (
          <div className="search-list" style={{ overflow: "auto", maxHeight: "500px" }}>
            {searchData.map((v, i) => {
              return (
                <div
                  key={i}
                  className="search-list-block"
                >
                  <a
                    style={{ color: "black", }}
                    href={`#${v.idx}`}
                    onMouseDown={() => handleOnClick(v.idx)
                  }>
                    <p className="Body3_B mb4">{v.plainTitle}</p>
                    <p className="Body4_R">
                      <>
                        <span className="brand500">{v.text}</span>
                      </>
                    </p>
                  </a>
                </div>
              );
            })}
          </div>
        )}
        <div className="guide">
          <div className="guide-content">
            {selectGroupNav?.map((value) => {
              return (
                <React.Fragment key={value.idx}>
                  <MainTemplate {...value} />
                  {
                    value?.children &&
                    value?.children.map((val) => {
                      return <SubMainTemplate key={val.idx} {...val} />;
                    })
                  }
                  { value?.button ? value.button : <></> }
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <Navigation list={selectGroupNav}  />
    </div>
  );
};

export default ManualContent;
