import React from "react";
import { Button } from "antd";

const AlertFooter = ({ textOk, handleOk }) => {
  return (
    <div className="content jc-c mt28 mb4">
      <Button
        className="btn-pri Body6_B br3"
        style={{
          width: "132px",
          height: "36px",
          marginLeft: "4px",
        }}
        type="primary"
        onClick={handleOk}
      >
        {textOk}
      </Button>
    </div>
  );
};

export default AlertFooter;
