import React, {useEffect, useState} from "react";
import {Input, Table, Tabs} from "antd";
import TooltipImage from "../../atoms/TooltipImage";
import RequiredText from "../../atoms/RequiredText";
import {EditableCell, EditableRow, envEditableCell,} from "../../atoms/Editable";
import TableWrapper from "../../molecules/TableWrapper";
import {loadCommonVariableApi, saveCommonVariableApi,} from "../../../api/commonVariable/commonVariableApi";
import useAxiosInterceptor from "../../../axios/axios";
import {
  commonEnvironmentVariableListState,
  commonUserVariableListState,
  isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import {useRecoilState} from "recoil";
import Image from "../../atoms/Image";
import {
  commonVariableCurrentTimeState,
  commonVariableEditingRecoilState,
  validateVariable,
} from "../../../recoil/commonVariableState/commonVariableState";
import {deepCopy} from "../subSider/ApiTree";
import TabsWrapper from "../../molecules/TabsWrapper";
import {SquareButton} from "../../atoms/Button";
import {alertHelpIcon, alertHelpIconOpenState, helpIconMessageState,} from "../../../recoil/api/apiState";
import _ from "lodash";
import {commonDuplicationSaveCheckApi} from "../../../api/project/projectApi";
import ConfirmModal from "../modals/ConfirmModal";

const CommonVariableContent = () => {
  const axios = useAxiosInterceptor();
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.15 [shiningtrue]: List 사용자변수 상태 관리 변수
  const [
    commonUserVariableListRecoilState,
    setCommonUserVariableListRecoilState,
  ] = useRecoilState(commonUserVariableListState);
  // 2024.03.15 [shiningtrue]: List 환경변수 상태 관리 변수
  const [
    commonEnvironmentVariableListRecoilState,
    setCommonEnvironmentVariableListRecoilState,
  ] = useRecoilState(commonEnvironmentVariableListState);
  // 2024.03.15 [shiningtrue]: 수정 여부 상태 관리 변수
  const [commonVariableEditingState, setCommonVariableEditingState] = useRecoilState(commonVariableEditingRecoilState);
  // 2024.04.02 [shiningtrue]: 변경 여부를 확인하고 화면 나갈때 저장 여부를 묻기 위한 최초 load된 사용자변수 object
  const [firstUserObjectData, setUserFirstObjectData] = useState(
    deepCopy(commonUserVariableListRecoilState),
  );
  // 2024.03.21 [shiningtrue]: 관리자 여부
  const isManager =
    project.authority === "OWNER" || project.authority === "ADMIN";
  // 2024.04.05 [shiningtrue]: 저장 버튼 클릭 상태
  const [isSave, setIsSave] = useState(false);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.05.11 [shiningtrue]: 해당 메뉴 load 시간
  const [currentTime, setCurrentTime] = useRecoilState(
      commonVariableCurrentTimeState,
  );
  // 2024.05.11 [shiningtrue]: modalOpen 상태 값
  const [modalOpen, setModalOpen] = useState(false);
  // 2024.05.11 [shiningtrue]: modal 메세지
  const [confirmModalMessage, setConfirmModalMessage] = useState("");
  // 2024.05.31 [evahong]: 1: 사용자변수, 2: 환경변수
  const [activeSubTab, setActiveSubTab] = useState("1");

  /**
   * 2024.05.10 [shiningtrue]: 화면 load 시점에 CurrentTime을 담음
   */
  useEffect(() => {
    if (currentTime.commonVariable === "") {
      setCurrentTime((prev) => ({
        ...prev,
        commonVariable: new Date(),
      }));
    }
  }, []);
  
  // 2024.03.15 [shiningtrue]: 저장 버튼
  const handleAllSave = () => {
    if (
        commonVariableEditingState.editing
    ) {
      const duplicationParams = {
        projectId: project.id,
        view: "commonVariable",
      };

      // 2024.05.10 [shiningtrue]: 다른 사용자가 저장한 이력이 있는지 조회
      commonDuplicationSaveCheckApi(
          axios,
          duplicationParams,
          duplicationSaveCheckApiSuccessCallback,
      );
    }
  };

  // 2024.05.09 [shiningtrue]: debounce
  const handleAllSaveThrottled = _.debounce(handleAllSave, 200);

  // 2024.05.11 [shiningtrue]: 다른 사용자가 저장한 이력이 있는지 확인
  const duplicationSaveCheckApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      let newMessage = "";
      const commonVariableUpdate = response.data.data.commonVariableUpdateDate;
      const commonVariableUpdateDate = new Date(commonVariableUpdate?.updateDate);

      // 공통변수 화면의 load 시간과 서버에서 업데이트된 시간을 가지고와서 비교
      if (
          (commonVariableUpdateDate > currentTime.commonVariable &&
              commonVariableEditingState.editing)
      ) {
        newMessage += `<span style="color:red;">[${commonVariableUpdate?.createUserName}]</span>님이 <span style="color:red;">[공통변수]</span> 메뉴를 수정하였습니다.\n\n`;
        setConfirmModalMessage(
            newMessage + "내가 수정한 내용으로 덮어 씌우시겠습니까?",
        );
        setModalOpen(true);
      } else {
        save();
      }
    }
  };

  const save = () => {
    if (commonVariableEditingState.editing) {
      const names = new Set(); // 중복된 name을 저장하기 위한 Set

      // commonVariableCombine 합쳐진 상태변수의 배열을 순회하면서 name 값 확인
      const combinedVariables = [
        ...commonUserVariableListRecoilState,
        ...commonEnvironmentVariableListRecoilState,
      ];

      for (const item of combinedVariables) {
        if (names.has(item.name)) {
          // 중복된 name이 발견되면 에러 메시지 출력
          setHelpIconMessage({
            code: "error",
            message: `${item.name}의 Key 값이 중복 되었습니다.`,
          });
          alertHelpIcon(setAlertHelpIconOpen);
          return false;
        }
        // Set에 name 값 추가
        names.add(item.name);
      }

      if (validateVariable(combinedVariables, setHelpIconMessage)) {
        const params = {
          commonVariableObject: combinedVariables,
          projectId: project.id,
        };

        saveCommonVariableApi(
            axios,
            params,
            saveCommonVariableApiSuccessCallback,
        );
      } else {
        alertHelpIcon(setAlertHelpIconOpen)
      }
    } else {
      commonVariableReLoad();
    }
  }
  
  const commonVariableReLoad = () => {
    loadCommonVariableApi(
        axios,
        project.id,
        loadCommonVariableApiSuccessCallback,
    );

    setCurrentTime((prev) => ({
      ...prev,
      commonVariable: new Date(),
    }));
  }
  
  // 2024.03.15 [shiningtrue]: 사용자변수 List 상태 관리 함수
  const handleCommonUserVariableData = (newData) => {
    setCommonUserVariableListRecoilState((prev) => [...prev, newData]);
  };

  // 2024.03.15 [shiningtrue]: 수정 여부 상태 변수 변경
  const modifyCommonVariable = (editing) => {
    setCommonVariableEditingState({ editing: editing });
  };

  /**
   * 2024.04.08 [shiningtrue]: 키보드 저장 이벤트
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      // 사용자가 Ctrl 키와 's' 키를 동시에 누른 경우
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        if (isProjectOwnerOrAdminAuthority(project.authority)) {
          handleAllSaveThrottled();
        }

        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    commonUserVariableListRecoilState,
    commonEnvironmentVariableListRecoilState,
    commonVariableEditingState.editing
  ]);

  // 2024.03.15 [shiningtrue]: List 조회 callback
  const loadCommonVariableApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      const userVariables = response.data.data.filter(
        (item) => !item.isEnvironment,
      ); // isEnvironment가 false인 요소들
      const environmentVariables = response.data.data.filter(
        (item) => item.isEnvironment,
      ); // isEnvironment가 true인 요소들
      setCommonUserVariableListRecoilState(userVariables);
      setCommonEnvironmentVariableListRecoilState(environmentVariables);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.15 [shiningtrue]: row별 input값 수정시 저장 함수
  const handleSave = (row, tabType) => {
    let newData = [];
    if (tabType === "USER") {
      newData = [...commonUserVariableListRecoilState];
    } else {
      newData = [...commonEnvironmentVariableListRecoilState];
    }
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    // 변경된 속성값
    let changedKey;
    // 변경된 속성의 value값
    let changedValue;

    // 두 객체의 모든 속성을 반복하여 비교
    for (const key in row) {
      // 같은 속성인지 확인하고, 값이 다르다면 changedValue에 추가
      if (row[key] !== item[key]) {
        changedKey = key;
        changedValue = row[key];
      }
    }

    if (item.isEnvironment) {
      if (changedKey === "name") {
        setHelpIconMessage({
          code: "error",
          message: "환경변수는 Value, Description만 변경 가능합니다.",
        });
        alertHelpIcon(setAlertHelpIconOpen);

        return false;
      } else if (changedKey === "value") {
        // UTF-8로 인코딩한 후 바이트 수 계산
        const encoder = new TextEncoder();
        const byteLength = encoder.encode(changedValue).length;
        if (item.name === "AES128" && byteLength < 16) {
          setEncryptionByteMessage("AES128 암호화 방식의 Value는 16byte로 설정 가능합니다.");
        } else if (item.name === "AES192" && byteLength < 24) {
          setEncryptionByteMessage("AES192 암호화 방식의 Value는 24byte로 설정 가능합니다.");
        } else if (item.name === "AES256" && byteLength < 32) {
          setEncryptionByteMessage("AES256 암호화 방식의 Value는 32byte로 설정 가능합니다.");
        }
      }
    }

    if (
      item.name === "projectKey" &&
      (changedKey === "name" || changedKey === "value")
    ) {
      setHelpIconMessage({
        code: "error",
        message: "projectKey는 Description만 변경 가능합니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    if (tabType === "USER") {
      setCommonUserVariableListRecoilState(newData);
    } else {
      setCommonEnvironmentVariableListRecoilState(newData);
    }

    modifyCommonVariable(true);
  };

  const setEncryptionByteMessage = (message) => {
    setHelpIconMessage({
      code: "error",
      message: message,
    });
    alertHelpIcon(setAlertHelpIconOpen);
  }


  // 2024.03.15 [shiningtrue]: 삭제
  const handleDelete = (key) => {
    const deleteData = commonUserVariableListRecoilState.find(
      (item) => item.key === key,
    );

    if (!deleteData.canDelete) {
      setHelpIconMessage({
        code: "error",
        message: "기본 변수는 삭제 할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    const newData = commonUserVariableListRecoilState.filter(
      (item) => item.key !== key,
    );
    setCommonUserVariableListRecoilState(newData);
    modifyCommonVariable(true);
  };

  // 2024.03.15 [shiningtrue]: 추가
  const handleAdd = () => {
    const newDataIndex = commonUserVariableListRecoilState.length;
    const newData = {
      name: "",
      key: `new-${newDataIndex}`,
      value: "",
      description: "",
      search: true,
      canDelete: true,
    };
    handleCommonUserVariableData(newData);
    modifyCommonVariable(true);
  };

  const userDefaultColumns = [
    {
      title: (
        <div className="content jc-c h36">
          {isProjectOwnerOrAdminAuthority(project.authority) && (
            <TooltipImage
              title={<div className="Body7_R">변수 추가</div>}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_add_bg"}
              onClick={handleAdd}
            />
          )}
        </div>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        commonUserVariableListRecoilState.length >= 1 ? (
          <div className="content jc-c h36">
            {isProjectOwnerOrAdminAuthority(project.authority) && (
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record.key)}
              />
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Key"} />,
      dataIndex: "name",
      width: "33%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonUserVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.name ? (
              <span className={"break-spaces-mw700"}>{record.name}</span>
            ) : (
              <span className="g300">Key를 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Value"} />,
      dataIndex: "value",
      width: "33%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonUserVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.value ? (
              <span className={"break-spaces-mw700"}>{record.value}</span>
            ) : (
              <span className="g300">Value를 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <div className="ml7"> Description</div>,
      dataIndex: "description",
      width: "34%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonUserVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.description ? (
              <span className={"break-spaces-mw700"}>{record.description}</span>
            ) : (
              <span className="g300">설명을 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
  ];

  const environmentDefaultColumns = [
    {
      title: "Key",
      dataIndex: "name",
      width: "33%",
      render: (_, record) =>
        commonEnvironmentVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.name ? (
              <span className={"break-spaces-mw700"}>{record.name}</span>
            ) : (
              <span className="g300">Key를 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <RequiredText className={"ml7"} text={"Value"} />,
      dataIndex: "value",
      width: "33%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonEnvironmentVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.value ? (
              <span className={"break-spaces-mw700"}>{record.value}</span>
            ) : (
              <span className="g300">Value를 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: (
        <div
          className="ml7"
          style={{ height: "36px", display: "flex", alignItems: "center" }}
        >
          {" "}
          Description
        </div>
      ),
      dataIndex: "description",
      width: "34%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonEnvironmentVariableListRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.description ? (
              <span className={"break-spaces-mw700"}>{record.description}</span>
            ) : (
              <span className="g300">설명을 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
  ];

  const userColumns = userDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row, "USER"),
      }),
    };
  });

  const environmentColumns = environmentDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row, "ENV"),
      }),
    };
  });

  // 2024.03.15 [shiningtrue]: 사용자변수 search가 true인 object 추출 (검색 하기 위함)
  const commonUserVariableList = () => {
    const filtered = commonUserVariableListRecoilState.filter(
      (item) => item.search === true,
    );
    return filtered;
  };

  // 2024.03.15 [shiningtrue]: 환경변수 search가 true인 object 추출 (검색 하기 위함)
  const commonEnvironmentVariableList = () => {
    const filtered = commonEnvironmentVariableListRecoilState.filter(
      (item) => item.search === true,
    );
    return filtered;
  };

  // 2024.03.15 [shiningtrue]: 검색 input
  const handleSearch = (e) => {
    const searchData = e.target.value.trim().toLowerCase(); // 입력된 검색어
    const userFiltered = commonUserVariableListRecoilState.map((item) => {
      const nameMatch = item.name.toLowerCase().includes(searchData);
      const valueMatch = item.value.toLowerCase().includes(searchData);
      const descriptionMatch = item.description
        .toLowerCase()
        .includes(searchData);
      const search = nameMatch || valueMatch || descriptionMatch; // 검색 결과 여부
      return { ...item, search }; // 검색 결과 여부를 포함한 객체 반환
    });

    const environmentFiltered = commonEnvironmentVariableListRecoilState.map(
      (item) => {
        const nameMatch = item.name.toLowerCase().includes(searchData);
        const valueMatch = item.value.toLowerCase().includes(searchData);
        const descriptionMatch = item.description
          .toLowerCase()
          .includes(searchData);
        const search = nameMatch || valueMatch || descriptionMatch; // 검색 결과 여부
        return { ...item, search }; // 검색 결과 여부를 포함한 객체 반환
      },
    );
    setCommonUserVariableListRecoilState(userFiltered);
    setCommonEnvironmentVariableListRecoilState(environmentFiltered);
    commonUserVariableList();
    commonEnvironmentVariableList();
  };

  // 2024.03.15 [shiningtrue]: 저장 callback
  const saveCommonVariableApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      modifyCommonVariable(false);
      // 기존 데이터로 초기화
      commonVariableReLoad();

      setHelpIconMessage({
        code: "success",
        message: "저장 완료되었습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
    setIsSave(false);
  };

  // 2024.04.03 [shiningtrue]: 사용자변수 테이블
  const UserTabsItem = () => {
    return (
      <TableWrapper>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={commonUserVariableList()}
          columns={userColumns}
          pagination={false}
        />
      </TableWrapper>
    );
  };

  // 2024.04.03 [shiningtrue]: 환경변수 테이블
  const EnvironmentTabsItem = () => {
    return (
      <TableWrapper>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: envEditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={commonEnvironmentVariableList()}
          columns={environmentColumns}
          pagination={false}
        />
      </TableWrapper>
    );
  };

  const items = [
    {
      key: "1",
      label: "사용자변수",
      children: <UserTabsItem />,
    },
    {
      key: "2",
      label: "환경변수",
      children: <EnvironmentTabsItem />,
    },
  ];

  const handleResponseSaveModalOk = () => {
    save();
    setModalOpen(false);
  };

  return (
    <div className="common-variable table-overflow-auto">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          className="Body6_R g300 bg-white b-g200 br3"
          placeholder="Search..."
          onChange={handleSearch}
          style={{ width: "252px", height: "32px" }}
          prefix={
            <Image
              className="mr9"
              path={`${process.env.PUBLIC_URL}/projectList`}
              name={"ic_search_24"}
            />
          }
        />
      </div>
      <TabsWrapper>
        <div className="common-variable-content">
          <div>
            {items.map((v) => {
              return (
                <div
                  className={`common-variable-content-subtab ${activeSubTab === v.key}`}
                  onClick={() => setActiveSubTab(v.key)}
                  key={v.key}
                >
                  {v.label}
                </div>
              );
            })}
          </div>
          {isManager ? (
            <SquareButton
              className={`btn-pri Body6_B ${
                !commonVariableEditingState.editing && "disable"
              }`}
              size={"sm-s"}
              onClick={handleAllSaveThrottled}
              text={"저장"}
              isButtonDisabled={!commonVariableEditingState.editing && true}
            />
          ) : null}
        </div>
        <div className="table-overflow-auto -true">
          {
            items.find((value) => {
              return value.key == activeSubTab;
            })?.children
          }
        </div>
      </TabsWrapper>
      <ConfirmModal
        open={modalOpen}
        title={"중복 저장 알림"}
        message={
          <div dangerouslySetInnerHTML={{ __html: confirmModalMessage }} />
        }
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleResponseSaveModalOk}
        handleCancel={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};
export default CommonVariableContent;
