import {useRecoilState} from "recoil";
import {currentResponseCaseState, editedTestCaseState, editorHighlight} from "../../../recoil/response/responseState";
import {linter, lintGutter} from "@codemirror/lint";
import {json, jsonParseLinter} from "@codemirror/lang-json";
import React, {useEffect, useRef} from "react";
import CodeMirror, {EditorSelection, EditorState} from "@uiw/react-codemirror";
import {vscodeDark} from "@uiw/codemirror-theme-vscode";
import {Prec} from "@codemirror/state";

const SettingTestCaseTable = ({
  setTestCaseError,
}) => {
  // 2024.03.26 [energysteel]: 현재 입력한 응답 JSON 정보
  const [currentResponseCase, setCurrentResponseCase] = useRecoilState(currentResponseCaseState);
  const [, setEditedTestCase] = useRecoilState(editedTestCaseState);

  const ref = useRef();

  // 2024.04.27 [energysteel]: 최초 진입 시 데이터 옮김
  useEffect(() => {
    setEditedTestCase(currentResponseCase.responseCaseDetail?.testCase);
  }, [])



  const handleRefreshTestCase = () => {
    // 2024.04.27 [energysteel]: 정상 응답 기본 값
    const defaultData = {};

    // 2024.04.27 [energysteel]: Editor 재생성
    createEditor(defaultData);

    setCurrentResponseCase(prev => {
      return {
        ...prev,
        responseCaseDetail: {
          ...prev.responseCaseDetail,
          testCase: defaultData,
        }
      }
    });

    setEditedTestCase(prev => {
      return defaultData;
    });

    setTestCaseError(false);
  }


  /**
   * 2024.03.26 [energysteel]: JSON Editor onChange Event
   * @param jsonString String 형태의 JSON
   */
  const handleJsonEditorOnChange = (jsonString) => {
    if (jsonString === "") {
      handleRefreshTestCase();
      return;
    }

    try {
      setEditedTestCase(JSON.parse(jsonString));
      setTestCaseError(false);
    } catch (e) {
      // 2024.04.27 [energysteel]: JSON parse 오류 시 저장 버튼 disabled
      setTestCaseError(true);
    }
  }

  /**
   * 2024.04.27 [energysteel]: JSON Editor 재생성
   * @param data Editor에 노출할 내용
   */
  const createEditor = (data) => {
    ref?.current?.view?.setState(
      EditorState.create({
        doc: JSON.stringify(data, null, 2),
        selection: EditorSelection.cursor(0),
      })
    );
  }

  /**
   * ref 생성
   * @param editor
   */
  const refCallback = (editor) => {
    if (!ref?.current && editor?.editor && editor?.state && editor?.view) {
      ref.current = editor; // store it
    }
  }

  return (
    <div className="Body6_R g900">
      <CodeMirror
        ref={refCallback}
        className='CodeMirror'
        lineNumber={true}
        value={
          JSON.stringify(
            currentResponseCase.responseCaseDetail?.testCase,
            null,
            2,
          )
        }
        extensions={[
          // 2024.04.27 [energysteel]: JSON 색상
          json(),
          // 2024.04.27 [energysteel]: JSON Lint
          linter(jsonParseLinter()),
          // 2024.04.27 [energysteel]: JSON 에러구문
          lintGutter(),
          Prec.highest(
            editorHighlight(
              {
                color: "#c3e607"
              }
            )
          ),
        ]}
        onChange={handleJsonEditorOnChange}
        theme={vscodeDark}
        height={"500px"}
        gutter={true}
      />
    </div>
  );
};

export default SettingTestCaseTable;
