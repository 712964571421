import React from "react";

const Title = ({ title, subTitle }) => {
  return (
    <>
      <div className="Body3_B g900">{title}</div>
      <div className="Body7_R g400 mt10 mb14">{subTitle}</div>
    </>
  );
};

export default Title;
