import React from "react";

const BusinessErrorCode = ({ clazz, setManualKey }) =>{
    return (
      <>
          <div className={clazz}>
              <p>API 처리중 발생할 수 있는 Business 오류 코드를 등록합니다.</p><br/>
              <br/>
              <div style={{width: 786}}>
                  <img
                    src={`${process.env.PUBLIC_URL}/manual/manual_common_error_1.png`}
                    alt={"example_head"}
                    width={784}
                    height={200}
                  />
                  <br/>
                  <br/>
                  <p><b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
                  <p>1. Error Name: Business Error의 이름을 입력합니다..</p><br/>
                  <p>2. Error Code: API 응답에 포함될 Error Code를 입력합니다.</p><br/>
                  <p>3. Error Message: API 응답에 포함될 Error Message를 입력합니다.</p><br/>
                  <p>4. Http Code: API 응답 시 수신받을 Http Code를 선택합니다.</p><br/>
              </div>
          </div>
      </>
    )
}

const ValidationErrorCode = ({ clazz, setManualKey }) =>{
    return (
      <>
          <div className={clazz}>
              <p>Validation 에러 코드는 API 요청 시 서버에서 처리할 입력값 체크에 대한 오류를 등록합니다.<br/>
              </p>
              <br/>
              <br/>
              <div style={{width: 786}}>
                  <img
                    src={`${process.env.PUBLIC_URL}/manual/manual_common_error_2.png`}
                    alt={"example_head"}
                    width={784}
                    height={240}
                  />
                  <br/>
                  <br/>
                  <p>Validation 에러코드는 <a href={'#2-3'} onClick={() => setManualKey("2-3")}>Request</a> Parameter에 대해
                    기본적으로 6가지 오류코드를 제공합니다. <br/>에러코드
                    추가는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
                  <p>1. Request 필수 여부: Request Parameter 의 필수여부 체크에 대한 오류코드 입니다.</p><br/>
                  <p>2. Request 암호화 여부 Type: Request Parameter 의 암호화 여부 체크에 대한 오류코드 입니다.</p><br/>
                  <p>3. Request Enum 체크: Request Parameter 의 Enum 항목 체크에 대한 오류코드 입니다.</p><br/>
                  <p>4. Request 정규식 체크: Request Parameter 의 정규식 체크에 대한 오류코드 입니다.</p><br/>
                  <p>5. Request Data Type 체크: Request Parameter 의 데이터 타입 체크에 대한 오류코드 입니다.</p><br/>
                  <p>6. Request Min/Max 체크: Request Parameter 의 입력 허용값 범위 체크에 대한 오류코드 입니다.</p><br/>
              </div>
          </div>
      </>
    )
}

const HttpErrorCode = ({ clazz, setManualKey }) => {
    return (

      <div className={clazz}>
          <p>Http 에러 코드는 </p>
          <br/>
          <br/>
          <div style={{width: 786}}>
              <img
                src={`${process.env.PUBLIC_URL}/manual/manual_common_error_3.png`}
                alt={"example_head"}
                width={784}
                height={200}
              />
              <br/>
              <br/>
          </div>
          <p>Test Case는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
          <p>1. Test Case Name: 테스트 케이스의 이름을 입력합니다.</p><br/>
          <p>2. Response Type: 응답 유형을 선택합니다.(정상응답/에러응답 중 선택할 수 있습니다)</p><br/>
          <p>3. Default Response: API요청의 기본 응답을 선택합니다.(한 개만 선택 가능합니다)</p><br/>
          <br/>
      </div>
    )
}
export { BusinessErrorCode, ValidationErrorCode, HttpErrorCode, };