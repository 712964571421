import React from "react";
import { Layout } from "antd";
import Header from "../layout/projectList/Header";
import Footer from "../layout/projectList/Footer";
import Content from "../layout/projectList/Content";

const Main = ({
  name,
  extend = name,
  error,
  resetErrorBoundary,
}) => {
  return (
    <Layout className={extend}>
      <Header />
      <Content name={name} error={error} resetErrorBoundary={resetErrorBoundary}/>
      <Footer />
    </Layout>
  );
};

export default Main;
