import React, { useCallback, useEffect, useState } from "react";
import Image from "../../atoms/Image";
import {Link, useLocation} from "react-router-dom";
import Numbering from "../../atoms/Numbering";
import { useRecoilState } from "recoil";
import { userState } from "../../../recoil/user/userState";
import { SquareButton } from "../../atoms/Button";
import RequiredText from "../../atoms/RequiredText";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import useAxiosInterceptor from "../../../axios/axios";
import { emailSendApi } from "../../../api/email/emailApi";
import _ from "lodash";
import Loader from "../../common/Loader";

const RootContent = () => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  const [num, setNum] = useState("1");
  const [user] = useRecoilState(userState); //사용자 세션 정보 관리
  const [currentImage, setCurrentImage] = useState(0);
  // 2024.04.10 [shiningtrue]: input에서 value를 담기 위한 state 생성
  const [inputValues, setInputValues] = useState("");
  const location = useLocation();
  const [isContactUsLoading, setIsContactUsLoading] = useState(false);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const onChangeInput = (e, name) => {
    const nameText = name;
    const value = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [nameText]: value,
    }));
  };

  const userCategory = [
    {
      img: `ic_developer`,
      title: "개인개발자",
      content: (
        <p>
          예비 개발자, Front-End 개발자,
          <br />
          자유롭게 애플리케이션을 개발하고 싶은 <br className="tabl-lg-br" />{" "}
          모든 개발자
        </p>
      ),
    },
    {
      img: `ic_startup`,
      title: "스타트업",
      content: (
        <p>
          Back-End 개발전에 MVP 검증을 위한
          <br className="tabl-lg-br" /> 애플리케이션 <br className="desk-br" />
          개발이 필요한
          <br className="tabl-lg-br" /> 1인 법인 및 스타트업 기업들
        </p>
      ),
    },
    {
      img: `ic_study`,
      title: "교육기관",
      content: (
        <p>
          커리큘럼에 따라 API 연동 및<br className="tabl-lg-br" /> 개발을
          MockBot으로
          <br className="desk-br" />
          처리하고 싶은
          <br className="tabl-lg-br" /> 모든 학교, 학원, 교육기관
        </p>
      ),
    },
    {
      img: `ic_company`,
      title: "일반기업",
      content: (
        <p>
          복잡한 등록 절차 없이 바로 개발이
          <br className="tabl-lg-br" /> 필요한 기업, <br className="desk-br" />
          중요 데이터를 보호하면서
          <br className="tabl-lg-br" /> 애플리케이션 개발이 필요한 기업,
          <br className="desk-br" />
          품질
          <br className="tabl-lg-br" /> 높은 코드를 위한 공통 표준이 필요한 기업
        </p>
      ),
    },
  ];

  const helpData = [
    {
      name: "enterprise",
      title: <RequiredText className="g500 mb8" text={"기업"} />,
      placeholder: "기업명을 입력해주세요.",
    },
    {
      name: "name",
      title: <RequiredText className="g500 mb8" text={"담당자 이름"} />,
      placeholder: "담당자 이름을 입력해주세요.",
    },
    {
      name: "phoneNumber",
      title: <RequiredText className="g500 mb8" text={"연락처"} />,
      placeholder: "연락처를 입력해주세요.",
    },
    {
      name: "email",
      title: <RequiredText className="g500 mb8" text={"이메일"} />,
      placeholder: "이메일 주소를 입력해주세요.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % 3); // Cycling through 3 images
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  // 2024.05.10 [shiningtrue]: 문의 이메일
  const emailSend = async (e) => {
    if (!inputValues.enterprise) {
      toast.error("기업명을 입력해 주세요.");
      return false;
    }

    if (!inputValues.name) {
      toast.error("담당자 이름을 입력해 주세요.");
      return false;
    }

    if (!inputValues.phoneNumber) {
      toast.error("연락처를 입력해 주세요.");
      return false;
    }

    if (!inputValues.email) {
      toast.error("이메일을 입력해 주세요.");
      return false;
    }

    if (!inputValues.contents) {
      toast.error("문의내용을 입력해 주세요.");
      return false;
    }
    const params = {
      enterprise: inputValues.enterprise,
      name: inputValues.name,
      phoneNumber: inputValues.phoneNumber,
      email: inputValues.email,
      contents: inputValues.contents,
    };

    setIsContactUsLoading(true);

    await emailSendApi(
      axios,
      params,
      emailSendApiSuccessCallback,
      () => setIsContactUsLoading(false),
    );
  };

  // 2024.05.19 [shiningtrue]: debounce
  const emailSendDebounce = _.debounce(emailSend, 200);

  const emailSendApiSuccessCallback = (data) => {
    setIsContactUsLoading(false);

    if (data.data.code === "0001") {
      toast.success("전송 되었습니다.");
      setInputValues("");
    } else {
      toast.error(data.data.data.message);
    }
  };

  return (
    <div className="root-main">
      <div
        className="body-start"
        style={{
          background: `url(${process.env.PUBLIC_URL}/main.png) no-repeat center`,
        }}
      >
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/ic_mockbot_logo_96_w.png`}
            alt={"main_logo"}
            width={96}
            height={96}
          />
          <h2 className="body-start-title">All-In-One API Service MockBot</h2>
          <p className="Body4_R mb52">
            MockBot 서비스는 REST API 기반의 Application
            <br />
            개발/테스트를 지원하기 위한 쉽고 빠른 API Mock 서비스 입니다.
          </p>
          <Link to={user.id !== null ? "/main" : "/signin"}>
            <button className="body-start-btn">
              <span className="body-start-lg">MockBot</span>
              <span className="body-start-sm">목봇</span> 시작하기
              <Image
                className="non-btn ml6 mb-4"
                path={process.env.PUBLIC_URL}
                name={"ic_arrow_start"}
                size="md"
              />
              <Image
                className="hover-btn ml6 mb-4"
                path={process.env.PUBLIC_URL}
                name={"ic_arrow_start_p"}
                size="md"
              />
            </button>
          </Link>
        </div>
      </div>
      <div className="body-sec">
        <div className="ta">
          <p className="body-all-title">애플리케이션 로직에만 집중하세요</p>
          <p className="Body3_R g500 mt6 mb40"></p>
          <div className="body-sec-flex">
            {[1, 2, 3].map((v, i) => {
              return (
                <div className="card">
                  <div className="card-btn">
                    {i === 0 ? (
                      <p>API 요청/응답</p>
                    ) : i === 1 ? (
                      <p>Validation 처리</p>
                    ) : i === 2 ? (
                      <p>Exception 처리</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-content">
                    {i === 0 ? (
                      <p>
                        MockBot은 API 요청/응답 정보를 등록하여 실제 개발/운영
                        서버와 연동하여 개발하는 것과 동일하게 애플리케이션을
                        개발할 수 있습니다
                      </p>
                    ) : i === 1 ? (
                      <p>
                        MockBot은 단순히 등록된 값을 리턴하는 것이 아니라
                        애플리케이션의 요청 파라미터의 유효성 체크를 통한
                        비즈니스 로직 구현을 지원합니다
                      </p>
                    ) : i === 2 ? (
                      <p>
                        MockBot은 실제 개발/운영 서버에서 발생할 수 있는 다양한
                        Exception을 등록하여 애플리케이션의 Exception 처리 로직
                        구현을 지원합니다
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="body-third">
        <div className="body-third-content">
          <p className="body-all-title">시간과 장소에 구애받지 마세요</p>
          <p className="Body3_R g500 mt6 mb40">
            Back-End 개발을 기다리지 마세요
          </p>
          <div>
            <div className="body-third-item">
              <img
                className="first-img"
                src={`${process.env.PUBLIC_URL}/img_main_web_allinone.png`}
                alt={"example_head"}
              />
              <div className="item-card">
                <Numbering className="item-card-num" num={"1"} type={"pri"} />
                <div>
                  <p className="item-card-title">
                    애플리케이션의 개발 비용과 <br className="desk-br" />
                    시간이 줄어듭니다
                  </p>
                  <p className="item-card-content">
                    언제 어디서 누구나 MockBot을 통해서
                    <br className="desk-br" />
                    애플리케이션을 개발할 수 있습니다.
                    <br />
                    서버에서 REST API를 통해서 데이터를{" "}
                    <br className="desk-br" />
                    받아오기 위한 복잡한 과정은 MockBot에{" "}
                    <br className="desk-br" />
                    맏겨놓고
                    <br className="tabl-tg-br" /> 애플리케이션 개발에
                    집중하세요. <br className="desk-br" />
                    <br className="tabl-lg-br" />
                    MockBot은 복잡한 권한과 계정 절차를
                    <br className="desk-br" />
                    기다리지 않고
                    <br className="tabl-tg-br" /> 바로 개발을 시작할 수 있어{" "}
                    <br className="desk-br" />
                    사업의 비용과 시간을 획기적으로 줄일 수{" "}
                    <br className="desk-br" />
                    있습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="body-third-item">
              <img
                className="first-img"
                src={`${process.env.PUBLIC_URL}/img_main_web_testcase.png`}
                alt={"img_main_web_testcase"}
              />
              <div className="item-card">
                <Numbering className="item-card-num" num={"2"} type={"pri"} />
                <div>
                  <p className="item-card-title">
                    다양한 테스트로 품질이 <br className="desk-br" />
                    향상됩니다
                  </p>
                  <p className="item-card-content">
                    MockBot은 API를 동록하고 다양한 응답값을 설정할 수 있습니다.{" "}
                    <br className="tabl-lg-br" />
                    <br className="tabl-tg-br" />
                    정상 응답부터 실제 발생할 수 있는 오류를 처리하기 위해
                    응답설정을 변경하며 개발할 수 있습니다.
                    <br />
                    이제 MockBot으로 비즈니스 로직 에러는
                    <br className="desk-br" />
                    물론이고 재현하기 어려운 시스템 에러까지 <br />
                    완벽한 테스트로 애플리케이션의 품질을
                    <br className="desk-br" />
                    향상할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="body-third-item">
              <div className="slideshow">
                <div
                  className="slide"
                  style={{ transform: `translateX(-${currentImage * 100}%)` }}
                >
                  <div>
                    <img
                      className="first-img"
                      src={`${process.env.PUBLIC_URL}/img03-01.png`}
                      alt={"img03-01"}
                    />
                  </div>
                  <div>
                    <img
                      className="first-img"
                      src={`${process.env.PUBLIC_URL}/img03-02.png`}
                      alt={"img03-02"}
                    />
                  </div>
                  <div>
                    <img
                      className="first-img"
                      src={`${process.env.PUBLIC_URL}/img03-03.png`}
                      alt={"img03-03"}
                    />
                  </div>
                </div>
                <div className="slide-btn">
                  {[0, 1, 2].map((val) => {
                    return (
                      <div
                        className="p8 cur"
                        onClick={() => setCurrentImage(val)}
                      >
                        <div
                          className={`${val === currentImage ? "slide-btn-act " : "slide-btn-inact"}  `}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="item-card last">
                <Numbering className="item-card-num" num={"3"} type={"pri"} />
                <div>
                  <p className="item-card-title">
                    공통에러/공통코드 등록으로 <br className="desk-br" />
                    코드 가독성이 높아집니다
                  </p>
                  <p className="item-card-content">
                    공통 코드와 변수를 사용하여 코드 중복을{" "}
                    <br className="desk-br" />
                    줄일 수 있습니다. <br className="tabl-lg-br" /> 일관된
                    패턴과 네이밍 <br className="desk-br" />
                    규칙을 <br className="tabl-tg-br" /> 적용하여 코드를 더 쉽게
                    이해할
                    <br className="desk-br" /> 수 있습니다.{" "}
                    <br className="tabl-lg-br" /> 공통 에러 처리를 통해 예외{" "}
                    <br className="desk-br" />
                    상황을 일관된 방식으로 <br className="tabl-tg-br" /> 처리할
                    수 있습니다. 이로 인해 운영 유지보수가 용이해집니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="body-fourth">
        <div className="body-fourth-width">
          <p className="body-all-title white">MockBot 자유롭게 사용하세요</p>
          <div className="body-fourth-content">
            {userCategory.map((v, i) => {
              return (
                <div className="card-lg">
                  <div className="content bg-brand500 br100 p10">
                    <Image
                      path={`${process.env.PUBLIC_URL}`}
                      name={v.img}
                      size="md"
                    />
                  </div>
                  <div>
                    <div className="card-btn">{v.title}</div>
                    <div className="card-content">{v.content}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="inquiry" className="body-last">
        <div className="body-last-content">
          <p className="body-all-title mt12 mb12 ">
            MockBot의 기능이 더 궁금하시다면
            <br/> 전문가의 도움을 받아보세요
          </p>
          <p className="Body3_R g500">
            해당 폼을 작성해 문의 주시면 최대한 빠르게 답변 드리겠습니다
          </p>
          <div className="body-last-form">
            {helpData.map((v, i) => {
              return (
                <div style={{display: "inlineFlex"}}>
                  {v.title}
                  <Input
                    className="body-last-form-input Body4_R"
                    value={inputValues[v.name]}
                    onChange={(e) => {
                      onChangeInput(e, v.name);
                    }}
                    placeholder={v.placeholder}
                  />
                </div>
              );
            })}
          </div>
          {isContactUsLoading &&
            <div style={{display: "flex", justifyContent: "center",}}>
              <Loader/>
            </div>
          }
          <p className="g500 mt32 mb8"><RequiredText className="g500 mb8" text={"문의내용"} /></p>
          <TextArea
            className="Body4_R g900 bg-white br3 mb24"
            placeholder="문의사항에 대해 자유롭게 작성해주세요."
            autoSize={{minRows: 5, maxRows: 5}}
            name="contents"
            value={inputValues.contents}
            onChange={(e) => {
              onChangeInput(e, "contents");
            }}
          />
          <div className="Body3_R g500 mb64">
            <p>
              <span
                className="Body3_B g900"
                style={{textDecoration: "underline"}}
              >
                MockBot의 개인정보 보호정책
              </span>
              에 동의하고 이 양식을 제출합니다.
            </p>
            <p className="mt8">
              별표(<span className="error">*</span>)가 있는 항목은
              필수항목입니다.
            </p>
          </div>
          <SquareButton
            className={"btn-pri"}
            text={"제출하기"}
            size="md"
            width="276px"
            onClick={emailSendDebounce}
          />
        </div>
      </div>
    </div>
  );
};

export default RootContent;
