import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, Dropdown, Pagination, Space, Tooltip} from "antd";
import Image from "../atoms/Image";
import CreateProjectModal from "../organisms/modals/CreateProjectModal";
import {Link} from "react-router-dom";
import SettingProjectModal from "../organisms/modals/SettingProjectModal";
import InviteAcceptModal from "../organisms/modals/InviteAcceptModal";
import {useRecoilState} from "recoil";
import {formatDate} from "date-fns";
import {
  isModalOpenState,
  openModal,
  pageState,
  projectListState, projectState,
  useDropdownItems
} from '../../recoil/project/projectState';
import InviteProjectUserModal from "../organisms/modals/InviteProjectUserModal";
import ProjectDeleteModal from "../organisms/modals/ProjectDeleteModal";
import {exportApi} from "../../api/project/projectApi";
import useAxiosInterceptor from "../../axios/axios";
import Loader from "../common/Loader";

const ProjectList = () => {
  const axios = useAxiosInterceptor();
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const [page, setPage] = useRecoilState(pageState);

  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [importLoading, setImportLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);


  useEffect(() => {
    setIsModalOpen(projectList.map((value) => ({ id: value.id, create: false, update: false, delete: false, inviteProject: false, inviteAccept: false, })));
  }, [projectList, setProjectList, setIsModalOpen]);

  const handleCreateModal = () => {
    setIsCreateModalOpen(true);
  }

  const useMoreItemsHandle = (project) => {
    return useDropdownItems(
      project,
      () => openModal(setIsModalOpen, project.id, 'update'),
      () => openModal(setIsModalOpen, project.id, 'invite'),
      () => projectExport(project),
      () => openModal(setIsModalOpen, project.id, 'delete'),
    );
  }

  /**
   * 2024.04.26 [energysteel]: Project Export
   * @param project 선택한 Project
   */
  const projectExport = (project) => {
    exportApi(axios, project);
  }

  const showInviteAcceptModal = (id) => {
    openModal(setIsModalOpen, id, "inviteAccept")
  };
  
  const handlePageClick = (newPageNumber) => {
    setPage(prevPage => ({
      ...prevPage,
      pageNumber: newPageNumber - 1,
    }))
  };


  return (
    <div className="projectCard">
      {
        projectList.map((value, i) => {
          return <div key={i}>
            <div
              className="card content jc-sb b-g200 br10"
              style={{
                height: "112px",
              }}
            >
              <div
                className={value.inviteStatus === 'PENDING' ? "content jc-sb m28" : "m28"}
                style={{
                  width: "calc(1194px - 171px - 28px - 28px )",
                }}
              >
                <Link 
                  to={`/project/${value.id}`}
                >
                  <div className="content">
                    <Tooltip
                      placement="bottom"
                      title={"Project Manager로 이동"}
                      className="tooltip content Body2_B g900 mr6"
                    >
                      <span className="mr6 ellipsis-mw930">{value.name}</span>
                      <Image
                        className="non"
                        path={`${process.env.PUBLIC_URL}/projectList`}
                        name={"ic_arrow_right_default_24"}
                        size={"md"}
                      />
                      <Image
                        className="hover"
                        path={`${process.env.PUBLIC_URL}/projectList`}
                        name={"ic_arrow_right_hovered_24"}
                        size={"md"}
                      />
                    </Tooltip>
                  </div>
                </Link>
                {value.inviteStatus === 'PENDING'
                  ?
                    <>
                      <div
                        className="cur content jc-c b-g300 b-g100 Body6_B g500 br3"
                        style={{
                          width: "80px",
                          height: "28px",
                          background: "#7c60ff",
                          color: "#fff",
                        }}
                        onClick={() => showInviteAcceptModal(value.id)}
                      >
                        초대 수락
                      </div>
                      {/* 초대 수락 Modal */}
                      {isModalOpen?.find(v => v.id === value.id)?.inviteAccept === true &&
                        <InviteAcceptModal
                          project={value}
                        />
                      }
                    </>
                  :
                    <>
                      <div className="Body6_R g400 mt11">
                        {value.authority} / {value.accessAuthority}
                      </div>
                    </>
                }
              </div>
              <div
                className="Body6_R g400 bl-g200"
                style={{ width: "171px", height: "75px" }}
              >

                <div
                  className="cur mr14"
                  style={{ height: "16px", textAlign: "right" }}
                >
                  {value.inviteStatus !== 'PENDING' &&
                    <Dropdown
                      placement="bottomRight"
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      menu={useMoreItemsHandle(value, i)}
                      className="g500"
                      overlayStyle={{
                        width: "156px",
                        height: "114px",
                      }}
                      trigger={["click"]}
                    >
                      <Space style={{ lineHeight: "16px" }}>
                        <Image path={`${process.env.PUBLIC_URL}/projectList`} name={"ic_more"} />
                      </Space>
                    </Dropdown>
                  }
                </div>
                <div
                  style={{
                    width: "171px",
                    height: "59px",
                    textAlign: "center",
                    marginTop: "12px",
                  }}
                >

                  {
                    value.inviteStatus !== 'PENDING' ?
                      `등록일 : ${formatDate(new Date(value.createDate), 'yyyy.MM.dd')}`
                      :
                      `만료일 : ${formatDate(new Date(value.expiredDate), 'yyyy.MM.dd')}`
                  }

                </div>
              </div>
            </div>
            {/* Dropdown 프로젝트 설정 Modal */}
            {isModalOpen?.find(v => v.id === value.id)?.update === true &&
              <SettingProjectModal
                project={value}
              />
            }
            {/* Dropdown 사용자 초대 Modal */}
            {isModalOpen?.find(v => v.id === value.id)?.invite === true &&
              <InviteProjectUserModal
                project={value}
              />
            }
            {/* Dropdown 삭제 Modal */}
            {isModalOpen?.find(v => v.id === value.id)?.delete === true &&
              <ProjectDeleteModal
                project={value}
                setDeleteLoading={setDeleteLoading}
              />
            }
          </div>
        })
      }
      <div className="content jc-c" style={{
        height: "59px",
        marginTop: "12px",
      }}>
        <ConfigProvider
          theme={{
            components: {
              Pagination: {
                colorPrimary: "#131418",
                colorPrimaryBorder: "#ffffff",
                colorPrimaryHover: "#131418",
                colorText: "#bfc7d9",
                lineWidth: 0,
              },
            },
          }}
        >
          <Pagination
            className="Body4_R"
            current={page.pageNumber + 1}
            total={page.totalElements}
            pageSize={page.pageSize}
            onChange={handlePageClick}
            showSizeChanger={false}
          />
        </ConfigProvider>

      </div>

      <div className="content jc-c">
        <Button
          className="btn-pri Body4_B mt36 br5"
          style={{ width: "200px", height: "48px" }}
          type="primary"
          onClick={handleCreateModal}
        >
          Project 생성하기
        </Button>
        <CreateProjectModal
          isCreateModalOpen={isCreateModalOpen}
          setIsCreateModalOpen={setIsCreateModalOpen}
          importLoading={importLoading}
          setImportLoading={setImportLoading}
        />
      </div>
      {(importLoading || deleteLoading) &&
        <div className="content jc-c">
          <Loader />
        </div>
      }
    </div >
  );
};

export default ProjectList;
