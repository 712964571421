import React from "react";

const EnvVariableChild = ({ clazz, setManualKey }) =>{
    return (
        <>
            <div className={clazz}>
                <p>등록은 불가능하며 변경만 가능합니다.</p><br/>
                <br/>
                <div style={{width: 786}}>
                    <img
                        src={`${process.env.PUBLIC_URL}/manual/manual_env_variable.png`}
                        alt={"example_head"}
                        width={791}
                        height={315}
                    />
                    <br/>
                    <br/>
                </div>
            </div>
        </>
    )
}
export default EnvVariableChild;