import React from "react";

const UserVariableChild = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>사용자 변수는 API의 <a href={'#2-4'} onClick={() => setManualKey("2-4")}>Response</a> Parameter로 등록할 수 있습니다.</p>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_user_variable_1.png`}
            alt={"example_head"}
            width={791}
            height={315}
          />
          <br/>
          <br/>
          <p>사용자변수는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
        </div>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_user_variable_2.png`}
            alt={"example_head"}
            width={791}
            height={315}
          />
          <br/>
          <br/>
          <p>사용자변수는 <b>휴지통</b> 버튼으로 삭제할 수 있습니다.</p><br/>
        </div>
      </div>
    </>
  )
}
export default UserVariableChild;