import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Layout, Space } from "antd";
import Image from "../../atoms/Image";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import {
  isModalOpenState,
  openModal,
  projectState,
  useDropdownItems,
} from "../../../recoil/project/projectState";
import SettingProjectModal from "../../organisms/modals/SettingProjectModal";
import InviteProjectUserModal from "../../organisms/modals/InviteProjectUserModal";
import {
  exportApi,
  loadProjectNamesApi,
} from "../../../api/project/projectApi";
import ProjectDeleteModal from "../../organisms/modals/ProjectDeleteModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../recoil/user/userState";
import useAxiosInterceptor from "../../../axios/axios";
import {
  alertHelpIconOpenState,
  helpIconState,
} from "../../../recoil/api/apiState";
import { commonErrorEditingRecoilState } from "../../../recoil/commonErrorCode/commonErrorCodeState";
import { commonVariableEditingRecoilState } from "../../../recoil/commonVariableState/commonVariableState";
import HelpIcon from "./HelpIcon";
import ConfirmModal from "../../organisms/modals/ConfirmModal";

const { Header: AntdHeader } = Layout;

const Header = ({ setMenuKey }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 현재 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.06 [energysteel]: 헤더 프로젝트 이름 목록
  const [projectNameList, setProjectNameList] = useState({});
  // 2024.03.06 [energysteel]: Modal Open 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  // 2024.03.06 [energysteel]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.03.06 [energysteel]: 사용자 ID
  const userId = userSession.id;

  const [alertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
   // 2024.03.18 [shiningtrue]: 공통에러 수정 여부 상태 관리 변수
  const [, setCommonResponseFormEditingState] =
    useRecoilState(commonErrorEditingRecoilState);
  // 2024.03.18 [shiningtrue]: 공통변수 수정 여부 상태 관리 변수
  const [, setCommonVariableEditingState] =
    useRecoilState(commonVariableEditingRecoilState);
  const [helpIcon] = useRecoilState(helpIconState);


  const { authContextLogout } = useAuth();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [location, setLocation] = useState("");


  // 2024.03.06 [energysteel]: 상단 프로젝트 이름 목록 조회
  useEffect(() => {
    loadProjectNamesApi(axios, userId, loadProjectNamesApiSuccessCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const loadProjectNamesApiSuccessCallback = (response) => {
    setProjectNameList({
      items: response.data.data.map((value) => ({
        label: (
          <div
            className="Body6_B brand500 ellipsis-w250"
            onClick={() => handleProjectNameOnClickEvent(value.id)}
            style={{ cursor: "pointer" }}
          >
            {value.name}
          </div>
        ),
        key: value.id,
      })),
    });
  };

  /**
   * 2024.03.07 [energysteel]: 프로젝트 이동 시 좌측 사이드탭 APIs로 이동
   *  - MenuKey:
   *   1: Apis
   *   2: 공통에러
   *   3: 공통변수
   *   4: 사용자
   */
  const handleProjectNameOnClickEvent = (id) => {
    const elementsWithActiveIconClass =
        document.querySelectorAll(".active-icon");
    if (elementsWithActiveIconClass.length > 0) {
      setModalOpen(true);
      setLocation(`/project/${id}`);
    } else {
      navigate(`/project/${id}`);
      setMenuKey("1");
    }
  };

  const handleMainClick = () => {
    const elementsWithActiveIconClass =
      document.querySelectorAll(".active-icon");
    if (elementsWithActiveIconClass.length > 0) {
      setModalOpen(true);
      setLocation("/main");
    } else {
      window.location.href = "/main";
    }
  };

  /**
   * 2024.03.06 [energysteel]: 헤더 케밥 메뉴 Dropdown 목록 Click Event
   * @returns {{items: *}}
   */
  const useDropdownItemsHandle = () => {
    return useDropdownItems(
      project,
      () => openModal(setIsModalOpen, project.id, "update"),
      () => openModal(setIsModalOpen, project.id, "invite"),
      () => projectExport(project),
      () => openModal(setIsModalOpen, project.id, "delete"),
    );
  }

  /**
   * 2024.04.26 [energysteel]: Project Export
   * @param project 선택한 Project
   */
  const projectExport = (project) => {
    exportApi(axios, project);
  }

  const headerLogout = () => {
    navigate("/signin");
    authContextLogout();
  };

  const profileItems = {
    items: [
      {
        label: (
          <Link to="/account">
            <div className="g500">
              <span className="Body6_B ellipsis-mw200">{userSession.name}</span>
              <br />
              <span className="Body7_R ellipsis-mw200">{userSession.email}</span>
            </div>
          </Link>
        ),
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: (
          <div className="header g500" onClick={headerLogout}>
            <Image
              path={`${process.env.PUBLIC_URL}/header`}
              name={"ic_logout"}
            />
            <span className="Body6_B ml4">로그아웃</span>
          </div>
        ),
        key: "1",
      },
    ],
  };

  const handleProjectMoveNonSave = () => {
    //공통에러 수정 여부 초기화
    setCommonResponseFormEditingState({
      responseHeaderEditing: false,
      responseBodyEditing: false,
      errorCodeEditing: false,
    });
    //공통변수 수정 여부 초기화
    setCommonVariableEditingState({ editing: false });

    navigate(location);
    setMenuKey("1");

    setModalOpen(false);
  }

  const handleProjectMoveNonSaveCancel = () => {
    setModalOpen(false);
    setLocation("");
  }


  return (
    <AntdHeader className="project-header">
      <div className="header">
        <div className="header mr48">
          <Image
            path={`${process.env.PUBLIC_URL}/header`}
            name={"ic_mockbot"}
          />
          <span className="Body6_B ml4">프로젝트 매니저</span>
        </div>
        <Dropdown
          menu={projectNameList}
          overlayStyle={{ width: "280px", height: "114px" }}
          trigger={["click"]}
        >
          <Space style={{ lineHeight: "16px" }}>
            <div
              className="header b-g150 bg-g100 roboto_R br3 mr6"
              style={{
                width: "94px",
                height: "24px",
                textAlign: "center",
              }}
            >
              <span className="g500 mr4 ft12 ml9 ellipsis">
                {project.name}
              </span>
              <Image
                path={`${process.env.PUBLIC_URL}/header`}
                name={"ic_dropdown_down"}
              />
            </div>
          </Space>
        </Dropdown>
        <Dropdown
          menu={useDropdownItemsHandle()}
          overlayStyle={{ width: "156px", height: "114px" }}
          trigger={["click"]}
        >
          <Space style={{ lineHeight: "16px" }}>
            <Image path={`${process.env.PUBLIC_URL}/header`} name={"ic_more"} style={{ cursor: "pointer" }} />
          </Space>
        </Dropdown>
        <HelpIcon
          alertHelpIconOpen={alertHelpIconOpen}
          setMenuKey={setMenuKey}
          code={helpIcon.icon}
          text={helpIcon.text}
        />
        {/* //다른 아이콘 예시 
        <HelpIcon code="warn" text={"프로젝트 내용을 확인해주세요."} />
        <HelpIcon
          code="success"
          text={"프로젝트를 정상적으로 이용할 수 있습니다."}
        /> */}
      </div>

      <div className="header">
        <div
          className="header mr48"
          style={{ cursor: "pointer" }}
          onClick={handleMainClick}
        >
          <span className="Body6_R g500 mr6">Mock Bot 메인으로 이동</span>
          <Image
            path={`${process.env.PUBLIC_URL}/header`}
            name={"ic_newpage"}
          />
        </div>
        <Dropdown
          menu={profileItems}
          overlayStyle={{ width: "280px", height: "114px" }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Avatar
            src={`${process.env.PUBLIC_URL}/header/ic_profile.png`}
            size={28}
            style={{ cursor: "pointer" }}
          />
        </Dropdown>
      </div>

      {isModalOpen.find((v) => v.id === project.id)?.update === true && (
        <SettingProjectModal project={project} />
      )}

      {isModalOpen.find((v) => v.id === project.id)?.invite === true && (
        <InviteProjectUserModal project={project} />
      )}

      {isModalOpen.find((v) => v.id === project.id)?.delete === true && (
        <ProjectDeleteModal redirect={true} project={project} />
      )}

      <ConfirmModal
        title={"프로젝트 이동"}
        message={"저장 되지 않은 데이터가 존재합니다.\n프로젝트를 이동 하시겠습니까?"}
        open={modalOpen}
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleProjectMoveNonSave}
        handleCancel={handleProjectMoveNonSaveCancel}
      />

    </AntdHeader>
  );
};

export default Header;
