import React, {useEffect, useState} from "react";
import TooltipImage from "../../../atoms/TooltipImage";
import {Table} from "antd";
import {EditableCell, EditableRow} from "../../../atoms/Editable";
import Image from "../../../atoms/Image";
import ModalSelect from "../../../atoms/ModalSelect";
import SettingTestCaseModal from "../../modals/SettingTestCaseModal";
import TableWrapper from "../../../molecules/TableWrapper";
import {useRecoilState, useRecoilValue} from "recoil";
import {commonErrorCodeListState, isApiAuthority, projectState,} from "../../../../recoil/project/projectState";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  helpIconMessageState,
  selectedApiState,
  tabOpenListState,
} from "../../../../recoil/api/apiState";
import {userState} from "../../../../recoil/user/userState";
import {dataTypeDefaultValue} from "../../../../recoil/response/responseState";

const ApiTestcaseContent = () => {
  const [isModalOpen, setIsModalOpen] = useState([]);
  const [own, setOwn] = useState(false);
  // 2024.03.19 [shiningtrue]: List 상태 관리 변수
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.03.29 [energysteel]: Project 정보
  const [project] = useRecoilState(projectState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.03.28 [energysteel]: Recoil 관리중인 에러 코드
  const [commonErrorCodesRecoil] = useRecoilState(commonErrorCodeListState);
  // 2024.03.28 [energysteel]: Recoil 관리중인 에러 코드 복사
  const [commonErrorCodes] = useState(commonErrorCodesRecoil);

  const commonErrorCodeList = commonErrorCodes
    .filter(errorCode => errorCode.type !== 'REQ_VALIDATION')
    .map(commonErrorCode => {
      return {
        value: commonErrorCode.key,
        label: commonErrorCode.name,
        code: commonErrorCode.code,
        message: commonErrorCode.message,
      }
    })

  let responseItem = tabOpenList.find((item) => item.id === selectedApi.id);
  useEffect(() => {
    responseItem = tabOpenList.find((item) => item.id === selectedApi.id);
  }, [selectedApi.id]);

  // 2024.03.19 [shiningtrue]: responseCase 수정시 editing true처리
  const modifyApiResponseCase = () => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseCaseEditing: true,
          };
        }
        return tab;
      });
    });
  };

  // 2024.03.19 [shiningtrue]: setTabOpenList 상태 변수 해당 object 변경 함수
  const addResponseCaseItem = (property, object) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            [property]: object,
          };
        }
        return tab;
      });
    });
    modifyApiResponseCase();
  };

  // 2024.03.18 [shiningtrue]: row별 input값 수정시 저장 함수
  const handleSave = (row) => {
    const newData = [...responseItem?.responseCase];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    addResponseCaseItem("responseCase", newData);
  };

  // 2024.03.19 [shiningtrue]: 상세 모달 open
  const showModal = (record) => {
    setIsModalOpen(prev => {
      if (prev.length === 0) {
        return [{ id: record.key, open: true }];
      }

      return prev.map((modal) => {
        if (modal.id === record.key) {
          return {
            ...modal,
            open: true,
          };
        } else {
          return {
            id: record.key,
            open: true,
          };
        }
      });
    });
  };

  // 2024.03.19 [shiningtrue]: row 삭제
  const handleDelete = (row) => {
    if (row.defaultResponse) {
      setHelpIconMessage({
        code: "error",
        message: "기본 응답으로 설정된 Test Case는 삭제할 수 없습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return;
    }
    const newData = responseItem?.responseCase.filter(
      (item) => item.key !== row.key,
    );
    addResponseCaseItem("responseCase", newData);
  };

  // 2024.03.19 [shiningtrue]: response recoil object 상태 변수 수정
  const setResponseCaseData = (newData) => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseCase: [...tab.responseCase, newData],
          };
        }
        return tab;
      });
    });
    modifyApiResponseCase();
  };

  // 2024.03.19 [shiningtrue]: row 추가
  const handleAdd = () => {
    if (!responseItem) return;

    const createDate = new Date();
    const newData = {
      key: `new-${responseItem.responseCase.length}`,
      name: "",
      responseType: "SUCCESS",
      defaultResponse: responseItem.responseCase.length === 0,
      createUserId: userSession.id,
      createUser: userSession.name,
      updateUser: userSession.name,
      createDate: createDate,
      order: responseItem.responseCase.length,
      search: true,
      responseCaseDetail: {
        testCase: jsonDefaultConvert(responseItem.responseParameter),
      }
    };
    setResponseCaseData(newData);
  };

  // 2024.03.19 [shiningtrue]: 응답 유형 변경 함수
  const handleSelectBoxChange = (row, value, property) => {
    let testCase = {};
    if (value === 'SUCCESS') {
      testCase = jsonDefaultConvert(responseItem.responseParameter);
    }
    const newData = responseItem?.responseCase.map(item => {
      if (item.key === row.key) {
        return {
          ...item,
          [property]: value,
          errorMessageType: value === 'SUCCESS' ? 'EMPTY' : 'COMMON',
          responseCaseDetail: {
            ...item.responseCaseDetail,
            testCase: testCase,
            mappingManagement: (value === 'ERROR' && commonErrorCodeList.length > 0) ? {
              mappingId: commonErrorCodeList[0].value,
              mappingType: 'COMMON_ERROR_CODE',
              targetId: undefined,
              targetType: 'RESPONSE_CASE',
              projectId: project.id,
            } :
            {}
          }
        };
      }
      return item;
    });
    addResponseCaseItem("responseCase", newData);
  };

  /**
   * 2024.03.26 [energysteel]: Response Parameter를 통해 기본 JSON 형태 생성
   * @param responses Response Parameter Table Tree 구조
   * @returns {{}}
   */
  const jsonDefaultConvert = (responses) => {
    const result = {};

    responses.forEach(item => {
      const {keyValue, dataType, mappingManagement, children} = item;

      if (dataType === "OBJECT") {
        result[keyValue] = jsonDefaultConvert(children);
      } else if (dataType === "ARRAY") {
        result[keyValue] = children.map(child => jsonDefaultConvert([child]));
      } else {
        if (dataType === "FILE") {
          result[keyValue] = dataTypeDefaultValue[dataType];
        } else {
          if (mappingManagement) {
            result[mappingManagement.key] = mappingManagement.value;
          } else {
            result[keyValue] = dataTypeDefaultValue[dataType];
          }
        }
      }
    });

    return result;
  }

  // 2024.03.19 [shiningtrue]: 기본 응답 설정 상태 처리 함수
  const getRecordDefaultResponse = (record) => {
    const defaultResponseFlag =
        responseItem?.responseCase.length >= 1
            ? findDefaultResponse(responseItem?.responseCase, record.key)
            : undefined;

    return defaultResponseFlag !== undefined ? defaultResponseFlag : false; // Default to false if undefined
  };

  const findDefaultResponse = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        return item.defaultResponse;
      }
    }

    return undefined; // Return undefined if the key is not found
  };

  // 2024.03.19 [shiningtrue]: 기본 응답 설정 클릭 함수
  const defaultResponseClick = (row, type) => {
    const name = "defaultResponse_" + row.key;
    const targetElement = document.querySelector(`[name="${name}"]`);
    const checkImageAlt = targetElement
        ? targetElement.getAttribute("alt")
        : "";

    // 2024.03.29 [energysteel]: radio 해제 못하도록 return
    if (checkImageAlt === "ic_radio_on_16") {
      return;
    }

    const defaultResponseOnOff = checkImageAlt === "ic_radio_off_16";

    const newData = [...responseItem?.responseCase];

    const updatedData = newData.map(item => {
      const mappingManagement = (item.errorMessageType === 'COMMON' &&
        !item.mappingManagement && commonErrorCodeList.length > 0) ? {
          mappingId: commonErrorCodeList[0].value,
          mappingType: 'COMMON_ERROR_CODE',
          targetId: undefined,
          targetType: 'RESPONSE_CASE',
          projectId: project.id,
        } :
        {};

      if (item.key === row.key) {
        return {
          ...item,
          [type]: defaultResponseOnOff,
          responseCaseDetail: {
            ...item.responseCaseDetail,
            mappingManagement,
          },
        };
      } else if (type === "defaultResponse" && defaultResponseOnOff && item.defaultResponse) {
        // 새로운 defaultResponse를 설정하고 있고, 이미 다른 항목에 선택된 defaultResponse가 있는 경우
        return {
          ...item,
          defaultResponse: false,
          responseCaseDetail: {
            ...item.responseCaseDetail,
            mappingManagement,
          }
        };
      }
      return item;
    });

    addResponseCaseItem("responseCase", updatedData);
  };

  const defaultColumns = [
    {
      title: (
        <>
          {isApiAuthority(project.authority) &&
            <div
              className="content jc-c h36"
              onClick={handleAdd}
            >
              <TooltipImage
                title={<div className="Body7_R">TestCase 추가</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_add_bg"}
              />
            </div>
          }
        </>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        responseItem?.responseCase.length >= 1 ? (
          <div className="content jc-c h36">
            <TooltipImage
              className={"mr4 cur"}
              title={<div className="Body7_R">Test Case 상세 설정</div>}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_detail_edit"}
              onClick={() => showModal(record)}
            />
            {isModalOpen?.find(modal => modal.id === record.key)?.open === true &&
              <SettingTestCaseModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                record={record}
                responseItem={responseItem}
                jsonDefaultConvert={jsonDefaultConvert}
                commonErrorCodeList={commonErrorCodeList}
              />
            }
            {isApiAuthority(project.authority) &&
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record)}
              />
            }
          </div>
        ) : null,
    },
    {
      title: <div className="ml7">Test Case Name</div>,
      dataIndex: "name",
      width: "32%",
      editable: isApiAuthority(project.authority),
      render: (_, record) =>
        responseItem?.responseCase.length >= 1 ? (
          <div className="ml6">
            {record.name ? (
              <p className={"break-spaces-mw600"}>{record.name}</p>
            ) : (
              <p className="g300">Case명을 입력해 주세요.</p>
            )}
          </div>
        ) : null,
    },
    {
      title: <div className="ml7">Response Type</div>,
      dataIndex: "responseType",
      width: "17%",
      render: (_, record) =>
        responseItem?.responseCase.length >= 1 ? (
          <div className="ml6">
            {record.responseType ? (
              <ModalSelect
                value={record.responseType}
                handleChange={(e, v) =>
                  isApiAuthority(project.authority) ?
                    handleSelectBoxChange(record, v.value, "responseType") :
                    {}
                }
                options={[
                  { value: "SUCCESS", label: "정상 응답" },
                  { value: "ERROR", label: "에러 응답" },
                ]}
                width="100%"
                height={28}
              />
            ) : (
              <p className="g300"></p>
            )}
          </div>
        ) : null,
    },
    {
      title: (
        <div className="content ml7">
          Default Response
          <TooltipImage
            title={
              <div className="Body7_R">
                API 요청 시 선택된 Case가 응답됩니다.
              </div>
            }
            className={"ml4"}
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_question"}
          />
        </div>
      ),
      dataIndex: "defaultResponse",
      width: "17%",
      render: (_, record) =>
        responseItem?.responseCase.length >= 1 ? (
          <div
            className="ml6"
            onClick={() => isApiAuthority(project.authority) ?
              defaultResponseClick(record, "defaultResponse") :
              {}
            }
          >
            <a className="Body8_R g900 mb10">
              <Image
                  className="mb-2"
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={
                    getRecordDefaultResponse(record)
                        ? "ic_radio_on_16"
                        : "ic_radio_off_16"
                  }
                  tagName={`defaultResponse_${record.key}`}
              />
              <span className="ml4" style={{display: "inline-block"}}>
                {record.defaultResponse ? "기본 응답" : "미설정"}
              </span>
            </a>
          </div>
      ) : null,
    },
    {
      title: <div className="ml7">Writer</div>,
      dataIndex: "createUser",
      width: "17%",
      render: (_, record) =>
        responseItem?.responseCase.length >= 1 ? (
          <div className="ml6">
            {record.createUser ? <p>{record.createUser}</p> : <p className="g300"></p>}
          </div>
        ) : null,
    },
    {
      title: <div className="ml7">Modifier</div>,
      dataIndex: "updateUser",
      width: "17%",
      render: (_, record) =>
          responseItem?.responseCase.length >= 1 ? (
              <div className="ml6">
                {record.updateUser ? <p>{record.updateUser}</p> : <p className="g300"></p>}
              </div>
          ) : null,
    },
  ];

  // 2024.03.20 [shiningtrue]: search가 true인 object 추출 (검색 하기 위함)
  const responseCaseList = () => {
    const filtered = responseItem?.responseCase.filter(
        (item) => item.search === true,
    );
    return filtered;
  };

  // 2024.03.20 [shiningtrue]: 본인 작성 Case만 보기
  const handleSearch = () => {
    setOwn((prev) => !prev);
    let filtered = "";
    if (!own) {
      filtered = responseItem?.responseCase.map((item) => {
        const createUserIdMatch = item.createUserId === userSession.id;
        const search = createUserIdMatch; // 검색 결과 여부
        return { ...item, search }; // 검색 결과 여부를 포함한 객체 반환
      });
    } else {
      filtered = responseItem?.responseCase.map((item) => {
        const search = true // 검색 결과 여부
        return { ...item, search }; // 검색 결과 여부를 포함한 객체 반환
      });
    }
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            responseCase: filtered,
          };
        }
        return tab;
      });
    });
    responseCaseList();
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <a className="Body7_R g900 mb10" onClick={handleSearch}>
        <Image
          className="mb-2"
          path={`${process.env.PUBLIC_URL}/content`}
          name={own ? "ic_checkbox_on_16" : "ic_checkbox_off_16"}
        />
        <span className="ml4" style={{ display: "inline-block" }}>
          본인 작성 Case만 보기
        </span>
      </a>
      <TableWrapper>
        <Table
          className="mt16"
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={responseCaseList()}
          columns={columns}
          pagination={false}
        />
      </TableWrapper>
    </div>
  );
};

export default ApiTestcaseContent;
