import {Input, Tooltip} from "antd";
import RequiredText from "../../atoms/RequiredText";
import ModalSelect from "../../atoms/ModalSelect";
import { useRecoilState } from "recoil";
import {saveProjectRequestState, modifyProjectState, projectValidationErrorsState} from "../../../recoil/project/projectState";
import React, {useEffect, useState} from "react";
import Image from "../../atoms/Image";
import {toast} from "react-toastify";
const { TextArea } = Input;

const CreateProjectTable = ({
  isUpdate = false,
  project,
  isNotOwnerAndProjectInfoClick,
}) => {
  const [saveProject, setSaveProject] = useRecoilState(saveProjectRequestState);
  const [modifyProject, setModifyProject] = useRecoilState(modifyProjectState);
  const [validationErrors, setValidationErrors] = useRecoilState(projectValidationErrorsState);
  const [copyText, setCopyText] = useState("Copy Project Key");


  useEffect(() => {
    setModifyProject((prevModifyProject) => ({
      ...prevModifyProject,
      ...project,
    }));
  }, [project, setSaveProject, setModifyProject]);

  const handleChange = (event) => {
    const { value, name } = event.target;

    validateField(name, value);

    if (!isUpdate) {
      return setSaveProject((prevState) => {
        return { ...prevState, [name]: value }
      });
    } else {
      return setModifyProject((prevState) => {   
        return { ...prevState, [name]: value }
      });
    }
  }

  const handleChangeSelect = (value) => {
    if (!isUpdate) {
      return setSaveProject(prevState => {
        return { ...prevState, accessAuthority: value }
      })
    } else {
      return setModifyProject(prevState => {
        return { ...prevState, accessAuthority: value }
      })
    }
  }

  const validateField = (name, value) => {
    if (name === 'name') {
      const isValid = value.trim() === '';
      isBlankField(name, isValid);
    }

    if (name === 'context') {
      const isValid = value.trim() === '';
      isBlankField(name, isValid);
    }
  }

  const isBlankField = (name, isValid) => {
    setValidationErrors(prevErrors => ({
      ...prevErrors,
      [name]: isValid,
    }));
  }


  /**
   * 2024.04.04 [energysteel]: Project Key 복사 버튼 onMouseEnter Event
   */
  const handleOnMouseEnter = () => {
    setCopyText("Copy Project Key");
  }

  /**
   * 2024.04.04 [energysteel]: Project Key 복사 버튼 onClick Event
   *  - URL Toast, Tooltip Copy URL -> Copied 변경
   */
  const handleCopiedURL = () => {
    window.navigator.clipboard.writeText(project.key)
      .then(() => {
        setCopyText("Copied Project Key");
        toast.success(project.key);
      });
  }


  return (
    <table>
      <colgroup>
        <col width="25%" />
        <col width="75%" />
      </colgroup>
      <tbody>
      <tr>
        <td className="Body6_B g500">
          {
            isNotOwnerAndProjectInfoClick ?
              <span>Project 명</span>
              :
              <RequiredText text={"Project 명"}/>
          }
        </td>
        <td>
          <Input
            className="Body6_R g900 bg-white br3"
            style={{
              height: "36px",
              background: validationErrors.name ? '#ffe1e1' : '',
              backgroundColor: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
              color: 'black',
            }}
            disabled={isNotOwnerAndProjectInfoClick}
            placeholder="Project 명을 입력해주세요."
            name="name"
            value={isUpdate ? modifyProject.name : saveProject.name}
            onChange={handleChange}
          />
        </td>
      </tr>
      {isUpdate &&
        <tr>
          <td className="Body6_B g500">
            {
              isNotOwnerAndProjectInfoClick ?
                <span>Project Key</span>
                :
                <RequiredText text={"Project Key"}/>
            }
          </td>
          <td>
            <Input
              className="Body6_R g900 bg-white br3"
              style={{
                height: "36px",
                background: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
                backgroundColor: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
                color: 'black',
              }}
              disabled={true}
              value={project.key}
              suffix={
                <div className="content">
                  <Tooltip
                    placement="bottom"
                    title={copyText}
                  >
                  <span
                    onMouseEnter={handleOnMouseEnter}>
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_copy"}
                      onClick={handleCopiedURL}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                  </Tooltip>
                </div>
              }
            />
          </td>
        </tr>
      }
      <tr>
        <td className="Body6_B g500">
          {
            isNotOwnerAndProjectInfoClick ?
              <span>Context</span>
              :
              <RequiredText text={"Context"}/>
          }
        </td>
        <td>
          <Input
            className="Body6_R g900 bg-white br3"
            style={{
              height: "36px",
              background: validationErrors.context ? '#ffe1e1' : '',
              backgroundColor: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
              color: 'black',
            }}
            disabled={isNotOwnerAndProjectInfoClick}
            placeholder="Context를 입력해주세요."
            name="context"
            onChange={handleChange}
            value={isUpdate ? modifyProject.context : saveProject.context}
          />
        </td>
      </tr>
      {isNotOwnerAndProjectInfoClick ?
        <tr>
          <td className="Body6_B g500">
            <span>접근 권한</span>
          </td>
          <td>
            <Input
              className="Body6_R g900 bg-white br3"
              style={{
                width: "362px",
                height: "36px",
                background: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
                color: 'black',
              }}
              disabled={isNotOwnerAndProjectInfoClick}
              value={
                modifyProject.accessAuthority?.substring(0, 1) +
                modifyProject.accessAuthority?.substring(1)?.toLowerCase()
              }
            />
          </td>
        </tr>
        :
        <tr>
          <td className="Body6_B g500">
            <RequiredText text={"접근 권한"}/>
          </td>
          <td>
            <ModalSelect
              isError={validationErrors.accessAuthority}
              placeholder={"Public"}
              options={[
                {value: "PUBLIC", label: "Public"},
                {value: "PRIVATE", label: "Private"},
              ]}
              value={isUpdate ?
                modifyProject.accessAuthority?.substring(0, 1) +
                modifyProject.accessAuthority?.substring(1)?.toLowerCase()
                : saveProject.accessAuthority}
              defaultValue={"PUBLIC"}
              handleChange={handleChangeSelect}
              name="accessAuthority"
              disabled={isNotOwnerAndProjectInfoClick}
            />
          </td>
        </tr>
      }
      <tr>
        <td className="Body6_B g500">
          <div style={{height: "170px"}}>
            <span>설명</span>
          </div>
        </td>
        <td>
          <TextArea
            className="Body6_R g900 bg-white br3"
            placeholder="Project 설명을 입력해주세요."
            autoSize={{minRows: 9, maxRows: 9}}
            name="description"
            value={isUpdate ? modifyProject.description : saveProject.description}
            onChange={handleChange}
            style={{
              backgroundColor: isNotOwnerAndProjectInfoClick ? '#ffffff' : '',
              color: 'black',
            }}
            disabled={isNotOwnerAndProjectInfoClick}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );
};
export default CreateProjectTable;
