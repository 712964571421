import React from "react";

const Project = ({ clazz, setManualKey }) =>{
    return (
      <>
        <div className={`${clazz}`}>
            <p>MockBot 서비스의 프로젝트는 실제 서비스 개발의 프로젝트와 같은 의미로 관리됩니다.<br/>프로젝트는 애플리케이션에서 사용할 REST API를 Context별로 관리할 수 있는 최상위 단위
                입니다.
            </p>
        </div>
      </>
)
}
export default Project;