import React from "react";
import {Button, Upload} from "antd";

const Dialogs = ({
  textLeft,
  textCenter,
  textRight,
  handleLeft,
  handleCenter,
  handleRight,
}) => {
  return (
    <div className="content jc-c mt28 mb4">
      <Button
        className="btn-sec Body6_B br3"
        style={{ width: "132px", height: "36px" }}
        onClick={handleLeft}
      >
        {textLeft}
      </Button>
      <Button
        className="btn-sec Body6_B br3"
        style={{ width: "132px", height: "36px" }}
        onClick={handleCenter}
      >
        {textCenter}
      </Button>
      <Button
        className="btn-pri Body6_B br3"
        style={{
          width: "132px",
          height: "36px",
          marginLeft: "4px",
        }}
        type="primary"
        onClick={handleRight}
      >
        {textRight}
      </Button>
    </div>
  );
};

export default Dialogs;
