import React, {useState} from "react";
import {AutoComplete, Table} from "antd";
import Image from "../../atoms/Image";
import TooltipImage from "../../atoms/TooltipImage";
import {EditableCell, EditableRow} from "../../atoms/Editable";
import ModalSelect from "../../atoms/ModalSelect";
import TabsWrapper from "../../molecules/TabsWrapper";
import useAxiosInterceptor from "../../../axios/axios";
import {useRecoilState} from "recoil";
import {
  commonResponseFormBodyListState,
  commonResponseFormHeaderListState,
  isProjectOwnerOrAdminAuthority,
  projectState,
} from "../../../recoil/project/projectState";
import {headers} from "../../../autocomplete/header";
import {commonErrorEditingRecoilState} from "../../../recoil/commonErrorCode/commonErrorCodeState";
import {alertHelpIcon, alertHelpIconOpenState, helpIconMessageState,} from "../../../recoil/api/apiState";
import {findAllChildrenKeys, removeDuplicatedExpandedKey} from "../../../recoil/common/utils";

const CommonResponseContent = () => {
  // 2024.03.11 [energysteel]: 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.03.22 [shiningtrue]: List 상태 관리 변수 Header
  const [
    commonResponseFormHeaderRecoilState,
    setCommonResponseFormHeaderRecoilState,
  ] = useRecoilState(commonResponseFormHeaderListState);
  // 2024.03.22 [shiningtrue]: List 상태 관리 변수 Body
  const [
    commonResponseFormBodyRecoilState,
    setCommonResponseFormBodyRecoilState,
  ] = useRecoilState(commonResponseFormBodyListState);
  // 2024.03.18 [shiningtrue]: 수정 여부 상태 관리 변수
  const [, setCommonResponseFormEditingState] =
    useRecoilState(commonErrorEditingRecoilState);
  // 2024.04.19 [energysteel]: 1: Header, 2: Body
  const [activeSubTab, setActiveSubTab] = useState("2");
  const [headerExpandedRowKeys, setHeaderExpandedRowKeys] = useState([]);
  const [bodyExpandedRowKeys, setBodyExpandedRowKeys] = useState([]);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  // 2024.03.22 [shiningtrue]: 수정시 editing true처리
  const modifyCommonResponse = (editing, tabType) => {
    if (tabType === "HEADER") {
      setCommonResponseFormEditingState((prevState) => ({
        ...prevState, // 이전 상태를 가져옴
        responseHeaderEditing: editing, // 변경된 값을 설정
      }));
    } else if (tabType === "BODY") {
      setCommonResponseFormEditingState((prevState) => ({
        ...prevState, // 이전 상태를 가져옴
        responseBodyEditing: editing, // 변경된 값을 설정
      }));
    } else {
      setCommonResponseFormEditingState((prevState) => ({
        ...prevState, // 이전 상태를 가져옴
        responseHeaderEditing: editing, // 변경된 값을 설정
        responseBodyEditing: editing, // 변경된 값을 설정
      }));
    }
  };

  // 2024.03.22 [shiningtrue]: setCommonResponseFormRecoilState 상태 변수 해당 object 변경 함수
  const addCommonResponseFormItem = (tabType, object) => {
    if (tabType === "HEADER") {
      setCommonResponseFormHeaderRecoilState(object);
    } else {
      setCommonResponseFormBodyRecoilState(object);
    }
    modifyCommonResponse(true, tabType);
  };

  // 2024.03.22 [shiningtrue]: list field 저장 함수
  const handleSave = (row, tabType) => {
    let newData = [];
    if (tabType === "HEADER") {
      newData = commonResponseFormHeaderRecoilState;
    } else {
      newData = commonResponseFormBodyRecoilState;
    }
    const updatedData = setNewData(newData, row);
    addCommonResponseFormItem(tabType, updatedData);
  };

  /* 2024.03.11 [shiningtrue]: setRequestHeaderDataSource, Body Object의 값을 변경하기 위한 재귀 함수 */
  const setNewData = (items, data) => {
    return items.map((item) => {
      if (item.key === data.key) {
        return { ...item, ...data };
      }

      if (item.children && item.children.length > 0) {
        const updatedChildren = setNewData(item.children, data);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  /* 2024.03.22 [shiningtrue]: row 삭제 버튼 */
  const handleDelete = (row, tabType) => {
    let newData = [];

    if (tabType === "HEADER") {
      newData = commonResponseFormHeaderRecoilState;
    } else {
      newData = commonResponseFormBodyRecoilState;
    }
    addCommonResponseFormItem(tabType, findAndDeleteItem(newData, row));
  };

  // 2024.03.22 [shiningtrue]: 삭제시 해당 row 찾기 위함
  const findAndDeleteItem = (items, row) => {
    const updatedItems = items.filter((item) => item.key !== row.key);

    return updatedItems.map((item) => {
      if (item.children && item.children.length > 0) {
        const updatedChildren = findAndDeleteItem(item.children, row);
        return { ...item, children: updatedChildren };
      }

      return item;
    });
  };

  // 2024.03.22 [shiningtrue]: recoil object 상태 변수 수정
  const setCommonResponseFormData = (newData, tabType) => {
    if (tabType === "HEADER") {
      setCommonResponseFormHeaderRecoilState((prev) => [...prev, newData]);
    } else {
      setCommonResponseFormBodyRecoilState((prev) => [...prev, newData]);
    }

    modifyCommonResponse(true, tabType);
  };

  const handleAdd = (tabType) => {
    const newDataIndex = tabType === 'HEADER' ?
        commonResponseFormHeaderRecoilState.length :
        commonResponseFormBodyRecoilState.length;
    const newData = {
      key: `new-${newDataIndex}`,
      description: null,
      keyValue: "",
      dataType: "STRING",
      responseCode: "DEFAULT",
      responseCodeValue: "",
      upperId: 0,
      type: tabType,
      children: [],
    };
    setCommonResponseFormData(newData, tabType);
  };

  const handleAddMiddle = (record, tabType) => {
    const addNewChild = (items) => {
      return items.map((item) => {
        if (item.key === record.key) {
          const newChild = {
            key: `${item.key}-${item.children.length}`,
            description: null,
            keyValue: "",
            dataType: "STRING",
            responseCode: "DEFAULT",
            responseCodeValue: "",
            upperId: "",
            type: tabType,
            children: [],
          };
          return {
            ...item,
            children: [...item.children, newChild],
          };
        }
        if (item.children && item.children.length > 0) {
          const updatedChildren = addNewChild(item.children);
          return {
            ...item,
            children: updatedChildren,
          };
        }

        return item;
      });
    };

    let updatedDataSource = "";
    if (tabType === "HEADER") {
      updatedDataSource = addNewChild(commonResponseFormHeaderRecoilState);
    } else {
      updatedDataSource = addNewChild(commonResponseFormBodyRecoilState);
    }

    // 상태 업데이트
    addCommonResponseFormItem(tabType, updatedDataSource);
  };

  // 2024.03.07 [shiningtrue]: list field DataType
  const getRecordDataType = (record, tabType) => {
    let dataType = "";

    if (tabType === "HEADER") {
      dataType =
        commonResponseFormHeaderRecoilState.length >= 1
          ? findDataTypeInChildren(
              commonResponseFormHeaderRecoilState,
              record.key,
            )
          : null;
    } else {
      dataType =
        commonResponseFormBodyRecoilState.length >= 1
          ? findDataTypeInChildren(
              commonResponseFormBodyRecoilState,
              record.key,
            )
          : null;
    }

    return dataType
      ? dataType.charAt(0).toUpperCase() + dataType.slice(1).toLowerCase()
      : "String";
  };

  // 2024.03.07 [shiningtrue]: list field DataType 하위 포함 조회
  const findDataTypeInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        return item.dataType;
      }

      if (item.children && item.children.length > 0) {
        const dataTypeInChildren = findDataTypeInChildren(item.children, key);
        if (dataTypeInChildren) {
          return dataTypeInChildren;
        }
      }
    }

    return null; // Return null if the key is not found
  };

  // 2024.03.22 [shiningtrue]: list field DataType 저장 함수
  // 2024.04.15 [energysteel]: AutoComplete 저장 추가
  const handleChange = (row, key, optionValue, tabType) => {
    let newData = [];

    if (tabType === "HEADER") {
      newData = commonResponseFormHeaderRecoilState;
    } else {
      newData = commonResponseFormBodyRecoilState;
    }

    const updatedData = setNewData(newData, {
      ...row,
      [key]: optionValue,
      responseCode: "DEFAULT",
    });

    addCommonResponseFormItem(tabType, updatedData);
  };

  const getRecordResponseCode = (record, tabType) => {
    let responseCode = "";
    if (tabType === "HEADER") {
      responseCode =
        commonResponseFormHeaderRecoilState.length >= 1
          ? findResponseCodeInChildren(
              commonResponseFormHeaderRecoilState,
              record.key,
            )
          : null;
    } else {
      responseCode =
        commonResponseFormBodyRecoilState.length >= 1
          ? findResponseCodeInChildren(
              commonResponseFormBodyRecoilState,
              record.key,
            )
          : null;
    }

    return responseCode ? responseCode : "Default";
  };

  // 2024.03.07 [shiningtrue]: list field DataType 하위 포함 조회
  const findResponseCodeInChildren = (items, key) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.key === key) {
        let responseCode = "";
        if (item.responseCode === "DEFAULT") {
          responseCode = "Default";
        } else if (item.responseCode === "ERROR_CODE") {
          responseCode = "Error Code";
        } else if (item.responseCode === "ERROR_MESSAGE") {
          responseCode = "Error Message";
        }

        return responseCode;
      }

      if (item.children && item.children.length > 0) {
        const dataTypeInChildren = findResponseCodeInChildren(
          item.children,
          key,
        );
        if (dataTypeInChildren) {
          return dataTypeInChildren;
        }
      }
    }

    return null;
  };

  // 2024.03.07 [shiningtrue]: list field responseCode 저장 함수
  const handleResponseCodeChange = (row, option, tabType) => {
    if (
      row.dataType !== "STRING" &&
      option.key.toUpperCase() === "ERROR_MESSAGE"
    ) {
      setHelpIconMessage({
        code: "error",
        message: "Data Type이 String인 경우에만 Error Message를 선택할 수 있습니다.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return false;
    }

    let newData = [];
    if (tabType === "HEADER") {
      newData = commonResponseFormHeaderRecoilState;
    } else {
      newData = commonResponseFormBodyRecoilState;
    }

    const updatedData = setNewData(newData, {
      ...row,
      responseCode: option.key.toUpperCase(),
      responseCodeValue: option.value,
    });

    addCommonResponseFormItem(tabType, updatedData);
  };

  const headerDefaultColumns = [
    {
      title: (
        <div className="content jc-c h36">
          {isProjectOwnerOrAdminAuthority(project.authority) && (
            <TooltipImage
              title={<div className="Body7_R">parameter 추가</div>}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_add_bg"}
              onClick={() => handleAdd("HEADER")}
            />
          )}
        </div>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        commonResponseFormHeaderRecoilState.length >= 1 ? (
          <div className="content jc-c h36">
            {isProjectOwnerOrAdminAuthority(project.authority) && (
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record, "HEADER")}
              />
            )}
          </div>
        ) : null,
    },
    {
      title: "Key",
      dataIndex: "keyValue",
      width: "55%",
      render: (_, record) =>
        commonResponseFormHeaderRecoilState.length >= 1 ? (
          <>
            <AutoComplete
              style={{
                width: "100%",
              }}
              defaultValue={_}
              onBlur={(event) =>
                handleChange(record, "keyValue", event.target.value, "HEADER")
              }
              placeholder={"Key를 입력해 주세요."}
              options={headers.filter(
                (header) =>
                header.value !== "projectKey" && header.value !== "apiKey",
                )}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </>
        ) : null,
    },
    {
      title: "Data Type",
      dataIndex: "type",
      width: "11%",
      render: (_, record) =>
        commonResponseFormHeaderRecoilState.length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              options={[
                { key: "STRING", value: "String" },
              ]}
              value={getRecordDataType(record, "HEADER")}
              handleChange={(e, v) =>
                isProjectOwnerOrAdminAuthority(project.authority)
                  ? handleChange(
                      record,
                      "dataType",
                      v.value.toUpperCase(),
                      "HEADER",
                    )
                  : {}
              }
              width="100%"
              height={28}
            />
          </div>
        ) : null,
    },
    {
      title: (
        <div className="content">
          응답되는 공통 에러코드 Column 명 선택
          <TooltipImage
            title={
              <div className="Body7_R">
                해당 컬럼 값이 Parameter 값으로 출력됩니다.
                <a href="/manual" className="content jc-c white">
                  <span style={{ borderBottom: "1px solid #ffffff" }}>
                    사용가이드 확인
                  </span>
                </a>
              </div>
            }
            className={"ml4"}
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_question"}
          />
        </div>
      ),
      dataIndex: "responseCode",
      width: "13%",
      render: (_, record) =>
        commonResponseFormHeaderRecoilState.length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              options={[
                { key: "DEFAULT", value: "Default" },
                { key: "ERROR_CODE", value: "Error Code" },
                { key: "ERROR_MESSAGE", value: "Error Message" },
              ]}
              value={getRecordResponseCode(record, "HEADER")}
              handleChange={(e, v) =>
                isProjectOwnerOrAdminAuthority(project.authority)
                  ? handleResponseCodeChange(record, v, "HEADER")
                  : {}
              }
              width="100%"
              height={28}
              disabled={
                record.dataType !== "STRING" &&
                record.dataType !== "INT" &&
                record.dataType !== "LONG"
              }
            />
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "32%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonResponseFormHeaderRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.description ? (
              <span className={"break-spaces-mw525"}>{record.description}</span>
            ) : (
              <span className="g300">설명을 입력해 주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <p style={{ width: 7 }} />,
      dataIndex: "key",
      width: "28px",
      render: (_, record) =>
        isProjectOwnerOrAdminAuthority(project.authority) &&
        commonResponseFormHeaderRecoilState.length >= 1 &&
        !record.upperId ? (
          <Image
            className="content jc-c mr-4 ml-4"
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_move"}
          />
        ) : null,
    },
  ];

  const bodyDefaultColumns = [
    {
      title: (
        <div className="content jc-c h36">
          {isProjectOwnerOrAdminAuthority(project.authority) && (
            <TooltipImage
              title={<div className="Body7_R">parameter 추가</div>}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_add_bg"}
              onClick={() => handleAdd("BODY")}
            />
          )}
        </div>
      ),
      dataIndex: "delete",
      width: "48px",
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 ? (
          <div className="content jc-c h36">
            {isProjectOwnerOrAdminAuthority(project.authority) && (
              <TooltipImage
                title={<div className="Body7_R">삭제</div>}
                path={`${process.env.PUBLIC_URL}/content`}
                name={"ic_delete"}
                onClick={() => handleDelete(record, "BODY")}
              />
            )}
          </div>
        ) : null,
    },
    {
      title: "Key",
      dataIndex: "keyValue",
      width: "55%",
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 ? (
          <>
            {record.keyValue ? (
              <span
                className={"break-spaces-mw250"}
                style={{
                  color:
                    record.dataType === 'ARRAY' ? "#098658"
                      :
                      record.dataType === 'OBJECT' ? "#B81515"
                        :
                        "",
                  fontWeight:
                    record.dataType === 'ARRAY' || record.dataType === 'OBJECT' ? 600
                      :
                      400,
                }}
              >
                {record.keyValue}
              </span>
            ) : (
              <span className="g300">Key를 입력해 주세요.</span>
            )}
          </>
        ) : null,
      editable: isProjectOwnerOrAdminAuthority(project.authority),
    },
    {
      title: "Data Type",
      dataIndex: "type",
      width: "11%",
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              options={[
                { key: "STRING", value: "String" },
                { key: "INT", value: "Int" },
                { key: "LONG", value: "Long" },
                { key: "SHORT", value: "Short" },
                { key: "DOUBLE", value: "Double" },
                { key: "FLOAT", value: "Float" },
                { key: "BOOLEAN", value: "Boolean" },
                { key: "OBJECT", value: "Object" },
                { key: "ARRAY", value: "Array" },
              ]}
              value={getRecordDataType(record, "BODY")}
              handleChange={(e, v) =>
                isProjectOwnerOrAdminAuthority(project.authority)
                  ? handleChange(
                      record,
                      "dataType",
                      v.value.toUpperCase(),
                      "BODY",
                    )
                  : {}
              }
              width="100%"
              height={28}
            />
          </div>
        ) : null,
    },
    {
      title: (
        <div className="content">
          응답되는 공통 에러코드 Column 명 선택
          <TooltipImage
            title={
              <div className="Body7_R">
                해당 컬럼 값이 Parameter 값으로 출력됩니다.
                <a href="/manual" className="content jc-c white">
                  <span style={{ borderBottom: "1px solid #ffffff" }}>
                    사용가이드 확인
                  </span>
                </a>
              </div>
            }
            className={"ml4"}
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_question"}
          />
        </div>
      ),
      dataIndex: "responseCode",
      width: "13%",
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 ? (
          <div className="content jc-c">
            <ModalSelect
              options={[
                { key: "DEFAULT", value: "Default" },
                { key: "ERROR_CODE", value: "Error Code" },
                { key: "ERROR_MESSAGE", value: "Error Message" },
              ]}
              value={getRecordResponseCode(record, "BODY")}
              handleChange={(e, v) =>
                isProjectOwnerOrAdminAuthority(project.authority)
                  ? handleResponseCodeChange(record, v, "BODY")
                  : {}
              }
              width="100%"
              height={28}
            />
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "32%",
      editable: isProjectOwnerOrAdminAuthority(project.authority),
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 ? (
          <div className="ml6">
            {record.description ? (
              <span className={"break-spaces-mw250"}>{record.description}</span>
            ) : (
              <span className="g300">Name을 입력해주세요.</span>
            )}
          </div>
        ) : null,
    },
    {
      title: <p style={{ width: 7 }} />,
      dataIndex: "key",
      width: "28px",
      render: (_, record) =>
        commonResponseFormBodyRecoilState.length >= 1 && !record.upperId ? (
          <Image
            className="content jc-c mr-4 ml-4"
            path={`${process.env.PUBLIC_URL}/content`}
            name={"ic_move"}
          />
        ) : null,
    },
  ];

  const headerColumns = headerDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row, "HEADER"),
      }),
    };
  });

  const bodyColumns = bodyDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => handleSave(row, "BODY"),
      }),
    };
  });

  const HeaderTabsItem = () => {
    const moveRow = (dragIndex, hoverIndex) => {
      const dragRow = commonResponseFormHeaderRecoilState[dragIndex];
      const newDataSource = [...commonResponseFormHeaderRecoilState];
      newDataSource.splice(dragIndex, 1);
      newDataSource.splice(hoverIndex, 0, dragRow);
      addCommonResponseFormItem("HEADER", newDataSource);
    };

    const handleDragRow = (record, index) => ({
      index,
      draggable: true, // 드래그 가능하도록 설정
      onDragStart: (e) => {
        if (headerExpandedRowKeys.length > 0) {
          setHelpIconMessage({
            code: "error",
            message: "열려 있는 Object 또는 Array를 닫은 후 이동해 주세요.",
          });
          alertHelpIcon(setAlertHelpIconOpen);

          return false;
        }
        e.dataTransfer.setData("text/plain", index); // 드래그된 요소의 인덱스 전달
      },
      onDragOver: (e) => {
        e.preventDefault(); // 드래그한 요소가 올바른 대상 위에 있을 때 드롭을 허용
      },
      onDrop: (e) => {
        e.preventDefault();
        const dropIndex = parseInt(e.dataTransfer.getData("text/plain")); // 드롭한 요소의 인덱스 가져오기
        if (dropIndex !== index) {
          moveRow(dropIndex, index); // 드래그 앤 드롭으로 인한 요소 위치 변경
        }
      },
    });

    return (
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={commonResponseFormHeaderRecoilState}
        columns={headerColumns}
        onRow={handleDragRow}
        pagination={false}
        expandedRowKeys={headerExpandedRowKeys}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <>
              {record.dataType.toUpperCase() === "OBJECT" ? (
                <>
                  <Image
                    className="mr4 cur mb-2"
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_plus"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddMiddle(record, "HEADER");
                      setHeaderExpandedRowKeys([
                        record.key,
                        ...headerExpandedRowKeys,
                      ]);
                    }}
                  />
                  {expanded ? (
                    <Image
                      className="cur mr4 mb-2"
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_arrow_down"}
                      onClick={(e) => {
                        setHeaderExpandedRowKeys(() => {
                          return headerExpandedRowKeys.filter(
                            (key) => key !== record.key,
                          );
                        });
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                    />
                  ) : (
                    <Image
                      className="cur mr4 mb-2"
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_arrow_right"}
                      onClick={(e) => {
                        setHeaderExpandedRowKeys([
                          record.key,
                          ...headerExpandedRowKeys,
                        ]);
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                    />
                  )}
                </>
              ) : record.upperId !== 0 ? (
                "┗ "
              ) : null}
            </>
          ),
          expandIconColumnIndex: 1,
          indentSize: 40,
        }}
      />
    );
  };

  const BodyTabsItem = () => {
    const moveRow = (dragIndex, hoverIndex) => {
      const dragRow = commonResponseFormBodyRecoilState[dragIndex];
      const newDataSource = [...commonResponseFormBodyRecoilState];
      newDataSource.splice(dragIndex, 1);
      newDataSource.splice(hoverIndex, 0, dragRow);
      addCommonResponseFormItem("BODY", newDataSource);
    };

    const handleDragRow = (record, index) => ({
      index,
      draggable: true, // 드래그 가능하도록 설정
      onDragStart: (e) => {
        if (bodyExpandedRowKeys.length > 0) {
          setHelpIconMessage({
            code: "error",
            message: "열려 있는 Object 또는 Array를 닫은 후 이동해 주세요.",
          });
          alertHelpIcon(setAlertHelpIconOpen);

          return false;
        }
        e.dataTransfer.setData("text/plain", index); // 드래그된 요소의 인덱스 전달
      },
      onDragOver: (e) => {
        e.preventDefault(); // 드래그한 요소가 올바른 대상 위에 있을 때 드롭을 허용
      },
      onDrop: (e) => {
        e.preventDefault();
        const dropIndex = parseInt(e.dataTransfer.getData("text/plain")); // 드롭한 요소의 인덱스 가져오기
        if (dropIndex !== index) {
          moveRow(dropIndex, index); // 드래그 앤 드롭으로 인한 요소 위치 변경
        }
      },
    });

    return (
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={commonResponseFormBodyRecoilState}
        columns={bodyColumns}
        onRow={handleDragRow}
        pagination={false}
        expandedRowKeys={bodyExpandedRowKeys}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <>
              {record.dataType.toUpperCase() === "OBJECT" ? (
                <>
                  <Image
                    className="mr4 cur mb-2"
                    path={`${process.env.PUBLIC_URL}/content`}
                    name={"ic_plus"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddMiddle(record, "BODY");
                      setBodyExpandedRowKeys([
                        record.key,
                        ...bodyExpandedRowKeys,
                      ]);
                    }}
                  />
                  {expanded ? (
                    <Image
                      className="cur mr4 mb-2"
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_arrow_down"}
                      onClick={(e) => {
                        setBodyExpandedRowKeys(() => {
                          return bodyExpandedRowKeys.filter(
                            (key) => key !== record.key,
                          );
                        });
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                    />
                  ) : (
                    <Image
                      className="cur mr4 mb-2"
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_arrow_right"}
                      onClick={(e) => {
                        const childrenExpandedRows = findAllChildrenKeys(record);
                        const uniqueExpandedRows = removeDuplicatedExpandedKey([
                          record.key,
                          ...bodyExpandedRowKeys,
                          ...childrenExpandedRows,
                        ]);

                        setBodyExpandedRowKeys(uniqueExpandedRows);
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                    />
                  )}
                </>
              ) : record.upperId !== 0 ? (
                "┗ "
              ) : null}
            </>
          ),
          expandIconColumnIndex: 1,
          indentSize: 40,
        }}
      />
    );
  };
  const items = [
    {
      key: "1",
      label: "Header",
      children: <HeaderTabsItem />,
    },
    {
      key: "2",
      label: "Body",
      children: <BodyTabsItem />,
    },
  ];

  return (
    <TabsWrapper>
      <div className="common-error-content">
        {items.map((v) => {
          return (
            <div
              className={`common-error-content-subtab ${activeSubTab === v.key}`}
              onClick={() => setActiveSubTab(v.key)}
              key={v.key}
            >
              {v.label}
            </div>
          );
        })}
      </div>
      <div className="table-overflow-auto -true">
        {
          items.find((value) => {
            return value.key == activeSubTab;
          })?.children
        }
      </div>
    </TabsWrapper>
  );
};

export default CommonResponseContent;
