import React from "react";

const ResponseErrorHeader = ({ clazz, setManualKey}) =>{
    return (
        <>
            <div className={clazz}>
                <p>Response Error Header는 클라이언트가 서버로 요청을 처리하던 중 오류가 발생할경우 애플리케이션이 수신할 Header를 등록할 수 있습니다.</p><br/>
                <br/>
                  <div style={{width: 786}}>
                      <img
                          src={`${process.env.PUBLIC_URL}/manual/manual_common_response_error_1.png`}
                          alt={"example_head"}
                          width={838}
                          height={276}
                      />
                      <br/>
                      <br/>
                      <p>Header는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
                  </div>
            </div>
        </>
    )
}

const ResponseErrorBody = ({ clazz, setManualKey }) =>{
  return (
    <>
      <div className={clazz}>
        <p>Response Error Body는 오류 응답 규격의 Template 을 생성하는 단계 입니다.</p>
        <p>사용자는 응답 규격의 Key와 Value의 Data Type을
          등록합니다.<br/>
        </p>
        <br/>
        <br/>
        <div style={{width: 786}}>
          <img
            src={`${process.env.PUBLIC_URL}/manual/manual_common_response_error_2.png`}
            alt={"example_head"}
            width={838}
            height={270}
          />
          <br/>
          <br/>
          <p>Body는 <b>+</b> 버튼으로 추가할 수 있습니다.</p><br/>
        </div>
        <br/>
        <br/>
      </div>
    </>
  )
}
export { ResponseErrorHeader , ResponseErrorBody };