const ModalHeadline = ({ open }) => {
  return (
    <div
      className="bb-g200 mb20"
      style={{
        position: "relative",
        width: open ? "990px" : "528px",
        left: "-24px",
      }}
    />
  );
};
export default ModalHeadline;
