import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Dropdown, Layout, Drawer, ConfigProvider } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Image from "../../atoms/Image";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../../recoil/user/userState";
import { supportMenuKeyState } from "../../../recoil/bbs/bbsState";
import {useCookies} from "react-cookie";
import {autoLoginApi} from "../../../api/signin/signinApi";
import useAxiosInterceptor from "../../../axios/axios";
import {toast} from "react-toastify";
import { MenuGuide } from "../manual/Sider";

const {Header: AntdHeader} = Layout;

const Header = ({ isHamburger }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();

  const { authContextLogin, authContextLogout } = useAuth();

  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.01.26 [shiningtrue]: 새로고침시 클릭한 메뉴 유지 하기 위함
  const [supportMenuKey, setSupportMenuKey] = useRecoilState(supportMenuKeyState);
  const navigate = useNavigate();
  //2024.04.12[shiningtrue]: cookie 관리
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [user, setUser] = useRecoilState(userState); //사용자 세션 정보 관리

  const [open, setOpen] = useState(false);

  const autoLoginApiSuccessCallback = useCallback((data) => {
    if (data.data.data === "CHANGE_PASSWORD") {
      //비밀번호 갱신 요청
      toast.error("비밀번호 갱신 기간입니다.\n다시 로그인 해주세요.");
      navigate("/signin");
      return false;
    } else if (data.data.data === "DORMANT_ACCOUNT") {
      //휴면 계정 해제 요청
      toast.error("휴면 계정 입니다.\n다시 로그인 해주세요.");
      navigate("/signin");
      return false;
    } else {
      authContextLogin(data); //사용자 로그인 상태 관리
      navigate("/main"); //2024.01.25[shiningtrue]: 화면 이동
    }
  }, []);

  useEffect(() => {
    // 2024.04.15 [shiningtrue]: 자동로그인이 체크되어있고 userKey가 저장되어있고 token이 null일 때
    if (
      cookies.autoLogin &&
      user.apiKey !== null &&
      sessionStorage.getItem("accessToken") === null
    ) {
      const params = {
        userKey: user.apiKey,
      };

      autoLoginApi(axios, params, autoLoginApiSuccessCallback);
    }
  }, []);

  const headerLogout = async () => {
    //2024.04.12[shiningtrue]: 로그아웃 클릭시 자동로그인 해제
    await removeCookie("autoLogin");
    authContextLogout();
    navigate("/signin");
  };

  const profileItems = {
    items: [
      {
        label: (
          <Link to="/account">
            <div className="g500">
              <span className="Body6_B ellipsis-mw200">{userSession.name}</span>
              <br />
              <span className="Body7_R ellipsis-mw200">{userSession.email}</span>
            </div>
          </Link>
        ),
        key: "0",
      },
      {
        type: "divider",
      },
      {
        label: (
          <div className="header g500" onClick={headerLogout}>
            <Image path={`${process.env.PUBLIC_URL}/header`} name={"ic_logout"}/>
            <span className="Body6_B ml4">로그아웃</span>
          </div>
        ),
        key: "1",
      },
    ],
  };

  // 2024.02.28 [shiningtrue]: 헤더 고객지원 클릭시 고객지원 menu Recoil값 초기화
  const supportClick = () => {
    setSupportMenuKey((prevValues) => ({
      ...prevValues,
      menu: "",
    }));
  }

  const HamburgerDrawer = () => {
    return (
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemSelectedBg: "#978eff5e",
              itemSelectedColor: "#7c60ff",
              itemHoverColor: "#636c83",
              itemHoverBg: "#ffffff",
              itemMarginInline: 10,
              subMenuItemBg: "",
            },
          },
          token: {
            lineWidth: 0,
            paddingLG: 0,
          },
        }}
      >
        <Drawer
          className="drawer"
          placement={"right"}
          closable={false}
          onClose={() => setOpen(false)}
          open={open}
          key={"right"}
          width={"252px"}
        >
          <div style={{ padding: "18px 21px 8px 207px" }}>
            <Image
              onClick={() => setOpen(false)}
              path={`${process.env.PUBLIC_URL}/content`}
              name={"ic_close_24"}
              size="md"
            />
          </div>
          <div
            className="drawer-menu b-g200 br4 content jc-sb Body5_B"
            style={{ width: 232, height: 44, margin: "8px 11px" }}
          >
            <Link
              to="/support"
              onClick={supportClick}
              className="drawer-padding"
            >
              <div>고객지원</div>
            </Link>
            {user.id !== null ? (
              <Dropdown
                menu={profileItems}
                overlayStyle={{ width: "280px", height: "114px" }}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Avatar
                  src={`${process.env.PUBLIC_URL}/header/ic_profile.png`}
                  size={28}
                />
              </Dropdown>
            ) : (
              <Link to={"/signin"} className="drawer-padding">
                <div>로그인</div>
              </Link>
            )}
          </div>
          <div className="drawer-title">사용 가이드</div>
          <MenuGuide value={""} />
        </Drawer>
      </ConfigProvider>
    );
  };

  return (
    <AntdHeader className="all-header">
      <div className="all-header-body">
        <Link to="/">
          <div className="header-content">
            <img
              src={`${process.env.PUBLIC_URL}/projectList/ic_mockbot_logo_44.png`}
              alt="ic_mockbot_logo_44"
              width={134}
              height={32}
            />
          </div>
        </Link>
        <div className={`header-content ${isHamburger}`}>
          <Link to="/manual" className="header-padding">
            <div>사용가이드</div>
          </Link>
          <Link to="/support" onClick={supportClick} className="header-padding">
            <div>고객지원</div>
          </Link>
          {user.id !== null ? (
            <Dropdown
              menu={profileItems}
              overlayStyle={{ width: "280px", height: "114px" }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Avatar
                src={`${process.env.PUBLIC_URL}/header/ic_profile.png`}
                size={28}
                style={{ cursor: "pointer" }}
              />
            </Dropdown>
          ) : (
            <Link to={"/signin"} className="header-padding">
              <div>로그인</div>
            </Link>
          )}
        </div>
        <div
          className={`hamburger ${isHamburger}`}
          onClick={() => setOpen(true)}
        >
          <Image
            path={`${process.env.PUBLIC_URL}/header`}
            name={"ic_hamburger"}
            size="md"
          />
        </div>
        <HamburgerDrawer />
      </div>
    </AntdHeader>
  );
};

export default Header;
