// AuthContext.js
import React, { createContext, useContext, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userState } from "./recoil/user/userState";
import { useCookies } from "react-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user ,setUser] = useRecoilState(userState); //사용자 세션 정보 관리
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    //2024.04.12[shiningtrue]: TOKEN이 없다면 사용자 정보 Recoil 초기화
    if (sessionStorage.getItem('accessToken') === null && !cookies.autoLogin) {
      authContextLogout();
    }
  }, []);

  const authContextLogin = (response) => {
    // 2024.02.02 [shiningtrue]: 헤더 로그인 버튼 상태관리
    //2024.01.25[shiningtrue]: TOKEN 저장
    sessionStorage.setItem("accessToken", response.data.data.token.accessToken);
    localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
    //TOKEN 저장 END
    setUser({
      id: response.data.data.id,
      name: response.data.data.name,
      email: response.data.data.email,
      adminYn: response.data.data.adminYn,
      registerPath: response.data.data.registerPath,
      apiKey: response.data.data.apiKey,
    });
  };

  const authContextLogout = () => {
    //2024.02.02 [shiningtrue]: 헤더 로그아웃 버튼 상태관리
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUser({ id: null, name: null, email: null, adminYn: null, registerPath: null, apiKey: null});
  };

  return (
    <AuthContext.Provider
      value={{ authContextLogin, authContextLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
