import React, {useEffect, useState} from "react";
import {Layout} from "antd";

import Header from "../layout/main/Header";
import MainSider from "../layout/main/Sider";
import SubSider from "../layout/main/SubSider";
import Content from "../layout/main/Content";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  commonEnvironmentVariableListState,
  commonErrorCodeListState,
  commonResponseFormBodyListState,
  commonResponseFormHeaderListState,
  commonUserVariableListState,
  contentTypeListState,
  encryptionMethodListState,
  projectState,
} from "../../recoil/project/projectState";
import {loadProjectApi} from "../../api/project/projectApi";
import {useParams} from "react-router-dom";
import useAxiosInterceptor from "../../axios/axios";
import Main from "./Main";
import {loadCommonErrorCodeApi, loadCommonErrorCodeConstantApi,} from "../../api/commonErrorCode/commonErrorCodeApi";
import {loadCommonVariableApi} from "../../api/commonVariable/commonVariableApi";
import {loadCommonResponseFormApi} from "../../api/commonResponseForm/commonResponseFormApi";
import {loadContentTypeApi, loadEncryptMethodApi} from "../../api/uri/uriApi";
import {commonErrorCodeConstantListState} from "../../recoil/commonErrorCode/commonErrorCodeState";
import {loadProjectApisApi} from "../../api/api/apiApi";
import {
  alertHelpIcon,
  alertHelpIconOpenState,
  apiTabRecoilPersistState,
  apiTreeDataNotEmptyState,
  helpIconMessageState,
} from "../../recoil/api/apiState";
import {loadInviteUsersApi} from "../../api/invite/inviteApi";
import {
  invitedUserListState,
  searchUserDataState,
  userListGroupByAuthorityState,
} from "../../recoil/invite/inviteState";
import {updateInviteUserListState, userState,} from "../../recoil/user/userState";

const ProjectMain = () => {
  // 2024.04.12 [energysteel]: axios
  const axios = useAxiosInterceptor();
  // 2024.03.06 [energysteel]: 유저 ID
  const userId = useRecoilValue(userState).id;
  // 2024.04.12 [energysteel]: 좌측 사이드탭 Menu (1-5)
  const [menuKey, setMenuKey] = useState("1");
  // 2024.05.19 [evahong]: 반응형 subsider open 유무
  const [isSubSiderOpen, setIsSubSiderOpen] = useState(true)
  // 2024.04.12 [energysteel]: API Tree 데이터
  const [treeData, setTreeData] = useState([]);
  // 2024.04.12 [energysteel]: 사용자 리스트
  const [userListGroupByAuthority, setUserListGroupByAuthority] = useRecoilState(userListGroupByAuthorityState);
  // 2024.04.12 [energysteel]: Project ID
  const { projectId } = useParams();
  // 2024.04.12 [energysteel]: Project 정보
  const [, setProject] = useRecoilState(projectState);
  // 2024.04.12 [energysteel]: 404 페이지
  const [notFoundProject, setNotFoundProject] = useState(false);
  // 2024.04.12 [energysteel]: Loading Flag
  const [loading, setLoading] = useState(true);
  // 2024.03.11 [energysteel]: Content-Type 정보
  const [, setContentTypeList] = useRecoilState(contentTypeListState);
  // 2024.03.11 [energysteel]: 암호화 방식 정보
  const [, setEncryptionMethodList] = useRecoilState(encryptionMethodListState);
  // 2024.03.15 [shiningtrue]: List 사용자변수 상태 관리 변수
  const [, setCommonUserVariableListRecoilState] = useRecoilState(
      commonUserVariableListState,
  );
  // 2024.03.15 [shiningtrue]: List 환경변수 상태 관리 변수
  const [, setCommonEnvironmentVariableListRecoilState] = useRecoilState(
      commonEnvironmentVariableListState,
  );
  // 2024.03.18 [shiningtrue]: List 상태 관리 변수
  const [, setCommonErrorCodeRecoilState] = useRecoilState(
      commonErrorCodeListState,
  );
  // 2024.03.18 [shiningtrue]: constant 상태 관리 변수
  const [, setCommonErrorCodeConstantRecoilState] = useRecoilState(
      commonErrorCodeConstantListState,
  );
  // 2024.03.22 [shiningtrue]: List 상태 관리 변수 Header
  const [, setCommonResponseFormHeaderRecoilState] = useRecoilState(
      commonResponseFormHeaderListState,
  );
  // 2024.03.22 [shiningtrue]: List 상태 관리 변수 Body
  const [, setCommonResponseFormBodyRecoilState] = useRecoilState(
      commonResponseFormBodyListState,
  );
  // 2024.03.08 [energysteel]: API TreeData가 있는지 flag
  const [, setApiTreeDataNotEmpty] = useRecoilState(apiTreeDataNotEmptyState);
  // 2024.03.06 [energysteel]: 유저 조회 키워드
  const searchUserData = useRecoilValue(searchUserDataState);
  // 2024.03.06 [energysteel]: 초대 완료, 초대 중인 전체 유저 리스트
  const [, setInvitedUserList] = useRecoilState(invitedUserListState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);
  const [tabRecoilPersistState, setTabRecoilPersistState] = useRecoilState(
      apiTabRecoilPersistState,
  );


  /**
   * 2024.03.07 [energysteel]: API 조회 API
   */
  useEffect(() => {
    loadProjectApisApi(axios, projectId, loadProjectApisSuccessCallback);
    // 2024.03.11 [energysteel]: Axios, loadProjectApiSuccessCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  /**
   * 2024.03.07 [energysteel]: API 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const loadProjectApisSuccessCallback = (response) => {
    let tree = response.data.data;
    // 2024.03.07 [energysteel]: 결과가 있으면 true, 없으면 false
    if (tree.length === 0) {
      setApiTreeDataNotEmpty(false);
    } else {
      setApiTreeDataNotEmpty(true);
    }

    let drawTreeData = drawTree(tree);
    setTreeData(drawTreeData);
    if (tabRecoilPersistState[projectId]) {
      const updatedPersistState = tabRecoilPersistState[projectId].filter(
          (id) => response.data.data.some((item) => item.id === id)
      );

      setTabRecoilPersistState({
        ...tabRecoilPersistState,
        [projectId]: updatedPersistState,
      });
    }
  };

  /**
   * 2024.03.07 [energysteel]: API 조회 API 결과를 통해 Tree Data 생성
   * @param data API 응답 값 Tree Data
   * @returns {*[]}
   */
  const drawTree = (data) => {
    const tree = [];

    data.forEach((value) => {
      const saveData = {
        key: "",
        id: value.id,
        title: value.apiName,
        packageYn: value.packageYn,
        isLeaf: !value.packageYn,
        // 2024.03.07 [energysteel]: 수정 시 Input 활성화
        update: false,
        search: true,
        upperId: value.upperId,
        httpMethod: value.httpMethod ?? "GET",
        children: [],
      };

      // 2024.03.07 [energysteel]: 현재까지 작성중인 TreeData 중 value의 Parent가 있는지 조회
      let parent = tree.find((parentNode) => parentNode.id === value.upperId);

      // 2024.03.07 [energysteel]: Parent 존재 시 0-0-0 형태의 key 생성 후, Parent의 Children으로 할당
      if (parent) {
        saveData.key = `${parent.key}-${value.order}`;
        parent.children.push(saveData);
        // 2024.03.07 [energysteel]: Parent가 존재하지 않는 경우 0-0 형태의 key 생성 후 Parent로써 할당
      } else {
        saveData.key = `0-${value.order}`;
        tree.push(saveData);
      }
    });

    return tree;
  };

  useEffect(() => {
    // 2024.03.06 [energysteel]: 초대 완료/초대 중 유저 전체 조회 API
    loadInviteUsersApi(
        axios,
        projectId,
        searchUserData,
        loadProjectApiRolesApiSuccessCallback,
    );

    // 2024.03.06 [energysteel]: axios
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, userId, searchUserData]);

  /**
   * 2024.03.06 [energysteel]: 초대 완료/초대 중 유저 전체 조회 성공 Callback
   * @param response API 응답 값
   */
  const loadProjectApiRolesApiSuccessCallback = (response) => {
    const data = response.data.data;

    setInvitedUserList((prev) => {
      return data.map((value, index) => {
        return updateInviteUserListState(value, index);
      });
    });
  };

  /**
   * 2024.03.07 [energysteel]: ContentType 조회 API
   */
  useEffect(() => {
    loadContentTypeApi(axios, loadContentTypeApiSuccessCallback);
    // 2024.03.11 [energysteel]: 암호화 방식 정보
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 2024.03.07 [energysteel]: ContentType 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const loadContentTypeApiSuccessCallback = (response) => {
    setContentTypeList(response.data.data);
  };

  // 2024.03.06 [energysteel]: 현재 프로젝트 상세 내용 조회
  useEffect(() => {
    loadProjectApi(
        axios,
        projectId,
        (response) => loadProjectApiSuccessCallback(response),
        () => loadProjectApiFailCallback(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  /**
   * 2024.04.04 [energysteel]: 현재 프로젝트 상세 내용 조회 API 성공 Callback
   * @param response API 성공 응답 값
   */
  const loadProjectApiSuccessCallback = (response) => {
    setProject(response.data.data);
    setNotFoundProject(false);
    setLoading(false);
  };

  /**
   * 2024.04.04 [energysteel]: 현재 프로젝트 상세 내용 조회 API 실패 Callback
   */
  const loadProjectApiFailCallback = () => {
    setNotFoundProject(true);
    setLoading(false);
  };

  /**
   * 2024.04.12 [energysteel]: API/사용자 Tree List에 Search Flag 부여
   * @param event onChange event
   * @param list Data List (API: treeData 사용자: userListGroupByAuthority)
   * @param setList Data List Set (API: treeData 사용자: userListGroupByAuthority)
   * @param setInputValue Input
   */
  const handleSearch = (event, list, setList, setInputValue) => {
    setInputValue(event.target.value);
    const searchData = event.target.value.replace(/ /g, "").toLowerCase(); // 입력된 검색어
    const filtered = list.map((item) => {
      let parentIncludedSearch = item.title
          .replace(/ /g, "")
          .toLowerCase()
          .includes(searchData);

      // 2024.04.11 [energysteel]: 부모노드가 조회되었다면 자식 노드까지 조회
      if (parentIncludedSearch) {
        return {
          ...item,
          search: true,
          children: item.children.map((child) => ({
            ...child,
            search: true,
          })),
        };
      }

      // 2024.04.11 [energysteel]: 자식노드가 조회되었다면 부모 노드까지 조회
      const updatedChildren = item.children.map((child) => {
        let childIncludedSearch = false;
        const childTitleSearch = child.title
            .replace(/ /g, "")
            .toLowerCase()
            .includes(searchData);
        const childEmailSearch = child.guestUserEmail
            ?.replace(/ /g, "")
            .toLowerCase()
            .includes(searchData);

        if (childTitleSearch || childEmailSearch) {
          childIncludedSearch = true;
          parentIncludedSearch = true;
        }

        return {
          ...child,
          search: childIncludedSearch,
        };
      });

      return {
        ...item,
        search: parentIncludedSearch,
        children: updatedChildren,
      };
    });

    setList(filtered);
  };

  /**
   * 2024.04.12 [energysteel]: 조회 대상의 사용자를 Filtering
   * @param list Data List (API: treeData 사용자: userListGroupByAuthority)
   * @return {*}
   */
  const filteringTreeData = (list) => {
    return list
        .map((item) => {
          if (item.search !== true) return false;
          const filteredChildren = item.children.filter(
              (child) => child.search === true,
          );

          return {
            ...item,
            children: filteredChildren,
          };
        })
        .filter(Boolean);
  };

  useEffect(() => {
    if (projectId !== 0) {
      // 2024.03.18 [shiningtrue]: 공통 에러코드 조회
      loadCommonErrorCodeApi(
          axios,
          projectId,
          loadCommonErrorCodeApiSuccessCallback,
      );

      // 2024.03.18 [shiningtrue]: 상수값 load
      loadCommonErrorCodeConstantApi(
          axios,
          loadCommonErrorCodeConstantApiSuccessCallback,
      );

      loadCommonVariableApi(
          axios,
          projectId,
          loadCommonVariableApiSuccessCallback,
      );

      // 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Header
      loadCommonResponseFormApi(
          axios,
          projectId,
          "HEADER",
          loadCommonResponseFormHeaderApiSuccessCallback,
      );

      // 2024.03.22 [shiningtrue]: 공통 응답규격 조회 Body
      loadCommonResponseFormApi(
          axios,
          projectId,
          "BODY",
          loadCommonResponseFormBodyApiSuccessCallback,
      );

      // 2024.03.22 [shiningtrue]: 환경변수 암호화 방식 조회
      loadEncryptMethodApi(
          axios,
          projectId,
          loadEncryptMethodApiSuccessCallback,
      );
    }
  }, [projectId]); // project 내부에서 header의 drop&down으로 프로젝트 이동시 재조회

  // 2024.03.18 [shiningtrue]: List 조회 callback
  const loadCommonErrorCodeApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorCodeRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.18 [shiningtrue]: constant 조회 callback
  const loadCommonErrorCodeConstantApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonErrorCodeConstantRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.22 [shiningtrue]: Header List 조회 callback
  const loadCommonResponseFormHeaderApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonResponseFormHeaderRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.22 [shiningtrue]: Body List 조회 callback
  const loadCommonResponseFormBodyApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      setCommonResponseFormBodyRecoilState(response.data.data);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  // 2024.03.15 [shiningtrue]: List 조회 callback
  const loadCommonVariableApiSuccessCallback = (response) => {
    if (response.data.code === "0001") {
      const userVariables = response.data.data.filter(
          (item) => !item.isEnvironment,
      ); // isEnvironment가 false인 요소들
      const environmentVariables = response.data.data.filter(
          (item) => item.isEnvironment,
      ); // isEnvironment가 true인 요소들
      setCommonUserVariableListRecoilState(userVariables);
      setCommonEnvironmentVariableListRecoilState(environmentVariables);
    } else {
      setHelpIconMessage({
        code: "error",
        message: response.data.message,
      });
      alertHelpIcon(setAlertHelpIconOpen);
    }
  };

  /**
   * 2024.03.07 [energysteel]: 암호화 방식 조회 API 성공 Callback
   * @param response API 응답 값
   */
  const loadEncryptMethodApiSuccessCallback = (response) => {
    setEncryptionMethodList(response.data.data);
  };


  return (
    <>
      {loading ?
        <>
        </>
        :
        <>
          {notFoundProject ?
            <Main name={"error"}/>
            :
            <Layout className="projectManager">
              <Header setMenuKey={setMenuKey}/>
              <Layout>
                <MainSider menuKey={menuKey} setMenuKey={setMenuKey} isSubSiderOpen={isSubSiderOpen} setIsSubSiderOpen={setIsSubSiderOpen}/>
                <Layout>
                  <Layout>
                    {menuKey === "1" || menuKey === "4" ? (
                      <SubSider
                        menuKey={menuKey}
                        isSubSiderOpen={isSubSiderOpen}
                        treeData={treeData}
                        setTreeData={setTreeData}
                        userListGroupByAuthority={userListGroupByAuthority}
                        setUserListGroupByAuthority={setUserListGroupByAuthority}
                        handleSearch={handleSearch}
                        filteringTreeData={filteringTreeData}
                      />
                    ) : null}
                    <Content
                      menuKey={menuKey}
                      treeData={treeData}
                      isSubSiderOpen={isSubSiderOpen}
                      setTreeData={setTreeData}
                      handleSearch={handleSearch}
                      filteringTreeData={filteringTreeData}
                      userListGroupByAuthority={userListGroupByAuthority}
                    />
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
          }
        </>
      }

    </>
  );
};

export default ProjectMain;
