import {toast} from "react-toastify";

export const loadHistoriesApi = (axios, loadHistoriesRequest, successCallback) => {
  axios
    .get("/history", {
      params: {
        userId: loadHistoriesRequest.userId,
        startDate: loadHistoriesRequest.startDate,
        endDate: loadHistoriesRequest.endDate,
      },
    })
    .then(function (response) {
      if (response.data.code === "0001") {
        successCallback(response);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      // 오류발생시 실행
      console.log(error);
    })
    .then(() => {
      // 항상 실행
    });
};