import RequiredText from "../../../atoms/RequiredText";
import {ConfigProvider, Dropdown, Input, Space, Tooltip} from "antd";
import ModalSelect from "../../../atoms/ModalSelect";
import Image from "../../../atoms/Image";
import {
  alertHelpIcon,
  helpIconMessageState,
  alertHelpIconOpenState,
  selectedApiState,
  tabOpenListState,
  useHttpMethods
} from "../../../../recoil/api/apiState";
import {useRecoilState} from "recoil";
import {contentTypeListState, encryptionMethodListState, projectState} from "../../../../recoil/project/projectState";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {queryStringState} from "../../../../recoil/uri/uriState";

const { TextArea } = Input;

/**
 * API URI
 * @param uriRefs
 * @returns {Element}
 * @constructor
 */
const ApiUrlContent = ({ uriRefs, isSubSiderOpen }) => {
  // 2024.03.07 [energysteel]: API Tree에서 선택한 API 정보
  const [selectedApi] = useRecoilState(selectedApiState);
  // 2024.03.07 [energysteel]: 상단에 열려있는 URI 탭 목록
  const [tabOpenList, setTabOpenList] = useRecoilState(tabOpenListState);
  let requestItem = tabOpenList.find((item) => item.id === selectedApi.id);
  useEffect(() => {
    requestItem = tabOpenList.find((item) => item.id === selectedApi.id);
  }, [selectedApi.id]);

  // 2024.04.08 [energysteel]: 현재 프로젝트 정보
  const [project] = useRecoilState(projectState);
  // 2024.04.08 [energysteel]: 복사 아이콘 mouse hover tooltip 메시지
  const [copyText, setCopyText] = useState("Copy URL");
  const uri = tabOpenList.find((item) => item.id === selectedApi.id)?.uri;
  // 2024.03.11 [energysteel]: Content-Type 정보
  const [contentTypeList] = useRecoilState(contentTypeListState);
  // 2024.03.11 [energysteel]: 암호화 방식 정보
  const [encryptionMethodList] = useRecoilState(encryptionMethodListState);
  const [, setAlertHelpIconOpen] = useRecoilState(alertHelpIconOpenState);
  const [, setHelpIconMessage] = useRecoilState(helpIconMessageState);

  const delayMin = 0;
  const delayMax = 60000;

  const uriEditing = () => {
    setTabOpenList((prev) => {
      return prev.map((item) => {
        if (item.id === selectedApi.id) {
          return {
            ...item,
            uriEditing: true,
          };
        }
        return item;
      });
    });
  };

  /**
   * 2024.03.07 [energysteel]: URI Content State 변경
   * @param name State Property
   * @param value 변경할 값
   */
  const handleChange = (name, value) => {
    uriEditing();

    setTabOpenList((prev) => {
      return prev.map(tab => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            uri: {
              ...tab.uri,
              [name]: value,
            }
          }
        } else {
          return tab;
        }
      })
    });
  }

  /**
   * 2024.05.09 [energysteel]: URI State 변경
   *  - 첫번째 입력 값이 / 가 아닌 경우 / 를 붙여줌
   * @param value 입력 value
   */
  const handleUriChange = (value) => {
    if (!value.startsWith("/") && value.length > 0) {
      value = `/${value}`;
    }

    const queryStringStartIndex = value.indexOf("?");

    if (queryStringStartIndex !== -1) {
      setupQueryString(value.substring(queryStringStartIndex + 1));
    } else {
      if (requestItem.requestParameter.length > 0) {
        clearRequestParameter();
      }
    }

    handleChange('uri', value);
  }

  const clearRequestParameter = () => {
    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            requestEditing: true,
            requestParameter: [],
          };
        }
        return tab;
      });
    });
  }

  const setupQueryString = (queryString) => {
    const keyValue = queryString.split('&');

    const parameters = [];

    for (const [index, keyValueElement] of keyValue.entries()) {
      const keyValueSplit = keyValueElement.split('=');
      const key = keyValueSplit[0];
      if (key === '') {
        continue;
      }

      parameters.push(createRequestParameter(index, key))
    }

    if (parameters.length === 0) {
      return;
    }

    const saveQueryStringKeys = parameters.map(parameter => parameter.keyValue);
    const loadQueryStringKeys = requestItem.requestParameter.map(parameter => parameter.keyValue);

    if (!isModifiedQueryString(saveQueryStringKeys, loadQueryStringKeys)) {
      return;
    }

    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            requestEditing: true,
            requestParameter: parameters,
          };
        }
        return tab;
      });
    });
  }

  const isModifiedQueryString = (leftArray, rightArray)=> {
    if (rightArray.length > leftArray.length) {
      return true;
    }

    for (let i = 0; i < leftArray.length; i++) {
      if (rightArray[i] === undefined || rightArray[i] !== leftArray[i]) {
        return true;
      }
    }

    return false;
  }


  const createRequestParameter = (index, key) => {
    return {
      apiId: selectedApi.id,
      dataType: 'STRING',
      description: undefined,
      key: `new-${index}`,
      keyValue: key,
      requiredYn: false,
      type: 'PARAMETER',
      children: [],
      upperId: 0,
      requestDetail: {
        isEncryption: false,
        min: undefined,
        max: undefined,
        regular: undefined,
        requestId: undefined,
        requestEnums: [],
      }
    }
  }

  /**
   * 2024.03.27 [energysteel]: EncryptionMethod 변경 Event
   * @param name State Property
   * @param value 변경할 값
   */
  const handleChangeEncryptionMethod = (name, value) => {
    uriEditing();
    if (value === 'NONE') {
      value = undefined;
    }

    setTabOpenList((prev) => {
      return prev.map(tab => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            uri: {
              ...tab.uri,
              [name]: value,
            }
          }
        } else {
          return tab;
        }
      })
    });
  };

  /**
   * 2024.03.27 [energysteel]: Delay 변경 Event
   * @param name State Property
   * @param value 변경할 값
   */
  const handleChangeDelay = (name, value) => {
    let isNumber = /^\d*$/.test(value);
    if ((value !== '' && !isNumber) || (value.substring(0, 1) === '0' && value.length > 1)) {
      return;
    }

    uriEditing();

    if (Number(value) > delayMax) {
      value = delayMax;
    }

    setTabOpenList((prev) => {
      return prev.map(tab => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            uri: {
              ...tab.uri,
              [name]: value,
            }
          }
        } else {
          return tab;
        }
      })
    })
  };

  /**
   * 2024.03.07 [energysteel]: API > URI > HttpMethod Dropdown Handler
   */
  const useHttpMethodsHandle = () => {
    return useHttpMethods(
      uri?.httpMethod ? uri?.httpMethod : 'GET',
      (e) => handleHttpMethodChange(e)
    );
  };

  // 2024.04.08 [energysteel]: HTTP Method Dropdown
  const httpMethodDropdown = useHttpMethodsHandle();

  /**
   * 2024.03.07 [energysteel]: API > URI > HttpMethod Dropdown
   *  - 기존과 다른 값으로 수정하면 Tab에 `*` 처리
   * @param event onChange Event
   */
  const handleHttpMethodChange = (event) => {
    uriEditing();

    setTabOpenList((prev) => {
      return prev.map((tab) => {
        if (tab.id === uri?.id) {
          return {
            ...tab,
            uriEditing: true,
          };
        }
        return tab;
      });
    });

    setTabOpenList((prev) => {
      return prev.map(tab => {
        if (tab.id === selectedApi.id) {
          return {
            ...tab,
            uri: {
              ...tab.uri,
              httpMethod: event.target.textContent,
            }
          }
        } else {
          return tab;
        }
      })
    })
  };

  /**
   * 2024.04.01 [energysteel]: URL 복사 버튼 onClick Event
   *  - URL Toast, Tooltip Copy URL -> Copied 변경
   */
  const handleCopiedURL = () => {
    if (!uri?.uri) {
      setHelpIconMessage({
        code: "error",
        message: "URI를 입력해주세요.",
      });
      alertHelpIcon(setAlertHelpIconOpen);

      return;
    }

    // 2024.05.09 [energysteel]: Protocol://HOST.DOMAIN/context/uri
    const url = `${process.env.REACT_APP_API_PATH}${project?.context}${uri?.uri}`;

    window.navigator.clipboard.writeText(url).then(() => {
      setCopyText("Copied");
      toast.success(url);
    });
  };

  /**
   * 2024.04.01 [energysteel]: URL 복사 버튼 onMouseEnter Event
   */
  const handleOnMouseEnter = () => {
    setCopyText("Copy URL");
  };


  return (
    <>
      <div className="Body7_R g900">
        API URI 및 기본 인터페이스 정보를 설정합니다.
      </div>
      <div className="mt16 mb16 mr2 caret-color">
        <div
          className="content b-g200 bg-g100 br3 Body6_B"
          style={{ width: "100%", height: "36px" }}
        >
          <Dropdown
            placement="bottomRight"
            menu={httpMethodDropdown}
            className="g500"
            overlayStyle={{
              width: "156px",
              height: "114px",
            }}
            trigger={["click"]}
          >
            <div
              className="content jc-sb Body6_B pr10 pl16 br-g200 h-full cur"
              style={{ width: "120px" }}
            >
              <span
                className="brand500"
                style={{
                  display: "inline-block",
                  color: httpMethodDropdown.items
                    .find(method => method.httpMethod === uri?.httpMethod)?.color ??
                    '#01803c'
                }}>
                {uri?.httpMethod ? uri?.httpMethod : "GET"}
              </span>
              <Space style={{ lineHeight: "16px" }}>
                <Image
                  path={`${process.env.PUBLIC_URL}/content`}
                  name={"ic_arrow_down"}
                />
              </Space>
            </div>
          </Dropdown>
          <div className="content jc-c br-g200 h-full pl16 pr16">
            <div className="url-mid">
              {project.context}
            </div>
          </div>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  activeBg: "white",
                  hoverBg: "white",
                },
              },
              token: { colorBgContainer: "white", lineWidth: 0 },
            }}
          >
            <Input
              onChange={(e) => handleUriChange(e.target.value)}
              className="input-B"
              placeholder={"URI를 입력해주세요."}
              suffix={
                <div className="content cur">
                  <Tooltip placement="bottom" title={copyText}>
                    <span onMouseEnter={handleOnMouseEnter}>
                      <Image
                        path={`${process.env.PUBLIC_URL}/content`}
                        name={"ic_copy"}
                        onClick={handleCopiedURL}
                      />
                    </span>
                  </Tooltip>
                </div>
              }
              value={uri?.uri}
              ref={(input) => (uriRefs.current['uri'] = input)}
            />
          </ConfigProvider>
        </div>
      </div>
      <div>
        <table className={`api-url-table isSubSiderOpen-${isSubSiderOpen}`}>
          <colgroup>
            <col className="apiurl-first" />
            <col className="apiurl-sec" />
          </colgroup>
          <tbody>
            <tr>
              <td className="Body6_B g500">
                <div className="ml16">
                  <RequiredText text={"Content Type"} />
                </div>
              </td>
              <td>
                {/* 2024.04.04 [energysteel]: 현재 사용 가능한 ContentType은
                application/json 한개로 disabled Input이나,
                추후 확장 시 Select로 변경해야 함 Commit 메시지 참고
                 */}
                <Input
                  width={"100%"}
                  className="Body6_R g900 bg-white br3"
                  style={{
                    background: "#ffffff",
                    color: "black",
                    height: "36px"
                  }}
                  placeholder={"ContentType을 선택해주세요."}
                  options={contentTypeList}
                  onChange={(e, v) => handleChange("contentType", v.label)}
                  value={contentTypeList.find(contentType => contentType.value === uri?.contentType)?.label
                    ?? 'application/json'
                  }
                  disabled={true}
                />
              </td>
            </tr>
            <tr>
              <td className="Body6_B g500">
                <div className="ml16">
                  <RequiredText text={"Delay (ms)"} />
                </div>
              </td>
              <td>
                <Input
                  type="number"
                  min={delayMin}
                  max={delayMax}
                  className="Body6_R g900 bg-white br3"
                  style={{ height: "36px" }}
                  placeholder="Delay를 입력해주세요. (최대 6,000ms)"
                  onChange={(e) => handleChangeDelay("delay", e.target.value)}
                  value={uri?.delay}
                  ref={(input) => (uriRefs.current['delay'] = input)}
                />
              </td>
            </tr>
            <tr>
              <td className="Body6_B g500">
                <div className="ml16">암호화 방식</div>
              </td>
              <td>
                <ModalSelect
                  width={"100%"}
                  placeholder="적용 안함"
                  value={
                    uri?.encryptionMethodId ?
                      encryptionMethodList.find(method => method.value === uri.encryptionMethodId.toString())?.value :
                      "적용 안함"
                  }
                  options={[
                    {
                      value: "NONE",
                      label: "적용 안함",
                    },
                    ...encryptionMethodList.map((variable, i) => ({
                      value: variable.value,
                      label: variable.label,
                    })),
                  ]}
                  handleChange={(value, obj) => handleChangeEncryptionMethod("encryptionMethodId", obj.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="Body6_B g500">
                <div className="ml16" style={{ height: "330px" }}>
                  <span>설명</span>
                </div>
              </td>
              <td>
                <TextArea
                  className="Body6_R g900 bg-white br3"
                  placeholder="설명을 입력해주세요."
                  autoSize={{ minRows: 17, maxRows: 17 }}
                  onChange={(e) => handleChange("description", e.target.value)}
                  value={uri?.description}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ApiUrlContent;
