import {Modal} from "antd";
import Footer from "../../molecules/modals/Footer";
import Title from "../../molecules/modals/Title";
import {useRecoilState} from "recoil";
import {closeModal, isModalOpenState, pageState, projectListState} from "../../../recoil/project/projectState";
import {deleteProjectApi, loadProjectsApi, loadProjectsApiSuccessCallback} from "../../../api/project/projectApi";
import {useNavigate} from "react-router-dom";
import useAxiosInterceptor from "../../../axios/axios";
import React, {useState} from "react";
import Loader from "../../common/Loader";
import {toast} from "react-toastify";

const ProjectDeleteModal = ({ redirect = false, project, setDeleteLoading }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  const navigate = useNavigate();
  // 2024.03.06 [energysteel]: Modal Open 상태
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenState);
  const [page, setPage] = useRecoilState(pageState);
  const [, setProjectList] = useRecoilState(projectListState);


  /**
   * 2024.03.06 [energysteel]: 삭제 버튼 onClick Event
   */
  const handleDeleteOk = () => {
    setDeleteLoading(true);
    deleteProjectApi(
      axios,
      project.id,
      deleteProjectApiSuccessCallback,
      () => setDeleteLoading(false),
    );

    handleCancel();
  }

  // 2024.03.06 [energysteel]: 프로젝트 조회 객체
  const loadProjectRequest = {
    pageNumber: 0,
    pageSize: page.pageSize,
    searchWord: "",
    searchCategories: "ALL",
  };

  /**
   * 2024.03.06 [energysteel]: 삭제 성공 시 callback
   */
  const deleteProjectApiSuccessCallback = () => {
    toast.success("Project 삭제 완료되었습니다.");

    // 2024.03.06 [energysteel]: 프로젝트 내부에서 삭제 시 main 페이지로 redirect
    if (redirect) {
      navigate("/main");
    } else {
      loadProjectsApi(
        axios,
        loadProjectRequest,
        (response) => {
          loadProjectsApiSuccessCallback(response, setProjectList, setPage);
          setDeleteLoading(false);
        },
        () => {},
      );
    }
  }

  /**
   * 2024.03.06 [energysteel]: Modal 종료
   */
  const handleCancel = () => {
    closeModal(setIsModalOpen, project.id, 'delete');
  }

  return (
    <>
      <Modal
        className="modal"
        title={
          <Title
            title={"Project 삭제"}
            subTitle={
              "Project를 삭제합니다."
            }
          />
        }
        open={isModalOpen?.find(v => v.id === project.id)?.delete}
        onCancel={handleCancel}
        width={528}
        centered
        footer={
          <Footer
            textCancel={"아니오"}
            textOk={"네"}
            handleCancel={handleCancel}
            handleOk={handleDeleteOk}
          />
        }
      >
        <div
          className="ta b-g200 bg-g100 g500 mt24"
          style={{padding: "32px 109px"}}
        >
          <p
            className="Body6_B"
             style={{whiteSpace: "pre-line"}}
          >
            {`${project.name}
              Project를 삭제 하시겠습니까?`}
          </p>
        </div>
      </Modal>
    </>
  )
}

export default ProjectDeleteModal;
