import React, {useCallback, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AttachButton, SquareButton } from "../../../atoms/Button";
import SupportWriteContent from "../../../molecules/SupportWriteContent";
import Image from "../../../atoms/Image";
import { useRecoilValue } from "recoil";
import { userState } from "../../../../recoil/user/userState";
import {
  deleteAnswerApi,
  deleteSupportApi,
  loadSupportAnswerDetailApi,
  loadSupportDetailApi,
} from "../../../../api/support/supportApi";
import {
  fileDownloadApi,
  loadFileListApi,
} from "../../../../api/common/commonApi";
import useAxiosInterceptor from "../../../../axios/axios";
import {toast} from "react-toastify";
import ConfirmModal from "../../modals/ConfirmModal";

const SupportDetail = ({ data, dataListCall }) => {
  // 2024.03.05[holywater]: axios 선언
  const axios = useAxiosInterceptor();
  // 2024.01.26 [shiningtrue]: 사용자 세션 정보
  const userSession = useRecoilValue(userState);
  // 2024.02.28 [shiningtrue]: 1:1 문의 답변하기 클릭여부
  const [isAnswer, setIsAnswer] = useState(false);
  // 2024.02.28 [shiningtrue]: 화면 이동 하기 위한 변수
  const navigate = useNavigate();
  const location = useLocation();
  // 2024.02.28 [shiningtrue]: 게시물 상세ID
  const detailId = useLocation().state?.id;
  // 2024.02.28 [shiningtrue]: 게시물 상세 데이터 세팅 상태 변수
  const [detail, setDetailData] = useState({
    title: "",
    content: "",
    typeText: "",
    createDate: "",
    createUserId: "",
  });
  // 2024.02.28 [shiningtrue]: 1:1문의 게시물 답변 상세 데이터 세팅 상태 변수
  const [answerDetail, setAnswerDetailData] = useState({
    id: "",
    inquiryId: "",
    content: "",
    createDate: "",
  });
  // 2024.02.28 [shiningtrue]: 게시물 파일 리스트 상태 변수
  const [fileListData, setFileListData] = useState({
    fileData: [],
  });
  // 2024.02.28 [shiningtrue]: 1:1문의 답변 파일 리스트 상태 변수
  const [answerFileListData, setAnswerFileListData] = useState({
    fileData: [],
  });
  // 2024.02.28 [shiningtrue]: 파일 그룹ID를 담기 위한 상태 변수
  const [fileGroupId, setFileGroupId] = useState();
  // 2024.02.28 [shiningtrue]: 1:1문의 답변 수정 버튼 클릭 상태 변수
  const [answerModify, setAnswerModify] = useState(false);
  const [deleteSupportModalOpen, setDeleteSupportModalOpen] = useState(false);
  const [deleteAnswerModalOpen, setDeleteAnswerModalOpen] = useState(false);


  // 2024.02.28 [shiningtrue]: 목록 버튼
  const handleGoBack = () => {
    navigate("/support");
    dataListCall(data.key);
  };

  useEffect(() => {
    detailData(location.state.id);
  }, []);

  const loadSupportDetailSuccessCallback = useCallback((data) => {
    // 상세 데이터를 setDetailData에 넣기
    setDetailData({
      title: data.data.data.title || "",
      content: data.data.data.content || "",
      typeText: data.data.data.typeText || "",
      createDate: data.data.data.createDate || "",
      createUserId: data.data.data.createUserId || "",
    });

    // 답변완료라면 답변 데이터 조회
    if (data.data.data.status === "02") {
      answerDetailData(location.state.id);
    }

    // 파일 리스트
    if (data.data.data.fileGroupId != null && data.data.data.fileGroupId != 0) {
      fileList(data.data.data.fileGroupId);
    }
  }, []);

  // 2024.02.28 [shiningtrue]: 상세 데이터 조회 함수
  const detailData = async (id) => {
    let params = {
      id: id,
    };
    await loadSupportDetailApi(axios, params, data.key, loadSupportDetailSuccessCallback)
  };

  const loadSupportAnswerDetailApiSuccessCallback = useCallback((data) => {
    // 답변 상세 데이터를 setAnswerDetailData 넣기
    setAnswerDetailData({
      id: data.data.data.id,
      inquiryId: data.data.data.inquiryId,
      content: data.data.data.content,
      createDate: data.data.data.createDate,
    });

    //파일 리스트
    if (data.data.data.fileGroupId != null && data.data.data.fileGroupId != 0) {
      setFileGroupId(data.data.data.fileGroupId);
      fileList(data.data.data.fileGroupId, "answer");
    }
  }, []);

  // 2024.02.28 [shiningtrue]: 1:1문의 답변 상세 데이터 조회 함수
  const answerDetailData = async (id) => {
    let params = {
      id: id,
    };
    await loadSupportAnswerDetailApi(axios, params, data.key, loadSupportAnswerDetailApiSuccessCallback)
  };

  const loadFileListSuccessCallback = useCallback((data, type) => {
    if (type !== "answer") {
      setFileListData(() => {
        if (data.data.data.length > 0) {
          return {
            fileData: data.data.data,
          };
        }
      });
    } else if (type === "answer") {
      setAnswerFileListData(() => {
        if (data.data.data.length > 0) {
          return {
            fileData: data.data.data,
          };
        }
      });
    }
  }, []);

  // 2024.02.28 [shiningtrue]: 게시물 파일 리스트 조회 함수
  const fileList = (groupId, type) => {
    let fileListParams = {
      fileGroupId: groupId,
      type: type,
    };
    loadFileListApi(axios, fileListParams, loadFileListSuccessCallback);
  };

  // 2024.02.28 [shiningtrue]: 상세에서 수정 버튼 클릭시
  const onModifyClick = () => {
    navigate("/support", {
      state: { detailId: detailId, isWrite: true, writeType: "modify" },
    });
  };

  // 2024.02.28 [shiningtrue]: 1:1문의 답변 수정 버튼 클릭 함수
  const onAnswerModifyClick = () => {
    settingIsAnswer(true);
    setAnswerModify(true);
  };

  // 2024.02.28 [shiningtrue]: SupportWriteContent.jsx에서 답변 등록 수정시 화면 폼 숨기기 위한 함수
  const settingIsAnswer = (bool) => {
    setIsAnswer(bool);
  };
  const isInquiry = data.key === "inquiry" ? true : false;

  const deleteSupportApiSuccessCallback = useCallback((data, uri) => {
    toast.success("삭제 되었습니다.");
    dataListCall(uri); //리스트 화면 호출
    navigate(-1);
  }, []);

  // 2024.02.28 [shiningtrue]: 게시물 삭제 버튼 함수 (Modal 실행)
  const onDelete = () => {
    setDeleteSupportModalOpen(true);
  };

  // 2024.05.10 [energysteel]: 게시물 삭제 Modal 확인 버튼
  const handleDeleteSupportOk = () => {
    setDeleteSupportModalOpen(false);

    const params = {
      id: data.id,
    };
    deleteSupportApi(axios, params, data.key, deleteSupportApiSuccessCallback);
  }

  const deleteAnswerApiSuccessCallback = useCallback(() => {
    toast.success("삭제 되었습니다.");
    setAnswerDetailData({
      id: "",
      inquiryId: "",
      content: "",
      createDate: "",
    });
  }, []);

  // 2024.02.28 [shiningtrue]: 1:1문의 답변 삭제 버튼 함수 (Modal 실행)
  const onAnswerDelete = () => {
    setDeleteAnswerModalOpen(true);
  };

  // 2024.05.10 [energysteel]: 1:1문의 답변 삭제 Modal 확인 버튼
  const handleAnswerDeleteOk = () => {
    setDeleteAnswerModalOpen(false);

    const params = {
      id: answerDetail.id,
    };
    deleteAnswerApi(axios, params, deleteAnswerApiSuccessCallback)
  }

  const downLoadFileApiSuccessCallback = useCallback((data, originalName) => {
    const blob = new Blob([data.data], {
      type: data.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = originalName; // 다운로드될 파일의 이름
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  // 2024.02.28 [shiningtrue]: 파일 클릭시 다운로드 함수
  const downLoadFile = (savePath, originalName) => {
    let params = {
      savePath: savePath,
      originalName: originalName,
    };
    fileDownloadApi(axios, params, downLoadFileApiSuccessCallback);
  };

  // 2024.02.28 [shiningtrue]: Text 데이터 세팅 함수
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setAnswerDetailData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div>
      <table
        className="support-table detail g500 Body6_R mt-9"
        width={"100%"}
        style={{ borderSpacing: "0", borderCollapse: "collapse" }}
      >
        <colgroup>
          <col className="first" />
          <col className="sec" />
          <col className="last" />
        </colgroup>
        <tbody>
          <tr className="Body3_R g900" key={data.key} height={65}>
            <td className="Body3_B">{detail.typeText}</td>
            <td>
              <div
                className="support-detail"
                style={{
                  overflow: "hidden",
                  whiteSpace: "normal",
                  margin: "15px 0",
                  overflowWrap: "break-word",
                }}
              >
                {detail.title}
              </div>
            </td>
            {userSession.adminYn && data.key !== "inquiry" ? (
              <td className="Body4_B">
                <span
                  className="cur g900 br-g300"
                  style={{ paddingRight: "11px" }}
                  onClick={() => onModifyClick()}
                >
                  수정
                </span>
                <span className="cur error ml11" onClick={onDelete}>
                  삭제
                </span>
              </td>
            ) : data.key !== "inquiry" ? (
              <td className="g400">{detail.createDate}</td>
            ) : null}

            {/* 1:1문의 일때 수정,삭제버튼 분기처리 */}
            {data.key === "inquiry" &&
            answerDetail.id === "" &&
            (userSession.adminYn || userSession.id === detail.createUserId) ? (
              <td className="Body4_B">
                <span
                  className="cur g900 br-g300"
                  style={{ paddingRight: "11px" }}
                  onClick={() => onModifyClick()}
                >
                  수정
                </span>
                <span className="cur error ml11" onClick={onDelete}>
                  삭제
                </span>
              </td>
            ) : data.key === "inquiry" && answerDetail.id !== "" ? (
              <td className="g400">{detail.createDate}</td>
            ) : null}
          </tr>
        </tbody>
      </table>
      <div
        className={isAnswer ? "bb-g900 mb20" : "bb-g200"}
        style={{ padding: "36px" }}
      >
        <p className="mb32">{detail.content}</p>
        {fileListData.fileData.length > 0 ? (
          <div>
            {fileListData.fileData?.map((item, i) => {
              return (
                <a key={i}>
                  <AttachButton
                    text={item.originalName}
                    deleteFlag={false}
                    onClick={() =>
                      downLoadFile(item.savePath, item.originalName)
                    }
                  />
                </a>
              );
            })}
          </div>
        ) : null}
      </div>
      {answerDetail.id !== "" && !isAnswer && (
        <div
          className={isAnswer ? "bb-g900 mb20" : "bb-g200"}
          style={{ padding: "36px" }}
        >
          <div className="content jc-sb mb16">
            <div className="content">
              <span className="content b-g200 br100" style={{ padding: "7px" }}>
                <Image
                  path={process.env.PUBLIC_URL}
                  name={"ic_mockbot_logo_40"}
                  size={"xmd"}
                />
              </span>
              <span className="ml16 Body3_B g900">MOCK BOT</span>
            </div>
            <div className="Body4_B mr-9">
              {userSession.adminYn ? (
                <>
                  <span
                    className="cur g900 br-g300"
                    style={{ paddingRight: "11px" }}
                    onClick={() => onAnswerModifyClick()}
                  >
                    수정
                  </span>
                  <span className="cur error ml11" onClick={onAnswerDelete}>
                    삭제
                  </span>
                </>
              ) : (
                <span className="g400">{answerDetail.createDate}</span> //사용자가 유저일 떄
              )}
            </div>
          </div>
          <p className="mb32">{answerDetail.content}</p>
          {answerFileListData.fileData.length > 0 ? (
            <div>
              {answerFileListData.fileData?.map((item, i) => {
                return (
                  <a key={i}>
                    <AttachButton
                      text={item.originalName}
                      deleteFlag={false}
                      onClick={() =>
                        downLoadFile(item.savePath, item.originalName)
                      }
                    />
                  </a>
                );
              })}
            </div>
          ) : null}
        </div>
      )}
      {!isAnswer ? (
        <div className="content jc-c mt40">
          <SquareButton
            className={"btn-line"}
            type="sec"
            text={"목록"}
            onClick={() => handleGoBack()}
          />
          {isInquiry && answerDetail.id === "" && userSession.adminYn ? (
            <SquareButton
              className={"btn-pri ml6"}
              text={"답변하기"}
              onClick={() => {
                setIsAnswer(true);
                setAnswerModify(false);
              }}
            />
          ) : null}
        </div>
      ) : (
        <SupportWriteContent
          data={data}
          contentValue={answerDetail.content}
          updateContentValue={onChangeInput}
          fileDetailList={answerFileListData}
          fileGroupId={fileGroupId}
          isInquiryAnswer={isAnswer} //1:1문의 답변하기 클릭여부
          answerDetail={answerDetail}
          answerModify={answerModify}
          settingIsAnswer={settingIsAnswer}
          answerDetailData={answerDetailData}
        /> // 답변하기 Input Component
      )}

      <ConfirmModal
        open={deleteSupportModalOpen}
        title={"게시물 삭제"}
        message={"삭제 하시겠습니까?"}
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleDeleteSupportOk}
        handleCancel={() => setDeleteSupportModalOpen(false)}
      />
      <ConfirmModal
        open={deleteAnswerModalOpen}
        title={"1:1 문의 답변 삭제"}
        message={"삭제 하시겠습니까?"}
        textOk={"네"}
        textCancel={"아니오"}
        handleOk={handleAnswerDeleteOk}
        handleCancel={() => setDeleteAnswerModalOpen(false)}
      />
    </div>
  );
};

export default SupportDetail;
