import React from "react";
import { Layout } from "antd";
import {Link} from "react-router-dom";

const { Footer: AntdFooter } = Layout;

const Footer = () => {
  return (
    <AntdFooter className="footer">
      <div className="footer-content">
        <div>
          <p>주식회사 (주)보이저소프트 사업자등록번호 842-81-02639</p>
          <p>대표 이승재 · 개인정보보호 책임자 이승재</p>
          <p>주소 서울시 강남구 강남대로 310 1209호</p>
          <p className="down">
            <br />
            <br />
            <p>
              <Link
                to={"/term"}
                state={{type: "termOfUse"}}
                style={{
                  color: "#949eb7"
                }}>
                서비스 이용약관
              </Link>
              ·
              <Link
                to={"/term"}
                state={{type: "privacyPolicy"}}
                style={{
                  color: "#949eb7"
                }}>
                개인정보처리방침
              </Link>
            </p>
          </p>
        </div>
        <div className="right">
          <p>
            <Link
              to={"/term"}
              state={{type: "termOfUse"}}
              style={{
                color: "#949eb7"
              }}>
              서비스 이용약관
            </Link>
            ·
            <Link
              to={"/term"}
              state={{type: "privacyPolicy"}}
              style={{
                color: "#949eb7"
              }}>
              개인정보처리방침
            </Link>
          </p>
        </div>
      </div>
    </AntdFooter>
  );
};

export default Footer;
