import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { RecoilRoot } from "recoil";
import { AuthProvider } from "./AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <AuthProvider>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "NotoSansKR",
              colorPrimaryHover: "",
            },
            components: {
              Layout: {
                bodyBg: "#ffffff",
              },
              Input: {
                activeBorderColor: "#7c60ff",
                hoverBorderColor: "#dde2ee",
                colorBorder: "#dde2ee",
              },
              Button: { defaultBorderColor: "" },
              Dropdown: { paddingBlock: 7 },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </AuthProvider>
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
