import {atom} from "recoil";
import {Decoration, MatchDecorator, ViewPlugin} from "@uiw/react-codemirror";
import {defaultBinaryImage} from "../file/fileState";

export const editedTestCaseState = atom ({
    key: "editedTestCaseState",
    default: {},
})

export const editedCustomTestCaseState = atom ({
    key: "editedCustomTestCaseState",
    default: {},
})

export const currentResponseCaseState = atom({
    key: "currentResponseCaseState",
    default: {},
})

// 2024.03.29 [energysteel]: 데이터 타입 기본 값
export const dataTypeDefaultValue = {
    LONG: 0,
    INT: 0,
    SHORT: 0,
    FLOAT: 0.1,
    DOUBLE: 0.1,
    STRING: "",
    BOOLEAN: false,
    FILE: defaultBinaryImage,
}

export const validateResponse = (responseParameter, type, setHelpIconMessage, apiName) => {
    let isValid = true;
    let name = '';
    const emptyResponseValueObjects = responseParameter.filter(item => item.keyValue === "");
    if (emptyResponseValueObjects.length > 0) {
        isValid = false;
        name = 'Key';
    }

    if (!isValid) {
        setHelpIconMessage({
            code: "error",
            message: `${apiName} Response ${type}의 ${name}을(를) 입력해주세요.`,
        });
    }

    return isValid;
}

export const validateResponseCase = (responseCase, setHelpIconMessage, apiName) => {
    let isValid = true;
    let name = '';
    const emptyResponseValueObjects = responseCase.filter(item => item.name === "");
    if (emptyResponseValueObjects.length > 0) {
        isValid = false;
        name = 'name';
    }

    if (!isValid) {
        setHelpIconMessage({
            code: 'error',
            message: `${apiName} Response Case의 ${name}을(를) 입력해주세요.`
        });
    }

    return isValid;
}

/**
 * 2024.03.26 [energysteel]: 공통 응답 규격에 공통 에러코드를 value로 넣은 JSON 형태 생성
 * @param commonResponseStructure 공통 응답 규격
 * @param commonCode 공통 에러코드
 * @returns {{}}
 */
export const jsonErrorStructureConvert = (commonResponseStructure, commonCode) => {
    const result = {};

    commonResponseStructure.forEach(item => {
        const {keyValue, dataType, responseCode, children} = item;

        if (dataType === "OBJECT") {
            result[keyValue] = jsonErrorStructureConvert(children, commonCode);
        } else if (dataType === "ARRAY") {
            result[keyValue] = children.map(child => jsonErrorStructureConvert([child], commonCode));
        } else {
            if (responseCode === 'DEFAULT') {
                result[keyValue] = dataTypeDefaultValue[dataType]
            } else if (responseCode === 'ERROR_CODE') {
                result[keyValue] = commonCode?.code;
            } else if (responseCode === 'ERROR_MESSAGE') {
                result[keyValue] = commonCode?.message;
            } else {
                result[keyValue] = "";
            }
        }
    });

    return result;
}

export const editorHighlight = ({
    highlightName = ['image.jpg', 'image.png', 'image.bmp'],
    color,
}) => {
    const fileNamePattern = highlightName.map(name => `"\\$\{${name}}"`).join('|');
    const regexp = `(?<=:\\s*)(${fileNamePattern})`;

    const decorator = new MatchDecorator({
        regexp: new RegExp(regexp, 'g'),
        decoration: Decoration.mark({attributes: {style: `color: ${color}`}})
    })

    return ViewPlugin.fromClass(class {
        constructor(view) {
            this.decorations = decorator.createDeco(view)
        }
        update(update) {
            this.decorations = decorator.updateDeco(update, this.decorations)
        }
    }, {
        decorations: v => v.decorations
    })
}