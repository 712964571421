import React, {useRef, useState} from "react";
import { Input } from "antd";
import RequiredText from "../../atoms/RequiredText";
import ModalSelect from "../../atoms/ModalSelect";
import Image from "../../atoms/Image";
import SwitchInput from "../../atoms/Switch";
import {useRecoilState} from "recoil";
import {isEmptyDetailDataType, requestState} from "../../../recoil/request/requestState";

const DataDetailSettingTable = ({ numberRange, minMaxChange, name, tabType }) => {
  // 2024.03.20 [energysteel]: Request 정보 (Request 상세 포함)
  const [request, setRequest] = useRecoilState(requestState);
  // 2024.03.20 [energysteel]: Enum Input Value
  const [enumInputValue, setEnumInputValue] = useState("");

  const inputRef = useRef({});


  // 2024.03.29 [energysteel]: 정수형 데이터 타입 리스트
  const isIntegerNumber = request?.dataType === 'INT' ||
    request?.dataType === 'LONG' ||
    request?.dataType === 'SHORT';

  // 2024.03.20 [energysteel]: Data Type 리스트
  const dataTypeList = [
    { key: "STRING", value: "String" },
    { key: "INT", value: "Int" },
    { key: "LONG", value: "Long" },
    { key: "SHORT", value: "Short" },
    { key: "DOUBLE", value: "Double" },
    { key: "FLOAT", value: "Float" },
    { key: "BOOLEAN", value: "Boolean" },
    { key: "FILE", value: "File" },
    { key: "OBJECT", value: "Object" },
    { key: "ARRAY", value: "Array" },
    { key: "ANY", value: "Any" },
  ]



  /**
   * 2024.03.20 [energysteel]: 데이터 타입 변경
   */
  const handleDataTypeChange = (value) => {
    const dataType = dataTypeList.find((v) => v.value === value);
    setRequest(prev => {
      return {
        ...prev,
        dataType: dataType?.key,
        requestDetail: {
          ...prev.requestDetail,
          min: minMaxChange(dataType?.key, 'min', prev?.requestDetail?.min),
          max: minMaxChange(dataType?.key, 'max', prev?.requestDetail?.max),
        }
      }
    });
  }

  /**
   * 2024.03.20 [energysteel]: enum Input 수정 Event
   * @param event 입력 Event
   */
  const handleChangeEnum = (event) => {
    setEnumInputValue(event.target.value);
  }

  /**
   * 2024.03.20 [energysteel]: enum Input Enter Event
   * @param event Enter Event
   */
  const handleSaveEnumOnEnter = (event) => {
    // 2024.03.20 [energysteel]: 한글 특성 상 문자 조합으로 인한 중복 방지
    if (event.isComposing || event.keyCode === 229) {
      return;
    }

    if (event.key === 'Enter') {
      handleRequestEnumSave();
    }
  }

  /**
   * 2024.03.20 [energysteel]: enum Input 추가 버튼 onClick Event
   */
  const handleRequestEnumSave = () => {
    if (enumInputValue === "") {
      return;
    }

    setRequest(prev => {
      const enums = prev.requestDetail?.requestEnums;
      let requestEnums = [];
      if (enums) {
        requestEnums = [
          ...enums,
          enumInputValue,
        ];
      } else {
        requestEnums = [enumInputValue];
      }

      return {
        ...prev,
        requestDetail: {
          ...prev.requestDetail,
          requestEnums,
        }
      }
    })

    setEnumInputValue("");
  }

  /**
   * 2024.03.20 [energysteel]: enum 리스트 아이템 삭제
   * @param deletedIndex enum 리스트에서 삭제할 아이템의 index
   */
  const handleDeleteEnum = (deletedIndex) => {
    setRequest(prev => {
      const updatedEnums = prev.requestDetail.requestEnums
          .filter((enums, index) => index !== deletedIndex);

      return {
        ...prev,
        requestDetail: {
          ...prev.requestDetail,
          requestEnums: updatedEnums,
        }
      }
    })
  }

  /**
   * 2024.03.20 [energysteel]: Request 대상의 onChange Event
   * @param name 수정한 property 이름
   * @param value 수정한 property 값
   */
  const handleRequestOnChange = (name, value) => {
    setRequest(prev => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  /**
   * 2024.03.26 [energysteel]: RequestDetail 대상의 Min/Max onChange Event
   *  - 빈 문자열, 정수, 음수만을 허용
   * @param name property 이름
   * @param value property 값
   */
  const handleRequestDetailMinMaxOnChange = (name, value) => {
    let isNumber = /^-?\d*$/.test(value);
    if ((value !== '' && !isNumber) || (value.substring(0, 1) === '0' && value.length > 1)) {
      return;
    }

    handleRequestDetailOnChange(name, value);
  }

  /**
   * 2024.03.20 [energysteel]: RequestDetail 대상의 onChange Event
   * @param name 수정한 property 이름
   * @param value 수정한 property 값
   */
  const handleRequestDetailOnChange = (name, value) => {
    setRequest(prev => {
      return {
        ...prev,
        requestDetail: {
          ...prev.requestDetail,
          [name]: value,
        }
      }
    })
  }

  /**
   * 2024.03.29 [energysteel]: Min, Max Input에 숫자 타입 검증
   *  - DataType의 기본 Range를 넘어가면 Min 또는 Max로 수정
   *  - String의 경우 Long의 Range를 따름
   *  - Max가 Min보다 작으면 Min 값으로 수정
   * @param name min / max
   * @param value 입력 value
   */
  const handleRequestDetailMinMaxRangeOnBlur = (name, value) => {
    // 2024.03.29 [energysteel]: Min/Max를 사용하지 않는 타입이 들어오면 return
    if (request.dataType === 'BOOLEAN' || request.dataType === 'ARRAY' || request.dataType === 'OBJECT') {
      return;
    }

    // 2024.03.29 [energysteel]: MIN이 MAX보다 큰 경우 MAX 값으로 변경
    if (name === 'min') {
      const max = request.requestDetail.max ?? undefined;
      let validateMax = value !== '' && Number(value) > Number(max);
      if (validateMax) {
        handleRequestDetailOnChange(name, max);
        return;
      }
    }
    // 2024.03.29 [energysteel]: MAX가 MIN보다 작은 경우 MIN 값으로 변경
    else if (name === 'max') {
      const min = request.requestDetail.min ?? undefined;
      let validateMax = value !== '' && Number(min) > Number(value);
      if (validateMax) {
        handleRequestDetailOnChange(name, min);
        return;
      }
    }

    if (!validateDataTypeRange(request.dataType, value)) {
      handleRequestDetailOnChange(name, changedDataDefaultValue(name));
    }
  }

  /**
   * 2024.03.29 [energysteel]: Value가 Value가 DataType 최소/최대 값에 포함되어 있는지 검증
   * @param dataType DataType
   * @param value input Value
   * @returns {boolean}
   */
  const validateDataTypeRange = (dataType, value) => {
    return Number(numberRange[dataType].MIN) <= Number(value) && Number(value) <= Number(numberRange[dataType].MAX);
  }

  /**
   * 2024.03.29 [energysteel]: Value가 DataType 최소/최대 값을 초과했을 때 기본 값으로 변경
   * @param name min/max
   * @returns {number|0x8008|*|0x8007} 최소/최대값
   */
  const changedDataDefaultValue = (name) => {
    if (name === 'max') {
        return numberRange[request.dataType].MAX;
    } else {
      return numberRange[request.dataType].MIN;
    }
  }


  return (
    <>
      <table className="dds">
        <colgroup>
          <col width="25%" />
          <col width="75%" />
        </colgroup>
        <tbody>
          <tr>
            <td className="Body6_B g500">
              <RequiredText text={"Data Type"} />
            </td>
            <td>
              <ModalSelect
                value={tabType === 'BODY' ?
                  dataTypeList.find(dataType =>
                    dataType.key === request?.dataType)?.value :
                  'String'
                }
                options={tabType === 'BODY' ? dataTypeList : [{key: 'STRING', value: 'String'}]}
                handleChange={handleDataTypeChange}
              />
            </td>
          </tr>
          <tr>
            <td className="Body6_B g500">
              <RequiredText text={"필수 여부"} />
            </td>
            <td>
              <SwitchInput
                text={"옵션"}
                checked={request?.requiredYn ?? false}
                onChange={(event) => handleRequestOnChange("requiredYn", event)}
              />
            </td>
          </tr>
          {isEmptyDetailDataType(request?.dataType) &&
            <tr>
              <td className="Body6_B g500">
                <RequiredText text={"암호화 여부"}/>
              </td>
              <td>
                <SwitchInput
                  text={"암호화"}
                  checked={request.requestDetail?.isEncryption ?? false
                  }
                  onChange={(event) => handleRequestDetailOnChange("isEncryption", event)}
                />
              </td>
            </tr>
          }
          {(isIntegerNumber || request?.dataType === 'STRING') &&
            <tr>
              <td className="Body6_B g500">
                <div>Min/Max</div>
              </td>
              <td>
                <Input
                  type="text"
                  value={request.requestDetail?.min}
                  className="Body6_R g900 bg-white input"
                  style={{ width: "171px" }}
                  placeholder={"최소값을 입력해주세요."}
                  onChange={(event) => handleRequestDetailMinMaxOnChange("min", event.target.value)}
                  onBlur={(event) => handleRequestDetailMinMaxRangeOnBlur("min", event.target.value)}
                />
                <span className="mr7 ml7">-</span>
                <Input
                  ref={(input) => (inputRef.current['max'] = input)}
                  type="number"
                  value={request.requestDetail?.max}
                  className="Body6_R g900 bg-white input"
                  style={{ width: "171px" }}
                  placeholder={"최대값을 입력해주세요."}
                  onChange={(event) => handleRequestDetailMinMaxOnChange("max", event.target.value)}
                  onBlur={(event) => handleRequestDetailMinMaxRangeOnBlur("max", event.target.value)}
                />
              </td>
            </tr>
          }
          {request?.dataType === 'STRING' &&
            <>
            <tr>
              <td className="Body6_B g500">Enum</td>
              <td>
                <Input
                  value={enumInputValue}
                  onChange={handleChangeEnum}
                  className="Body6_R g300 bg-white mb4 input"
                  placeholder="입력 후 Enter 또는 '추가' 클릭"
                  onKeyDown={handleSaveEnumOnEnter}
                  suffix={
                    <Image
                      path={`${process.env.PUBLIC_URL}/content`}
                      name={"ic_add_bg"}
                      onClick={handleRequestEnumSave}
                    />
                  }
                />
              </td>
            </tr>
              {request.requestDetail?.requestEnums?.map((enumData, index) => {
                return <tr key={index}>
                    <td></td>
                    <td>
                  <div>
                    <Input
                      value={enumData}
                      className="Body6_R g300 bg-white mb4 input"
                      suffix={
                        <Image
                          path={`${process.env.PUBLIC_URL}/content`}
                          name={"ic_delete"}
                          onClick={() => handleDeleteEnum(index)}
                        />
                      }
                    />
                  </div>
                </td>
                  </tr>
                  })
                }
            </>
          }
          {request?.dataType === 'STRING' &&
            <tr>
              <td className="Body6_B g500">
                <div>정규식</div>
              </td>
              <td>
                <Input
                  value={request.requestDetail?.regular}
                  className="Body6_R g900 bg-white mb4 input"
                  placeholder={"정규식을 입력해주세요"}
                  onChange={(event) => handleRequestDetailOnChange("regular", event.target.value)}
                />
              </td>
            </tr>
          }
        </tbody>
      </table>
    </>
  );
};
export default DataDetailSettingTable;
