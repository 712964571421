// 2024.03.11 [energysteel]: 유저 API 권한 조회 API
export const loadUserApiRoleApi = async (axios, projectId, userId, successCallback, failCallback) => {
    axios
        .get(`/project/${projectId}/user/${userId}/api/role`)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response.data.data);
            } else {
                failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}

// 2024.03.11 [energysteel]: 유저 API 권한 저장 API
export const saveUserApiRolesApi = async (axios, projectId, userId, apiRoleSaveRequest, successCallback, failCallback) => {
    axios
        .post(`/project/${projectId}/user/${userId}/api/role`, apiRoleSaveRequest)
        .then(response => {
            if (response.data.code === "0001") {
                successCallback(response.data.data);
            } else {
                failCallback();
            }
        })
        .catch((error) => {
            // 오류발생시 실행
            console.log(error);
        })
        .then(() => {
            // 항상 실행
        });
}